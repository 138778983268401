import { useContext, createContext, PropsWithChildren } from "react";
import { User } from "../types";
import { useSelector } from "react-redux";
import { getToken, getUser } from "../api/selectors";
import { State } from "../store/State";

interface AuthContextInterface {
  token: string | null;
  user: User | null;
}

const AuthContext = createContext<AuthContextInterface>({
  token: null,
  user: null
});

const AuthProvider = ({ children }: PropsWithChildren) => {
  const token = useSelector(state => getToken(state as State));
  const user = useSelector(state => getUser(state as State));

  return (
    <AuthContext.Provider value={{ token, user }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};