import { Box, Flex, Text } from "@chakra-ui/react";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../utils/colors";
import { FormInput } from "../../components/FormInput";
import { SecurityIn } from "../../types/CraftsManIn";
import { useCallback } from "react";
import { SecurityErrorDataMap } from "../ProfilePage";

const Security = ({
  errors,
  setSecurityData,
  securityData,
}: {
  errors: SecurityErrorDataMap;
  securityData: SecurityIn;
  setSecurityData: (userData: SecurityIn) => void;
}) => {
  const handleSubmit = useCallback((e: any) => {
    e.preventDefault();
  }, []);

  return (
    <Box minWidth={"40rem"} p="4" mx="auto">
      <Text fontSize={18} fontWeight={"bold"} color={Colors.textGray} mb={4}>
        Сигурност
      </Text>

      <form onSubmit={handleSubmit}>
        <Flex justifyContent="space-between">
          <FormInput
            title="Текуща парола"
            type="password"
            value={securityData.oldPassword}
            onChange={(e) => setSecurityData({ ...securityData, oldPassword: e.target.value })}
            placeholder="Текуща парола"
            width="45%"
            hasError={errors["oldPassword"] !== undefined}
            errorMessage={errors["oldPassword"]}
            icon={faLock}
          />

          <FormInput
            title="Нова парола"
            type="password"
            value={securityData.newPassword}
            onChange={(e) => setSecurityData({ ...securityData, newPassword: e.target.value })}
            placeholder="Нова парола"
            width="45%"
            hasError={errors["newPassword"] !== undefined}
            errorMessage={errors["newPassword"]}
            icon={faLock}
          />
        </Flex>
        <Text fontSize={14} color={Colors.textGray} mt={5}>
          * Новата парола трябва да е дълга най-малко 8 символа и да съдържа поне 1 число и един специален
          символ.
        </Text>
      </form>
    </Box>
  );
};

export default Security;
