import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  retry,
} from "@reduxjs/toolkit/query/react";

import { Tags } from "../types";
import { State } from "../store/State";
import { getBaseAPIUrl, getHeaders } from "../store/utils";
import { logOut } from "./actions";
import history from "../utils/history";

const UPLOAD_IMAGE_ENDPOINT = "uploadImage";
const UPLOAD_VIDEO_ENDPOINT = "uploadVideo";

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  WebApi,
  extraOptions
) => {
  const rawBaseQuery = retry(
    fetchBaseQuery({
      baseUrl: getBaseAPIUrl(),
      prepareHeaders: (headers, { getState, endpoint }) => {
        if (endpoint === "getCity" || endpoint === "getLocation") {
          headers.append("Accept-Language", "en-us");
          return;
        }
        return getHeaders(
          headers,
          getState() as State,
          endpoint === UPLOAD_IMAGE_ENDPOINT || endpoint === UPLOAD_VIDEO_ENDPOINT
        );
      },
    })
  );
  const result = await rawBaseQuery(args, WebApi, extraOptions);
  console.log(args);
  if (result.error && result.error.status === 401 && !(args as any)?.url?.includes("profile")) {
    const dispatch = WebApi.dispatch;

    dispatch(logOut());

    localStorage.removeItem("token");
    localStorage.removeItem("user");

    history.replace("/home");
  }
  return result;
};

export const appApi = createApi({
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
  reducerPath: "craft-365",
  refetchOnReconnect: true,
  tagTypes: Object.values(Tags),
});
