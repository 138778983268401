import { Button, IconButton } from "@chakra-ui/react";
import styled from "styled-components";
import { ChakraIcon } from "./ChakraIcon";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Colors from "../utils/colors";
import { useEffect, useState } from "react";


export interface PaginatorProps {
    totalPages: number;
    onPageChanged: (page: number) => void;
    forcePageReset: boolean;
}

function Paginator({ totalPages, onPageChanged, forcePageReset = false }: PaginatorProps) {
    const [page, setPage] = useState(1);
    useEffect(() => {
        setPage(1);
    }, [forcePageReset])
    const changePage = (page: number) => {
        if (page > totalPages || page === 0) {
            return
        }
        setPage(page)
        onPageChanged(page)
    }

    if (totalPages <= 1) return null;

    return (
        <Container>
            <IconButton
                background={"transparent"}
                aria-label='Back to previous page'
                icon={<ChakraIcon icon={faArrowLeft} color={Colors.textGray} />}

                onClick={() => changePage(page - 1)}
            />
            {Array.from({ length: totalPages }, (v, i) => i + 1).map((item, index) => {
                return <Button
                    key={index}
                    color={item === page ? "white" : Colors.textGray}
                    borderRadius={6}
                    backgroundColor={item === page ? Colors.textGray : "transparent"}
                    marginLeft={1}
                    marginRight={1}
                    size={"sm"}
                    onClick={() => changePage(item)}>
                    {item}
                </Button>
            })}

            <IconButton
                background={"transparent"}
                aria-label='Back to previous page'
                icon={<ChakraIcon icon={faArrowRight} color={Colors.textGray} />}
                onClick={() => changePage(page + 1)}
            />
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`

export default Paginator;