import { appApi } from "../../api/rtk-query-init";
import { Tags } from "../../types";
import { ReviewIn } from "../../types/ReviewIn";
import { ServiceRequestApplicationOut } from "../../types/services";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    applyForServiceRequest: builder.mutation<void, ServiceRequestApplicationOut>({
      invalidatesTags: [Tags.SERVICE_REQUESTS],
      query({ serviceRequestId, applicationDescription }) {
        return {
          url: `service-requests/${serviceRequestId}/apply`,
          method: "PUT",
          body: { applicationDescription },
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    acceptServiceApplication: builder.mutation<void, string>({
      query: (serviceApplicationId: string) => ({
        url: `services/application/${serviceApplicationId}/confirm`,
        method: "PUT",
      }),
      invalidatesTags: [Tags.SERVICES],
    }),
    declineServiceApplication: builder.mutation<void, string>({
      query: (serviceApplicationId: string) => ({
        url: `services/application/${serviceApplicationId}/decline`,
        method: "PUT",
      }),
      invalidatesTags: [Tags.SERVICES],
    }),
    completeServiceApplication: builder.mutation<void, string>({
      query: (serviceApplicationId: string) => ({
        url: `services/application/${serviceApplicationId}/complete`,
        method: "PUT",
      }),
      invalidatesTags: [Tags.APPLIED_SERVICES],
    }),
    leaveReviewForASerivce: builder.mutation<void, { serviceApplicationId: string; review: ReviewIn }>({
      query: ({ serviceApplicationId, review }) => ({
        url: `services/application/${serviceApplicationId}/review`,
        method: "PUT",
        body: review,
      }),
      invalidatesTags: [Tags.APPLIED_SERVICES],
    }),
    getReviewForAServiceApplication: builder.query<ReviewIn, string>({
      query: (serviceRequestApplicationId: string) => ({
        url: `craftsmen/reviews/service-application/${serviceRequestApplicationId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useApplyForServiceRequestMutation,
  useAcceptServiceApplicationMutation,
  useDeclineServiceApplicationMutation,
  useCompleteServiceApplicationMutation,
  useLeaveReviewForASerivceMutation,
  useGetReviewForAServiceApplicationQuery,
} = extendedApi;

export default extendedApi;
