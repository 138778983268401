import { Box, Flex, Progress } from "@chakra-ui/react";
import Colors from "../../utils/colors";

const StepButton = ({ step, currentStep, title }: { step: number; currentStep: number; title: string }) => (
  <Box
    as="button"
    paddingY={2}
    w="md"
    bg={currentStep === step ? Colors.darkPurple : "transparent"}
    color={currentStep === step ? "white" : "gray.600"}
    borderWidth={2}
    borderRadius="30px"
    fontWeight="bold"
  >
    {step}. {title}
  </Box>
);

const RegistrationStepProgress = ({ currentStep }: { currentStep: number }) => {
  return (
    <Box width="100%" mx="auto">
      <Flex mb={4} align="center" justifyContent="space-between">
        <StepButton step={1} currentStep={currentStep} title={"Данни за вход"} />
        <Box backgroundColor={Colors.darkGray} width={10} height={0.5} mx={1} />
        <StepButton step={2} currentStep={currentStep} title={"Данни за профил"} />
        <Box backgroundColor={Colors.darkGray} width={10} height={0.5} mx={1} />
        <StepButton step={3} currentStep={currentStep} title={"Публикуване на услуга"} />
      </Flex>

      <Progress value={(currentStep - 1) * 50} borderRadius={4} size="sm" colorScheme="green" mb={4} />
    </Box>
  );
};

export default RegistrationStepProgress;
