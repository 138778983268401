import { Checkbox, Flex, Image, Text } from "@chakra-ui/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Colors from "../utils/colors";
import star from "../assets/star.svg";
import { useState } from "react";


interface RatingDropdownComponentProps {
    isSelected: boolean;
    onClick: (id: string, isSelected: boolean) => void;
    onSelected: (rating: number) => void;
}


function RatingDropdownComponent({ isSelected = false, onClick, onSelected }: RatingDropdownComponentProps) {

    const [rating, setRating] = useState(0);
    const ratings = [0, 1, 2, 3, 4, 5];


    return (
        <Flex flexDir={"column"}>
            <Flex height={"70px"}
                onClick={() => {
                    onClick("rating", isSelected);
                }}
                cursor={"pointer"}
                backgroundColor={Colors.dirtyWhite}
                borderRadius={"10px"}
                alignItems={"center"}
                justifyContent={"space-between"}
                padding={"16px"}
                marginBottom={"8px"}
            >
                <Flex flexDir={"row"}>
                    <Image src={star} width={"32px"} height={"32px"} marginRight={"16px"} />
                    <Text fontWeight={"700"} style={{ fontSize: 18, color: Colors.textGray }}>Оценка</Text>
                </Flex>
                <FontAwesomeIcon icon={faChevronDown} />
            </Flex>
            {isSelected &&
                <Flex flexDir={"column"}
                    backgroundColor={Colors.dirtyWhite}
                    padding={"16px"}
                    marginBottom={"8px"}
                    borderRadius={"10px"}>
                    {ratings.map(rating => {
                        return <Checkbox
                            colorScheme={"gray"}
                            variant={"darkBorder"}
                            iconColor={"black"}
                            spacing={3} size="lg"
                            marginBottom={"8px"}
                            onChange={(e) => onSelected(rating)}
                            >
                            <Text fontSize={14} align="left">{rating}</Text>
                            {/* TODO add the translations and call them via lang[translationKey]*/}
                        </Checkbox>
                    })}
                </Flex>
            }
        </Flex>
    )
}

export default RatingDropdownComponent;