import { Grid, GridItem, Flex, Box, Text } from "@chakra-ui/react";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TopBar } from "./TopNavigationBar";
import { StatusButton } from "./ApplicationStatusButton";
import { AppliedServiceOut, ApplicationStatus } from "./types/AppliedServiceOut";
import { useNavigate } from "react-router-dom";
import {
  isServiceInProgress,
  isServiceRequested,
  isServiceCompleted,
  isServiceNotRequested,
} from "../utils/serviceRequests";
import { useGetMyAppliedServiceRequestsQuery } from "./api/ServiceRequests";
import { AppliedServiceRequestCard } from "./components/AppliedServiceRequestCard";
import { AppliedServiceRequestOut } from "./types/AppliedServiceRequestOut";
import ServiceRequestChat from "./components/ServiceRequestChat";

export enum AppliedServiceRequestSelectionStatus {
  ALL,
  REQUESTED,
  ACTIVE,
  COMPLETED,
  DECLINED,
}

function MyAppliedServiceRequestsPage() {
  const [selectedStatus, setSelectedStatus] = useState<AppliedServiceRequestSelectionStatus>(
    AppliedServiceRequestSelectionStatus.REQUESTED
  );
  const [selectedService, setSelectedService] = useState<AppliedServiceRequestOut | undefined>();

  const { data: serviceRequests, refetch } = useGetMyAppliedServiceRequestsQuery();
  const navigate = useNavigate();

  const activeChatId = useMemo(() => {
    if (selectedService) {
      if (isServiceInProgress(selectedService)) {
        const result = selectedService.applications.find((s) => s.status === ApplicationStatus.IN_PROGRESS);
        return result?.chatRoomId;
      }
      if (isServiceRequested(selectedService)) {
        const result = selectedService.applications.find((s) => s.status === ApplicationStatus.IN_PROGRESS);
        return result?.chatRoomId;
      }
      if (isServiceCompleted(selectedService)) {
        const result = selectedService.applications.find((s) =>
          [ApplicationStatus.COMPLETED, ApplicationStatus.REVIEWED].includes(s.status)
        );
        return result?.chatRoomId;
      }
    }

    return [...(selectedService?.applications ?? [])]?.sort(
      (s1, s2) => Date.parse(s2.createdAt) - Date.parse(s1.createdAt)
    )?.[0]?.chatRoomId;
  }, [selectedService]);

  const filteredServices = useMemo(() => {
    return serviceRequests?.filter((service: AppliedServiceOut) => {
      if (selectedStatus === AppliedServiceRequestSelectionStatus.REQUESTED)
        return service.applications.find((s) => s.status === ApplicationStatus.REQUESTED);

      if (selectedStatus === AppliedServiceRequestSelectionStatus.ACTIVE)
        return service.applications.find((s) => s.status === ApplicationStatus.IN_PROGRESS);

      if (selectedStatus === AppliedServiceRequestSelectionStatus.COMPLETED) {
        return service.applications.find((s) =>
          [ApplicationStatus.COMPLETED, ApplicationStatus.REVIEWED].includes(s.status)
        );
      }

      if (selectedStatus === AppliedServiceRequestSelectionStatus.DECLINED) {
        return service.applications.find((s) => s.status === ApplicationStatus.DECLINED);
      }
      return false;
    });
  }, [selectedStatus, serviceRequests]);

  const onSelectService = useCallback(
    (serviceRequest: AppliedServiceRequestOut) => {
      if (!isServiceNotRequested(serviceRequest)) {
        setSelectedService(serviceRequest);
      } else {
        navigate(`/service-request/${serviceRequest.id}`);
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (selectedService) {
      const service = serviceRequests?.find((s: AppliedServiceOut) => s.id === selectedService.id);
      setSelectedService(service);
    }
  }, [serviceRequests, selectedService]);

  return (
    <Container>
      <TopBar />
      <ServiceRequestChat
        isOpen={!!selectedService}
        onClose={() => {
          setSelectedService(undefined);
        }}
        serviceRequest={selectedService}
        onChangeStatus={refetch}
        activeChatId={activeChatId}
      />
      <Grid templateColumns="repeat(20, 1fr)" gap={6} w={"1300px"} mt={6}>
        <GridItem colSpan={4}>
          <Flex direction={"column"} alignItems={"left"} maxW={"95%"}>
            <StatusButton
              title="Заявени"
              selected={selectedStatus === AppliedServiceRequestSelectionStatus.REQUESTED}
              onClick={() => setSelectedStatus(AppliedServiceRequestSelectionStatus.REQUESTED)}
            />
            <StatusButton
              title="Активни"
              selected={selectedStatus === AppliedServiceRequestSelectionStatus.ACTIVE}
              onClick={() => setSelectedStatus(AppliedServiceRequestSelectionStatus.ACTIVE)}
            />
            <StatusButton
              title="Завършени"
              selected={selectedStatus === AppliedServiceRequestSelectionStatus.COMPLETED}
              onClick={() => setSelectedStatus(AppliedServiceRequestSelectionStatus.COMPLETED)}
            />
            <StatusButton
              title="Отказани"
              selected={selectedStatus === AppliedServiceRequestSelectionStatus.DECLINED}
              onClick={() => setSelectedStatus(AppliedServiceRequestSelectionStatus.DECLINED)}
            />
          </Flex>
        </GridItem>
        <GridItem colSpan={1} />
        <GridItem colSpan={15}>
          {filteredServices && filteredServices.length ? (
            <Box flex={1}>
              {filteredServices?.map((serviceRequest: AppliedServiceRequestOut) => (
                <AppliedServiceRequestCard
                  key={serviceRequest.id}
                  serviceRequestApplication={serviceRequest}
                  selectedStatus={selectedStatus}
                  onClick={() =>
                    selectedStatus === AppliedServiceRequestSelectionStatus.REQUESTED
                      ? navigate(`/service-request/${serviceRequest.id}`)
                      : onSelectService(serviceRequest)
                  }
                />
              ))}
            </Box>
          ) : (
            <Text w="75%" fontSize={20} pt={20} textAlign={"center"}>
              Нямате все още заявени услуги
            </Text>
          )}
        </GridItem>
      </Grid>
    </Container>
  );
}

const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default MyAppliedServiceRequestsPage;
