import { appApi } from "../../api/rtk-query-init";
import { Tags } from "../../types";
import { getBaseAPIUrl } from "../../store/utils";



const extendedApi = appApi.injectEndpoints({
    endpoints: builder => ({
        loginWithFacebook: builder.query<{ payload: { token: string } }, { token: string }>({
            query: ({ token }) => ({
                url: `${getBaseAPIUrl()}users/authenticateWithFacebook`,
                method: "POST",
                body: { token }
            }),
            providesTags: [Tags.SERVICES],
        }),
        loginWithGoogle: builder.query<{ payload: { token: string } }, { token: string }>({
            query: ({ token }) => ({
                url: `${getBaseAPIUrl()}users/authenticateWithGoogle`,
                method: "POST",
                body: { token }
            }),
            providesTags: [Tags.SERVICES],
        }),
    }),
});

export const { useLazyLoginWithGoogleQuery, useLazyLoginWithFacebookQuery } = extendedApi;

export default extendedApi;