import { FetchArgs } from "@reduxjs/toolkit/query";
import { appApi } from "../../api/rtk-query-init";
import { ListResponse, Tags } from "../../types";
import { Article } from "../types/Article";
import { ArticleFilterQuery } from "../types/ArticleFilterQuery";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    requestArticlesList: builder.query<ListResponse<Article>, ArticleFilterQuery | undefined>({
      providesTags: [Tags.ARTICLES],
      query: (query: ArticleFilterQuery = { page: 1 }): FetchArgs => ({
        url: "articles/query",
        method: "POST",
        body: { query },
      }),
    }),
    requestArticleDetails: builder.query<Article, string>({
      providesTags: [Tags.ARTICLES],
      query: (id: string) => `articles/${id}`,
    }),
  }),
});

export const { useRequestArticlesListQuery, useRequestArticleDetailsQuery } = extendedApi;

export default extendedApi;
