import React, { useMemo } from "react";
import { Box, Text, Image, Flex, SimpleGrid, Avatar } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import ArticlesCard from "./ArticlesCard";
import remont from "../../assets/remont.svg";
import calendar from "../../assets/calendar.svg";
import Spacer from "../../components/Spacer";
import Colors from "../../utils/colors";
import { useRequestArticleDetailsQuery, useRequestArticlesListQuery } from "../api/articles";
import { getDateDisplayShort } from "../../utils/date";
import background from "../../assets/background-service.svg";
import DOMPurify from "dompurify";

function SingleArticlePage() {
  let { id } = useParams();
  const { data: article } = useRequestArticleDetailsQuery(id ?? "", { skip: !id });
  const { data: articles } = useRequestArticlesListQuery(
    { categoryId: article?.categoryId },
    { skip: !article?.categoryId }
  );

  const { title, image, tags, content } = article ?? {};

  const titles = useMemo(() => {
    const node = document.createRange().createContextualFragment(content ?? "");
    return Array.from(node.querySelectorAll("h1, h2, h3, h4, h5, h6")).map((n) => n.innerHTML);
  }, [content]);

  return (
    <>
      <Flex
        width="99.5dvw"
        paddingX={"10%"}
        maxWidth={"99.5dvw"}
        mt={4}
        height={100}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom={7}
        backgroundImage={background}
      >
        <Text fontSize={36} as={"b"}>
          Статии
        </Text>
        <Flex gap={"1"} alignItems="center" flexWrap="wrap">
          <Avatar size={"md"} name={article?.author.name} src={article?.author.image} />
          <Flex flexDir={"column"}>
            <Text fontFamily={"Inter"} fontSize={"12px"} as={"b"} color={Colors.textGray} marginLeft={"4px"}>
              {article?.author.name}
            </Text>
            <Text fontFamily={"Inter"} fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
              Автор
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex w={"100%"} flexDirection={"row"}>
        <Flex w={"20%"} flexDirection={"column"} marginTop={4}>
          {titles.map((title, index) => (
            <Text
              fontFamily={"Inter"}
              fontSize={16}
              fontWeight={"bold"}
              marginBottom={7}
              cursor={"pointer"}
              // TODO: calculate the exact position
              onClick={() => window.scrollTo({ top: (index + 1) * 300 })}
            >
              {title}
            </Text>
          ))}
        </Flex>
        <Flex w={"80%"} flexDirection={"column"}>
          <Text fontSize={28} fontWeight={"bold"}>
            {title}
          </Text>
          <Flex mt={1} mb={3}>
            <Image src={remont} width={"18px"} height={"18px"} marginRight={"4px"} />
            {tags?.map((tag) => (
              <Text fontFamily={"Inter"} fontSize={12} textColor={Colors.textGray} marginRight={"4px"}>
                {tag}
              </Text>
            ))}

            <Image src={calendar} width={"18px"} height={"18px"} marginRight={"4px"} marginLeft={"16px"} />
            {article && (
              <Text fontFamily={"Inter"} fontSize={12} textColor={Colors.textGray}>
                {getDateDisplayShort(new Date(Date.parse(article?.createdAt || "")))}
              </Text>
            )}
          </Flex>
          <Spacer />

          <Box bg="white" borderRadius={6}>
            <Box flex={1}>
              <Image height={"30rem"} width={"100%"} borderRadius={6} objectFit="contain" src={image} />
              <Box padding={6} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content ?? "") }} />
            </Box>
          </Box>
          <Spacer size={70} />
          <Text fontFamily={"Inter"} fontSize={24}>
            Подобни статии
          </Text>
          <Spacer />
          <SimpleGrid spacing={4} templateColumns="repeat(auto-fill, minmax(20rem, 1fr))">
            {articles?.items
              .filter(({ id: articleId }) => id !== articleId)
              .map(({ id, title, image, author }) => {
                return <ArticlesCard id={id.toString()} title={title} img={image} author={author} />;
              })}
          </SimpleGrid>
          <Spacer size={100} />
        </Flex>
      </Flex>
    </>
  );
}

export default SingleArticlePage;
