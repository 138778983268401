import { Box, Text, Flex, Image } from "@chakra-ui/react";

import pin from "../../assets/pin.svg";
import remont from "../../assets/remont.svg";
import Spacer from "../../components/Spacer";
import Colors from "../../utils/colors";
import { ChakraIcon } from "../../components/ChakraIcon";
import { faHardHat, faUser } from "@fortawesome/free-solid-svg-icons";

function RequestServiceHeader({
  title,
  image,
  city,
  categories,
  craftsMan,
  user,
}: {
  title: string;
  image: string;
  city: string;
  categories: string;
  craftsMan?: { id: string; name: string; image?: string };
  user?: { id: string; name: string; image?: string };
}) {
  return (
    <>
      <Box bg={Colors.lightGray} borderTopRadius={25} paddingLeft={"30px"} paddingRight={"30px"}>
        <Spacer />
        <Text
          as={"b"}
          fontSize={24}
          fontFamily={"Inter"}
          w={"100%"}
          textAlign={"center"}
          display={"block"}
          color={Colors.textGray}
        >
          Заявка за услуга
        </Text>
        <Spacer />
      </Box>

      <Flex
        flexDir="row"
        w={"100%"}
        paddingTop={5}
        paddingBottom={5}
        paddingLeft={"30px"}
        paddingRight={"30px"}
      >
        <Image w={150} h={100} borderRadius={10} marginRight={"20px"} src={image} />
        <Flex flexDir="column">
          <Text as={"b"} fontSize={18} fontFamily={"Inter"} color={"black"}>
            {title}
          </Text>
          <Flex flexDir={"row"} alignItems={"center"}>
            {(craftsMan || user) && (
              <Flex flexDir={"row"} alignItems={"center"} mr={3}>
                <Image borderRadius="full" boxSize={"32px"} src={(craftsMan ?? user)?.image} />
                <Text
                  as={"b"}
                  fontSize={"14px"}
                  color={Colors.textGray}
                  marginLeft={"4px"}
                  marginRight={"4px"}
                >
                  {craftsMan?.name ?? user?.name}
                </Text>
                <ChakraIcon icon={craftsMan ? faHardHat : faUser} color={Colors.textGray} size={3} />
              </Flex>
            )}
            <Image src={pin} color={Colors.textGray} width={"12px"} height={"16px"} />
            <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
              {city}
            </Text>
            <Spacer horizontal />
            <Image src={remont} color={Colors.textGray} width={"16px"} height={"16px"} />
            <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
              {categories}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

export default RequestServiceHeader;
