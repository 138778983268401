import { Flex, Text, Textarea } from "@chakra-ui/react";
import Colors from "../utils/colors";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { HTMLInputTypeAttribute, useCallback, useRef } from "react";
import InputWithIcon from "./InputWithIcon";

export const FormInput = ({
  title,
  value,
  isMandatory = true,
  onChange,
  width,
  placeholder,
  hasError,
  errorMessage,
  icon,
  type = "text",
  iconPosition = "left",
  isReadOnly,
}: {
  title: string;
  isMandatory?: boolean;
  onChange: (e: React.ChangeEvent<{ value: string }>) => void;
  width?: string;
  placeholder?: string;
  hasError?: boolean;
  value?: string;
  errorMessage?: string;
  icon?: IconDefinition;
  type?: HTMLInputTypeAttribute | "text-area";
  iconPosition?: "left" | "right";
  isReadOnly?: boolean;
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleSelectImage = useCallback(() => {
    fileInputRef?.current?.click();
  }, []);

  return (
    <Flex flexDir={"column"} w={width ?? undefined}>
      <Flex flexDir={"row"}>
        <Text fontSize={12} fontFamily={"Inter"} as="b" mb={1}>
          {title}
          {isMandatory && "*"}
        </Text>
        {!isMandatory && (
          <Text fontSize={12} ml={1} fontFamily={"Inter"} color={Colors.lightTextGray}>
            (незадължително)
          </Text>
        )}
      </Flex>
      {type === "image" && (
        <input accept="image/*" type="file" hidden ref={fileInputRef} onChange={onChange}></input>
      )}
      {type !== "text-area" ? (
        <InputWithIcon
          icon={icon}
          value={value}
          type={type !== "image" ? type : "text"}
          onClick={type === "image" ? handleSelectImage : undefined}
          isReadOnly={isReadOnly || type === "image"}
          onChange={onChange}
          iconPosition={iconPosition}
          borderColor={hasError ? Colors.inputError : Colors.borderGray}
          border={"1px solid"}
          borderRadius={"50px"}
          size="lg"
          paddingLeft={"40px"}
          backgroundColor={"white"}
          placeholder={placeholder}
          color={Colors.textGray}
          fontSize={14}
          label={placeholder}
        />
      ) : (
        <Textarea
          value={value}
          onChange={onChange}
          borderColor={Colors.borderGray}
          size="lg"
          resize={"none"}
          backgroundColor={"white"}
          placeholder={placeholder}
        />
      )}
      {hasError && (
        <Text color="red.500" fontSize="sm" mt={2}>
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
};
