import { Flex, Text, Image, Box, RadioGroup, Radio, SimpleGrid } from "@chakra-ui/react";
import background from "../assets/background-service.svg";
import styled from "styled-components";
import { useAuth } from "../utils/AuthProvider";
import { CraftButton } from "../components/CraftButton";
import { useMemo, useState } from "react";
import Colors from "../utils/colors";
import { FaCalendarAlt, FaCoins } from "react-icons/fa";
import { useGetTransactionsQuery } from "./api/transactions";
import { useNavigate } from "react-router-dom";

export const BudgetPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [selectedAmount, setSelectedAmount] = useState<string | null>(null);
  const { data: transactions } = useGetTransactionsQuery();

  const transactionHistory = useMemo(() => {
    return transactions?.map((transaction) => ({
      date: transaction.createdAt.toISOString().split("T")[0],
      id: transaction.paymentIntentId,
      amount: transaction.amount + "лв.", //TODO: fix this
      status: transaction.status,
    }));
  }, [transactions]);

  return (
    <HeaderContainer className="container">
      <Flex
        width="99.5dvw"
        paddingX={"10%"}
        maxWidth={"99.5dvw"}
        mt={4}
        height={100}
        backgroundImage={background}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text fontSize={24} fontWeight={"bold"}>
          Баланс
        </Text>
        <Flex direction="row" alignItems="center">
          <Image
            borderRadius="full"
            width={"4rem"}
            mr={3}
            src={user?.image?.url}
            boxSize="4rem"
            alt="craftsMan"
          />
          <Text fontWeight="bold" fontSize={16}>
            {user?.firstName} {user?.lastName}
          </Text>
        </Flex>
      </Flex>
      <Flex width={"100%"} justifyContent={"space-between"} mt={5} p={10}>
        <Box
          backgroundColor={Colors.lightGray}
          height={"15rem"}
          borderRadius={10}
          padding={25}
          width={"20rem"}
          flex={1}
        >
          {/* Current Balance */}
          <Text fontSize={18} fontWeight="bold">
            Наличност:
          </Text>
          <Text fontSize={32} fontWeight="bold" color="green.500">
            {user?.balance} лв.
          </Text>
          <Text fontSize={14} color="gray.600" mt={2}>
            <strong>ВАЖНО:</strong> Тази сума ви е нужна, за да може да правите определен брой заявки по обяви
            на клиенти, както и да отговаряте на запитвания за ваши услуги. Може да презареждате с нова сума
            по всяко време.
          </Text>
        </Box>

        <Flex flexDirection={"column"} flex={4} alignItems={"center"}>
          <Box backgroundColor={Colors.lightGray} borderRadius={10} padding={25} width={"70rem"}>
            <Text fontSize={18} fontWeight="bold" mb={1}>
              Зареди баланс
            </Text>
            <Text mb={6}>Изберете сума, с която да заредите своя баланс</Text>

            <RadioGroup onChange={setSelectedAmount} value={selectedAmount ?? ""}>
              <SimpleGrid columns={2} spacing={3}>
                {[20, 50, 100, 150, 200, 300].map((amount) => (
                  <Box
                    key={amount}
                    display="flex"
                    alignItems="center"
                    borderRadius="full"
                    backgroundColor="white"
                    padding={4}
                    boxShadow="md"
                    width="100%"
                    cursor="pointer"
                  >
                    <Radio value={amount.toString()}>
                      <Text fontSize={16} fontWeight="bold">
                        Зареди с {amount}лв.
                      </Text>
                    </Radio>
                  </Box>
                ))}
              </SimpleGrid>
            </RadioGroup>
          </Box>

          <Flex justifyContent="flex-end" mt={5} width={"50rem"} mb={7}>
            <CraftButton
              title={"Извърши плащане"}
              onClick={() => navigate(`/payments?amount=${selectedAmount}`)}
            />
          </Flex>

          <Flex
            flexDirection={"column"}
            backgroundColor={Colors.lightGray}
            borderRadius={10}
            padding={25}
            width={"70rem"}
            maxHeight={"40rem"}
            overflow={"auto"}
            mb={4}
          >
            <Text fontSize={20} fontWeight="bold" mb={4}>
              История на плащанията
            </Text>
            {transactionHistory?.map((transaction, index) => (
              <Flex
                key={index}
                backgroundColor="white"
                padding={4}
                borderRadius={10}
                alignItems="center"
                justifyContent="space-between"
                mb={3}
                width="100%"
              >
                <Flex flex={1} align="center">
                  <FaCalendarAlt size={20} style={{ marginRight: "10px" }} />
                  <Text fontSize={16}>
                    <strong>Дата:</strong> {transaction?.date}
                  </Text>
                </Flex>

                <Flex flex={2} justifyContent="center" align="center">
                  <Text fontSize={16} textAlign="center">
                    <strong>ID на транзакцията:</strong> {transaction.id}
                  </Text>
                </Flex>

                <Flex flex={1} align="center" justifyContent="flex-end" minWidth={"80px"}>
                  <FaCoins size={20} style={{ marginRight: "5px" }} />
                  <Text fontSize={16} fontWeight="bold">
                    Сума: {transaction.amount}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  width: "100%";
`;
