import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../utils/AuthProvider";

const PrivateRoute = () => {
    const user = useAuth();
    // TODO get token from localstorage if redux is null

    if (!user.token && !localStorage.getItem("token")) {
        return <Navigate to="/home" />;
    }
    return <Outlet />;
};

export default PrivateRoute;