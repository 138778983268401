import { Box, Text, Grid, GridItem, Flex } from "@chakra-ui/react";
import Spacer from "../components/Spacer";
import styled from "styled-components";
import Colors from "../utils/colors";
import IconWithText from "../components/IconWithText";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import { useLanguage } from "../utils/useLanguage";
import { useCallback, useMemo, useState } from "react";
import { User } from "../types";
import { useLazyCheckEmailQuery, useRegisterCraftsmanMutation } from "./api/register";
import { useNavigate } from "react-router-dom";
import RegistrationStepProgress from "./components/RegistrationProgress";
import EmailPassword from "./components/EmailPassword";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { CraftButton } from "../components/CraftButton";
import ProfileData from "./components/ProfileData";
import { PublishServiceForm } from "../publishService/components/PublishServiceForm";
import { usePublishServiceMutation } from "../publishService/api/PublishService";
import { CraftsManIn } from "../types/CraftsManIn";
import { step1Schema, step2Schema } from "./utils/validations";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../api/reducers";
import { ServiceIn } from "../types/ServiceIn";

const NUMBER_OF_STEPS = 3;

export type ErrorDataMap = {
  [key in keyof CraftsManIn]?: string;
};

const Registration = () => {
  const lang = useLanguage();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // state
  const [errors, setErrors] = useState<ErrorDataMap>({});
  const [userData, setUserData] = useState<CraftsManIn>({} as CraftsManIn);
  const [serviceData, setServiceData] = useState<ServiceIn>({} as ServiceIn);
  const [serviceErrors, setServiceErrors] = useState<{ [key: string]: string }>({});

  const [currentStep, setCurrentStep] = useState(1);

  // queries
  const [publishServiceCall] = usePublishServiceMutation();
  const [register] = useRegisterCraftsmanMutation();
  const [checkEmail] = useLazyCheckEmailQuery();

  const validateForm = useCallback(() => {
    let newErrors: { [key: string]: string } = {};

    if (!serviceData?.title?.trim()) newErrors.title = "Заглавието е задължително";
    if (!serviceData?.categoryId) newErrors.category = "Трябва да изберете поне една категория";
    if (!serviceData?.city) newErrors.city = "Градът е задължителен";
    if (!serviceData?.description?.trim()) newErrors.description = "Описанието е задължително";

    setServiceErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [serviceData?.categoryId, serviceData?.city, serviceData?.description, serviceData?.title]);

  const registerUser = useCallback(() => {
    register(userData)
      .unwrap()
      .then((res: { payload: { user: User; token: string } }) => {
        dispatch(setToken(res?.payload.token));
        dispatch(setUser(res?.payload?.user));
        setCurrentStep(currentStep + 1);
      });
  }, [currentStep, dispatch, register, userData]);

  const handleNextStep = useCallback(() => {
    let schema;
    if (currentStep === 1) {
      schema = step1Schema;
    } else if (currentStep === 2) {
      schema = step2Schema;
    } else if (currentStep === 3) {
      if (serviceData?.title) {
        if (!validateForm()) return;

        publishServiceCall(serviceData)
          .unwrap()
          .then((res) => {
            navigate("/home");
          });
      } else {
        navigate("/home");
      }
      return;
    }

    if (schema) {
      schema
        .validate(userData, { abortEarly: false })
        .then(() => {
          // Check if email is already registered
          checkEmail(userData.email)
            .unwrap()
            .then(({ available }) => {
              if (!available) {
                setErrors({ email: "Този емейл вече е зает" });
                return;
              } else {
                // Clear errors if validation passes
                setErrors({});
                // If it's the final mandatory step, trigger registration; otherwise, go to next step
                if (currentStep === NUMBER_OF_STEPS - 1) {
                  registerUser();
                } else {
                  setCurrentStep(currentStep + 1);
                }
              }
            });
        })
        .catch((err: Yup.ValidationError) => {
          const validationErrors: ErrorDataMap = err.inner.reduce((acc: ErrorDataMap, curr) => {
            if (curr.path) {
              acc[curr.path as keyof CraftsManIn] = curr.message;
            }
            return acc;
          }, {});
          setErrors(validationErrors);
        });
    }
  }, [
    checkEmail,
    currentStep,
    navigate,
    publishServiceCall,
    registerUser,
    serviceData,
    userData,
    validateForm,
  ]);

  const handlePrevStep = useCallback(() => {
    if (currentStep > 1 && currentStep < NUMBER_OF_STEPS) {
      setCurrentStep(currentStep - 1);
    }
  }, [currentStep]);

  const tips = lang.registrationTips;

  const renderedStep = useMemo(() => {
    if (currentStep === 1) {
      return <EmailPassword errors={errors} userData={userData} setUserData={setUserData} />;
    }
    if (currentStep === 2) {
      return (
        <ProfileData
          errors={errors}
          userData={userData}
          setUserData={setUserData as any}
          profileMode={"craftsman"}
        />
      );
    }
    if (currentStep === 3) {
      return (
        <>
          <Text fontSize={18} ml={7} fontWeight={"bold"} color={Colors.textGray} mb={4}>
            Добави първата си обява (незадължително)
          </Text>

          <PublishServiceForm service={serviceData} setService={setServiceData} errors={serviceErrors} />
        </>
      );
    }
  }, [currentStep, errors, serviceData, serviceErrors, userData]);

  const canGoBack = currentStep > 1 && currentStep < NUMBER_OF_STEPS;

  return (
    <Container>
      <Grid templateColumns="repeat(20, 1fr)" gap={6}>
        <GridItem colSpan={6}>
          <Flex direction={"column"} alignItems={"left"} maxW={"95%"}>
            <Spacer />
            <Text as="b" fontSize={18} width={"100%"} textAlign={"left"} fontFamily={"Inter"}>
              {tips.publish_tips1}
            </Text>
            <Spacer />
            <IconWithText icon={faLightbulb} text={tips.publish_tips2} />
            <Spacer />
            <Text as="b" fontSize={18} width={"100%"} textAlign={"left"} fontFamily={"Inter"}>
              {tips.publish_tips3}
            </Text>
            <Spacer />
            <IconWithText icon={faLightbulb} text={tips.publish_tips4} />
            <Spacer />
            <Spacer />
            <Text as="b" fontSize={18} width={"100%"} textAlign={"left"} fontFamily={"Inter"}>
              {tips.publish_tips5}
            </Text>
            <Spacer />
            <IconWithText icon={faLightbulb} text={tips.publish_tips6} />
            <Spacer />
          </Flex>
        </GridItem>
        <GridItem colSpan={1} />
        <GridItem colSpan={13}>
          <RegistrationStepProgress currentStep={currentStep} />
          <Box
            padding={6}
            borderRadius={25}
            paddingLeft={"30px"}
            paddingRight={"30px"}
            backgroundColor={Colors.lightGray}
          >
            {renderedStep}
          </Box>
          <Flex w="100%" justifyContent={!canGoBack ? "flex-end" : "space-between"} mt={7}>
            {canGoBack && (
              <CraftButton iconPosition="left" title="Назад" onClick={handlePrevStep} icon={faArrowLeft} />
            )}

            <CraftButton
              iconPosition="right"
              title={currentStep === 2 ? "Регистрация" : "Продължи"}
              onClick={handleNextStep}
              icon={faArrowRight}
            />
          </Flex>
        </GridItem>
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem 0px;
`;

export default Registration;
