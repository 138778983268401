import { Modal, ModalOverlay, ModalContent, IconButton } from "@chakra-ui/react";
import { FaTimes } from "react-icons/fa";

export const VideoPlayer = ({
  url,
  isPlayerOpen,
  closePlayer,
}: {
  url: string;
  isPlayerOpen: boolean;
  closePlayer: () => void;
}) => (
  <Modal isOpen={isPlayerOpen} onClose={closePlayer} size="full">
    <ModalOverlay />
    <ModalContent bg="black" display="flex" justifyContent="center" alignItems="center">
      <IconButton
        aria-label="Close video player"
        icon={<FaTimes />}
        onClick={closePlayer}
        position="absolute"
        top="20px"
        right="20px"
        zIndex={1}
        background="rgba(255, 255, 255, 0.2)"
        _hover={{ background: "rgba(255, 255, 255, 0.4)" }}
        size="lg"
        color="white"
      />
      <video
        src={url}
        controls
        autoPlay
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
      />
    </ModalContent>
  </Modal>
);
