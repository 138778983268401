import { Box, Button, Flex, IconButton, Input } from "@chakra-ui/react";
import { FaPlus, FaPaperPlane } from "react-icons/fa";
import Colors from "../../utils/colors";

interface MessageInputProps {
  currMessage: string;
  onMessageChange: (message: string) => void;
  onSendMessage: () => void;
  onSelectImage: () => void;
  onComplete?: () => void;
  showCompleteButton?: boolean;
}

const MessageInput = ({
  currMessage,
  onMessageChange,
  onSendMessage,
  onSelectImage,
  onComplete,
  showCompleteButton,
}: MessageInputProps) => (
  <Flex paddingTop={2}>
    <IconButton
      aria-label="Select image or video"
      icon={<FaPlus />}
      onClick={onSelectImage}
      background="transparent"
      size="sm"
      color={Colors.textGray}
    />
    <Flex
      borderRadius={25}
      backgroundColor={Colors.lightBorderGray}
      w="75%"
      alignItems="center"
      h="30px"
      mr={4}
      pr={2}
      justifyContent="space-between"
    >
      <Input
        h="30px"
        fontSize="12px"
        color={Colors.textGray}
        placeholder="Напиши съобщение"
        borderRadius={25}
        w={showCompleteButton ? "65%" : "90%"}
        value={currMessage}
        focusBorderColor="transparent"
        onKeyDown={(e) => e.key === "Enter" && onSendMessage()}
        onChange={(e) => onMessageChange(e.target.value)}
      />
      <Box cursor="pointer" onClick={onSendMessage} left={-2} position={"relative"}>
        <FaPaperPlane color={Colors.textGray} size={18} />
      </Box>
    </Flex>
    {showCompleteButton && (
      <Button
        w={"150px"}
        h="30px"
        alignSelf="center"
        backgroundColor={Colors.purple}
        color="white"
        fontFamily={"Inter"}
        fontSize="13px"
        borderRadius={50}
        onClick={onComplete}
      >
        Завърши заявкa
      </Button>
    )}
  </Flex>
);

export default MessageInput;
