import { State } from "../store/State";
import { User } from "../types";
import reducer from "./reducers";

const getApiState = (state: State): ReturnType<typeof reducer> => state.api;

export const getToken = (state: State): string | null => getApiState(state).token;
export const getUser = (state: State): User | null => getApiState(state).user;

export const isLoggedIn = (state: State): boolean => getToken(state) !== null;
