import { Flex, Box, Text, Image, Button } from "@chakra-ui/react";

import Colors from "../../utils/colors";

import Spacer from "../../components/Spacer";
import pin from "../../assets/pin.svg";
import remont from "../../assets/remont.svg";
import editPen from "../../assets/editPen.svg";
import preview from "../../assets/preview.svg";
import deleteIcon from "../../assets/delete.svg";
import { ApplicationStatus } from "../types/AppliedServiceOut";
import { PublishedServiceRequest } from "../../types/PublishedServiceRequest";
import DOMPurify from "dompurify";
import placeholder from "../../assets/placeholder.svg";

export const ServiceRequestCard = ({
  serviceRequest,
  selectedStatus,
  onDelete,
  onEdit,
  onPreview,
}: {
  serviceRequest: PublishedServiceRequest;
  selectedStatus: ApplicationStatus;
  onEdit: () => void;
  onDelete: () => void;
  onPreview: () => void;
}) => {
  return (
    <Flex
      bg={Colors.lightGray}
      borderRadius={25}
      padding={5}
      flexDir="row"
      w={"100%"}
      key={serviceRequest.id}
      marginBottom={10}
    >
      <Image
        w={310}
        minW={310}
        maxH={200}
        h={200}
        borderRadius={10}
        src={
          serviceRequest.image && serviceRequest.image.trim().length > 0 ? serviceRequest.image : placeholder
        }
        marginRight={"20px"}
      ></Image>
      <Flex flexDir="column" w={"100%"} justifyContent={"space-between"}>
        <Flex flexDir="column" w={"100%"}>
          <Text as={"b"} fontSize={18} fontFamily={"Inter"} color={"black"}>
            {serviceRequest.title}
          </Text>
          <Spacer size={8} />
          <Flex flexDir={"row"}>
            <Image src={pin} color={Colors.textGray} width={"12px"} height={"16px"} />
            <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
              {serviceRequest.city}
            </Text>
            <Spacer horizontal />
            <Image src={remont} color={Colors.textGray} width={"16px"} height={"16px"} />
            <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
              {serviceRequest.category.name + (serviceRequest.subCategories ? " - " : "")}
              {serviceRequest.subCategories
                ?.slice(0, 2)
                ?.map((s) => s.translationKey)
                .join(", ")}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDir="column" w={"100%"}>
          <Text
            fontSize={"12px"}
            color={Colors.textGray}
            marginLeft={"4px"}
            noOfLines={2}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(serviceRequest.description) }}
          />
          <Spacer />
        </Flex>
        <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
          {selectedStatus === ApplicationStatus.NEW && (
            <Text fontSize={"12px"} as={"b"} color={Colors.textGray} marginLeft={"4px"}>
              Тази обява все още няма заявки за изпълнение
            </Text>
          )}
          {selectedStatus === ApplicationStatus.REQUESTED && (
            <Flex flexDir={"row"} alignItems={"center"}>
              <Text fontSize={"12px"} as={"b"} color={Colors.orange} marginRight={"18px"}>
                {`Тази услуга има ${serviceRequest.craftsMen.length} ${
                  serviceRequest.craftsMen.length === 1 ? "заявкa" : "заявки"
                } за изпълнение`}
              </Text>
              {serviceRequest.craftsMen.slice(0, 3).map((u: { image: string | undefined }) => (
                <Image
                  src={u.image}
                  key={u.image}
                  w="40px"
                  h="40px"
                  borderRadius="20px"
                  borderWidth={2}
                  ml={"-10px"}
                  borderColor={Colors.white}
                />
              ))}
            </Flex>
          )}
          {selectedStatus === ApplicationStatus.IN_PROGRESS && (
            <Flex flexDir={"row"} alignItems={"center"}>
              <Text fontSize={"12px"} as={"b"} color={Colors.green} marginRight={"18px"}>
                Тази обява е в процес на изпълнение от майстор
              </Text>
              {serviceRequest.craftsMen.slice(0, 3).map((u: { image: string | undefined }) => (
                <Image
                  src={u.image}
                  key={u.image}
                  w="40px"
                  h="40px"
                  borderRadius="20px"
                  borderWidth={2}
                  ml={"-10px"}
                  borderColor={Colors.white}
                />
              ))}
            </Flex>
          )}
          {selectedStatus === ApplicationStatus.COMPLETED && (
            <Flex flexDir={"row"} alignItems={"center"}>
              <Text fontSize={"12px"} as={"b"} color={Colors.textGray} marginRight={"18px"}>
                Всички дейности по тази обява са завършени
              </Text>
            </Flex>
          )}
          <Box>
            <Button
              size="md"
              height="30px"
              color={Colors.textGray}
              backgroundColor={Colors.lightGray}
              fontSize={12}
              onClick={onDelete}
            >
              <Image
                src={deleteIcon}
                color={Colors.textGray}
                width={"12px"}
                height={"16px"}
                marginRight={2}
              />
              Изтрий
            </Button>
            <Button
              size="md"
              height="30px"
              w={"140px"}
              color={Colors.textGray}
              borderColor={Colors.textGray}
              border={"1px solid"}
              backgroundColor={Colors.lightGray}
              borderRadius={15}
              fontSize={12}
              marginRight={2}
              onClick={onEdit}
            >
              <Image src={editPen} color={Colors.textGray} width={"12px"} height={"16px"} marginRight={2} />
              Редактирай
            </Button>
            <Button
              size="md"
              height="30px"
              w={"120px"}
              color={Colors.textGray}
              borderColor={Colors.textGray}
              border={"1px solid"}
              backgroundColor={Colors.lightGray}
              borderRadius={15}
              fontSize={12}
              marginRight={2}
              onClick={onPreview}
            >
              <Image src={preview} color={Colors.textGray} width={"12px"} height={"16px"} marginRight={2} />
              Прегледай
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
