import { Box, Flex, Text } from "@chakra-ui/react";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../utils/colors";
import { FormInput } from "../../components/FormInput";
import { ErrorDataMap } from "../Registration";
import { CraftsManIn } from "../../types/CraftsManIn";
import { useCallback } from "react";

const EmailPassword = ({
  errors,
  userData,
  setUserData,
}: {
  errors: ErrorDataMap;
  userData: CraftsManIn;
  setUserData: (userData: CraftsManIn) => void;
}) => {
  const handleSubmit = useCallback((e: any) => {
    e.preventDefault();
  }, []);

  return (
    <Box minWidth={"40rem"} p="4" mx="auto">
      <Text fontSize={18} fontWeight={"bold"} color={Colors.textGray} mb={4}>
        Данни за вход
      </Text>

      <form onSubmit={handleSubmit}>
        <Flex justifyContent="space-between">
          <FormInput
            title="Имейл"
            type="email"
            value={userData.email}
            onChange={(e) => setUserData({ ...userData, email: e.target.value })}
            placeholder="Въведи имейл адрес"
            width="45%"
            hasError={errors["email"] !== undefined}
            errorMessage={errors["email"]}
            icon={faEnvelope}
          />

          <FormInput
            title="Парола"
            type="password"
            value={userData.password}
            onChange={(e) => setUserData({ ...userData, password: e.target.value })}
            placeholder="Въведи парола"
            width="45%"
            hasError={errors["password"] !== undefined}
            errorMessage={errors["password"]}
            icon={faLock}
          />
        </Flex>
        <Text fontSize={14} color={Colors.textGray} mt={5}>
          * Паролата трябва да е дълга най-малко 8 символа и да съдържа поне 1 число и един специален символ.
        </Text>
      </form>
    </Box>
  );
};

export default EmailPassword;
