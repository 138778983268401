import {
  Flex,
  Text,
  Image,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { faHardHat, faUser } from "@fortawesome/free-solid-svg-icons";
import { ChakraIcon } from "../../components/ChakraIcon";
import { useAuth } from "../../utils/AuthProvider";
import Colors from "../../utils/colors";
import { MessageOut } from "../types/ChatRoomOut";
import Spacer from "../../components/Spacer";
import VideoToImagePreview from "../../serviceDetails/components/VideoPreview";
import { useState } from "react";
import ImageGallery from "react-image-gallery";

export interface ChatMessageBodyProps {
  message: MessageOut;
  isReviewComment?: boolean;
}

function ChatMessageBody({ message, isReviewComment = false }: ChatMessageBodyProps) {
  const user = useAuth();
  const isMyMessage = user.user?.id === message.senderId;
  const [isGalleryOpened, setGalleryOpened] = useState(false);
  // TODO: BE to store/return thumbnail
  const imagesForGallery = message?.content?.images?.map((image) => ({
    original: image.url,
    thumbnail: image.url,
  }));

  return (
    <>
      {isMyMessage ? (
        <Flex
          width={"100%"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          marginBottom={3}
          flexDir={"row"}
        >
          <Flex
            w={"fit-content"}
            maxW={"70%"}
            padding={2}
            borderRadius={15}
            marginRight={3}
            backgroundColor={isReviewComment ? Colors.purple : Colors.primaryYellow}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {message.content.contentDescription && (
              <Text
                fontSize={"12px"}
                color={Colors.authButtonDark}
                marginRight={"4px"}
                fontWeight={"bold"}
                noOfLines={1}
                minWidth={"fit-content"}
              >
                {message.content.contentDescription + ":"}
              </Text>
            )}
            {message.content.text && (
              <Text
                fontSize={"12px"}
                color={isReviewComment ? Colors.white : Colors.authButtonDark}
                marginLeft={"4px"}
                marginRight={"4px"}
              >
                {message.content.text}
              </Text>
            )}
            <Flex onClick={() => setGalleryOpened(true)} cursor="pointer">
              {message.content.images?.map((image) => (
                <Image key={image?.url} w={"120px"} h={"100px"} borderRadius={"10px"} src={image?.url} />
              ))}
            </Flex>
            <Flex>
              {message.content.videos?.map(({ url }) => (
                <VideoToImagePreview key={url} url={url} />
              ))}
            </Flex>
          </Flex>
          <ChakraIcon
            icon={user.user?.isCraftsMan && !isReviewComment ? faHardHat : faUser}
            color={Colors.textGray}
            size={3}
          />
          <Spacer size={10} />
        </Flex>
      ) : (
        <Flex w={"98%"} flexDir={"row"} key={message.id}>
          <Flex
            width={"fit-content"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            alignSelf={"right"}
            marginBottom={3}
          >
            <ChakraIcon icon={user.user?.isCraftsMan ? faUser : faHardHat} color={Colors.textGray} size={3} />
            <Flex
              flexDir={"column"}
              w={"fit-content"}
              paddingX={4}
              paddingY={4}
              borderRadius={15}
              backgroundColor={Colors.purple}
              alignItems={"center"}
              justifyContent={"center"}
              marginLeft={3}
            >
              <Flex>
                {message.content.contentDescription && (
                  <Text
                    fontSize={"12px"}
                    color={Colors.white}
                    marginRight={"4px"}
                    fontWeight={"bold"}
                    noOfLines={1}
                    minWidth={"fit-content"}
                  >
                    {message.content.contentDescription + ":"}
                  </Text>
                )}
                {message.content.text && (
                  <Text fontSize={"12px"} color={Colors.white} marginLeft={"4px"} marginRight={"4px"}>
                    {message.content.text}
                  </Text>
                )}
              </Flex>
              <Flex onClick={() => setGalleryOpened(true)} cursor="pointer">
                {message.content.images?.map(({ url }) => (
                  <Image key={url} w={"120px"} h={"100px"} borderRadius={"10px"} src={url} />
                ))}
              </Flex>
              <Flex>
                {message.content.videos?.map(({ url }) => (
                  <VideoToImagePreview key={url} url={url} />
                ))}
              </Flex>
            </Flex>
            <Spacer size={10} />
          </Flex>
        </Flex>
      )}
      <Modal isOpen={isGalleryOpened} onClose={() => setGalleryOpened(false)} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Снимки</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ImageGallery items={imagesForGallery ?? []} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={() => setGalleryOpened(false)}>
              Затвори
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ChatMessageBody;
