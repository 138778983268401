import React, { useState, useCallback } from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Underline from "@tiptap/extension-underline";
import { Box, VStack, HStack, IconButton } from "@chakra-ui/react";
import { FaBold, FaItalic, FaUnderline, FaHeading, FaListOl, FaListUl, FaRedo, FaUndo } from "react-icons/fa";
import Colors from "../utils/colors";

interface ArticleEditorProps {
  value?: string;
  onChange: (content: string) => void;
}

const RichTextEditor: React.FC<ArticleEditorProps> = ({ onChange, value }) => {
  const [imageUrl, setImageUrl] = useState("");

  const editor = useEditor({
    extensions: [StarterKit, Image, Underline],
    content: value ?? "",
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
  });

  const addImage = useCallback(() => {
    if (editor && imageUrl) {
      editor.chain().focus().setImage({ src: imageUrl }).run();
      setImageUrl("");
    }
  }, [editor, imageUrl]);

  return (
    <VStack spacing={4} align="stretch" my={4}>
      <HStack>
        <IconButton
          aria-label="Bold"
          icon={<FaBold />}
          onClick={() => editor?.chain().focus().toggleBold().run()}
        />
        <IconButton
          aria-label="Italic"
          icon={<FaItalic />}
          onClick={() => editor?.chain().focus().toggleItalic().run()}
        />
        <IconButton
          aria-label="Underline"
          icon={<FaUnderline />}
          onClick={() => editor?.chain().focus().toggleUnderline().run()}
        />
        <IconButton
          aria-label="H1"
          icon={<FaHeading />}
          onClick={() => editor?.chain().focus().toggleHeading({ level: 1 }).run()}
        />
        <IconButton
          aria-label="H2"
          icon={<FaHeading style={{ fontSize: "0.9em" }} />}
          onClick={() => editor?.chain().focus().toggleHeading({ level: 2 }).run()}
        />
        <IconButton
          aria-label="H3"
          icon={<FaHeading style={{ fontSize: "0.8em" }} />}
          onClick={() => editor?.chain().focus().toggleHeading({ level: 3 }).run()}
        />
        <IconButton
          aria-label="Bullet List"
          icon={<FaListUl />}
          onClick={() => editor?.chain().focus().toggleBulletList().run()}
        />
        <IconButton
          aria-label="Ordered List"
          icon={<FaListOl />}
          onClick={() => editor?.chain().focus().toggleOrderedList().run()}
        />
        <IconButton
          aria-label="Undo"
          icon={<FaUndo />}
          onClick={() => editor?.chain().focus().undo().run()}
        />
        <IconButton
          aria-label="Redo"
          icon={<FaRedo />}
          onClick={() => editor?.chain().focus().redo().run()}
        />
      </HStack>
      <Box
        backgroundColor={Colors.white}
        borderRadius={10}
        height={"10rem"}
        overflow={"auto"}
        onClick={() => editor?.commands?.focus()}
        cursor="pointer"
      >
        <EditorContent editor={editor} className="tiptap" height={"10rem"} />
      </Box>
      {/* <Input placeholder="Image URL" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} /> */}
      {/* <Button colorScheme="blue" onClick={addImage} isDisabled={!imageUrl}>
        Add Image
      </Button> */}
    </VStack>
  );
};

export default RichTextEditor;
