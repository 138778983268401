import { createAction } from "@reduxjs/toolkit";

/* Reset action */
export const RESET_STATE = "RESET_STATE";
export const resetState = createAction(RESET_STATE);

/* Log out */
export const LOGOUT = "api.LOGOUT";
export const logOut = createAction(LOGOUT);

