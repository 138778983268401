import * as Yup from "yup";

const MIN_PASSWORD_LENGTH = 6;
const passwordRegex = /[!@#$%^&*(),.?":{}|<>]/;
const phoneRegex = /^[+]?\d*$/;

export const step1Schema = Yup.object().shape({
  email: Yup.string()
    .email("Моля въведете валиден емейл адрес")
    .required("Моля въведете валиден емейл адрес"),
  password: Yup.string()
    .min(MIN_PASSWORD_LENGTH, `Паролата трябва да е дълга най-малко ${MIN_PASSWORD_LENGTH} символа`)
    .matches(/\d/, "Паролата трябва да съдържа поне 1 число")
    .matches(passwordRegex, "Паролата трябва да съдържа поне 1 специален символ")
    .required(
      "Паролата трябва да е дълга най-малко 8 символа и да съдържа поне 1 число и един специален символ."
    ),
});

export const step2Schema = Yup.object().shape({
  firstName: Yup.string().trim().required("Моля въведете валидно първо име"),
  lastName: Yup.string().trim().required("Моля въведете валидно фамилно име"),
  phone: Yup.string().trim().notRequired().matches(phoneRegex, {
    message: "Моля въведете валиден телефонен номер",
    excludeEmptyString: true,
  }),
  city: Yup.string().trim().required("Моля въведете валиден град"),
  description: Yup.string().trim().required("Моля въведете валидно представяне"),
  title: Yup.string().trim().required("Моля въведете валидна професия"),
});

export const userProfilSchema = Yup.object().shape({
  firstName: Yup.string().trim().required("Моля въведете валидно първо име"),
  lastName: Yup.string().trim().required("Моля въведете валидно фамилно име"),
  phone: Yup.string().trim().notRequired().matches(phoneRegex, {
    message: "Моля въведете валиден телефонен номер",
    excludeEmptyString: true,
  }),
});

export const securitySchema = Yup.object().shape({
  oldPassword: Yup.string().required("Старата парола е задължителна"),

  newPassword: Yup.string()
    .min(8, "Новата парола трябва да е дълга най-малко 8 символа")
    .matches(/\d/, "Новата парола трябва да съдържа поне 1 число")
    .matches(/[!@#$%^&*(),.?":{}|<>]/, "Новата парола трябва да съдържа поне 1 специален символ")
    .required("Новата парола е задължителна")
    .test("is-different", "Новата парола трябва да бъде различна от старата", function (value) {
      return this.parent.oldPassword !== value;
    }),
});
