import { Box, Text, Button, Flex, Icon } from "@chakra-ui/react";
import { FaExclamationCircle } from "react-icons/fa";
import Colors from "../../utils/colors";

interface ImportantNoteProps {
  onActionClicked: () => void;
  applyDisabled?: boolean;
}

const ImportantNote = ({ onActionClicked, applyDisabled }: ImportantNoteProps) => {
  return (
    <Box
      border="1px solid"
      borderColor={Colors.borderGray}
      borderRadius="md"
      padding="16px"
      backgroundColor={Colors.white}
      width="100%"
      maxW="310px"
    >
      <Flex alignItems="center" marginBottom="8px">
        <Icon as={FaExclamationCircle} color="gray.600" marginRight="8px" />
        <Text fontWeight="bold" color="gray.700">
          ВАЖНО:
        </Text>
      </Flex>
      <Text color="gray.700" fontSize="14px" marginBottom="16px">
        Прегледайте внимателно профила на майстора преди да заявите услуга. Опишете подробно вашите изисквания
        за ремонт и по възможност качете снимки или видео. В срок до 7 дни, заявката ви ще бъде приета или
        отхвърлена.
      </Text>
      {!applyDisabled && (
        <Button
          background={Colors.purple}
          color={Colors.white}
          width="100%"
          rounded={20}
          onClick={onActionClicked}
        >
          Заяви тази услуга
        </Button>
      )}
    </Box>
  );
};

export default ImportantNote;
