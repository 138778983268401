import { Button } from "@chakra-ui/react";
import Colors from "../utils/colors";

export const StatusButton = ({
  title,
  selected,
  onClick,
}: {
  title: string;
  selected: boolean;
  onClick: () => void;
}) => (
  <Button
    bg={selected ? Colors.dirtyWhite : Colors.white}
    borderRadius={10}
    height={50}
    marginBottom={3}
    w={"216px"}
    color={Colors.textGray}
    fontSize={18}
    fontWeight={"bold"}
    textAlign={"left"}
    onClick={onClick}
  >
    {title}
  </Button>
);
