import { Flex, Image, Text } from "@chakra-ui/react";
import Colors from "../../utils/colors";
import { ChakraIcon } from "../../components/ChakraIcon";
import { faHardHat, faUser } from "@fortawesome/free-solid-svg-icons";

interface ChatRoomListProps {
  chatRooms: any[];
  selectedRoom: any;
  onRoomSelect: (room: any) => void;
  usersType: "craftsMan" | "user";
}

const ChatRoomList = ({ chatRooms, selectedRoom, onRoomSelect, usersType }: ChatRoomListProps) => (
  <Flex
    flexDir="column"
    w="33%"
    h="570px"
    maxH="570px"
    paddingTop={5}
    paddingBottom={5}
    overflow="auto"
    paddingRight={5}
    borderRight={`3px solid ${Colors.lightBorderGray}`}
  >
    {chatRooms.map((room) => (
      <Flex
        key={room.id}
        w="fit-content"
        paddingLeft={5}
        paddingRight={5}
        paddingTop={3}
        paddingBottom={3}
        marginBottom={5}
        borderRadius={10}
        alignItems="center"
        border={`1px solid ${Colors.lightBorderGray}`}
        cursor="pointer"
        backgroundColor={selectedRoom?.id === room.id ? Colors.lightBorderGray : Colors.white}
        onClick={() => onRoomSelect(room)}
      >
        <Image
          borderRadius="full"
          boxSize="32px"
          src={usersType === "user" ? room.user?.image : room.craftsMan?.image}
        />
        <Text as="b" fontSize="14px" color={Colors.textGray} marginLeft="4px" marginRight="4px">
          {usersType === "user" ? room.user?.name : room.craftsMan?.name}
        </Text>
        <ChakraIcon icon={usersType === "user" ? faUser : faHardHat} color={Colors.green} size={3} />
      </Flex>
    ))}
  </Flex>
);

export default ChatRoomList;
