import { useCallback, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { faUser, faPhone, faMapMarkerAlt, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../utils/colors";
import { useUploadImageMutation } from "../../serviceDetails/api/images";
import { FormInput } from "../../components/FormInput";
import { ErrorDataMap } from "../Registration";
import { CraftsManIn } from "../../types/CraftsManIn";
import { UserIn } from "../../types/UserIn";

const ProfileData = ({
  errors,
  userData,
  setUserData,
  profileMode,
}: {
  errors: ErrorDataMap;
  userData: CraftsManIn | UserIn;
  setUserData: (userData: CraftsManIn | UserIn) => void;
  profileMode: "user" | "craftsman";
}) => {
  const [imageFile, setImageFile] = useState<File | null>();
  const [uploadImageApi] = useUploadImageMutation();

  const handleImageChange = useCallback(
    (e: any) => {
      if (e.target.files && e.target.files[0]) {
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        uploadImageApi(formData)
          .unwrap()
          .then((res) => {
            setImageFile(e.target.files[0]);
            setUserData({ ...userData, image: res });
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    [setUserData, uploadImageApi, userData]
  );

  const handleSubmit = useCallback((e: any) => {
    e.preventDefault();
  }, []);

  return (
    <Flex
      flexDirection={"column"}
      minWidth={"80%"}
      p="4"
      mx="auto"
      alignItems={"stretch"}
      justifyContent={"space-between"}
    >
      <Text fontSize={"1rem"} fontWeight={"bold"} color={Colors.textGray} mb={4}>
        Данни за профил
      </Text>

      <form onSubmit={handleSubmit}>
        <Flex justifyContent="space-between" mb={4}>
          <FormInput
            title="Име"
            value={userData.firstName}
            onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
            placeholder="Въведи име"
            width="45%"
            hasError={errors["firstName"] !== undefined}
            errorMessage={errors["firstName"]}
            icon={faUser}
          />
          <FormInput
            title="Фамилия"
            value={userData.lastName}
            onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
            placeholder="Въведи фамилия"
            width="45%"
            hasError={errors["lastName"] !== undefined}
            errorMessage={errors["lastName"]}
            icon={faUser}
          />
        </Flex>

        <Flex justifyContent="space-between" mb={4}>
          <FormInput
            title="Телефон"
            value={userData.phone}
            onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
            type="phone"
            isMandatory={false}
            placeholder="Въведи телефон"
            width="45%"
            hasError={errors["phone"] !== undefined}
            errorMessage={errors["phone"]}
            icon={faPhone}
          />

          <FormInput
            title="Снимка"
            type="image"
            isMandatory={false}
            onChange={handleImageChange}
            placeholder={imageFile ? `Избрана снимка: ${imageFile.name}` : "Качи профилна снимка"}
            width="45%"
            hasError={errors["image"] !== undefined}
            errorMessage={errors["image"]}
            icon={faArrowUp}
          />
        </Flex>

        <Flex justifyContent="space-between" mb={4}>
          <FormInput
            title="Град"
            value={userData.city}
            onChange={(e) => setUserData({ ...userData, city: e.target.value })}
            placeholder="Въведи град"
            width={profileMode === "craftsman" ? "45%" : "100%"}
            hasError={errors["city"] !== undefined}
            errorMessage={errors["city"]}
            icon={faMapMarkerAlt}
          />

          {profileMode === "craftsman" && (
            <FormInput
              title="Професия"
              value={(userData as CraftsManIn).title}
              onChange={(e) => setUserData({ ...userData, title: e.target.value })}
              placeholder="Въведи професия"
              width="45%"
              hasError={errors["title"] !== undefined}
              errorMessage={errors["title"]}
              icon={faMapMarkerAlt}
            />
          )}
        </Flex>

        {profileMode === "craftsman" && (
          <FormInput
            type="text-area"
            title="Представяне"
            value={(userData as CraftsManIn).description}
            onChange={(e) => setUserData({ ...userData, description: e.target.value })}
            placeholder="Въведи описание"
            width="100%"
            hasError={errors["description"] !== undefined}
            errorMessage={errors["description"]}
          />
        )}
      </form>
    </Flex>
  );
};

export default ProfileData;
