import { ApplicationStatus } from "../home/types/AppliedServiceOut";
import { AppliedServiceRequestOut } from "../home/types/AppliedServiceRequestOut";
import { PublishedServiceRequest } from "../types/PublishedServiceRequest";

export const isServiceNotRequested = (serviceRequest: AppliedServiceRequestOut | PublishedServiceRequest) =>
  !isServiceInProgress(serviceRequest) &&
  !isServiceRequested(serviceRequest) &&
  !isServiceCompleted(serviceRequest);

export const isServiceRequested = (serviceRequest: AppliedServiceRequestOut | PublishedServiceRequest) =>
  serviceRequest.applications.some((s) => ApplicationStatus.REQUESTED === s.status);

export const isServiceInProgress = (serviceRequest: AppliedServiceRequestOut | PublishedServiceRequest) =>
  serviceRequest.applications.some((s) => ApplicationStatus.IN_PROGRESS === s.status);

export const isServiceCompleted = (serviceRequest: AppliedServiceRequestOut | PublishedServiceRequest) =>
  serviceRequest.applications.some((s) =>
    [ApplicationStatus.COMPLETED, ApplicationStatus.REVIEWED].includes(s.status)
  );

export const isServiceDeclined = (serviceRequest: AppliedServiceRequestOut | PublishedServiceRequest) =>
  serviceRequest.applications.some((s) => ApplicationStatus.DECLINED === s.status);

export const isServiceNotInProgress = (serviceRequest: AppliedServiceRequestOut | PublishedServiceRequest) =>
  !isServiceInProgress(serviceRequest) && !isServiceCompleted(serviceRequest);
