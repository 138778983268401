import { createSlice } from '@reduxjs/toolkit'

const languageSlice = createSlice({
  name: 'language',
  initialState: {language: 'bg'},
  reducers: {
    changeLanguage(state, action) {
      state.language = action.payload.language
    },
  }
})

export const { changeLanguage } = languageSlice.actions
export default languageSlice.reducer