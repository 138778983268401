import { Badge, Checkbox, Flex, Image, Text, Box } from "@chakra-ui/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Colors from "../../utils/colors";
import { Category } from "../../types";
import { useEffect, useState } from "react";
import { getBaseIMGUrl } from "../../store/utils";

interface CategoryDropDownProps {
  isSelected: boolean;
  onClick: (id: string, isSelected: boolean) => void;
  category: Category;
  selectedSubCategoryIds: string[];
  onSelected: (categoryId: string, subcategoryId: string, checked: boolean) => void;
  variant?: CategoryDropDownStyleVariant;
  disabled?: boolean;
  badgeCount?: number | undefined;
}

const BadgeComponent = ({ count }: { count: number }) => {
  return (
    <Badge
      position="absolute"
      top="-10px"
      right="-10px"
      bg="gray.600"
      color="white"
      borderRadius="full"
      w="1.5rem"
      h="1.5rem"
      display="flex"
      alignItems="center"
      justifyContent="center"
      fontSize="sm"
      fontWeight="bold"
    >
      {count}
    </Badge>
  );
};

export enum CategoryDropDownStyleVariant {
  HOME_PAGE_VARIANT = "HOME_PAGE_VARIANT",
  PUBLISH_PAGE_VARIANT = "PUBLISH_PAGE_VARIANT",
}

function CategoryDropDownComponent({
  isSelected = false,
  onClick,
  category,
  onSelected,
  variant = CategoryDropDownStyleVariant.HOME_PAGE_VARIANT,
  disabled = false,
  selectedSubCategoryIds,
  badgeCount,
}: CategoryDropDownProps) {
  const [selectedSubCategories, setSelectedSubcategories] = useState(
    selectedSubCategoryIds ?? ([] as string[])
  );

  const getComponentBasedOnVariant = (variant: CategoryDropDownStyleVariant) => {
    let component = null;
    switch (variant) {
      case CategoryDropDownStyleVariant.HOME_PAGE_VARIANT:
        component = (
          <Flex
            height={"70px"}
            onClick={() => {
              onClick(category.name, isSelected);
            }}
            cursor={"pointer"}
            backgroundColor={Colors.dirtyWhite}
            borderRadius={"10px"}
            alignItems={"center"}
            justifyContent={"space-between"}
            padding={"16px"}
            marginBottom={"8px"}
            borderWidth={badgeCount !== undefined ? 1 : 0}
            borderColor={Colors.black}
            position="relative"
          >
            {badgeCount && <BadgeComponent count={badgeCount} />}

            <Flex flexDir={"row"}>
              <Image
                src={`${getBaseIMGUrl()}${category.imageUrl}`}
                width={"32px"}
                height={"32px"}
                marginRight={"16px"}
              />
              <Text fontWeight={"700"} style={{ fontSize: 18, color: Colors.textGray }}>
                {category.name}
              </Text>
            </Flex>
            <FontAwesomeIcon icon={faChevronDown} />
          </Flex>
        );
        break;
      case CategoryDropDownStyleVariant.PUBLISH_PAGE_VARIANT:
        component = (
          <Flex
            height={"50px"}
            onClick={() => {
              !disabled && onClick(category.name, isSelected);
            }}
            bg={disabled ? Colors.dirtyWhite : "white"}
            border={"1px solid"}
            borderColor={Colors.textGray}
            textColor={Colors.textGray}
            borderRadius={"50px"}
            marginBottom={"10px"}
            textAlign={"left"}
            cursor={disabled ? "default" : "pointer"}
            alignItems={"center"}
            justifyContent={"space-between"}
            padding={"16px"}
          >
            <Flex flexDir={"row"}>
              <Image
                src={`${getBaseIMGUrl()}${category.imageUrl}`}
                width={"24px"}
                height={"24px"}
                marginRight={"16px"}
              />
              <Text>{category.name}</Text>
            </Flex>
            <FontAwesomeIcon icon={faChevronDown} />
          </Flex>
        );
        break;
    }
    return component;
  };

  useEffect(() => {
    setSelectedSubcategories(selectedSubCategoryIds);
  }, [selectedSubCategoryIds]);

  return (
    <Flex flexDir={"column"}>
      {getComponentBasedOnVariant(variant)}
      {isSelected && (
        <Flex
          flexDir={"column"}
          backgroundColor={Colors.dirtyWhite}
          padding={"16px"}
          marginBottom={"8px"}
          borderRadius={"10px"}
        >
          {category.subcategories.map((subcategory) => {
            return (
              <Checkbox
                key={subcategory.id}
                colorScheme={"gray"}
                variant={"darkBorder"}
                iconColor={"black"}
                spacing={3}
                size="lg"
                marginBottom={"8px"}
                isChecked={selectedSubCategories.includes(subcategory.id)}
                onChange={(e) => {
                  onSelected(category.id, subcategory.id, e.target.checked);
                  if (e.target.checked) {
                    setSelectedSubcategories([...selectedSubCategories, subcategory.id]);
                  } else {
                    setSelectedSubcategories(selectedSubCategories.filter((id) => id !== subcategory.id));
                  }
                }}
              >
                <Text fontSize={14} align="left">
                  {subcategory.translationKey}
                </Text>
                {/* TODO add the translations and call them via lang[translationKey]*/}
              </Checkbox>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
}

export default CategoryDropDownComponent;
