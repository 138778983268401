
interface SpacerProp {
    horizontal?: boolean
    size?: number
}

export default function Spacer({ horizontal = false, size = 20 }: SpacerProp) {
    return (
        <div style={horizontal ? {minWidth: size} : {minHeight: size}}></div>
    ); 
}