import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./../node_modules/react-image-gallery/styles/css/image-gallery.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AuthProvider from "./utils/AuthProvider";
import { checkboxAnatomy, modalAnatomy, tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
import Colors from "./utils/colors";
import store from "./store/createStore";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers([
    ...checkboxAnatomy.keys,
    ...modalAnatomy.keys,
    ...tabsAnatomy.keys,
  ]);

const darkBorder = definePartsStyle({
  control: defineStyle({
    borderColor: Colors.textGray,
    borderRadius: "5px",
  }),
});

export const checkboxTheme = defineMultiStyleConfig({
  variants: { darkBorder },
});

const authDialog = definePartsStyle({
  dialog: defineStyle({
    borderRadius: 25,
    backgroundColor: "transparent",
    maxW: "600px",
  }),
});

export const modalTheme = defineMultiStyleConfig({
  variants: { authDialog },
});

const authTabs = definePartsStyle({
  // define the part you're going to style
  tab: {
    backgroundColor: "transparent",
    borderTopRadius: 25,
    color: "white",
    boxShadow: "none",
    height: 50,
    _selected: {
      color: Colors.primaryYellow,
      backgroundColor: Colors.primaryBlue,
    },
  },
  tabpanel: {
    marginBottom: 0,
  },
  tablist: { marginBottom: "0px" },
});
const tabsTheme = defineMultiStyleConfig({ variants: { authTabs } });

// Chakra theme with dynamic heading size based on the "as" prop
const theme = extendTheme({
  fonts: {
    heading: `"Inter", sans-serif;`,
    body: `"Inter", sans-serif;`,
  },
  colors: {
    authButtonDark: {
      500: Colors.authButtonDark,
    },
    primaryYellow: {
      500: Colors.primaryYellow,
    },
  },
  styles: {
    global: {
      ul: {
        marginLeft: "25px",
      },
      li: {
        marginLeft: "25px",
      },
      // styles for the `body`
      h1: {
        fontSize: "2em",
        fontWeight: "bolder",
      },
      h2: {
        fontSize: "1.5em",
        fontWeight: "bolder",
      },
      h3: {
        fontSize: "1.17em",
        fontWeight: "bolder",
      },
      h4: {
        fontSize: "1em",
        fontWeight: "bolder",
      },
      // styles for the `a`
      a: {
        color: "blue",
        _hover: {
          textDecoration: "underline",
        },
      },
    },
  },
  components: {
    Checkbox: checkboxTheme,
    Modal: modalTheme,
    Tabs: tabsTheme,
  },
});

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? ""
);
root.render(
  <GoogleOAuthProvider clientId="175660901151-fkh9168la59evg9rkpa8vdeoj3nejvh3.apps.googleusercontent.com">
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <AuthProvider>
            <ChakraProvider theme={theme}>
              <Elements stripe={stripePromise}>
                <App />
              </Elements>
            </ChakraProvider>
          </AuthProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
reportWebVitals();
