import {
  Grid,
  GridItem,
  Flex,
  Box,
  Text,
  Image,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";

import styled from "styled-components";

import Colors from "../utils/colors";

import { useCallback, useEffect, useMemo, useState } from "react";
import Spacer from "../components/Spacer";
import pin from "../assets/pin.svg";
import remont from "../assets/remont.svg";
import editPen from "../assets/editPen.svg";
import preview from "../assets/preview.svg";
import deleteIcon from "../assets/delete.svg";
import {
  useDeleteServiceByIdMutation,
  useGetMyServicesQuery,
  useUpdateServiceMutation,
} from "./api/Services";
import { TopBar } from "./TopNavigationBar";
import { StatusButton } from "./ApplicationStatusButton";
import { ApplicationStatus } from "./types/AppliedServiceOut";
import { useNavigate } from "react-router-dom";
import RequestedServiceChat from "../services/RequestedServiceChat";
import { Service } from "../types";
import {
  isServiceInProgress,
  isServiceRequested,
  isServiceCompleted,
  isServiceNotRequested,
} from "../utils/services";
import placeholder from "../assets/placeholder.svg";
import DOMPurify from "dompurify";
import { PublishServiceForm } from "../publishService/components/PublishServiceForm";
import { FaTimes } from "react-icons/fa";
import { ServiceIn } from "../types/ServiceIn";
import { CraftButton } from "../components/CraftButton";
import ConfirmationModal from "../components/ConfirmationModal";
import { ImageIn } from "../types/services";

enum ServiceSelectionStatus {
  ALL,
  ACTIVE,
  COMPLETED,
}

const UserImage = ({ src }: { src: string }) => (
  <Image
    key={src}
    src={src}
    w={9}
    h={9}
    ml={-1}
    borderRadius="20px"
    borderWidth={1}
    boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
  />
);

function MyServicesPage() {
  const [selectedStatus, setSelectedStatus] = useState<ServiceSelectionStatus>(ServiceSelectionStatus.ALL);
  const [service, setService] = useState<Service | undefined>();
  const [editedService, setEditedService] = useState<ServiceIn | undefined>();
  const [deletedService, setDeletedService] = useState<Service | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const { isOpen: isConfirmOpen, onOpen: openConfirmModal, onClose: closeConfirmModal } = useDisclosure();

  const closeDeleteModal = useCallback(() => {
    setDeletedService(undefined);
    closeConfirmModal();
  }, [closeConfirmModal]);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const { data: services, refetch } = useGetMyServicesQuery();
  const [updateService] = useUpdateServiceMutation();
  const [deleteService] = useDeleteServiceByIdMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (service) {
      setService(services?.find((s: Service) => s.id === service.id));
    }
  }, [service, services]);

  useEffect(() => {
    if (deletedService) {
      openConfirmModal();
    }
  }, [deletedService, openConfirmModal]);

  const filteredServices = useMemo(() => {
    return services?.filter((service: Service) => {
      if (selectedStatus === ServiceSelectionStatus.ALL) return true;

      if (selectedStatus === ServiceSelectionStatus.ACTIVE)
        return service.applications.find((s) =>
          [ApplicationStatus.REQUESTED, ApplicationStatus.IN_PROGRESS].includes(s.status)
        );

      if (selectedStatus === ServiceSelectionStatus.COMPLETED) {
        return service.applications.find((s) =>
          [ApplicationStatus.COMPLETED, ApplicationStatus.REVIEWED].includes(s.status)
        );
      }
      return false;
    });
  }, [selectedStatus, services]);

  const activeChatId = useMemo(() => {
    if (service) {
      if (isServiceInProgress(service)) {
        const result = service.applications.find((s) => s.status === ApplicationStatus.IN_PROGRESS);
        return result?.chatRoomId;
      }
      if (isServiceRequested(service)) {
        const result = service.applications.find((s) => s.status === ApplicationStatus.IN_PROGRESS);
        return result?.chatRoomId;
      }
      if (isServiceCompleted(service)) {
        const result = service.applications.find((s) => s.status === ApplicationStatus.COMPLETED);
        return result?.chatRoomId;
      }
    }

    return [...(service?.applications ?? [])]?.sort(
      (s1, s2) => Date.parse(s2.createdAt) - Date.parse(s1.createdAt)
    )?.[0]?.chatRoomId;
  }, [service]);

  const validateForm = useCallback(() => {
    let newErrors: { [key: string]: string } = {};

    if (!service?.title?.trim()) newErrors.title = "Заглавието е задължително";
    if (!service?.categoryId) newErrors.category = "Трябва да изберете поне една категория";
    if (!service?.city) newErrors.city = "Градът е задължителен";
    if (!service?.description?.trim()) newErrors.description = "Описанието е задължително";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [service?.categoryId, service?.city, service?.description, service?.title]);

  const editService = useCallback(() => {
    if (editedService) {
      if (!validateForm()) return;

      updateService(editedService);
      closeModal();
      refetch();
      setEditedService(undefined);
    }
  }, [closeModal, editedService, refetch, updateService, validateForm]);

  const removeService = useCallback(() => {
    if (deletedService) {
      deleteService(deletedService.id);
      refetch();
      closeDeleteModal();
      setDeletedService(undefined);
    }
  }, [closeDeleteModal, deleteService, deletedService, refetch]);

  return (
    <Container>
      <TopBar />
      <RequestedServiceChat
        isOpen={!!service}
        onClose={() => {
          setService(undefined);
        }}
        service={service}
        onChangeStatus={refetch}
        activeChatId={activeChatId}
      />
      <Grid templateColumns="repeat(20, 1fr)" gap={6} w={"1300px"} mt={6}>
        <GridItem colSpan={4}>
          <Flex direction={"column"} alignItems={"left"} maxW={"95%"}>
            <StatusButton
              title="Всички"
              selected={selectedStatus === ServiceSelectionStatus.ALL}
              onClick={() => setSelectedStatus(ServiceSelectionStatus.ALL)}
            />
            <StatusButton
              title="Активни"
              selected={selectedStatus === ServiceSelectionStatus.ACTIVE}
              onClick={() => setSelectedStatus(ServiceSelectionStatus.ACTIVE)}
            />
            <StatusButton
              title="Завършени"
              selected={selectedStatus === ServiceSelectionStatus.COMPLETED}
              onClick={() => setSelectedStatus(ServiceSelectionStatus.COMPLETED)}
            />
          </Flex>
        </GridItem>
        <GridItem colSpan={16}>
          <Box flex={1}>
            {filteredServices?.map((service: Service) => {
              return (
                <Flex
                  bg={Colors.lightGray}
                  borderRadius={25}
                  padding={2}
                  flexDir="row"
                  w={"100%"}
                  key={service.id}
                  marginBottom={10}
                >
                  <Image
                    w={310}
                    h={200}
                    maxH={200}
                    minH={200}
                    maxW={310}
                    minW={310}
                    borderRadius={10}
                    src={service.image && service.image.length > 0 ? service.image : placeholder}
                    marginRight={"20px"}
                  ></Image>
                  <Flex flexDir="column" w={"100%"} justifyContent={"space-between"}>
                    <Flex flexDir="column" w={"100%"}>
                      <Text as={"b"} fontSize={18} fontFamily={"Inter"} color={"black"}>
                        {service.title}
                      </Text>
                      <Spacer size={8} />
                      <Flex flexDir={"row"}>
                        <Image src={pin} color={Colors.textGray} width={"12px"} height={"16px"} />
                        <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
                          {service.city}
                        </Text>
                        <Spacer horizontal />
                        <>
                          <Image src={remont} color={Colors.textGray} width={"16px"} height={"16px"} />
                          <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
                            {service.category.name + " "}
                            {service.category.subcategories?.map((s) => s.translationKey)?.join(", ")}
                          </Text>
                        </>
                      </Flex>
                    </Flex>
                    <Flex flexDir="column" w={"100%"}>
                      <Text
                        fontSize={"12px"}
                        color={Colors.textGray}
                        marginLeft={"4px"}
                        noOfLines={2}
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(service.description) }}
                      />
                      <Spacer />
                    </Flex>
                    <Flex w={"100%"} flexDir="column">
                      {isServiceNotRequested(service) && (
                        <Text fontSize={"12px"} as={"b"} color={Colors.textGray} marginLeft={"4px"}>
                          Тази обява все още няма заявки за изпълнение
                        </Text>
                      )}
                      {isServiceRequested(service) && !isServiceInProgress(service) && (
                        <Flex alignItems={"center"}>
                          <Text fontSize={"12px"} as={"b"} color={Colors.orange} marginRight={"10px"}>
                            {`Тази услуга има ${service.users.length} ${
                              service.users.length === 1 ? "заявкa" : "заявки"
                            } за изпълнение`}
                          </Text>
                          <Flex ml={2} flex={1}>
                            {service.users.slice(0, 3).map((image: ImageIn) => (
                              <UserImage src={image.url} />
                            ))}
                          </Flex>
                        </Flex>
                      )}
                      {isServiceInProgress(service) && (
                        <Flex alignItems={"center"}>
                          <Text fontSize={"12px"} as={"b"} color={Colors.green} marginRight={"10px"}>
                            Тази услуга е в процес на изпълнение за клиент
                          </Text>
                          <Flex ml={2} flex={1}>
                            {service.users.slice(0, 3).map((image: ImageIn) => (
                              <UserImage src={image.url} />
                            ))}
                          </Flex>
                        </Flex>
                      )}
                      <Flex flexDir={"row"} mt={3}>
                        <Button
                          size="md"
                          height="30px"
                          w={"120px"}
                          color={Colors.textGray}
                          borderColor={Colors.textGray}
                          border={"1px solid"}
                          backgroundColor={Colors.lightGray}
                          borderRadius={15}
                          fontSize={12}
                          marginRight={2}
                          onClick={() => setDeletedService(service)}
                        >
                          <Image
                            src={deleteIcon}
                            color={Colors.textGray}
                            width={"12px"}
                            height={"16px"}
                            marginRight={2}
                          />
                          Изтрий
                        </Button>
                        <Button
                          size="md"
                          height="30px"
                          w={"120px"}
                          color={Colors.textGray}
                          borderColor={Colors.textGray}
                          border={"1px solid"}
                          backgroundColor={Colors.lightGray}
                          borderRadius={15}
                          fontSize={12}
                          marginRight={2}
                          onClick={() => {
                            setEditedService({
                              id: service.id,
                              title: service.title,
                              description: service.description,
                              categoryId: service.categoryId,
                              subCategoryIds: service.subCategoryIds,
                              city: service.city,
                              location: service.location,
                              images: service.images,
                              videos: service.videos,
                            });
                            openModal();
                          }}
                        >
                          <Image
                            src={editPen}
                            color={Colors.textGray}
                            width={"12px"}
                            height={"16px"}
                            marginRight={2}
                          />
                          Редактирай
                        </Button>
                        <Button
                          size="md"
                          height="30px"
                          w={"120px"}
                          color={Colors.textGray}
                          borderColor={Colors.textGray}
                          border={"1px solid"}
                          backgroundColor={Colors.lightGray}
                          borderRadius={15}
                          fontSize={12}
                          marginRight={2}
                          onClick={() => {
                            if (!isServiceNotRequested(service)) {
                              setService(service);
                            } else {
                              navigate(`/service/${service.id}`);
                            }
                          }}
                        >
                          <Image
                            src={preview}
                            color={Colors.textGray}
                            width={"12px"}
                            height={"16px"}
                            marginRight={2}
                          />
                          Прегледай
                        </Button>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Box>
        </GridItem>
      </Grid>
      <ConfirmationModal isOpen={isConfirmOpen} onConfirm={removeService} onCancel={closeDeleteModal}>
        <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
          Сигурни ли се че искате да изтриете тази услуга?
        </Text>
      </ConfirmationModal>
      {editedService && (
        <Modal isOpen={isOpen} onClose={closeModal} isCentered variant={"authDialog"}>
          <ModalOverlay />
          <ModalContent backgroundColor={Colors.lightGray} w="828px" minW="828px">
            <IconButton
              aria-label="Close modal"
              icon={<FaTimes />}
              onClick={closeModal}
              position="absolute"
              top="20px"
              right="20px"
              zIndex={1}
              background="rgba(1, 1, 1, 0.2)"
              _hover={{ background: "rgba(0, 255, 0, 0.4)" }}
              size="sm"
              color="white"
            />
            <ModalHeader backgroundColor={Colors.lightGray} borderTopRadius={"25px"}>
              <Flex w={"100%"} alignItems={"center"} justifyContent={"center"}>
                <Text fontSize={"24px"} fontFamily={"Inter"} as={"b"} color={Colors.textGray}>
                  Редактиране на услуга
                </Text>
              </Flex>
            </ModalHeader>
            <ModalBody>
              <Box flex={1}>
                <Box padding={1}>
                  <PublishServiceForm
                    service={editedService}
                    setService={(service: ServiceIn) => setEditedService(service)}
                    errors={errors}
                  />
                </Box>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Flex justifyContent="flex-end" width={"50rem"}>
                <CraftButton title="Редактирай" onClick={editService} />
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 0.2rem 0px 1rem 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default MyServicesPage;
