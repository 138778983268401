import { appApi } from "../../api/rtk-query-init";
import { Tags, User } from "../../types";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query<User, void>({
      extraOptions: { maxRetries: 0 },
      query: () => ({
        url: "users/profile",
        method: "GET",
      }),
      providesTags: [Tags.USER],
    }),
  }),
});

export const { useGetUserProfileQuery } = extendedApi;

export default extendedApi;
