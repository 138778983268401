export const translations = {
  bg: {
    register_profile: "Регистрация на профил",
    login_to_profile: "Вход в профил",
    register_facebook: "Регистрация с Facebook",
    register_google: "Регистрация с Google",
    login_facebook: "Вход с Facebook",
    login_google: "Вход с Google",
    input_name: "Име на потребителя",
    input_email: "Имейл адрес",
    input_pass: "Парола",
    input_pass_confirm: "Потвърждаване на парола",
    input_photo: "Профилна снимка",
    login: "Вход",
    register: "Регистрация",
    forgot_pass: "Забравена парола",
    register_privacy_policy_agree_checkbox:
      "С натискането на бутон “Регистрация” се съгласявам с Общите условия на платформата www.craft-365.com",
    footer_category: "Категории",
    footer_how_it_works: "Как работи?",
    footer_partners: "Партньори",
    footer_terms_and_conditions: "Общи условия",
    footer_contacts: "Контакти",
    footer_tm: "Maistor.bg Всички права запазени",
    tabs_services: "Услуги",
    tabs_adds: "Обяви",
    tabs_articles: "Статии",
    publish_service_header: "Публикуване на услуга",
    company: "Фирма",
    handyman: "Майстор",
    publish_as: "Публикувай като:",
    categories: "Категории",
    city: "Населено място",
    add_photos_title: "ДОБАВЯНЕ НА СНИМКИ",
    publish: "Публикувай",
    registrationTips: {
      publish_tips1: "Стъпка 1:",
      publish_tips2: "Имейл и парола",
      publish_tips3: "Стъпка 2:",
      publish_tips4: "Данни за профил - имена, телефон, адрес, представяне",
      publish_tips5: "Стъпка 3:",
      publish_tips6: "Публикувай своята първа услуга (незадължително)",
    },
    publishTips: {
      handymen: {
        publish_tips1: "Напиши подходящо заглавие за обявата. Например:",
        publish_tips2: "Извършвам домашни ремонти - фаянс, теракот, замазки, мазилка",
        publish_tips3: "Изграждане и ремонт на покриви на къщи в София и областта",
        publish_tips4: "Предлагаме профилактика и ремонт на офис климатици",
        publish_tips5: "Добавете красиви и реални снимки",
        publish_tips6: "Може да посочиш само една основна категория и можество под-категории",
        publish_tips7: "Опиши с детайли дейностите, които трябва да бъдат извършени. Например:",
        publish_tips8:
          "Малка бригада с дългогодишен опит и строително образование извършва ремонт на къщи и апартаменти - шпакловка, боя, гипсокартон,фаянс, теракот, гранитогрес и др.",
        publish_tips9:
          "ВиК услуги, изграждане, ремонт и подмяна на водопроводна инсталация. Изграждане, ремонт и подмяна на канализационна инсталация. Ремонт на водопроводи, мивки, смесители, душ кабини, казанчета. Монтаж на бойлери. ",
        publish_tips10:
          "Извършвам всички видове услуги свързани с климатична, отоплителна и вентилационна техника за град София и околия до 80км! Сервиз, монтаж и диагностика на всички марки климатици.",
      },
      client: {
        publish_tips1: "Напиши подходящо заглавие за обявата. Например:",
        publish_tips2: "Търся майстор за домашни ремонти - фаянс, теракот, замазки, мазилка",
        publish_tips3: "Изграждане на нов покрив на селска къща с битумни керемиди",
        publish_tips4: "Имаме нужда от профилактика и ремонт на офис климатици",
        publish_tips5: "Избери правилната категория услуги",
        publish_tips6: "Може да посочиш само една основна категория и можество под-категории",
        publish_tips7: "Опиши с детайли дейностите, които трябва да бъдат извършени. Например:",
        publish_tips8:
          "Ремонтът трябва да се извърши на апартамент 99м2 груб строеж. Има нужда от шпакловане, боядисване (бяла боя) и поставане на теракотни плочи за баня 10м2 и кухня 12м2. Всички материали са закупени предварително.",
        publish_tips9:
          "Цялостен ремонт на покрив с битумни керемиди. Трябва да се положи и нова дървена конструкция. Ограничен бюджет.",
        publish_tips10:
          "Профилактиката трябва да бъде направена за 5 климатика - 2 Mitsubishi Electric и 3 Daikin. Единият от климатиците има проблем с функцията за охлаждането на помещението.",
      },
    },
    publish_tips1: "Уверете се, че обявата е правилната категория",
    publish_tips2: "Пишете на кирилица",
    publish_tips3: "Напишете кратко и ясно заглавие на обявата",
    publish_tips4: "Напишете подробно описание на вашата обява",
    publish_tips5: "Добавете красиви и реални снимки",
    title: "Заглавие",
    publish_title_placeholder: "Въведи заглавие на обявата",
    description: "Описание",
    publish_description_placeholder: "Въведи описание на обявата",
    post_code: "Пощенски код",
    publish_tips_title: "СЪВЕТИ ЗА ПУБЛИКУВАНЕ",
    publish_add_footer: "Публикувай услуга",
    publish_service_footer: "Публикувай обява",
    all_adds: "Всички обяви",
    all_services: "Всички услуги",
    all_articles: "Всички статии",
    qna: "Отговори на въпроси",
    our_vision: "Нашата визия",
    team: "Екип",
    for_clients: "За клиенти",
    for_handymen: "За майстори",
    about_us: "За нас",
    terms_of_use: "Условия за ползване",
    personal_data_protection: "Защита на личните данни",
    cookies_policy: "Политика за бисквитки",
  },

  en: {
    register_profile: "Register profile",
    login_to_profile: "Login",
    register_facebook: "FACEBOOK REGISTER",
    register_google: "GOOGLE REGISTER",
    login_facebook: "FACEBOOK LOGIN",
    login_google: "GOOGLE LOGIN",
    input_name: "Username",
    input_email: "E-mail",
    input_pass: "Password",
    input_pass_confirm: "Confirm password",
    input_photo: "Profile photo",
    login: "Login",
    register: "Register",
    forgot_pass: "Forgot password",
    register_privacy_policy_agree_checkbox:
      "I agree that my personal information will be processed in accordance with the Terms and conditions of Maistor.bg and the law. I declare that I am of legal age *",
    footer_category: "Categories",
    footer_how_it_works: "How it works?",
    footer_partners: "Partners",
    footer_terms_and_conditions: "Terms and conditions",
    footer_contacts: "Contact",
    footer_tm: "Maistor.bg All rights reserved",
    tabs_services: "Services",
    tabs_adds: "Adds",
    tabs_articles: "Articles",
    publish_service_header: "Publish a service",
    company: "Company",
    handyman: "Handyman",
    publish_as: "Publish as a:",
    categories: "Categories",
    city: "City",
    add_photos_title: "ADD PHOTOS",
    publish: "Publish",
    registrationTips: {
      publish_tips1: "Стъпка 1:",
      publish_tips2: "Имейл и парола",
      publish_tips3: "Стъпка 2:",
      publish_tips4: "Данни за профил - имена, телефон, адрес, представяне",
      publish_tips5: "Стъпка 3:",
      publish_tips6: "Публикувай своята първа услуга (незадължително)",
    },
    publishTips: {
      handymen: {
        publish_tips1: "Напиши подходящо заглавие за обявата. Например:",
        publish_tips2: "Пишете на кирилица",
        publish_tips3: "Напишете кратко и ясно заглавие на обявата",
        publish_tips4: "Напишете подробно описание на вашата обява",
        publish_tips5: "Добавете красиви и реални снимки",
        publish_tips6: "Може да посочиш само една основна категория и можество под-категории",
        publish_tips7: "Опиши с детайли дейностите, които трябва да бъдат извършени. Например:",
        publish_tips8:
          "Ремонтът трябва да се извърши на апартамент 99м2 груб строеж. Има нужда от шпакловане, боядисване (бяла боя) и поставане на теракотни плочи за баня 10м2 и кухня 12м2. Всички материали са закупени предварително.",
        publish_tips9:
          "Цялостен ремонт на покрив с битумни керемиди. Трябва да се положи и нова дървена конструкция. Ограничен бюджет.",
        publish_tips10:
          "Профилактиката трябва да бъде направена за 5 климатика - 2 Mitsubishi Electric и 3 Daikin. Единият от климатиците има проблем с функцията за охлаждането на помещението.",
      },
      client: {
        publish_tips1: "Напиши подходящо заглавие за обявата. Например:",
        publish_tips2: "Търся майстор за домашни ремонти - фаянс, теракот, замазки, мазилка",
        publish_tips3: "Изграждане на нов покрив на селска къща с битумни керемиди",
        publish_tips4: "Имаме нужда от профилактика и ремонт на офис климатици",
        publish_tips5: "Избери правилната категория услуги",
        publish_tips6: "Може да посочиш само една основна категория и можество под-категории",
        publish_tips7: "Опиши с детайли дейностите, които трябва да бъдат извършени. Например:",
        publish_tips8:
          "Ремонтът трябва да се извърши на апартамент 99м2 груб строеж. Има нужда от шпакловане, боядисване (бяла боя) и поставане на теракотни плочи за баня 10м2 и кухня 12м2. Всички материали са закупени предварително.",
        publish_tips9:
          "Цялостен ремонт на покрив с битумни керемиди. Трябва да се положи и нова дървена конструкция. Ограничен бюджет.",
        publish_tips10:
          "Профилактиката трябва да бъде направена за 5 климатика - 2 Mitsubishi Electric и 3 Daikin. Единият от климатиците има проблем с функцията за охлаждането на помещението.",
      },
    },
    publish_tips1: "Make sure your add is in the correct category",
    publish_tips2: "Write in cyrilic",
    publish_tips3: "Write a short and clear title for your add",
    publish_tips4: "Write a detailed description for your add",
    publish_tips5: "Add attractive and real photos",
    title: "Title",
    publish_title_placeholder: "Въведи заглавие на обявата",
    description: "Description",
    publish_description_placeholder: "Въведи описание на обявата",
    publish_tips_title: "TIPS FOR PUBLISHING",
    publish_add_footer: "Publish add",
    publish_service_footer: "Publish service",
    all_adds: "All adds",
    all_services: "All services",
    post_code: "Пощенски код",
    all_articles: "All articles",
    qna: "FAQ",
    our_vision: "Our vision",
    team: "Team",
    for_clients: "For clients",
    for_handymen: "For handymen",
    about_us: "About us",
    terms_of_use: "Terms of use",
    personal_data_protection: "Personal data protection",
    cookies_policy: "Cookies policy",
  },
};
