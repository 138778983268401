export enum TAB_INDEX {
  SERVICES = 1,
  SERVICE_REQUEST = 2,
  ARTICLES = 3,
}

export enum HOME_PAGE_CONTENT_INDEX {
  HOME_PAGE_CONTENT = 1,
  PUBLISH = 2,
  MY_SERVICES = 3,
  REQUESTED_SERVICES = 4,
}
