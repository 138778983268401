import {
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  ModalOverlay,
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Box,
  Flex,
  Checkbox,
} from "@chakra-ui/react";
import { faEnvelope, faHardHat, faKey } from "@fortawesome/free-solid-svg-icons";
import InputWithIcon from "./InputWithIcon";
import { useLanguage } from "../utils/useLanguage";
import Spacer from "./Spacer";
import { FaFacebookF, FaGoogle } from "react-icons/fa";
import { fbLogin } from "../utils/FacebookSDK";
import { useGoogleLogin } from "@react-oauth/google";
import Colors from "../utils/colors";
import { ChakraIcon } from "./ChakraIcon";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { useEffect, useState } from "react";
import {
  useLoginCraftsManMutation,
  useLoginUserMutation,
  useRegisterCraftsManMutation,
  useRegisterUserMutation,
} from "../api/login";
import { useNavigate } from "react-router-dom";
import { useLazyLoginWithFacebookQuery, useLazyLoginWithGoogleQuery } from "../login/api/Login";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../api/reducers";
import { useAuth } from "../utils/AuthProvider";

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  isRegister: boolean | undefined;
}

function AuthModal({ isOpen, onClose, isRegister }: ModalProps) {
  const lang = useLanguage();
  const iconColors = [Colors.primaryYellow, "white"];
  const [tabIndex, setTabIndex] = useState(0);
  const [loginUser] = useLoginUserMutation();
  const [registerUser] = useRegisterUserMutation();
  const [loginCraftsMan] = useLoginCraftsManMutation();
  const [registerCrafstMan] = useRegisterCraftsManMutation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [authWithFacebook, { isLoading, data }] = useLazyLoginWithFacebookQuery();
  const [authWithGoogle, { isLoading: googleIsLoading, data: googleData }] = useLazyLoginWithGoogleQuery();
  const dispatch = useDispatch();
  const user = useAuth();

  const loginWithFacebook = () => {
    fbLogin().then((response) => {
      if (response.status === "connected") {
        authWithFacebook({ token: response.authResponse.accessToken });
      }
    });
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      authWithGoogle({ token: codeResponse.access_token });
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (data?.payload.token && !isLoading) {
      // when merged with auth branch
      dispatch(setToken(data?.payload.token));

      navigate("/home");
    }
  }, [data, dispatch, isLoading, navigate]);

  useEffect(() => {
    if (googleData?.payload.token && !googleIsLoading) {
      // when merged with auth branch
      dispatch(setToken(googleData?.payload.token));
      navigate("/home");
    }
  }, [data, dispatch, googleData?.payload.token, googleIsLoading, isLoading, navigate]);

  useEffect(() => {
    if (user) {
      onClose();
    }
  }, [onClose, user]);

  useEffect(() => setTabIndex(0), [isOpen]);

  const auth = () => {
    const isCraftsman = tabIndex !== 0;

    let result;
    if (isRegister) {
      navigate("/sign-up");
      onClose();
      return;
    } else {
      result = login(isCraftsman);
    }
    result
      .then((res) => {
        dispatch(setUser(res.payload.user));
      })
      .catch((e) => {
        alert(e.data.error);
        console.log(e);
      });
  };

  const login = (isCraftsman: boolean) => {
    if (isCraftsman) {
      return loginCraftsMan({ email, password }).unwrap();
    } else {
      return loginUser({ email, password }).unwrap();
    }
  };

  const register = (isCraftsman: boolean) => {
    if (isCraftsman) {
      return registerCrafstMan({ email, password }).unwrap();
    } else {
      return registerUser({ email, password }).unwrap();
    }
  };

  const getTabPanel = (index: number) => {
    return (
      <TabPanel backgroundColor={Colors.primaryBlue} borderBottomRadius={25} padding={"20px"}>
        <ModalBody>
          <Text
            fontFamily={"Inter"}
            color={Colors.secondaryGray}
            visibility={isRegister ? "visible" : "hidden"}
            fontSize={12}
          >
            {index === 0
              ? "Ако искате да намерите специалист или да публикувате обява за вашите ремонтни дейности, можете да се регистрирате като ПОТРЕБИТЕЛ"
              : "Ако искате да публикувате обява за вашите ремонтни дейности, можете да се регистрирате като МАЙСТОР"}
          </Text>
          <Spacer />
          <Button
            onClick={loginWithFacebook}
            colorScheme="authButtonDark"
            leftIcon={<FaFacebookF />}
            justifyContent={"flex-start"}
            borderRadius={50}
            fontWeight={500}
            color={Colors.secondaryGray}
            height="50px"
            width={"100%"}
          >
            {isRegister ? lang.register_facebook : lang.login_facebook}
          </Button>
          <Spacer />
          <Button
            onClick={() => loginWithGoogle()}
            leftIcon={<FaGoogle />}
            justifyContent={"flex-start"}
            colorScheme="authButtonDark"
            borderRadius={50}
            fontWeight={500}
            color={Colors.secondaryGray}
            height="50px"
            width="100%"
          >
            {isRegister ? lang.register_google : lang.login_google}
          </Button>
          {(index === 0 || !isRegister) && (
            <>
              <Flex flexDir={"row"} align={"center"} justifyContent={"center"} width={"100%"} marginTop={5}>
                <Box h={"1px"} backgroundColor={Colors.secondaryGray} w={"100%"} />
                <Text marginLeft={6} marginRight={6} color={Colors.secondaryGray} fontSize={14}>
                  ИЛИ
                </Text>
                <Box h={"1px"} backgroundColor={Colors.secondaryGray} w={"100%"} />
              </Flex>
              <Spacer />
              <InputWithIcon
                type="email"
                icon={faEnvelope}
                label={lang.input_email}
                onChange={(value) => {
                  setEmail(value.target.value);
                }}
              />
              <Spacer />
              <InputWithIcon
                type="password"
                icon={faKey}
                label={lang.input_pass}
                onChange={(value) => {
                  setPass(value.target.value);
                }}
              />
            </>
          )}
          <Spacer />
        </ModalBody>
        <ModalFooter>
          <Flex flexDir={"column"}>
            <Button
              colorScheme="primaryYellow"
              width={"100%"}
              height={"50px"}
              borderRadius={50}
              textColor={Colors.primaryBlue}
              fontFamily={"Inter"}
              fontSize={18}
              fontWeight={700}
              onClick={auth}
            >
              {isRegister
                ? index === 0
                  ? "Регистрация като потребител"
                  : "Регистрация като майстор"
                : index === 0
                ? "Вход като потребител"
                : "Вход като майстор"}
            </Button>
            <Spacer />
            <Checkbox spacing={3} size="lg">
              <Text fontSize={12} align="left" color={Colors.secondaryGray}>
                {lang.register_privacy_policy_agree_checkbox}
              </Text>
            </Checkbox>
          </Flex>
        </ModalFooter>
      </TabPanel>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered variant={"authDialog"}>
      <ModalOverlay />

      <ModalContent>
        <Tabs isFitted variant="authTabs" tabIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>
              <ChakraIcon icon={faUser} color={iconColors[tabIndex]} props={{ marginRight: "10px" }} />
              <Text fontSize={18} as={"b"}>
                Търся услуга
              </Text>
            </Tab>
            <Tab>
              <ChakraIcon icon={faHardHat} color={iconColors[tabIndex - 1]} props={{ marginRight: "10px" }} />
              <Text fontSize={18} as={"b"}>
                Търся работа
              </Text>
            </Tab>
          </TabList>
          <TabPanels backgroundColor={"transparent"}>
            {getTabPanel(0)}
            {getTabPanel(1)}
          </TabPanels>
        </Tabs>
      </ModalContent>
    </Modal>
  );
}

export default AuthModal;
