import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { translations } from './translations';
import { State } from '../store/State';

export function useLanguage() {
  const language = useSelector((state: State) => state?.language?.language)
  const [lang, setLang] = useState(translations[language as keyof typeof translations])

  useEffect(() => {
    setLang(translations[language as keyof typeof translations])
  }, [language])

  return lang;
}