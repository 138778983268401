import { appApi } from "../../api/rtk-query-init";
import { Tags } from "../../types";
import { CraftsManIn, SecurityIn } from "../../types/CraftsManIn";
import { UserIn } from "../../types/UserIn";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    updateCraftsManProfile: builder.mutation<{ payload: { user: CraftsManIn } }, CraftsManIn>({
      extraOptions: { maxRetries: 0 },
      invalidatesTags: [Tags.USER],
      query: (body: CraftsManIn) => ({
        url: "craftsmen/profile",
        method: "PUT",
        body,
      }),
    }),
    updateProfileSecurity: builder.mutation<void, SecurityIn>({
      extraOptions: { maxRetries: 0 },
      query: (body: SecurityIn) => ({
        url: "craftsmen/security",
        method: "PUT",
        body,
      }),
    }),
    updateUserProfile: builder.mutation<{ payload: { user: UserIn } }, UserIn>({
      extraOptions: { maxRetries: 0 },
      invalidatesTags: [Tags.USER],
      query: (body: UserIn) => ({
        url: "users/profile",
        method: "PUT",
        body,
      }),
    }),
    updateUserProfileSecurity: builder.mutation<void, SecurityIn>({
      extraOptions: { maxRetries: 0 },
      query: (body: SecurityIn) => ({
        url: "users/security",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useUpdateCraftsManProfileMutation,
  useUpdateProfileSecurityMutation,
  useUpdateUserProfileMutation,
  useUpdateUserProfileSecurityMutation,
} = extendedApi;

export default extendedApi;
