import { CraftsManOut, ImageIn, VideoIn } from "../../types/services";

export enum ApplicationStatus {
  NEW = "NEW",
  REQUESTED = "REQUESTED",
  IN_PROGRESS = "IN_PROGRESS",
  DECLINED = "DECLINED",
  COMPLETED = "COMPLETED",
  REVIEWED = "REVIEWED",
}

export interface AppliedServiceOut {
  id: string;
  image: string;
  title: string;
  craftsMan: CraftsManOut;
  city: string;
  description: string;
  category: string;
  subCategories: string[];
  applications: Array<ServiceApplication>;
}

export interface ServiceApplication {
  id: string;
  createdAt: string;
  userId: string;
  serviceId: string;
  chatRoomId: string;
  description: string;
  preferedVisitDateTime?: string;
  status: ApplicationStatus;
  images?: ImageIn[];
  videos?: VideoIn[];
  phoneNumber?: string;
  location?: Location;
}
