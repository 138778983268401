import { State } from "./State";
import { getToken } from "../api/selectors";

export const getHeaders = (headers: Headers, state: State, skipContentType?: boolean) => {
  headers.append("Accept-Language", "en-us");
  if (!skipContentType) {
    headers.append("Content-Type", "application/json");
  }
  const token = getToken(state) || localStorage.getItem("token");
  if (token !== null) {
    headers.append("Authorization", token);
  }
  return headers;
};

export const getBaseAPIUrl = () => {
  return process.env.REACT_APP_BASE_API_URL;
};

export const getBaseIMGUrl = () => {
  return process.env.REACT_APP_BASE_IMG_URL;
};

export const deepEqual = (a: any, b: any) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export function timeAgo(dateString?: string): string {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  const intervals: { [key: string]: number } = {
    година: 31536000,
    месец: 2592000,
    седмица: 604800,
    ден: 86400,
    час: 3600,
    минута: 60,
    секунда: 1,
  };

  const plurals: { [key: string]: string } = {
    година: "години",
    месец: "месеца",
    седмица: "седмици",
    ден: "дни",
    час: "часа",
    минута: "минути",
    секунда: "секунди",
  };

  for (const key in intervals) {
    const interval = Math.floor(seconds / intervals[key]);
    if (interval >= 1) {
      return `преди ${interval} ${interval > 1 ? plurals[key] : key}`;
    }
  }
  return "току-що";
}
