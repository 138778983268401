import { ServiceApplication } from "../home/types/AppliedServiceOut";
import { ImageIn, VideoIn } from "./services";

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  image: ImageIn;
  city: string;
  address: string;
  isCraftsMan?: boolean;
  balance?: number;
}

export interface UserOut {
  email: string;
  password: string;
}

export type LoginPayload = {
  email: string;
  password: string;
};

export enum Tags {
  AUTH = "AUTH",
  ARTICLES = "ARTICLES",
  SERVICES = "SERVICES",
  APPLIED_SERVICES = "APPLIED_SERVICES",
  LOCATION = "LOCATION",
  USER = "USER",
  CATEGORIES = "CATEGORIES",
  SERVICE_REQUESTS = "SERVICE_REQUESTS",
  CHAT_ROOMS = "CHAT_ROOMS",
  TRANSACTIONS = "TRANSACTIONS",
}

export interface SubCategory {
  translationKey: string;
  id: string;
}

export interface Category {
  id: string;
  name: string;
  imageUrl: string;
  subcategories: SubCategory[];
}

export interface Service {
  id: string;
  image: string;
  images: ImageIn[];
  videos: VideoIn[];
  title: string;
  city: string;
  description: string;
  rating: number;
  craftsMan: {
    image: string;
    isACompany: boolean;
    firstName: string;
    lastName: string;
  };
  categoryId: string;
  subCategoryIds: string[];
  category: Category;
  users: any[];
  location: Location;
  applications: Array<ServiceApplication>;
}

export interface ServiceRequest {
  id: string;
  image: string;
  videos: string[];
  title: string;
  ownerId: string;
  appliedCraftManIds: string[];
  confirmedCraftsManId: string;
  user: {
    firstName: string;
    lastName: string;
    image: string;
  };
  city: string;
  description: string;
  categoryId: string;
  subCategoryIds: string[];
  location: Location;
}

export type HomePageCardContent = {
  id: number;
  image: string;
  title: string;
  user: HomePageCardOwner;
  description: string;
  city: string;
  rating?: number;
};

export type HomePageCardOwner = {
  image: string;
  isACompany?: boolean;
  firstName: string;
  lastName: string;
};
export interface Location {
  latitude: number;
  longitude: number;
}

export interface FilterQuery {
  page?: number;
  keyword?: string;
  location?: Location;
  categoryId?: string;
  subCategoryIds?: string[];
  rating?: number;
  sortBy?: string;
  userId?: string;
}

export interface ListResponse<T> {
  paginationInfo: {
    currentPage: number;
    totalPages: number;
    resultsCount: number;
  };
  items: T[];
}

export interface ModalMeta {
  [name: string]: any;
}

export type Modal = {
  id: string;
  open: boolean;
  meta?: ModalMeta;
};

export type ModalMap = {
  [id: string]: Modal;
};

export interface Location {
  latitude: number;
  longitude: number;
}

export interface Message {
  senderId: string;
  content: MessageContent;
  status: string;
}

export enum ContentType {
  VIDEO = "VIDEO",
  IMAGE = "IMAGE",
  TEXT = "TEXT",
}

export interface MessageContent {
  contentType: ContentType;
  contentDescription?: string;
  text?: string;
  paths?: string[];
}

export interface ChatRoom {
  socketId?: string;
  serviceId?: string;
  serviceRequestId?: string;
  craftsManId: string;
  userId: string;
  messages: Message[];
  craftsMan?: User;
  _id: string;
  id: string;
}
