import { ApplicationStatus, AppliedServiceOut } from "../home/types/AppliedServiceOut";
import { Service } from "../types";

export const isServiceNotRequested = (service: Service | AppliedServiceOut) =>
  !isServiceInProgress(service) && !isServiceRequested(service) && !isServiceCompleted(service);

export const isServiceRequested = (service: Service | AppliedServiceOut) =>
  service.applications.some((s) => ApplicationStatus.REQUESTED === s.status);

export const isServiceInProgress = (service: Service | AppliedServiceOut) =>
  service.applications.some((s) => ApplicationStatus.IN_PROGRESS === s.status);

export const isServiceCompleted = (service: Service | AppliedServiceOut) =>
  service.applications.some((s) =>
    [ApplicationStatus.COMPLETED, ApplicationStatus.REVIEWED].includes(s.status)
  );

export const isServiceDeclined = (service: Service | AppliedServiceOut) =>
  service.applications.some((s) => ApplicationStatus.DECLINED === s.status);

export const isServiceNotInProgress = (service: Service | AppliedServiceOut) =>
  !isServiceInProgress(service) && !isServiceCompleted(service);
