import { appApi } from '../../api/rtk-query-init';


const extendedApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        uploadVideo: builder.mutation<{ videoUrl: string }, FormData>({
            query: (formData) => ({
                url: '/videos',
                method: 'POST',
                body: formData,
            }),
        }),
        removeVideo: builder.mutation<void, string>({
            query: (path) => ({
                url: `/videos/${encodeURIComponent(path)}`,
                method: 'DELETE',
            })
        }),
    }),
});

export const { useUploadVideoMutation, useRemoveVideoMutation } = extendedApi;