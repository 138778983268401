import React from "react";
import { Button, Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, HStack } from "@chakra-ui/react";

const ConfirmationModal = ({
  isOpen,
  children,
  onConfirm,
  onCancel,
}: {
  isOpen: boolean;
  children: React.ReactNode | React.ReactNode[];
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel} isCentered>
      <ModalOverlay />
      <ModalContent p={4} borderRadius="20px" textAlign="center" maxW="400px" bg="gray.50">
        <ModalBody>{children} </ModalBody>

        <ModalFooter justifyContent="center">
          <HStack spacing={4}>
            <Button
              variant="outline"
              border="2px solid"
              borderColor="gray.400"
              borderRadius="full"
              px={6}
              fontWeight="bold"
              color="gray.700"
              onClick={onCancel}
            >
              НЕ
            </Button>
            <Button
              bg="yellow.400"
              color="black"
              borderRadius="full"
              px={6}
              fontWeight="bold"
              _hover={{ bg: "yellow.500" }}
              onClick={onConfirm}
            >
              ДА
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
