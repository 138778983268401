import { Box, Flex, Textarea, Button, Text, Image } from "@chakra-ui/react";
import styled from "styled-components";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApplyForServiceRequestMutation } from "../../services/api/RequestService";
import Colors from "../../utils/colors";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { ChakraIcon } from "../../components/ChakraIcon";
import { useRequestServiceRequestDetailQuery } from "../api/servicesRequests";
import pin from "../../assets/pin.svg";
import remont from "../../assets/remont.svg";
import { faHardHat } from "@fortawesome/free-solid-svg-icons";
import clock from "../../assets/clock.svg";
import { timeAgo } from "../../store/utils";
import { useAuth } from "../../utils/AuthProvider";

function ApplyForServiceRequestPage() {
  const navigate = useNavigate();
  let { id } = useParams();
  const craftsMan = useAuth();
  const { data: serviceRequest } = useRequestServiceRequestDetailQuery(id ?? "");
  const [apply] = useApplyForServiceRequestMutation();

  const [description, setDescription] = useState("");

  if (!serviceRequest) return null;
  const { owner: user } = serviceRequest;

  return (
    <Container>
      <Box w={"100%"} flex={1} padding={6}>
        <Box
          bg={Colors.lightGray}
          borderTopRadius={25}
          paddingY={3}
          paddingLeft={"30px"}
          paddingRight={"30px"}
        >
          <Text
            as={"b"}
            fontSize={24}
            fontFamily={"Inter"}
            w={"100%"}
            textAlign={"center"}
            display={"block"}
            color={Colors.textGray}
          >
            Заявка по обява
          </Text>
        </Box>
        <Flex
          flexDir="row"
          w={"100%"}
          paddingTop={5}
          paddingBottom={5}
          paddingLeft={"30px"}
          paddingRight={"30px"}
        >
          <Image w={150} h={100} borderRadius={10} marginRight={"20px"} src={serviceRequest.images[0]} />
          <Flex flexDir="column">
            <Text as={"b"} fontSize={18} fontFamily={"Inter"} color={"black"} mb={2}>
              {serviceRequest.title}
            </Text>
            <Flex w="100%" mb={3} alignItems={"center"}>
              <Image src={pin} color={Colors.textGray} width={"12px"} height={"16px"} />
              <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
                {serviceRequest.city}
              </Text>
              <Image src={remont} color={Colors.textGray} width={"16px"} height={"16px"} ml={5} />
              <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
                {serviceRequest.category?.name + "   "}
                {serviceRequest.category?.subCategories?.slice(0, 4).join(", ")}
              </Text>
              <Image src={clock} color={Colors.textGray} width={"16px"} height={"16px"} ml={5} />
              <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
                {timeAgo(serviceRequest.createdAt)}
              </Text>
              <Flex marginLeft={5} alignItems={"center"}>
                <ChakraIcon icon={faHardHat} color={Colors.textGray} width={"16px"} height={"16px"} />
                <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
                  {serviceRequest.numberOfApplications} заявки от майстори
                </Text>
              </Flex>
            </Flex>
            <Flex flexDir={"row"} alignItems={"center"}>
              {user && (
                <Flex flexDir={"row"} alignItems={"center"} mr={3}>
                  <Image borderRadius="full" boxSize={"32px"} src={user.image} />
                  <Text
                    as={"b"}
                    fontSize={"14px"}
                    color={Colors.textGray}
                    marginLeft={"4px"}
                    marginRight={"4px"}
                  >
                    {user?.name}
                  </Text>
                  <ChakraIcon icon={faUser} color={Colors.textGray} size={3} />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>

        <Box
          w={"100%"}
          bg={Colors.lightGray}
          paddingLeft={"30px"}
          paddingRight={"30px"}
          borderBottomRadius={25}
        >
          <Textarea
            w={"100%"}
            h={"17rem"}
            my={3}
            resize={"none"}
            placeholder={
              "Въведи детайлно описание на дейностите, които могат да бъдат извършени по тази обява. Сподели информация за професионален опит и методи на работа."
            }
            border={"1px solid"}
            borderColor={Colors.textGray}
            borderRadius={10}
            backgroundColor={"white"}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Text my={2}>
            При изпращане на заявка за тази услуга от балансът ви ще бъде взет{" "}
            <b style={{ color: "red" }}>1лв</b>. Разполагаема сума:{" "}
            <b style={{ color: "green" }}>{craftsMan.user?.balance ?? 0} лв.</b>
          </Text>
          <Flex width={"100%"} alignItems={"center"} justifyContent={"flex-end"} paddingBottom={4}>
            <Button
              size="md"
              height="40px"
              w={"200px"}
              disabled={description.trim() === ""}
              colorScheme="green"
              onClick={() => {
                if (id && description) {
                  apply({
                    serviceRequestId: id,
                    applicationDescription: description,
                  })
                    .unwrap()
                    .then(() => navigate("/applied-service-requests"));
                }
              }}
            >
              Изпрати заявката
            </Button>
          </Flex>
        </Box>
      </Box>
    </Container>
  );
}

const Container = styled.div`
  margin: 0px 0px 40px 0px;
  width: 70%;
  background-color: "white";
  max-height: 885px;
  height: 885px;
`;

export default ApplyForServiceRequestPage;
