import { useCallback, useState } from "react";
import styled from "styled-components";
import Colors from "../../utils/colors";
import { Flex, IconButton, Input, Select } from "@chakra-ui/react";
import Divider from "../Divider";
import { SearchIcon } from "@chakra-ui/icons";
import { updateQuery } from "../../store/querySlice";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../store/State";

function CustomSearch() {
  const [searchTerm, setSearchTerm] = useState("");
  const [type, setType] = useState("service");
  const dispatch = useDispatch();
  const query = useSelector((state: State) => state.query);

  const search = useCallback(
    () => dispatch(updateQuery({ ...query, keyword: searchTerm, type: type, page: 1 })),
    [dispatch, query, searchTerm, type]
  );

  return (
    <InputContainer>
      <Input
        variant="unstyled"
        placeholder="Въведи ключова дума"
        w={"65%"}
        paddingLeft={6}
        color={Colors.textGray}
        fontSize={"1rem"}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            search();
          }
        }}
      />
      <Divider horizontal={false} size={2} secondarySize={30} />

      <Flex flexDir={"column"} w={"10rem"}>
        <Select
          border={"0px solid transparent"}
          cursor={"pointer"}
          outline={"none"}
          fontSize={"1rem"}
          onChange={(e) => setType(e.target.value)}
        >
          <option value="services">Търся Услуга</option>
          <option value="service-requests">Търся Обява</option>
          <option value="articles">Търся Статия</option>
        </Select>
      </Flex>

      <IconButton
        aria-label="Search"
        icon={<SearchIcon />}
        backgroundColor={Colors.textGray}
        borderRadius={"50%"}
        ml={3}
        w={"45px"}
        h={"45px"}
        color={"white"}
        onClick={search}
      />
    </InputContainer>
  );
}

const InputContainer = styled.div`
  height: 3rem;
  width: 40rem;
  border: 1px solid ${Colors.secondaryGray};
  border-radius: 25px;
  display: flex;
  flexdirection: row;
  align-items: center;
  justify-content: space-between;
`;

export default CustomSearch;
