import { appApi } from "../../api/rtk-query-init";
import { ListResponse, ServiceRequest, Tags } from "../../types";
import { ServiceRequestIn } from "../../types/ServiceRequestIn";
import { ServiceRequestDetailsOut } from "../../types/services";
import { SearchParams } from "../types/SearchParams";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    requestServiceRequests: builder.query<ListResponse<ServiceRequest>, SearchParams>({
      providesTags: [Tags.SERVICES],
      query: (params) => ({
        url: "service-requests/query",
        method: "POST",
        body: { query: params ?? {} },
      }),
    }),
    requestServiceRequestDetail: builder.query<ServiceRequestDetailsOut, string>({
      providesTags: [Tags.SERVICES],
      query: (id: string) => `service-requests/${id}`,
    }),
    editServiceRequest: builder.mutation({
      invalidatesTags: [Tags.SERVICE_REQUESTS],
      query: ({ id, body }: { id: string; body: ServiceRequestIn }) => {
        return {
          url: `service-requests/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const {
  useRequestServiceRequestsQuery,
  useRequestServiceRequestDetailQuery,
  useEditServiceRequestMutation,
} = extendedApi;

export default extendedApi;
