import { combineReducers, Reducer } from "@reduxjs/toolkit";

import { State } from "./State";
import { RESET_STATE, LOGOUT } from "../api/actions";
import { appApi } from "../api/rtk-query-init";
import languageReducer from "./languageSlice";
import userReducer from "./userSlice";
import queryReducer from "./querySlice";
import apiReducer from "../api/reducers"
import homePageContentIndexRecuder from './homePageIndexSlice';


const combinedReducer = combineReducers<State>({
    [appApi.reducerPath]: appApi.reducer,
    // @ts-ignore
    api: apiReducer,
    // @ts-ignore
    language: languageReducer,
    // @ts-ignore
    user: userReducer,
    // @ts-ignore
    query: queryReducer,
    // @ts-ignore
    homePageContentIndex: homePageContentIndexRecuder
});

const rootReducer: Reducer<State> = (state, action) => {
    if (action.type === RESET_STATE || action.type === LOGOUT) {
        state = undefined;
    }

    return (combinedReducer as Reducer)(state, action);
};

export default rootReducer;
