import { useCallback, useEffect, useState } from "react";
import { useUploadImageMutation, useRemoveImageMutation } from "../serviceDetails/api/images";
import { useUploadVideoMutation, useRemoveVideoMutation } from "../serviceDetails/api/videos";
import { ImageIn, VideoIn } from "../types/services";

export const useUploadContent = (initialImages?: ImageIn[], initialVideos?: VideoIn[]) => {
  const [images, setImages] = useState<ImageIn[]>(initialImages?.length ? initialImages : []);
  const [videos, setVideos] = useState<VideoIn[]>(initialVideos?.length ? initialVideos : []);
  const [uploadImageApi] = useUploadImageMutation();
  const [removeImageApi] = useRemoveImageMutation();
  const [uploadVideoApi] = useUploadVideoMutation();
  const [removeVideoApi] = useRemoveVideoMutation();

  useEffect(() => {
    setImages(initialImages?.length ? initialImages : []);
    setVideos(initialVideos?.length ? initialVideos : []);
  }, [initialImages, initialVideos]);

  const uploadImage = useCallback(
    (image: string | Blob) => {
      const formData = new FormData();
      formData.append("image", image);
      const index = images.length;

      setImages((images) => {
        return [
          ...images,
          {
            id: "loading-" + index,
            path: "",
            url: "",
          },
        ];
      });

      uploadImageApi(formData)
        .unwrap()
        .then((res) =>
          setImages((images) => {
            const newIndex = images.findIndex((i) => i.id === "loading-" + index);
            const newImages = [...images];
            newImages[newIndex] = res as unknown as ImageIn;
            return newImages;
          })
        )
        .catch(() => setImages(images.splice(index, 1)));
    },
    [images, uploadImageApi]
  );

  const removeImage = useCallback(
    (image: ImageIn) => {
      console.log("images", images);
      console.log("image to delete", image);
      removeImageApi(image.path)
        .unwrap()
        .then((_) => setImages(images.filter((img) => img.id !== image.id)))
        .catch((e) => console.log(e));
    },
    [images, removeImageApi]
  );

  const uploadVideo = useCallback(
    (video: string | Blob) => {
      const formData = new FormData();
      formData.append("video", video);
      const index = videos.length;

      setVideos((videos) => {
        return [
          ...videos,
          {
            id: "loading-" + index,
            path: "",
            url: "",
          },
        ];
      });

      uploadVideoApi(formData)
        .unwrap()
        .then((res) =>
          setVideos((videos) => {
            const newIndex = videos.findIndex((i) => i.id === "loading-" + index);
            const newVideos = [...videos];
            newVideos[newIndex] = res as unknown as VideoIn;
            return newVideos;
          })
        )
        .catch(() => setVideos(videos.splice(index, 1)));
    },
    [videos, uploadVideoApi]
  );

  const removeVideo = useCallback(
    (video: VideoIn) => {
      removeVideoApi(video.path)
        .unwrap()
        .then((_) => setVideos(videos.filter((v) => v.id !== video.id)))
        .catch((e) => console.log(e));
    },
    [removeVideoApi, videos]
  );

  return {
    images,
    videos,
    uploadImage,
    uploadVideo,
    removeImage,
    removeVideo,
  };
};
