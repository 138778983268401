import { appApi } from "../rtk-query-init";
import { Tags } from "../../types";
import { ChatRoomOut } from "../../services/types/ChatRoomOut";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    listChatRooms: builder.query<ChatRoomOut[], string>({
      extraOptions: { maxRetries: 0 },
      providesTags: [Tags.CHAT_ROOMS],
      query: (serviceId: string) => `chat-rooms/service/${serviceId}`,
    }),
    listChatRoomsForServiceRequest: builder.query<ChatRoomOut[], string>({
      extraOptions: { maxRetries: 0 },
      providesTags: [Tags.CHAT_ROOMS],
      query: (serviceRequestId: string) => `chat-rooms/service-request/${serviceRequestId}`,
    }),
  }),
});

export const { useListChatRoomsQuery, useListChatRoomsForServiceRequestQuery } = extendedApi;

export default extendedApi;
