import { Grid, GridItem, Flex, Box, Text } from "@chakra-ui/react";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetMyAppliedServicesQuery } from "./api/Services";
import { TopBar } from "./TopNavigationBar";
import { StatusButton } from "./ApplicationStatusButton";
import { AppliedServiceOut, ApplicationStatus } from "./types/AppliedServiceOut";
import { AppliedServiceCard } from "./components/AppliedServiceCard";
import { useNavigate } from "react-router-dom";
import RequestedServiceChat from "../services/RequestedServiceChat";
import {
  isServiceInProgress,
  isServiceRequested,
  isServiceCompleted,
  isServiceNotRequested,
} from "../utils/services";

export enum RequestedServiceSelectionStatus {
  ALL,
  REQUESTED,
  ACTIVE,
  COMPLETED,
  DECLINED,
}

function MyRequestedServicesPage() {
  const [selectedStatus, setSelectedStatus] = useState<RequestedServiceSelectionStatus>(
    RequestedServiceSelectionStatus.REQUESTED
  );
  const [selectedService, setSelectedService] = useState<AppliedServiceOut | undefined>();

  const { data: services, refetch } = useGetMyAppliedServicesQuery();
  const navigate = useNavigate();

  const activeChatId = useMemo(() => {
    if (selectedService) {
      if (isServiceInProgress(selectedService)) {
        const result = selectedService.applications.find((s) => s.status === ApplicationStatus.IN_PROGRESS);
        return result?.chatRoomId;
      }
      if (isServiceRequested(selectedService)) {
        const result = selectedService.applications.find((s) => s.status === ApplicationStatus.IN_PROGRESS);
        return result?.chatRoomId;
      }
      if (isServiceCompleted(selectedService)) {
        const result = selectedService.applications.find((s) =>
          [ApplicationStatus.COMPLETED, ApplicationStatus.REVIEWED].includes(s.status)
        );
        return result?.chatRoomId;
      }
    }

    return [...(selectedService?.applications ?? [])]?.sort(
      (s1, s2) => Date.parse(s2.createdAt) - Date.parse(s1.createdAt)
    )?.[0]?.chatRoomId;
  }, [selectedService]);

  const filteredServices = useMemo(() => {
    return services?.filter((service: AppliedServiceOut) => {
      if (selectedStatus === RequestedServiceSelectionStatus.REQUESTED)
        return service.applications.find((s) => s.status === ApplicationStatus.REQUESTED);

      if (selectedStatus === RequestedServiceSelectionStatus.ACTIVE)
        return service.applications.find((s) => s.status === ApplicationStatus.IN_PROGRESS);

      if (selectedStatus === RequestedServiceSelectionStatus.COMPLETED) {
        return service.applications.find((s) =>
          [ApplicationStatus.COMPLETED, ApplicationStatus.REVIEWED].includes(s.status)
        );
      }

      if (selectedStatus === RequestedServiceSelectionStatus.DECLINED) {
        return service.applications.find((s) => s.status === ApplicationStatus.DECLINED);
      }
      return false;
    });
  }, [selectedStatus, services]);

  const onSelectService = useCallback(
    (service: AppliedServiceOut) => {
      if (!isServiceNotRequested(service)) {
        setSelectedService(service);
      } else {
        navigate(`/service/${service.id}`);
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (selectedService) {
      const service = services?.find((s: AppliedServiceOut) => s.id === selectedService.id);
      setSelectedService(service);
    }
  }, [services, selectedService]);

  return (
    <Container>
      <TopBar />
      <RequestedServiceChat
        isOpen={!!selectedService}
        onClose={() => {
          setSelectedService(undefined);
        }}
        service={selectedService}
        onChangeStatus={refetch}
        activeChatId={activeChatId}
      />
      <Grid templateColumns="repeat(20, 1fr)" gap={6} w={"1300px"} mt={6}>
        <GridItem colSpan={4}>
          <Flex direction={"column"} alignItems={"left"} maxW={"95%"}>
            <StatusButton
              title="Заявени"
              selected={selectedStatus === RequestedServiceSelectionStatus.REQUESTED}
              onClick={() => setSelectedStatus(RequestedServiceSelectionStatus.REQUESTED)}
            />
            <StatusButton
              title="Активни"
              selected={selectedStatus === RequestedServiceSelectionStatus.ACTIVE}
              onClick={() => setSelectedStatus(RequestedServiceSelectionStatus.ACTIVE)}
            />
            <StatusButton
              title="Завършени"
              selected={selectedStatus === RequestedServiceSelectionStatus.COMPLETED}
              onClick={() => setSelectedStatus(RequestedServiceSelectionStatus.COMPLETED)}
            />
            <StatusButton
              title="Отказани"
              selected={selectedStatus === RequestedServiceSelectionStatus.DECLINED}
              onClick={() => setSelectedStatus(RequestedServiceSelectionStatus.DECLINED)}
            />
          </Flex>
        </GridItem>
        <GridItem colSpan={1} />
        <GridItem colSpan={15}>
          {filteredServices && filteredServices.length ? (
            <Box flex={1}>
              {filteredServices?.map((service: AppliedServiceOut) => (
                <AppliedServiceCard
                  key={service.id}
                  service={service}
                  selectedStatus={selectedStatus}
                  onClick={() =>
                    selectedStatus === RequestedServiceSelectionStatus.REQUESTED
                      ? navigate(`/service/${service.id}`)
                      : onSelectService(service)
                  }
                />
              ))}
            </Box>
          ) : (
            <Text w="75%" fontSize={20} pt={20} textAlign={"center"}>
              Нямате все още заявени услуги
            </Text>
          )}
        </GridItem>
      </Grid>
    </Container>
  );
}

const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default MyRequestedServicesPage;
