import { Flex, Input, Button } from "@chakra-ui/react";
import { FaPaperPlane } from "react-icons/fa";
import Colors from "../utils/colors";
import { useEffect, useRef } from "react";
import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import { fromLatLng, OutputFormat, setDefaults } from "react-geocode";

const types = ["address"];
const GOOGLE_PLACES_CONFIG = {
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
  libraries: ["places"] as Libraries,
};

const AUTOCOMPLETE_CONFIG = {
  types,
  componentRestrictions: { country: "BG" },
};

setDefaults({
  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  language: "bg",
  region: "bg",
  outputFormat: OutputFormat.JSON,
});

const fetchUserLocation = (
  setAddress: (x: any) => void,
  inputRef: React.RefObject<HTMLInputElement> | null
) => {
  if (!navigator.geolocation) {
    alert("Geolocation is not supported by your browser");
    return;
  }

  navigator.geolocation.getCurrentPosition(
    (position) => {
      if (!inputRef || !inputRef.current) return;

      const { latitude, longitude } = position.coords;
      fromLatLng(latitude, longitude).then(({ results }) => {
        setAddress({
          formatted_address: results[0].formatted_address,
          geometry: {
            location: {
              lat: () => latitude,
              lng: () => longitude,
            },
          },
        });
      });
    },
    (error) => {
      console.error("Error fetching location:", error);
      alert("Unable to fetch location. Please try again.");
    }
  );
};

export const LocationInput = ({
  address,
  onChange,
  onPickAddress,
}: {
  address: google.maps.places.PlaceResult | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPickAddress: (a: google.maps.places.PlaceResult | null) => void;
}) => {
  // refs
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { isLoaded } = useJsApiLoader(GOOGLE_PLACES_CONFIG);

  useEffect(() => {
    if (isLoaded && inputRef.current) {
      autocompleteRef.current = new google.maps.places.Autocomplete(
        inputRef.current,
        AUTOCOMPLETE_CONFIG
      );

      autocompleteRef.current.addListener("place_changed", () => {
        const place = autocompleteRef.current?.getPlace();
        if (place && place.formatted_address) {
          onPickAddress(place);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, inputRef.current]);

  return (
    <Flex
      borderColor={Colors.borderGray}
      border={"1px solid"}
      borderRadius={"50px"}
      paddingLeft={"15px"}
      backgroundColor={"white"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Input
        ref={inputRef}
        value={address?.formatted_address || ""}
        onChange={onChange}
        size="lg"
        border={"none"}
        focusBorderColor="transparent"
        placeholder="Въведи улица, вход, апартамент..."
        color={Colors.textGray}
        fontSize={14}
      />
      <Button
        size="md"
        variant="solid"
        leftIcon={<FaPaperPlane />}
        fontSize={12}
        width={"300px"}
        backgroundColor={"#E3E5F1"}
        color={Colors.primaryBlue}
        borderRadius={"50px"}
        mr={1}
        colorScheme="blue"
        onClick={() => fetchUserLocation(onPickAddress, inputRef)}
      >
        Текущото местоположение
      </Button>
    </Flex>
  );
};
