import { useCallback, useEffect, useState } from "react";
import { VStack, HStack, Text, Textarea, Button } from "@chakra-ui/react";
import Rating from "react-rating-stars-component";
import Divider from "../../components/Divider";
import { ReviewIn, ReviewRating } from "../../types/ReviewIn";
import Colors from "../../utils/colors";

const RatingWidget = ({
  onReviewComplete,
  ratingsFilled,
  disabled,
}: {
  onReviewComplete?: (review: ReviewIn) => void;
  ratingsFilled?: ReviewRating[];
  disabled?: boolean;
}) => {
  const [ratings, setRatings] = useState({});

  useEffect(() => {
    if (ratingsFilled) {
      setRatings((ratings) => {
        const newRatings = { ...ratings };
        ratingsFilled.forEach((review) => {
          (newRatings as any)[review.category] = review.rating;
        });
        return newRatings;
      });
    } else {
      setRatings({
        "Качество на усугите": 3,
        "Срок на изпълнение": 3,
        "Коректно отношение": 3,
        Комуникация: 3,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratingsFilled]);

  const [review, setReview] = useState("");

  const onSendReview = useCallback(() => {
    onReviewComplete?.({
      reviews: Object.entries(ratings).map(([category, rating]) => ({
        category,
        rating,
      })) as unknown as ReviewRating[],
      comment: review,
    });
  }, [onReviewComplete, ratings, review]);

  console.log("ratings", ratings);

  return (
    <VStack spacing={1} align="start" p={3} bg="gray.50" borderRadius="lg">
      <Divider size={1} backgroundColor="black" />
      {Object.keys(ratings).map((category) => (
        <HStack key={category} justify="flex-end" w="full">
          <Text fontSize="md" fontWeight="bold" color={Colors.darkPurple} mr={2}>
            {category}
          </Text>
          <Rating
            count={5}
            disabled={Boolean(ratingsFilled) || disabled}
            value={(ratings as any)[category as string]}
            onChange={(value: number) => setRatings((prev) => ({ ...prev, [category]: value }))}
            size={20}
            isHalf={true}
            activeColor="#ffd700"
          />
        </HStack>
      ))}
      {!ratingsFilled && (
        <Textarea
          placeholder="Напиши ревю за извършената услуга"
          bg="gray.100"
          resize={"none"}
          borderRadius="md"
          value={review}
          mt={2}
          mb={2}
          onChange={(e) => setReview(e.target.value)}
        />
      )}

      {onReviewComplete && (
        <HStack justify="center" w="full" mt={2}>
          <Button
            bg="yellow.400"
            color="gray.900"
            borderRadius="full"
            px={6}
            _hover={{ bg: "yellow.500" }}
            onClick={onSendReview}
          >
            Потвърди оценката
          </Button>
        </HStack>
      )}
    </VStack>
  );
};

export default RatingWidget;
