import { Box, Text, Image, Flex, Icon, SimpleGrid, Avatar } from "@chakra-ui/react";
import { FaStar } from "react-icons/fa";
import Colors from "../../utils/colors";
import { ServiceRequest } from "../../types";

const ServiceRequestCard = ({ serviceRequest }: { serviceRequest: ServiceRequest }) => {
  const user = serviceRequest?.user;

  return (
    <Box
      borderRadius="md"
      overflow="hidden"
      backgroundColor="white"
      border="1px solid"
      borderColor={Colors.borderGray}
      boxShadow="md"
    >
      {serviceRequest.image ? (
        <Image
          src={serviceRequest.image}
          alt={serviceRequest.title}
          objectFit="cover"
          width="100%"
          height="150px"
        />
      ) : (
        <Box
          width="100%"
          height="150px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor={Colors.lightGray}
        >
          <Icon as={FaStar} color="gray.400" boxSize="3rem" />
        </Box>
      )}
      <Box padding="12px">
        {user && (
          <Flex alignItems="center" marginBottom="8px">
            <Avatar src={user?.image} size="sm" marginRight="8px" />
            <Text fontWeight="bold" color="gray.700">
              {user.firstName} {user.lastName}
            </Text>
          </Flex>
        )}
        <Text fontWeight="bold" color="gray.700" marginBottom="4px" isTruncated>
          {serviceRequest.title}
        </Text>
        <Flex alignItems="center">
          <Icon as={FaStar} color="gray.400" marginRight="4px" />
          <Text color="gray.500" fontSize="14px">
            {serviceRequest.city}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

const SimilarServiceRequests = ({
  similarServiceRequests,
}: {
  similarServiceRequests?: ServiceRequest[];
}) => {
  return (
    <Box width="100%" margin="0 auto">
      <Text fontSize="22px" marginBottom="16px" color="gray.600" fontWeight="200">
        Подобни обяви
      </Text>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing="16px">
        {similarServiceRequests?.map((serviceRequest, index) => (
          <ServiceRequestCard key={index} serviceRequest={serviceRequest} />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default SimilarServiceRequests;
