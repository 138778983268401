import {
  Modal,
  ModalContent,
  ModalBody,
  Button,
  ModalOverlay,
  Text,
  ModalHeader,
  Flex,
  Image,
  Textarea,
  Input,
  IconButton,
  Box,
} from "@chakra-ui/react";
import Colors from "../../utils/colors";
import Spacer from "../../components/Spacer";
import remont from "../../assets/remont.svg";
import pin from "../../assets/pin.svg";
import hardHat from "../../assets/hardhat.svg";
import { ServiceApplicationIn, ServiceDetailsOut } from "../../types/services";
import ImageOrVideoSelectButton from "../../components/ImageOrVideoSelectButton";
import InputWithIcon from "../../components/InputWithIcon";
import { useState, useEffect, useCallback } from "react";
import { useUploadContent } from "../../hooks/useUploadContent";
import { UploadedContent } from "../../components/UploadedContent";
import { FaTimes } from "react-icons/fa";
import { useApplyForServiceMutation } from "../api/services";
import { useNavigate } from "react-router-dom";
import { LocationInput } from "../../components/LocationInput";
import placeholder from "../../assets/placeholder.svg";

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  service?: ServiceDetailsOut;
}

function RequestServiceModal({ isOpen, onClose, service }: ModalProps) {
  // state
  const [address, setAddress] = useState<google.maps.places.PlaceResult | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [description, setDescription] = useState("");
  const [visitDateTime, setVisitDateTime] = useState<string | undefined>(undefined);
  const [addressError, setAddressError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  // hooks
  const { images, videos, uploadImage, uploadVideo, removeImage, removeVideo } = useUploadContent();
  const [applyForService, { isLoading, error, isError, isSuccess }] = useApplyForServiceMutation();
  const navigate = useNavigate();

  // callbacks
  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      formatted_address: e.target.value,
    }));
    setAddressError(false);
  };

  const handleChangeDescription = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescriptionError(false);
    setDescription(e.target.value ?? "");
  }, []);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const phoneRegex = /^[+]?\d*$/;

    if (value && !phoneRegex.test(value)) {
      setPhoneError("Невалиден телефонен номер.");
    } else {
      setPhoneError(null);
    }

    setPhoneNumber(value);
  };

  const handleFormSubmit = useCallback(() => {
    let hasError = false;

    if (!address?.formatted_address) {
      setAddressError(true);
      hasError = true;
    } else {
      setAddressError(false);
    }

    if (!description.trim()) {
      setDescriptionError(true);
      hasError = true;
    } else {
      setDescriptionError(false);
    }

    if (!hasError && service) {
      const data: ServiceApplicationIn = {
        description,
        images,
        videos,
        visitDateTime,
        address: address?.formatted_address ?? "",
        location: {
          latitude: address?.geometry?.location?.lat() ?? 0,
          longitude: address?.geometry?.location?.lng() ?? 0,
        },
        phoneNumber,
      };

      applyForService({ serviceId: service.id, ...data });
    }
  }, [
    address?.formatted_address,
    address?.geometry?.location,
    applyForService,
    description,
    images,
    phoneNumber,
    service,
    videos,
    visitDateTime,
  ]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/services-requested");
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (isError) {
      alert((error as any)?.message);
    }
  }, [error, isError, isSuccess, navigate]);

  if (!service) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered variant={"authDialog"}>
      <ModalOverlay />

      <ModalContent backgroundColor={Colors.lightGray} w="828px" minW="828px">
        <IconButton
          aria-label="Close video player"
          icon={<FaTimes />}
          onClick={onClose}
          position="absolute"
          top="20px"
          right="20px"
          zIndex={1}
          background="rgba(1, 1, 1, 0.2)"
          _hover={{ background: "rgba(255, 0, 0, 0.4)" }}
          size="sm"
          color="white"
        />
        <ModalHeader backgroundColor={Colors.lightGray} borderTopRadius={"25px"}>
          <Flex w={"100%"} alignItems={"center"} justifyContent={"center"}>
            <Text fontSize={"24px"} fontFamily={"Inter"} as={"b"} color={Colors.textGray}>
              Заявка за услуга
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex flexDir={"column"}>
            <Flex
              backgroundColor={"white"}
              paddingLeft={2}
              paddingRight={"40px"}
              paddingTop="10px"
              paddingBottom="10px"
              borderRadius={10}
            >
              <Image
                src={
                  service.images?.[0] && service.images?.[0]?.trim().length > 0
                    ? service.images[0]
                    : placeholder
                }
                w={160}
                h={100}
                marginRight={"15px"}
                borderRadius={"20px"}
              />
              <Flex flexDir={"column"}>
                <Text
                  fontSize={"18px"}
                  fontFamily={"Inter"}
                  as={"b"}
                  marginTop={"4px"}
                  marginBottom="8px"
                  maxWidth={"30rem"}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {service?.title}
                </Text>
                <Flex>
                  <Image src={remont} color={Colors.textGray} marginRight={"4px"} w={"16px"} h={"16px"} />
                  <Text fontSize={14} fontFamily={"Inter"} color={Colors.textGray}>
                    {service?.category}
                  </Text>
                  <Spacer horizontal />
                  <Image src={pin} color={Colors.textGray} marginRight={"4px"} w={"12px"} h={"16px"} />
                  <Text fontSize={14} fontFamily={"Inter"} color={Colors.textGray}>
                    {service?.city}
                  </Text>
                </Flex>
                <Flex alignItems={"center"} marginTop="6px">
                  <Image
                    borderRadius="full"
                    boxSize="36px"
                    src={service?.craftsMan?.image}
                    alt={service?.craftsMan?.name}
                    marginRight="8px"
                  />
                  <Text fontSize={14} fontFamily={"Inter"} as="b" marginRight="8px" color={Colors.textGray}>
                    {service?.craftsMan?.name}
                  </Text>
                  <Image src={hardHat} color={Colors.textGray} marginRight={"4px"} w={"16px"} h={"14px"} />
                </Flex>
              </Flex>
            </Flex>
            <Flex flexDir={"column"} paddingTop={"20px"} backgroundColor={Colors.lightGray}>
              <Flex flexDir={"column"} w="100%">
                <Text fontSize={12} fontFamily={"Inter"} as="b" mb={1}>
                  Точен адрес *
                </Text>
                <LocationInput
                  address={address}
                  onChange={handleChangeAddress}
                  onPickAddress={(a) => setAddress(a)}
                />
                {addressError && (
                  <Text color="red.500" fontSize="sm" mt={2}>
                    Моля попълнете адрес
                  </Text>
                )}
              </Flex>
              <Flex
                flexDir={"row"}
                paddingTop={"20px"}
                paddingBottom={"20px"}
                justifyContent="space-between"
                alignItems={"center"}
                backgroundColor={Colors.lightGray}
              >
                <Flex flexDir={"column"} w="47%">
                  <Flex flexDir={"row"}>
                    <Text fontSize={12} fontFamily={"Inter"} as="b" mb={1}>
                      Телефон
                    </Text>
                    <Text fontSize={12} ml={1} fontFamily={"Inter"} color={Colors.lightTextGray}>
                      (незадължително)
                    </Text>
                  </Flex>
                  <Input
                    onChange={handlePhoneChange}
                    borderColor={Colors.borderGray}
                    border={"1px solid"}
                    borderRadius={"50px"}
                    type="phone"
                    size="lg"
                    paddingLeft={"30px"}
                    backgroundColor={"white"}
                    placeholder="Въведете телефонен номер"
                    color={Colors.textGray}
                    fontSize={14}
                  />
                  {phoneError && (
                    <Text color="red.500" fontSize="sm" mt={2}>
                      Моля попълнете валиден телефон
                    </Text>
                  )}
                </Flex>

                <Flex flexDir={"column"} w="47%">
                  <Flex flexDir={"row"} alignItems="center">
                    <Text fontSize={12} fontFamily={"Inter"} as="b" mb={1}>
                      Посещение за оглед
                    </Text>
                    <Text fontSize={12} ml={1} fontFamily={"Inter"} color={Colors.lightTextGray}>
                      (незадължително)
                    </Text>
                  </Flex>
                  <InputWithIcon
                    borderColor={Colors.borderGray}
                    border={"1px solid"}
                    borderRadius={"50px"}
                    size="lg"
                    paddingLeft={"20px"}
                    backgroundColor={"white"}
                    placeholder="Въведи ден и час"
                    color={Colors.textGray}
                    fontSize={14}
                    onChange={(e) => {
                      setVisitDateTime(e.target.value);
                    }}
                    isDateInput
                    minDate={new Date()}
                    label={""}
                    _placeholder={undefined}
                  />
                </Flex>
              </Flex>
              <Flex flexDir={"column"} marginBottom={"20px"}>
                <Text fontSize={12} fontFamily={"Inter"} as="b">
                  Описание *
                </Text>
                <Flex border={"1px solid"} flexDir={"column"} borderRadius={"20px"} backgroundColor={"white"}>
                  <Textarea
                    value={description}
                    onChange={(e) => handleChangeDescription(e)}
                    variant={"unstyled"}
                    padding="20px"
                    w={"100%"}
                    h={"160px"}
                    resize={"none"}
                    placeholder="Въведи детайлно описание на дейностите, които трябва да бъда извършени."
                  />
                  {descriptionError && (
                    <Text color="red.500" fontSize="sm" mt={2} ml={"16px"} mb={2}>
                      Моля въведете описание
                    </Text>
                  )}
                  <Box borderTop={"1px solid"} padding="4px">
                    <UploadedContent
                      images={images}
                      videos={videos}
                      onRemoveImage={removeImage}
                      onRemoveVideo={removeVideo}
                    />

                    <Flex justifyContent={"space-between"}>
                      <ImageOrVideoSelectButton onContent={(files) => files.forEach(uploadImage)} />
                      <Box height={"35px"} width={"1px"} borderLeft={"1px solid"} />
                      <ImageOrVideoSelectButton
                        isVideo={true}
                        onContent={(files) => files.forEach(uploadVideo)}
                      />
                    </Flex>
                  </Box>
                </Flex>
              </Flex>

              <Button
                w={"224px"}
                h="50px"
                alignSelf="center"
                backgroundColor={Colors.primaryBlue}
                color="white"
                fontFamily={"Inter"}
                as={"b"}
                fontSize="18px"
                borderRadius={50}
                disabled={isLoading}
                onClick={handleFormSubmit}
              >
                Изпрати заявка
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default RequestServiceModal;
