import { appApi } from "../../api/rtk-query-init";
import { Tags } from "../../types";
import { getBaseAPIUrl } from "../../store/utils";
import { ServiceIn } from "../../types/ServiceIn";
import { ServiceRequestIn } from "../../types/ServiceRequestIn";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    postImage: builder.mutation({
      query(body) {
        return {
          url: "images",
          method: "POST",
          body,
          headers: {
            "Content-Type": "multipart/form-data;",
          },
        };
      },
    }),
    publishService: builder.mutation<{ id: string }, ServiceIn>({
      query(body) {
        return {
          url: "services/",
          method: "POST",
          body,
          invalidatesTags: [Tags.SERVICES],
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
    publishServiceRequest: builder.mutation<{ id: string }, ServiceRequestIn>({
      query(body) {
        return {
          url: "service-requests/",
          method: "POST",
          body,
          invalidatesTags: [Tags.SERVICE_REQUESTS],
        };
      },
      extraOptions: { maxRetries: 0 },
    }),
  }),
});

export const { usePostImageMutation, usePublishServiceMutation, usePublishServiceRequestMutation } =
  extendedApi;

export default extendedApi;
