import { createSlice } from "@reduxjs/toolkit";

import loginApi from "../login";
import { User } from "../../types";

const initialState: {
  token: string | null;
  user: User | null;
} = {
  token: null,
  user: null,
};

const setUserAuthDetails = (state: { token: any; user: any }, { payload }: any) => {
  state.token = payload.payload.token;
  state.user = payload.payload.user;
  localStorage.setItem("token", state.token ?? "");
  localStorage.setItem("user", JSON.stringify(payload.payload.user));
};

const authSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addMatcher(loginApi.endpoints.loginUser.matchFulfilled, setUserAuthDetails)
      .addMatcher(loginApi.endpoints.registerUser.matchFulfilled, setUserAuthDetails)
      .addMatcher(loginApi.endpoints.loginCraftsMan.matchFulfilled, setUserAuthDetails)
      .addMatcher(loginApi.endpoints.registerCraftsMan.matchFulfilled, setUserAuthDetails);
  },
  initialState,
  name: "api",
  reducers: {
    clearAuthCredentials: (state) => {
      state.token = null;
      state.user = null;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
  },
});
export const { clearAuthCredentials, setToken, setUser } = authSlice.actions;
export default authSlice.reducer;
