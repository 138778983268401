import { Flex, Text, Image, useToast } from "@chakra-ui/react";
import background from "../assets/background-service.svg";
import styled from "styled-components";
import { useAuth } from "../utils/AuthProvider";
import { CraftsManIn, SecurityIn } from "../types/CraftsManIn";
import ProfileData from "../registration/components/ProfileData";
import { useCallback, useState } from "react";
import { ErrorDataMap } from "../registration/Registration";
import {
  useUpdateCraftsManProfileMutation,
  useUpdateProfileSecurityMutation,
  useUpdateUserProfileMutation,
  useUpdateUserProfileSecurityMutation,
} from "./api/profile";
import { CraftButton } from "../components/CraftButton";
import {
  step2Schema as craftsManProfileSchema,
  securitySchema,
  userProfilSchema,
} from "../registration/utils/validations";
import * as Yup from "yup";
import { setUser } from "../api/reducers";
import { useDispatch } from "react-redux";
import Colors from "../utils/colors";
import Security from "./components/Security";
import { UserIn } from "../types/UserIn";

export type SecurityErrorDataMap = {
  [key in keyof SecurityIn]?: string;
};

export const ProfilePage = () => {
  const { user } = useAuth();

  const dispatch = useDispatch();
  const [userData, setUserData] = useState<CraftsManIn | UserIn>({
    ...user,
  } as unknown as CraftsManIn);
  const [securityData, setSecurityData] = useState<SecurityIn>({
    oldPassword: "",
    newPassword: "",
  });

  const [errors, setErrors] = useState<ErrorDataMap>({});
  const [securityErrors, setSecurityErrors] = useState<SecurityErrorDataMap>({});
  const [updateProfile] = useUpdateCraftsManProfileMutation();
  const [updateProfileSecurity] = useUpdateProfileSecurityMutation();

  const [updateUserProfile] = useUpdateUserProfileMutation();
  const [updateUserProfileSecurity] = useUpdateUserProfileSecurityMutation();

  const toast = useToast();

  const updateProfileData = useCallback(() => {
    const validationSchema = user?.isCraftsMan ? craftsManProfileSchema : userProfilSchema;
    const mutation = user?.isCraftsMan ? updateProfile : updateUserProfile;

    validationSchema
      .validate(userData, { abortEarly: false })
      .then(() => {
        mutation(userData as any)
          .unwrap()
          .then((res) => {
            dispatch(setUser(res?.payload?.user));
            toast({
              title: "Успешно обновен профил",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          })
          .catch((err) => {
            toast({
              title: "Възникна грешка, моля опитайте отново",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          });
      })
      .catch((err: Yup.ValidationError) => {
        const validationErrors: ErrorDataMap = err.inner.reduce((acc: ErrorDataMap, curr) => {
          if (curr.path) {
            acc[curr.path as keyof CraftsManIn] = curr.message;
          }
          return acc;
        }, {});
        setErrors(validationErrors);
      });
  }, [user?.isCraftsMan, updateProfile, updateUserProfile, userData, dispatch, toast]);

  const updateSecurityData = useCallback(() => {
    const mutation = user?.isCraftsMan ? updateProfileSecurity : updateUserProfileSecurity;

    securitySchema
      .validate(securityData, { abortEarly: false })
      .then(() => {
        mutation(securityData)
          .unwrap()
          .then(() => {
            toast({
              title: "Успешно обновенa парола",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          })
          .catch((e) => {
            toast({
              title: e?.data?.message ?? "Възникна грешка, моля опитайте отново",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          });
      })
      .catch((err: Yup.ValidationError) => {
        const validationErrors: SecurityErrorDataMap = err.inner.reduce((acc: SecurityErrorDataMap, curr) => {
          if (curr.path) {
            acc[curr.path as keyof SecurityIn] = curr.message;
          }
          return acc;
        }, {});
        setSecurityErrors(validationErrors);
      });
  }, [securityData, toast, updateProfileSecurity, updateUserProfileSecurity, user?.isCraftsMan]);

  return (
    <HeaderContainer className="container">
      <Flex
        width="99.5dvw"
        paddingX={"10%"}
        maxWidth={"99.5dvw"}
        mt={4}
        height={100}
        backgroundImage={background}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text fontSize={24} fontWeight={"bold"}>
          Настройки
        </Text>
        <Flex direction="row" alignItems="center">
          <Image
            borderRadius="full"
            width={"3rem"}
            mr={3}
            src={user?.image?.url ?? "user.png"}
            boxSize="3rem"
            alt="craftsMan"
          />

          <Text fontWeight="bold" fontSize={16}>
            {user?.firstName} {user?.lastName}
          </Text>
        </Flex>
      </Flex>

      <Flex
        flexDirection={"column"}
        my={5}
        alignItems={"flex-end"}
        width={"50rem"}
        mx="auto"
        backgroundColor={Colors.lightGray}
        borderRadius={10}
        padding={25}
      >
        <ProfileData
          errors={errors}
          userData={userData}
          setUserData={setUserData}
          profileMode={user?.isCraftsMan ? "craftsman" : "user"}
        />

        <Flex my={5}>
          <CraftButton title={"Запази промените"} onClick={updateProfileData} />
        </Flex>
      </Flex>

      <Flex
        flexDirection={"column"}
        my={5}
        alignItems={"flex-end"}
        width={"50rem"}
        mx="auto"
        backgroundColor={Colors.lightGray}
        borderRadius={10}
        padding={25}
      >
        <Security errors={securityErrors} securityData={securityData} setSecurityData={setSecurityData} />
        <Flex my={5}>
          <CraftButton title={"Запази промените"} onClick={updateSecurityData} />
        </Flex>
      </Flex>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  width: "100%";
`;
