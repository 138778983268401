import { appApi } from "../rtk-query-init";
import { Tags, Category } from "../../types";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    requestCategories: builder.query<Category[], void>({
      providesTags: [Tags.CATEGORIES],
      query: () => "categories/categories",
    }),
  }),
});

export const { useRequestCategoriesQuery } = extendedApi;

export default extendedApi;
