import React, { useMemo, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Image,
  Button,
  Stack,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
} from "@chakra-ui/react";
import { FaImages } from "react-icons/fa";
import { FaCirclePlay } from "react-icons/fa6";
import placeholder from "../../assets/placeholder.svg";
import { ServiceDetailsOut } from "../../types/services";
import ImageGallery from "react-image-gallery";
import DOMPurify from "dompurify";
import remont from "../../assets/remont.svg";
import pin from "../../assets/pin.svg";
import Colors from "../../utils/colors";
import { VideoPlayer } from "./VideoPlayerModal";

export const ServiceDescription = ({
  title,
  description,
  images,
  videos,
  city,
  category,
  subCategories,
}: ServiceDetailsOut) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const [isPlayerOpen, setIsPlayerOpen] = useState(false);
  const openPlayer = () => setIsPlayerOpen(true);
  const closePlayer = () => setIsPlayerOpen(false);

  const sanitizedHtml = useMemo(() => DOMPurify.sanitize(description), [description]);
  // TODO: BE to store/return thumbnail
  const testImages = images.map((image) => ({ original: image, thumbnail: image }));

  return (
    <Box p={4}>
      <Heading as="h1" size="xl" mb={4}>
        {title}
      </Heading>
      <Flex w="100%" mb={3}>
        <Image src={pin} color={Colors.textGray} width={"12px"} height={"16px"} />
        <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
          {city}
        </Text>
        <Image src={remont} color={Colors.textGray} width={"16px"} height={"16px"} ml={5} />
        <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
          {category + " "}
          {subCategories?.slice(0, 4).join(", ")}
        </Text>
      </Flex>

      <Flex direction={"row"} mb={8}>
        {images && images.length > 0 ? (
          <>
            <Image
              src={images[0]}
              alt="Main Image"
              w="100%"
              maxHeight="24rem"
              objectFit="contain"
              mr={4}
              borderRadius={10}
              cursor={"pointer"}
            />
            <Stack spacing={7} width={"34%"}>
              {images.slice(1, 3).map((image, index) => (
                <Image
                  key={index}
                  src={image}
                  maxHeight="200px"
                  width="100%"
                  objectFit="cover"
                  borderRadius={10}
                  cursor={"pointer"}
                />
              ))}
            </Stack>
          </>
        ) : (
          <Box
            w="100%"
            h="300px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bg="gray.100"
            borderRadius={10}
          >
            <Image src={placeholder} alt="No images available" maxH="200px" />
          </Box>
        )}
      </Flex>
      <Flex mb={10}>
        {images && images.length > 0 && (
          <Button
            leftIcon={<Icon as={FaImages} size={16} />}
            colorScheme="gray"
            variant="outline"
            borderRadius="full"
            borderColor="gray.500"
            color="gray.500"
            _hover={{ bg: "gray.100" }}
            mr={4}
            onClick={openModal}
          >
            Виж всички снимки
          </Button>
        )}
        {videos && videos.length > 0 && (
          <Button
            leftIcon={<Icon as={FaCirclePlay} />}
            colorScheme="gray"
            variant="outline"
            borderRadius="full"
            borderColor="gray.500"
            color="gray.500"
            _hover={{ bg: "gray.100" }}
            onClick={openPlayer}
          >
            Изгледай видео
          </Button>
        )}
      </Flex>

      <Box mb={4}>
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
      </Box>

      {images && images.length > 0 && (
        <Modal isOpen={isOpen} onClose={closeModal} size="full">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Снимки</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ImageGallery items={testImages} />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="purple" mr={3} onClick={closeModal}>
                Затвори
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <VideoPlayer url={videos?.[0]} isPlayerOpen={isPlayerOpen} closePlayer={closePlayer} />
    </Box>
  );
};

export default ServiceDescription;
