import { appApi } from "../../api/rtk-query-init";
import { Tags } from "../../types";
import { Transaction } from "../../types/Payments";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getTransactions: builder.query<Transaction[], void>({
      query: () => ({
        url: "payments/transactions",
        method: "GET",
      }),
      providesTags: [Tags.TRANSACTIONS],
      transformResponse: (response: Transaction[]) =>
        response.map((r) => ({
          ...r,
          amount: r.amount / 100,
          createdAt: new Date(r.createdAt.toString()),
        })),
    }),
  }),
});

export const { useGetTransactionsQuery } = extendedApi;

export default extendedApi;
