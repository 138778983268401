import Colors from "../utils/colors";

interface DividerProps {
  horizontal?: boolean;
  size?: number;
  secondarySize?: number;
  backgroundColor?: string;
}

export default function Divider({
  horizontal = true,
  size = 1,
  secondarySize,
  backgroundColor,
}: DividerProps) {
  return (
    <div
      style={
        horizontal
          ? {
              height: size,
              width: secondarySize ? secondarySize : "100%",
              backgroundColor: backgroundColor ?? Colors.borderGray,
            }
          : {
              width: size,
              height: secondarySize ? secondarySize : "100%",
              backgroundColor: backgroundColor ?? Colors.borderGray,
            }
      }
    ></div>
  );
}
