import { Grid, GridItem, Flex, Box, useDisclosure, Text } from "@chakra-ui/react";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TopBar } from "./TopNavigationBar";
import { StatusButton } from "./ApplicationStatusButton";
import { ApplicationStatus } from "./types/AppliedServiceOut";
import { useGetMyServiceRequestsQuery, useRemoveServiceRequestMutation } from "./api/ServiceRequests";
import { ServiceRequestCard } from "./components/ServiceRequestCard";
import { useNavigate } from "react-router-dom";
import EditServiceRequestDetailsModal from "../serviceRequestDetails/EditServiceRequestDetailsModal";
import { PublishedServiceRequest } from "../types/PublishedServiceRequest";
import {
  isServiceCompleted,
  isServiceInProgress,
  isServiceNotRequested,
  isServiceRequested,
} from "../utils/serviceRequests";
import ServiceRequestChat from "./components/ServiceRequestChat";
import ConfirmationModal from "../components/ConfirmationModal";
import Colors from "../utils/colors";

function MyServiceRequestsPage() {
  const [selectedStatus, setSelectedStatus] = useState<ApplicationStatus>(ApplicationStatus.NEW);
  const [selectedServiceRequest, setSelectedServiceRequest] = useState<PublishedServiceRequest | undefined>();
  const [editedServiceRequest, setEditedServiceRequest] = useState<PublishedServiceRequest | undefined>(
    undefined
  );
  const [deletedServiceRequest, setDeletedServiceRequest] = useState<PublishedServiceRequest | undefined>();

  const { isOpen: isServiceRequestModalOpen, onOpen, onClose: onServiceRequestModalClose } = useDisclosure();
  const { isOpen: isConfirmOpen, onOpen: openConfirmModal, onClose: closeConfirmModal } = useDisclosure();

  const navigate = useNavigate();

  const { data: serviceRequests, refetch } = useGetMyServiceRequestsQuery();
  const [deleteServiceRequest] = useRemoveServiceRequestMutation();

  const closeDeleteModal = useCallback(() => {
    setDeletedServiceRequest(undefined);
    closeConfirmModal();
  }, [closeConfirmModal]);

  const removeServiceRequest = useCallback(() => {
    if (deletedServiceRequest) {
      deleteServiceRequest(deletedServiceRequest.id);
      refetch();
      closeDeleteModal();
      setDeletedServiceRequest(undefined);
    }
  }, [closeDeleteModal, deleteServiceRequest, deletedServiceRequest, refetch]);

  const handleEdit = useCallback(
    (serviceRequest: PublishedServiceRequest) => {
      setEditedServiceRequest({ ...serviceRequest });
      onOpen();
    },
    [onOpen]
  );

  const filteredServiceRequests = useMemo(
    () =>
      serviceRequests?.filter(
        (serviceRequest: PublishedServiceRequest) => serviceRequest.status === selectedStatus
      ),
    [selectedStatus, serviceRequests]
  );

  const activeChatId = useMemo(() => {
    if (selectedServiceRequest) {
      if (isServiceInProgress(selectedServiceRequest)) {
        const result = selectedServiceRequest.applications.find(
          (s) => s.status === ApplicationStatus.IN_PROGRESS
        );
        return result?.chatRoomId;
      }
      if (isServiceRequested(selectedServiceRequest)) {
        const result = selectedServiceRequest.applications.find(
          (s) => s.status === ApplicationStatus.IN_PROGRESS
        );
        return result?.chatRoomId;
      }
      if (isServiceCompleted(selectedServiceRequest)) {
        const result = selectedServiceRequest.applications.find(
          (s) => s.status === ApplicationStatus.COMPLETED
        );
        return result?.chatRoomId;
      }
    }

    return [...(selectedServiceRequest?.applications ?? [])]?.sort(
      (s1, s2) => Date.parse(s2.createdAt) - Date.parse(s1.createdAt)
    )?.[0]?.chatRoomId;
  }, [selectedServiceRequest]);

  useEffect(() => {
    if (selectedServiceRequest) {
      const service = serviceRequests?.find(
        (s: PublishedServiceRequest) => s.id === selectedServiceRequest.id
      );
      setSelectedServiceRequest(service);
    }
  }, [serviceRequests, selectedServiceRequest]);

  useEffect(() => {
    if (deletedServiceRequest) {
      openConfirmModal();
    }
  }, [deletedServiceRequest, openConfirmModal]);

  return (
    <Container>
      <TopBar />
      <ServiceRequestChat
        isOpen={!!selectedServiceRequest}
        onClose={() => {
          setSelectedServiceRequest(undefined);
        }}
        serviceRequest={selectedServiceRequest}
        onChangeStatus={refetch}
        activeChatId={activeChatId}
      />
      <Grid templateColumns="repeat(20, 1fr)" gap={6} w={"90%"} mt={6}>
        <GridItem colSpan={4}>
          <Flex direction={"column"} alignItems={"left"} maxW={"95%"}>
            <StatusButton
              title="Публикувани"
              selected={selectedStatus === ApplicationStatus.NEW}
              onClick={() => setSelectedStatus(ApplicationStatus.NEW)}
            />
            <StatusButton
              title="Заявени"
              selected={selectedStatus === ApplicationStatus.REQUESTED}
              onClick={() => setSelectedStatus(ApplicationStatus.REQUESTED)}
            />
            <StatusButton
              title="Активни"
              selected={selectedStatus === ApplicationStatus.IN_PROGRESS}
              onClick={() => setSelectedStatus(ApplicationStatus.IN_PROGRESS)}
            />
            <StatusButton
              title="Завършени"
              selected={selectedStatus === ApplicationStatus.COMPLETED}
              onClick={() => setSelectedStatus(ApplicationStatus.COMPLETED)}
            />
          </Flex>
        </GridItem>
        <GridItem colSpan={1} />
        <GridItem colSpan={15}>
          <Box flex={1}>
            {filteredServiceRequests?.map((serviceRequest: PublishedServiceRequest) => (
              <ServiceRequestCard
                key={serviceRequest.id}
                serviceRequest={serviceRequest}
                selectedStatus={selectedStatus}
                onEdit={() => handleEdit(serviceRequest)}
                onDelete={() => setDeletedServiceRequest(serviceRequest)}
                onPreview={() => {
                  if (!isServiceNotRequested(serviceRequest)) {
                    setSelectedServiceRequest(serviceRequest);
                  } else {
                    navigate(`/service-request/${serviceRequest.id}`);
                  }
                }}
              />
            ))}
            {!filteredServiceRequests?.length && (
              <Text w="75%" fontSize={20} pt={20} textAlign={"center"}>
                Няма намерени заявки
              </Text>
            )}
          </Box>
        </GridItem>
      </Grid>
      <EditServiceRequestDetailsModal
        isOpen={isServiceRequestModalOpen}
        onClose={onServiceRequestModalClose}
        serviceRequest={editedServiceRequest}
      />
      <ConfirmationModal isOpen={isConfirmOpen} onConfirm={removeServiceRequest} onCancel={closeDeleteModal}>
        <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
          Сигурни ли се че искате да изтриете тази обява за услуга?
        </Text>
      </ConfirmationModal>
    </Container>
  );
}

const Container = styled.div`
  padding: 0.2rem 0px 1rem 0px;
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  align-items: center;
`;

export default MyServiceRequestsPage;
