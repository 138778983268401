import Colors from "../../utils/colors";
import { Text } from "@chakra-ui/react";


function HomePageNotLoggedInHeader() {
    return (
        <>
            <Text fontSize={36} fontWeight={"black"} lineHeight={"40px"} style={{ color: Colors.primaryBlue }}>Търсене и предлагане на</Text>
            <Text lineHeight={"60px"} style={{ fontWeight: 900, fontSize: 48, color: Colors.primaryYellow }}>ПРОФЕСИОНАЛНИ УСЛУГИ</Text>
            <Text fontWeight={"bold"} style={{ fontSize: 18, color: Colors.primaryBlue }}>Бързи, лесни и ефективни решения за вашия дом</Text>
        </>
    )
}

export default HomePageNotLoggedInHeader;