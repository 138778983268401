import { appApi } from "../../api/rtk-query-init";
import { Tags, FilterQuery, ListResponse, HomePageCardContent } from "../../types";
import { PublishedServiceRequest } from "../../types/PublishedServiceRequest";
import { ReviewIn } from "../../types/ReviewIn";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllServiceRequests: builder.query<ListResponse<HomePageCardContent>, FilterQuery>({
      query: (query) => ({
        url: "service-requests/query",
        method: "POST",
        body: { query },
      }),
      providesTags: [Tags.SERVICE_REQUESTS],
    }),
    getMyServiceRequests: builder.query<Array<PublishedServiceRequest>, void>({
      providesTags: [Tags.SERVICE_REQUESTS],
      query: () => ({
        url: "service-requests/mine",
        method: "GET",
      }),
    }),
    getMyAppliedServiceRequests: builder.query<any, void>({
      extraOptions: { maxRetries: 0 },
      providesTags: [Tags.SERVICE_REQUESTS],
      query: () => ({
        url: "service-requests/mine:applied",
        method: "GET",
      }),
    }),
    removeServiceRequest: builder.mutation<any, any>({
      extraOptions: { maxRetries: 0 },
      invalidatesTags: [Tags.SERVICE_REQUESTS],
      query: (id: string) => ({
        url: `service-requests/${id}`,
        method: "DELETE",
      }),
    }),
    acceptServiceRequestApplication: builder.mutation<void, string>({
      query: (serviceRequestApplicationId: string) => ({
        url: `service-requests/application/${serviceRequestApplicationId}/confirm`,
        method: "PUT",
      }),
      invalidatesTags: [Tags.SERVICE_REQUESTS],
    }),
    declineServiceRequestApplication: builder.mutation<void, string>({
      query: (serviceRequestApplicationId: string) => ({
        url: `service-requests/application/${serviceRequestApplicationId}/decline`,
        method: "PUT",
      }),
      invalidatesTags: [Tags.SERVICE_REQUESTS],
    }),
    completeServiceRequestApplication: builder.mutation<void, string>({
      query: (serviceRequestApplicationId: string) => ({
        url: `service-requests/application/${serviceRequestApplicationId}/complete`,
        method: "PUT",
      }),
      invalidatesTags: [Tags.SERVICE_REQUESTS],
    }),
    leaveReviewForAServiceRequest: builder.mutation<
      void,
      { serviceRequestApplicationId: string; review: ReviewIn }
    >({
      query: ({ serviceRequestApplicationId, review }) => ({
        url: `service-requests/application/${serviceRequestApplicationId}/review`,
        method: "PUT",
        body: review,
      }),
      invalidatesTags: [Tags.SERVICE_REQUESTS],
    }),
    getReviewForAServiceRequestApplication: builder.query<ReviewIn, string>({
      query: (serviceRequestApplicationId: string) => ({
        url: `craftsmen/reviews/service-request-application/${serviceRequestApplicationId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetAllServiceRequestsQuery,
  useGetMyServiceRequestsQuery,
  useGetMyAppliedServiceRequestsQuery,
  useRemoveServiceRequestMutation,
  useAcceptServiceRequestApplicationMutation,
  useDeclineServiceRequestApplicationMutation,
  useCompleteServiceRequestApplicationMutation,
  useLeaveReviewForAServiceRequestMutation,
  useGetReviewForAServiceRequestApplicationQuery,
} = extendedApi;

export default extendedApi;
