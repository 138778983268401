import { Box, Flex, Modal, ModalContent, ModalOverlay, IconButton, Button, Text } from "@chakra-ui/react";
import Colors from "../utils/colors";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import RequestServiceHeader from "./components/RequestServiceHeader";
import { useListChatRoomsQuery } from "../api/common/ChatRooms";
import { useAuth } from "../utils/AuthProvider";
import socket from "../utils/socket";
import { ChatRoomOut, ContentType, MessageOut } from "./types/ChatRoomOut";
import { FaTimes } from "react-icons/fa";
import { useUploadImageMutation } from "../serviceDetails/api/images";
import { useUploadVideoMutation } from "../serviceDetails/api/videos";
import { ImageIn, VideoIn } from "../types/services";
import ChatRoomList from "./components/ChatRoomList";
import MessageDisplay from "./components/MessageDisplay";
import { useSocket } from "./hooks/useSocket";
import { Service } from "../types";
import MessageInput from "./components/MessageInput";
import { ApplicationStatus, AppliedServiceOut } from "../home/types/AppliedServiceOut";
import {
  useAcceptServiceApplicationMutation,
  useCompleteServiceApplicationMutation,
  useDeclineServiceApplicationMutation,
  useGetReviewForAServiceApplicationQuery,
  useLeaveReviewForASerivceMutation,
} from "./api/RequestService";
import RatingWidget from "./components/RatingWidget";
import { usePrevious } from "../utils/usePrevious";
import { ReviewIn } from "../types/ReviewIn";
import placeholder from "../assets/placeholder.svg";

export interface RequestedServiceChatProps {
  isOpen: boolean;
  onClose: () => void;
  service?: Service | AppliedServiceOut;
  onChangeStatus: () => void;
  activeChatId?: string;
}

function RequestedServiceChat({
  isOpen,
  onClose,
  service,
  onChangeStatus,
  activeChatId,
}: RequestedServiceChatProps) {
  const { data: chatRoomsFetched, refetch } = useListChatRoomsQuery(service?.id ?? "", { skip: !service });

  const [accept] = useAcceptServiceApplicationMutation();
  const [decline] = useDeclineServiceApplicationMutation();
  const [complete] = useCompleteServiceApplicationMutation();
  const [review] = useLeaveReviewForASerivceMutation();

  const [chatRooms, setChatRooms] = useState<ChatRoomOut[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<ChatRoomOut | null>(null);
  const [currMessage, setCurrMessage] = useState("");

  const fileInputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const user = useAuth();
  const [uploadImageApi] = useUploadImageMutation();
  const [uploadVideoApi] = useUploadVideoMutation();

  const application = useMemo(() => {
    return (service as any)?.applications?.find(
      (application: any) => application.chatRoomId === selectedRoom?.id
    );
  }, [selectedRoom?.id, service]);

  const { data: reviews } = useGetReviewForAServiceApplicationQuery(application?.id ?? "", {
    skip: !application?.id || application.status !== ApplicationStatus.REVIEWED,
  });

  const handleSelectImage = useCallback(() => {
    fileInputRef?.current?.click();
  }, []);

  const onChange = useCallback(
    (event: any) => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const message = {
          chatRoomId: selectedRoom?.id,
          senderId: user.user?.id,
          recipientId: user.user?.isCraftsMan ? selectedRoom?.user?.id : selectedRoom?.craftsMan?.id,
          content: { text: "", contentType: ContentType.TEXT },
        } as MessageOut;

        const formData = new FormData();

        if (file.type.startsWith("image/")) {
          message.content.contentType = ContentType.IMAGE;
          formData.append("image", file);
          uploadImageApi(formData)
            .unwrap()
            .then((res) => {
              const image = res as unknown as ImageIn;
              message.content.images = [image];
              socket?.emit("sendMessage", message);
            });
        } else if (file.type.startsWith("video/")) {
          message.content.contentType = ContentType.VIDEO;
          formData.append("video", file);
          uploadVideoApi(formData)
            .unwrap()
            .then((res) => {
              const video = res as unknown as VideoIn;
              message.content.videos = [video];
              socket?.emit("sendMessage", message);
            });
        }
      }
    },
    [
      selectedRoom?.craftsMan?.id,
      selectedRoom?.id,
      selectedRoom?.user?.id,
      uploadImageApi,
      uploadVideoApi,
      user.user?.id,
      user.user?.isCraftsMan,
    ]
  );

  const onMessageReceived = useCallback(
    (message: MessageOut) => {
      setChatRooms((oldRooms) =>
        oldRooms.map((room) => {
          if (room.id === message.chatRoomId) {
            const newRoom = { ...room, messages: [...room.messages] };
            newRoom.messages.push(message);

            if (selectedRoom?.id === newRoom.id) {
              setSelectedRoom({ ...newRoom });
            }
            return newRoom;
          }
          return { ...room };
        })
      );
    },
    [selectedRoom?.id]
  );

  useEffect(() => {
    if (!selectedRoom && chatRooms && chatRooms.length) {
      const room = chatRooms.find((chatRoom) => chatRoom?.id === activeChatId);

      if (room) {
        setSelectedRoom(room);
      } else {
        setSelectedRoom(chatRooms[0]);
      }
    }
  }, [activeChatId, chatRooms, selectedRoom, service]);

  const prevStatus = usePrevious(application?.status);

  useEffect(() => {
    if (
      prevStatus !== application?.status &&
      [ApplicationStatus.COMPLETED, ApplicationStatus.DECLINED, ApplicationStatus.IN_PROGRESS].includes(
        application?.status ?? ApplicationStatus.NEW
      )
    ) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevStatus]);

  useSocket(onMessageReceived);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatRooms]);

  useEffect(() => {
    if (!user || !socket || !chatRoomsFetched) return;

    setChatRooms([...chatRoomsFetched].sort((c1, c2) => Date.parse(c2.createdAt) - Date.parse(c1.createdAt)));

    chatRoomsFetched.forEach(({ id: chatRoomId }) => socket?.emit("joinRoom", { chatRoomId }));
  }, [chatRoomsFetched, service, user]);

  const handleSendMessage = useCallback(() => {
    if (currMessage.trim().length === 0) return;

    socket?.emit("sendMessage", {
      chatRoomId: selectedRoom?.id,
      senderId: user.user?.id,
      recipientId: user.user?.isCraftsMan ? selectedRoom?.user?.id : selectedRoom?.craftsMan?.id,
      content: { text: currMessage, contentType: ContentType.TEXT },
    });
    setCurrMessage("");
  }, [
    currMessage,
    selectedRoom?.craftsMan?.id,
    selectedRoom?.id,
    selectedRoom?.user?.id,
    user.user?.id,
    user.user?.isCraftsMan,
  ]);

  const acceptApplication = useCallback(() => {
    if (!selectedRoom?.application?.id) return;

    accept(selectedRoom?.application?.id)
      .unwrap()
      .then(() => {
        onChangeStatus();
      });
  }, [accept, onChangeStatus, selectedRoom?.application?.id]);

  const declineApplication = useCallback(() => {
    if (!selectedRoom?.application?.id) return;

    decline(selectedRoom?.application?.id)
      .unwrap()
      .then(() => {
        setChatRooms((chatRooms) => chatRooms.filter((r) => r.id !== selectedRoom.id));
        setSelectedRoom(null);
        if (chatRooms.length === 1) {
          onClose();
        }
        onChangeStatus();
      });
  }, [chatRooms.length, decline, onChangeStatus, onClose, selectedRoom?.application?.id, selectedRoom?.id]);

  const completeApplication = useCallback(() => {
    if (!selectedRoom?.application?.id) return;

    complete(selectedRoom?.application?.id)
      .unwrap()
      .then(() => {
        onChangeStatus();
      });
  }, [complete, onChangeStatus, selectedRoom]);

  const reviewApplication = useCallback(
    (reviewIn: ReviewIn) => {
      if (!selectedRoom?.application?.id) return;

      review({ serviceApplicationId: selectedRoom?.application?.id, review: reviewIn })
        .unwrap()
        .then(() => {
          onChangeStatus();
        });
    },
    [onChangeStatus, review, selectedRoom?.application?.id]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered variant={"authDialog"}>
      <ModalOverlay />
      <input accept={"image/*,video/*"} type="file" hidden ref={fileInputRef} onChange={onChange}></input>
      <ModalContent
        style={{
          width: "60%",
          minWidth: "60%",
          backgroundColor: "white",
          maxHeight: "885px",
          height: "885px",
        }}
      >
        <IconButton
          aria-label="Close chat message"
          icon={<FaTimes />}
          onClick={onClose}
          position="absolute"
          top="10px"
          right="10px"
          zIndex={1}
          background="rgba(1, 1, 1, 0.2)"
          _hover={{ background: "rgba(255, 0, 0, 0.4)" }}
          size="sm"
          color="white"
        />
        <Box>
          <Box w={"100%"} flex={1} padding={0}>
            <RequestServiceHeader
              title={selectedRoom?.service?.title ?? ""}
              image={selectedRoom?.service?.images?.[0] ?? placeholder}
              city={selectedRoom?.service?.city ?? ""}
              categories={selectedRoom?.service?.category ?? ""}
              craftsMan={selectedRoom?.craftsMan}
            />
            <Flex
              flexDir="row"
              bg={Colors.lightGray}
              paddingLeft={"30px"}
              borderBottomRadius={25}
              paddingTop={5}
            >
              <ChatRoomList
                chatRooms={chatRooms}
                selectedRoom={selectedRoom}
                onRoomSelect={(room) => setSelectedRoom(room)}
                usersType="user"
              />
              <Flex
                w={"75%"}
                h={"590px"}
                maxH={"590px"}
                paddingLeft={5}
                paddingTop={1}
                paddingBottom={5}
                paddingRight={7}
                flexDir={"column"}
              >
                <MessageDisplay
                  messages={selectedRoom?.messages ?? []}
                  inactive={
                    ![ApplicationStatus.IN_PROGRESS, ApplicationStatus.REQUESTED].includes(
                      application?.status ?? ApplicationStatus.NEW
                    )
                  }
                  reviews={application?.status === ApplicationStatus.REVIEWED ? reviews : undefined}
                />

                {application?.status === ApplicationStatus.IN_PROGRESS && (
                  <MessageInput
                    currMessage={currMessage}
                    onComplete={completeApplication}
                    onMessageChange={(message) => setCurrMessage(message)}
                    onSendMessage={handleSendMessage}
                    onSelectImage={handleSelectImage}
                    showCompleteButton={
                      !user?.user?.isCraftsMan && application?.status === ApplicationStatus.IN_PROGRESS
                    }
                  />
                )}
                {application?.status === ApplicationStatus.COMPLETED && (
                  <RatingWidget onReviewComplete={reviewApplication} />
                )}
                {user?.user?.isCraftsMan && application?.status === ApplicationStatus.REQUESTED && (
                  <>
                    <Text padding={2} margin={2} textAlign={"center"}>
                      Ако след комуникацията с клиента се стигнали до съгласие за започване на работни
                      дейности по вашата услуга, може да приемете заявката.{" "}
                    </Text>
                    <Text textAlign={"center"}>
                      От балансът ви ще бъде взет{" "}
                      <span style={{ color: "red", fontWeight: "bold" }}>1лв.</span>
                    </Text>
                    <Text textAlign="center" mb={3}>
                      <b>РАЗПОЛАГАЕМА СУМА:</b>{" "}
                      <span style={{ color: "green", fontWeight: "bold" }}>{user?.user?.balance}лв.</span>
                    </Text>
                    <Flex flexDirection="row" w="100%" justifyContent="space-between" mb={2}>
                      <Button
                        w={"224px"}
                        h="50px"
                        alignSelf="center"
                        backgroundColor={Colors.buttonGreen}
                        color="white"
                        fontFamily={"Inter"}
                        as={"b"}
                        fontSize="18px"
                        borderRadius={50}
                        onClick={acceptApplication}
                      >
                        Приеми заявкa
                      </Button>
                      <Button
                        w={"224px"}
                        h="50px"
                        alignSelf="center"
                        backgroundColor={Colors.buttonRed}
                        color="white"
                        fontFamily={"Inter"}
                        as={"b"}
                        fontSize="18px"
                        borderRadius={50}
                        onClick={declineApplication}
                      >
                        Откажи заявкa
                      </Button>
                    </Flex>
                  </>
                )}
              </Flex>
            </Flex>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
}

export default RequestedServiceChat;
