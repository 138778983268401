import { appApi } from "../../api/rtk-query-init";

const DEFAULT_CURRENCY = "bgn";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    createPaymentIntent: builder.query<any, number>({
      query: (amount: number) => ({
        url: "payments/create-payment-intent",
        method: "POST",
        body: {
          amount: Math.round(amount * 100),
          currency: DEFAULT_CURRENCY,
        },
      }),
    }),
    getPaymentStatus: builder.query<any, string>({
      query: (paymentIntentId) => ({
        url: `payments/intent/${paymentIntentId}/status`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyCreatePaymentIntentQuery, useGetPaymentStatusQuery } = extendedApi;

export default extendedApi;
