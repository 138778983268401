import { Box, Flex } from "@chakra-ui/react";
import ChatMessageBody from "./ChatMessageBody";
import { useEffect, useRef } from "react";
import { ReviewIn } from "../../types/ReviewIn";
import RatingWidget from "./RatingWidget";
import { ContentType, MessageOut } from "../types/ChatRoomOut";
import { useAuth } from "../../utils/AuthProvider";

interface MessageDisplayProps {
  messages: MessageOut[];
  inactive: boolean;
  reviews?: ReviewIn;
}

const MessageDisplay = ({ messages, inactive, reviews }: MessageDisplayProps) => {
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  const user = useAuth();

  return (
    <Flex flexDir="column" w="100%" h="94%" maxH="94%" overflow="auto" paddingRight={2}>
      <Flex opacity={inactive ? "0.5" : "1"} flexDir="column" w="100%">
        {messages.map((message, index) => (
          <ChatMessageBody key={index} message={message} />
        ))}
      </Flex>
      {reviews && <RatingWidget ratingsFilled={reviews?.reviews} disabled />}
      {reviews?.comment && (
        <ChatMessageBody
          isReviewComment
          message={{
            ...messages[0],
            id: "review-comment",
            senderId: user.user?.id ?? "",
            content: {
              text: reviews?.comment,
              contentType: ContentType.TEXT,
            },
          }}
        />
      )}
      <Box ref={messagesEndRef} />
    </Flex>
  );
};

export default MessageDisplay;
