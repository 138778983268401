import { chakra, ChakraProps } from "@chakra-ui/react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Colors from "../utils/colors";

interface CharkraIconProps extends ChakraProps {
  icon: IconDefinition;
  size?: number;
  color?: string;
  props?: any;
}

export const ChakraIcon = ({ icon, size, color, props }: CharkraIconProps) => {
  const Icon = chakra(FontAwesomeIcon);

  return <Icon icon={icon} color={color ? color : Colors.borderGray} boxSize={size} {...props} />;
};
