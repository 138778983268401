import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Image, VStack, Icon, Modal, ModalOverlay, ModalContent, IconButton } from "@chakra-ui/react";
import { FaPlay, FaTimes } from "react-icons/fa";
import { VideoPlayer } from "./VideoPlayerModal";

const VideoToImagePreview = ({ url }: { url: string }) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const generateImagePreview = useCallback(async () => {
    if (videoRef.current && canvasRef.current) {
      videoRef.current.crossOrigin = "anonymous";
      videoRef.current.src = url;

      videoRef.current.onloadedmetadata = () => {
        const video = videoRef.current!;
        const canvas = canvasRef.current!;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Seek to a specific time (e.g., 0.5 seconds)
        video.currentTime = 0.5;

        video.onseeked = () => {
          const ctx = canvas.getContext("2d");
          if (ctx) {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageURL = canvas.toDataURL("image/png");
            setImagePreview(imageURL); // Set the image URL for preview
          }
        };
      };

      videoRef.current.onerror = () => {
        alert("Error loading the video. Please check the URL.");
      };
    }
  }, [url]);

  useEffect(() => {
    generateImagePreview();
  }, [generateImagePreview, url]);

  const openPlayer = () => setIsPlayerOpen(true);
  const closePlayer = () => setIsPlayerOpen(false);

  return (
    <>
      <VStack spacing={4} position="relative">
        <Box
          w={120}
          h={100}
          borderRadius={8}
          position="relative"
          overflow="hidden"
          cursor="pointer"
          onClick={openPlayer}
        >
          {imagePreview && (
            <Image src={imagePreview} alt="Video Frame Preview" w="100%" h="100%" borderRadius={8} />
          )}
          <Icon
            as={FaPlay}
            color="white"
            w={8}
            h={8}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            background="rgba(0, 0, 0, 0.5)"
            borderRadius="50%"
            padding="10px"
          />
        </Box>
        <video ref={videoRef} style={{ display: "none" }} />
        <canvas ref={canvasRef} style={{ display: "none" }} />
      </VStack>
      <VideoPlayer url={url} isPlayerOpen={isPlayerOpen} closePlayer={closePlayer} />
    </>
  );
};

export default VideoToImagePreview;
