import Colors from "../utils/colors";
import { useCallback, useRef } from "react";
import { Button } from "@chakra-ui/react";
import { FaPhotoFilm, FaVideo } from "react-icons/fa6";

interface ImageSelectProps {
  onContent: (content: File[]) => void;
  isVideo?: boolean;
}

function ImageOrVideoSelectButton({ onContent, isVideo }: ImageSelectProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSelectImage = useCallback(() => {
    fileInputRef?.current?.click();
  }, []);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        onContent(Array.from(event.target.files));
      }
    },
    [onContent]
  );

  return (
    <>
      <Button
        colorScheme="white"
        leftIcon={isVideo ? <FaVideo /> : <FaPhotoFilm />}
        color={Colors.textGray}
        fontWeight={"normal"}
        fontSize={16}
        fontFamily={"Inter"}
        backgroundColor={"white"}
        onClick={handleSelectImage}
        w={"49%"}
      >
        {isVideo ? "Прикачи видео" : "Прикачи снимки"}
      </Button>

      <input
        accept={isVideo ? "video/*" : "image/*"}
        type="file"
        multiple
        hidden
        ref={fileInputRef}
        onChange={onChange}
      />
    </>
  );
}

export default ImageOrVideoSelectButton;
