import { Flex, Box, Text, Image, Button } from "@chakra-ui/react";
import Spacer from "../../components/Spacer";
import pin from "../../assets/pin.svg";
import remont from "../../assets/remont.svg";
import editPen from "../../assets/editPen.svg";

import { faUser } from "@fortawesome/free-solid-svg-icons";
import { ChakraIcon } from "../../components/ChakraIcon";
import Colors from "../../utils/colors";
import DOMPurify from "dompurify";
import { AppliedServiceRequestOut } from "../types/AppliedServiceRequestOut";
import { AppliedServiceRequestSelectionStatus } from "../MyAppliedServiceRequestsPage";

export const AppliedServiceRequestCard = ({
  serviceRequestApplication,
  onClick,
  selectedStatus,
}: {
  serviceRequestApplication: AppliedServiceRequestOut;
  onClick: () => void;
  selectedStatus: AppliedServiceRequestSelectionStatus;
}) => {
  return (
    <Flex
      bg={Colors.lightGray}
      borderRadius={25}
      padding={5}
      flexDir="row"
      w={"100%"}
      marginBottom={10}
      key={serviceRequestApplication.id}
    >
      <Image
        w={310}
        h={200}
        maxW={310}
        maxH={200}
        minW={310}
        minH={200}
        borderRadius={10}
        src={serviceRequestApplication.image}
        marginRight={"20px"}
      />
      <Flex flexDir="column" w={"100%"} justifyContent={"space-between"}>
        <Flex flexDir="column" w={"100%"}>
          <Text as={"b"} fontSize={18} fontFamily={"Inter"} color={"black"}>
            {serviceRequestApplication.title}
          </Text>
          <Spacer size={8} />
          <Flex flexDir={"row"} alignItems={"center"}>
            <Flex flexDir={"row"} alignItems={"center"} mr={4}>
              <Image borderRadius="full" boxSize={"32px"} src={serviceRequestApplication.user.image} />
              <Text as={"b"} fontSize={"14px"} color={Colors.textGray} marginLeft={"4px"} marginRight={"4px"}>
                {serviceRequestApplication.user.name}
              </Text>
              <ChakraIcon icon={faUser} color={Colors.textGray} size={3} />
            </Flex>
            <Image src={pin} color={Colors.textGray} width={"12px"} height={"16px"} />
            <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
              {serviceRequestApplication.city}
            </Text>
            <Spacer horizontal />
            <Image src={remont} color={Colors.textGray} width={"16px"} height={"16px"} />
            <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
              {serviceRequestApplication.category + (serviceRequestApplication.subCategories ? " - " : "")}
              {serviceRequestApplication.subCategories?.slice(0, 2)?.join(", ")}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDir="column" w={"100%"}>
          <Text
            fontSize={"12px"}
            color={Colors.textGray}
            marginLeft={"4px"}
            noOfLines={2}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(serviceRequestApplication.description) }}
          />
          <Spacer />
        </Flex>
        <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
          {selectedStatus === AppliedServiceRequestSelectionStatus.REQUESTED && (
            <Text fontSize={"12px"} as={"b"} color={Colors.textGray} marginLeft={"4px"}>
              Тази заявка по обява все още чака одобрение
            </Text>
          )}
          {selectedStatus === AppliedServiceRequestSelectionStatus.ACTIVE && (
            <Text fontSize={"12px"} as={"b"} color={Colors.green} marginLeft={"4px"}>
              Тази заявка по обява е в процес за изпълнение
            </Text>
          )}
          {selectedStatus === AppliedServiceRequestSelectionStatus.DECLINED && (
            <Text fontSize={"12px"} as={"b"} color={Colors.textRed} marginLeft={"4px"}>
              Тази заявка по обява е отказана от клиент
            </Text>
          )}
          {selectedStatus === AppliedServiceRequestSelectionStatus.COMPLETED && (
            <Text fontSize={"12px"} as={"b"} color={Colors.textGray} marginLeft={"4px"}>
              Всички дейности по тази заявка по обява са завършени
            </Text>
          )}
          <Box>
            <Button
              size="md"
              height="30px"
              w={"140px"}
              color={Colors.textGray}
              borderColor={Colors.textGray}
              border={"1px solid"}
              backgroundColor={Colors.lightGray}
              borderRadius={15}
              fontSize={12}
              onClick={onClick}
            >
              <Image src={editPen} color={Colors.textGray} width={"12px"} height={"16px"} marginRight={2} />
              Прегледай
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
