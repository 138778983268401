import { CloseIcon } from "@chakra-ui/icons";
import { Flex, Box, IconButton, Image, Center, Spinner } from "@chakra-ui/react";
import VideoToImagePreview from "../serviceDetails/components/VideoPreview";
import Colors from "../utils/colors";
import { ImageIn, VideoIn } from "../types/services";

const ContentWithRemove = ({
  onClick,
  isLoading,
  url,
  isVideo,
}: {
  onClick: () => void;
  isLoading: boolean;
  url: string;
  isVideo: boolean;
}) => (
  <Box position={"relative"}>
    <IconButton
      aria-label="Remove"
      icon={<CloseIcon />}
      backgroundColor={Colors.textGray}
      fontSize={"6px"}
      position={"absolute"}
      size={"xs"}
      zIndex={5000}
      isRound={true}
      cursor={"pointer"}
      top={-2}
      right={1}
      color={"white"}
      onClick={onClick}
    />

    {isLoading ? (
      <Center mt={4}>
        <Spinner size="xl" color="teal.500" />
      </Center>
    ) : isVideo ? (
      <VideoToImagePreview url={url} />
    ) : (
      <Image src={url} w={"120px"} h={"100px"} borderRadius={8} marginRight={4} />
    )}
  </Box>
);

export const UploadedContent = ({
  images,
  videos,
  onRemoveImage,
  onRemoveVideo,
}: {
  images?: ImageIn[];
  videos?: VideoIn[];
  onRemoveImage: (image: ImageIn) => void;
  onRemoveVideo: (video: VideoIn) => void;
}) => (
  <Flex flexWrap={"wrap"} alignItems={"center"} justifyContent={"flex-start"}>
    {images?.map((image, index) => (
      <ContentWithRemove
        key={index}
        isVideo={false}
        url={image.url}
        isLoading={image?.id?.includes("loading")}
        onClick={() => onRemoveImage(image)}
      />
    ))}
    {videos?.map((video, index) => (
      <ContentWithRemove
        key={index}
        isVideo
        url={video.url}
        isLoading={video?.id?.includes("loading")}
        onClick={() => onRemoveVideo(video)}
      />
    ))}
  </Flex>
);
