import { Box, Button, Flex, Text, CloseButton, HStack } from "@chakra-ui/react";
import background from "../../assets/background-service.svg";
import bluebg from "../../assets/bluebg.svg";
import styled from "styled-components";
import Colors from "../../utils/colors";
import Spacer from "../../components/Spacer";
import { useAuth } from "../../utils/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRequestCategoriesQuery } from "../../api/common/Categories";
import { Category } from "../../types";
import { initialState, updateQuery } from "../../store/querySlice";
import { useCallback } from "react";

const NavBarButton = ({ title, path }: { title: string; path: string }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Button
      size="md"
      height="50px"
      w={"250px"}
      color={Colors.primaryBlue}
      backgroundColor={location.pathname.includes(path) ? Colors.primaryYellow : Colors.white}
      borderRadius={50}
      fontWeight={"bold"}
      fontSize={20}
      onClick={() => navigate(path)}
    >
      {title}
    </Button>
  );
};

const CRAFTSMAN_NAVIGATION = [
  { title: "Начало", path: "/home" },
  { title: "Моите услуги", path: "/services-mine" },
  { title: "Заявени обяви", path: "/applied-service-requests" },
];

const USER_NAVIGATION = [
  { title: "Начало", path: "/home" },
  { title: "Моите обяви", path: "/service-requests-mine" },
  { title: "Заявени услуги", path: "/services-requested" },
];

const FilterBadge = ({ categories }: { categories: Category[] | undefined }) => {
  const dispatch = useDispatch();
  const query = useSelector((state: any) => state.query);
  const category = categories?.find((c) => c.id === query.categoryId);
  const subcategories = category?.subcategories.filter((s) => query.subCategoryIds?.includes(s.id));

  const removeSubCategory = useCallback(
    (id: string) => {
      dispatch(
        updateQuery({
          ...query,
          categoryId: query.subCategoryIds.length === 1 ? undefined : query.categoryId,
          subCategoryIds: query.subCategoryIds.filter((s: string) => s !== id),
        })
      );
    },
    [dispatch, query]
  );

  return (
    <HStack spacing={4}>
      {subcategories?.map((sc: any) => (
        <Box
          key={sc.id}
          display="flex"
          alignItems="center"
          border="1px solid"
          borderColor="gray.500"
          borderRadius="full"
          px={3}
          py={1}
          color="gray.600"
        >
          <Text fontSize="sm">{sc.translationKey}</Text>
          <CloseButton size="sm" ml={2} onClick={() => removeSubCategory(sc.id)} />
        </Box>
      ))}
    </HStack>
  );
};

const HomeActionButtonsHeader = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const navButtons = user?.isCraftsMan ? CRAFTSMAN_NAVIGATION : USER_NAVIGATION;
  const query = useSelector((state: any) => state.query);
  const { data: categories } = useRequestCategoriesQuery();

  const clearFilters = useCallback(() => {
    dispatch(updateQuery(initialState));
  }, [dispatch]);

  return (
    <HeaderContainer>
      <Box
        width="99.5dvw"
        maxWidth={"99.5dvw"}
        height="30%"
        backgroundImage={background}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection={"column"}
      >
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          width="90%"
          backgroundImage={bluebg}
          height="22%"
          backgroundColor={"#363688"}
          borderRadius={25}
          backgroundPosition={"center"}
          paddingBottom={25}
        >
          <Text fontSize={32} fontWeight={"bold"} lineHeight={"40px"} style={{ color: "white" }}>
            Търсене и предлагане на професионални услуги
          </Text>
          <Spacer />
          <Flex direction="row" alignItems="flex-end" justifyContent="space-between" w={"61%"} marginTop={5}>
            {navButtons.map((nav) => (
              <NavBarButton title={nav.title} path={nav.path} key={nav.path} />
            ))}
          </Flex>
        </Flex>
        {(query.keyword || query.categoryId) && (
          <Flex direction="row" alignItems="center" w={"61%"} marginTop={5} mb={5}>
            {query.keyword && (
              <Text mr={4}>
                Резултат от търсенето на: <b>"{query.keyword}"</b>
              </Text>
            )}
            {query.categoryId && <FilterBadge categories={categories} />}
            <Button variant="link" color="gray.600" mx={3} fontWeight="bold" onClick={clearFilters}>
              Изчисти филтрите
            </Button>
          </Flex>
        )}
      </Box>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  background: ${background};
`;

export default HomeActionButtonsHeader;
