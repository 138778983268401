import { createSlice } from "@reduxjs/toolkit";

export type searchType = "articles" | "services" | "service-requests";

export const initialState = {
  page: undefined,
  keyword: undefined,
  location: undefined,
  city: undefined,
  categoryId: undefined,
  subCategoryIds: undefined as unknown as Array<string>,
  rating: undefined,
  sortBy: undefined,
  userId: undefined,
  type: "services" as searchType,
};

const querySlice = createSlice({
  name: "query",
  initialState,
  reducers: {
    updateQuery(state, action) {
      state.page = action.payload.page;
      state.keyword = action.payload.keyword;
      state.location = action.payload.location;
      state.city = action.payload.city;
      state.categoryId = action.payload.categoryId;
      state.subCategoryIds = action.payload.subCategoryIds;
      state.rating = action.payload.rating;
      state.sortBy = action.payload.sortBy;
      state.userId = action.payload.userId;
      state.type = action.payload.type;
    },
  },
});

export const { updateQuery } = querySlice.actions;
export default querySlice.reducer;
