

import { Box, Text, Grid, GridItem, Flex, Input, Textarea, Button } from "@chakra-ui/react";
import Spacer from "../components/Spacer";
import styled from "styled-components";
import Colors from "../utils/colors";
import IconWithText from "../components/IconWithText";
import { faLocationPin, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import Divider from "../components/Divider";
import { FaFacebookF, FaInstagram } from "react-icons/fa";



function ContactUs() {


    return (
        <Container>
            <Text fontSize={28} fontWeight={"bold"}>Връзка с нас</Text>

            <Spacer />

            <Grid
                templateColumns='repeat(10, 1fr)'

                gap={6}>

                <GridItem colSpan={7} bg="white" borderRadius={6} border={"1px solid"} borderColor={Colors.borderGray}>
                    <Box flex={1}>
                        <Box padding={6}>
                            <Input placeholder="Име"></Input>
                            <Spacer />
                            <Input placeholder="Имейл адрес"></Input>
                            <Spacer />
                            <Input placeholder="Телефон"></Input>
                            <Spacer />
                            <Textarea w={"100%"} h={"12rem"} resize={"none"} placeholder="Съобщение"></Textarea>
                            <Spacer />
                            <Button size='md'
                                height='40px'
                                w={"200px"}
                                colorScheme='blue'
                                variant='outline'>Изпрати</Button>
                        </Box>

                    </Box>
                </GridItem>

                <GridItem colSpan={3} >
                    <Flex direction={"column"} alignItems={"center"} bg="white" borderRadius={6} border={"1px solid"} borderColor={Colors.borderGray}>
                        <Spacer />
                        <Text as="b" fontSize={20}>Контакти</Text>
                        <Spacer />
                        <IconWithText icon={faLocationPin} text={"ул. Карнеги 11А, 1000 София, България"} />
                        <Spacer />
                        <IconWithText icon={faEnvelope} text={"тел: 02/123123"} />
                        <Spacer />
                        <IconWithText icon={faPhone} text={"e-mail: admin@maistor.bg"} />
                        <Spacer />
                        <Spacer />
                        <Box paddingLeft={4} paddingRight={4} width={"100%"}>
                            <Divider />
                        </Box>
                       
                        <Flex flexDirection={"column"} alignItems={"flex-start"} w={"100%"}>
                            <Button
                                colorScheme='white'
                                textColor={"#B1BCC2"}
                                leftIcon={<FaFacebookF />}
                                height='40px'>
                                Facebook
                            </Button>
                            <Button
                                colorScheme='white'
                                textColor={"#B1BCC2"}
                                leftIcon={<FaInstagram />}
                                height='40px'>
                                Instagram
                            </Button>
                            <Spacer />
                        </Flex>
                    </Flex>
                </GridItem>
            </Grid>
        </Container >
    );

}

const Container = styled.div`
    padding: 40px 0px 40px 0px
`;


export default ContactUs;