import { useEffect, useMemo, useState } from "react";
import ArticlesCard from "../../articles/components/ArticlesCard";
import { updateQuery } from "../../store/querySlice";
import { TAB_INDEX } from "../../utils/constants";
import HomeCard from "../HomeCard";
import { useDispatch, useSelector } from "react-redux";
import { useRequestCategoriesQuery } from "../../api/common/Categories";
import { useRequestArticlesListQuery } from "../../articles/api/articles";
import { State } from "../../store/State";
import { useGetAllServiceRequestsQuery } from "../api/ServiceRequests";
import { useGetAllServicesQuery } from "../api/Services";
import { useNavigate } from "react-router-dom";
import { SimpleGrid, Box, Flex } from "@chakra-ui/react";
import CityDropDownComponent from "../../components/CityDropdownComponent";
import PageTabs from "../../components/PageTabs";
import Paginator from "../../components/Paginator";
import RatingDropdownComponent from "../../components/RatingDropdownComponent";
import CategoryDropDownComponent from "./CategoryDropDownComponent";
import Spacer from "../../components/Spacer";

const TABS = ["services", "service-requests", "articles"];

function HomePageContent() {
  const dispatch = useDispatch();
  const query = useSelector((state: State) => state.query);

  const [selected, setSelected] = useState("");
  const [tabIndex, setTabIndex] = useState(TAB_INDEX.SERVICES);
  const [totalPages, setTotalPages] = useState(1);
  const [forcePageReset, setForcePageReset] = useState(false);

  const { data: categories } = useRequestCategoriesQuery();
  console.log("categories", categories);
  const { data: services, refetch: refetchServices } = useGetAllServicesQuery(query);
  const {
    data: serviceRequests,
    refetch: refetchServiceRequests,
    isUninitialized,
  } = useGetAllServiceRequestsQuery(query);

  const { data: articles, refetch: refetchArticles } = useRequestArticlesListQuery(query);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(updateQuery({ page: 1 }));
  }, [dispatch, tabIndex]);

  useEffect(() => {
    if (query.type === "services") {
      refetchServices();
      setTabIndex(TAB_INDEX.SERVICES);
    }
    if (query.type === "service-requests") {
      refetchServiceRequests();
      setTabIndex(TAB_INDEX.SERVICE_REQUEST);
    }

    if (query.type === "articles") {
      refetchArticles();
      setTabIndex(TAB_INDEX.ARTICLES);
    }
  }, [
    articles,
    isUninitialized,
    query,
    refetchArticles,
    refetchServiceRequests,
    refetchServices,
    serviceRequests,
    services,
  ]);

  const content = useMemo(() => {
    if (tabIndex === TAB_INDEX.SERVICES) {
      setTotalPages(services?.paginationInfo.totalPages!!);

      return services?.items.map((item) => {
        return <HomeCard key={item.id} content={item} onClick={(id) => navigate(`/service/${id}`)} />;
      });
    }
    if (tabIndex === TAB_INDEX.SERVICE_REQUEST) {
      setTotalPages(serviceRequests?.paginationInfo.totalPages!!);

      return serviceRequests?.items.map((item) => {
        return <HomeCard key={item.id} content={item} onClick={(id) => navigate(`/service-request/${id}`)} />;
      });
    }
    if (tabIndex === TAB_INDEX.ARTICLES) {
      setTotalPages(articles?.paginationInfo.totalPages!!);
      return articles?.items.map(({ id, title, image, author }) => (
        <ArticlesCard id={id.toString()} title={title} img={image} author={author} />
      ));
    }
    return null;
  }, [
    articles?.items,
    articles?.paginationInfo.totalPages,
    navigate,
    serviceRequests?.items,
    serviceRequests?.paginationInfo.totalPages,
    services?.items,
    services?.paginationInfo.totalPages,
    tabIndex,
  ]);

  const changeQueryCategories = (categoryId: string, subcategoryId: string, checked: boolean) => {
    let subcategoryIds: string[] | undefined =
      query.subCategoryIds && categoryId === query.categoryId ? [...query.subCategoryIds] : [];

    let category: string | undefined = categoryId;
    if (checked) {
      category = categoryId;

      subcategoryIds.push(subcategoryId);
    } else {
      subcategoryIds = subcategoryIds.filter((t) => t !== subcategoryId);
      if (!subcategoryIds.length) {
        category = undefined;
        subcategoryIds = undefined;
      }
    }
    dispatch(
      updateQuery({
        ...query,
        categoryId: category,
        subCategoryIds: subcategoryIds,
      })
    );
  };

  return (
    <>
      <PageTabs
        index={tabIndex}
        onTabChanged={(index) => {
          dispatch(updateQuery({ ...query, type: TABS[index - 1], page: 1 }));
          setTabIndex(index);
          setForcePageReset(!forcePageReset);
        }}
      />
      <Spacer size={30} />
      <SimpleGrid columns={4} spacing={4}>
        <Box>
          <Flex flexDir={"column"}>
            {categories?.map((category) => {
              return (
                <CategoryDropDownComponent
                  key={category.id}
                  category={category}
                  selectedSubCategoryIds={query.categoryId === category.id ? query.subCategoryIds : []}
                  onSelected={changeQueryCategories}
                  badgeCount={query.categoryId === category.id ? query?.subCategoryIds?.length : undefined}
                  onClick={(text, isSelected) => {
                    setSelected(isSelected ? "" : text);
                  }}
                  isSelected={selected === category.name}
                />
              );
            })}
            <CityDropDownComponent
              isExpanded={selected === "city"}
              location={query.location}
              city={query.city}
              onExpand={() => {
                setSelected(selected === "city" ? "" : "city");
              }}
              onSelectCity={(city) => dispatch(updateQuery({ ...query, city }))}
              onSelectLocation={(location) => dispatch(updateQuery({ ...query, location }))}
            />
            <RatingDropdownComponent
              isSelected={selected === "rating"}
              onSelected={(rating) => {
                dispatch(updateQuery({ ...query, rating }));
              }}
              onClick={(id, isSelected) => {
                setSelected(isSelected ? "" : "rating");
              }}
            />
          </Flex>
        </Box>
        <Box gridColumnStart={2} gridColumnEnd={5}>
          <SimpleGrid spacing={4} templateColumns="repeat(auto-fill, minmax(16rem, 1fr))">
            {content}
          </SimpleGrid>
        </Box>
      </SimpleGrid>
      <Paginator
        totalPages={totalPages}
        onPageChanged={(page) => {
          dispatch(updateQuery({ ...query, page }));
        }}
        forcePageReset={forcePageReset}
      />
    </>
  );
}

export default HomePageContent;
