import { Checkbox, Flex, Image, Input, Text } from "@chakra-ui/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Colors from "../utils/colors";
import pin from "../assets/pin.svg";
import { cities } from "../mock_data/cities";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import { useEffect, useState } from "react";
import { useLazyGetLocationQuery } from "../api/common/Location";
import { Location } from "../types";
import { CategoryDropDownStyleVariant } from "../home/components/CategoryDropDownComponent";

interface CityDropDownProps {
  isExpanded: boolean;
  onExpand: () => void;
  onSelectLocation: (location: Location) => void;
  onSelectCity: (city: string) => void;
  variant?: CategoryDropDownStyleVariant;
  location?: Location;
  city?: string;
}

function CityDropDownComponent({
  variant = CategoryDropDownStyleVariant.HOME_PAGE_VARIANT,
  isExpanded = false,
  onExpand,
  onSelectLocation,
  onSelectCity,
  city,
}: CityDropDownProps) {
  const [data, setData] = useState(cities);
  const [getLocation] = useLazyGetLocationQuery();

  useEffect(() => {
    if (city) {
      getLocation(city)
        .unwrap()
        .then((res) => onSelectLocation(res));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  const getComponentBasedOnVariant = (variant: CategoryDropDownStyleVariant) => {
    let component = null;
    switch (variant) {
      case CategoryDropDownStyleVariant.HOME_PAGE_VARIANT:
        component = (
          <Flex
            height={"70px"}
            onClick={() => {
              onExpand();
              setData(cities);
            }}
            cursor={"pointer"}
            backgroundColor={Colors.dirtyWhite}
            borderRadius={"10px"}
            alignItems={"center"}
            justifyContent={"space-between"}
            padding={"16px"}
            marginBottom={"8px"}
          >
            <Flex flexDir={"row"}>
              <Image src={pin} width={"32px"} height={"32px"} marginRight={"16px"} />
              <Text fontWeight={"700"} style={{ fontSize: 18, color: Colors.textGray }}>
                Град
              </Text>
            </Flex>
            <FontAwesomeIcon icon={faChevronDown} />
          </Flex>
        );
        break;
      case CategoryDropDownStyleVariant.PUBLISH_PAGE_VARIANT:
        component = (
          <Flex
            height={"50px"}
            onClick={() => {
              onExpand();
              setData(cities);
            }}
            cursor={"pointer"}
            alignItems={"center"}
            justifyContent={"space-between"}
            padding={"16px"}
            marginBottom={"8px"}
            bg="white"
            border={"1px solid"}
            borderColor={Colors.textGray}
            borderRadius={"50px"}
            width={"100%"}
            fontFamily={"Inter"}
            fontWeight={"regular"}
            textAlign={"left"}
          >
            <Flex flexDir={"row"}>
              <Image src={pin} width={"24px"} height={"24px"} marginRight={"16px"} />
              <Text>{city ? city : "Град"}</Text>
            </Flex>
            <FontAwesomeIcon icon={faChevronDown} />
          </Flex>
        );
        break;
    }
    return component;
  };

  const Row: React.FC<ListChildComponentProps> = ({ index, style }) => (
    <div style={style}>
      <Checkbox
        colorScheme={"gray"}
        variant={"darkBorder"}
        iconColor={"black"}
        spacing={3}
        size="lg"
        marginBottom={"8px"}
        isChecked={city === data[index].name_bg}
        onChange={(e) => {
          onSelectCity(data[index].name_bg);
          onExpand();
        }}
      >
        <Text fontSize={14} align="left">
          {data[index].name_bg}
        </Text>
        {/* TODO add the translations and call them via lang[translationKey]*/}
      </Checkbox>
    </div>
  );

  return (
    <Flex flexDir={"column"}>
      {getComponentBasedOnVariant(variant)}
      {isExpanded && (
        <Flex
          flexDir={"column"}
          backgroundColor={Colors.dirtyWhite}
          padding={"16px"}
          marginBottom={"8px"}
          borderRadius={"10px"}
        >
          <Input
            borderColor={Colors.borderGray}
            placeholder="Търси град"
            w={"100%"}
            paddingLeft={6}
            color={Colors.textGray}
            backgroundColor={Colors.white}
            fontSize={14}
            marginTop={1}
            marginBottom={4}
            onChange={(e) => {
              setData(cities.filter((item) => item.name_bg.includes(e.target.value)));
            }}
          />
          <List width={"100%"} height={300} itemCount={data.length} itemSize={25}>
            {Row}
          </List>
        </Flex>
      )}
    </Flex>
  );
}

export default CityDropDownComponent;
