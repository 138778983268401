import { Box, Button, ButtonProps } from "@chakra-ui/react";
import Colors from "../utils/colors";
import { ChakraIcon } from "./ChakraIcon";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

interface CraftButtonProps extends ButtonProps {
  title: string;
  icon?: IconDefinition;
  iconPosition?: "left" | "right";
}

export const CraftButton = ({ title, icon, iconPosition = "right", ...props }: CraftButtonProps) => (
  <Button
    w={"224px"}
    h="50px"
    backgroundColor={Colors.darkGray}
    color={Colors.white}
    fontFamily={"Inter"}
    as={"b"}
    fontSize="18px"
    borderRadius={50}
    {...props}
  >
    {icon && iconPosition === "left" && (
      <Box marginRight={2}>
        <ChakraIcon icon={icon} color={Colors.white} />
      </Box>
    )}
    {title}
    {icon && iconPosition === "right" && (
      <Box marginLeft={2} marginTop="3px">
        <ChakraIcon icon={icon} color={Colors.white} />
      </Box>
    )}
  </Button>
);
