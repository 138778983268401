import { appApi } from "../../api/rtk-query-init";
import { Tags, Location } from "../../types";

const extendedApi = appApi.injectEndpoints({
    endpoints: builder => ({
        getLocation: builder.query<Location, string>({
            query: (address) => ({
                    url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
                method: "GET",
            }),
            providesTags: [Tags.LOCATION],
            transformResponse: (response: any) => {
                const mappedResponse = {
                    latitude: response.results[0].geometry.location.lat,
                    longitude: response.results[0].geometry.location.lng
                };
                return mappedResponse as unknown as Location;
            }
        }),
        getCity: builder.query<string, Location>({
            query: (location) => ({
                url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.latitude},${location.longitude}&language=bg&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
                method: "GET",
            }),
            providesTags: [Tags.LOCATION],
            transformResponse: (response: any) => {
                const mappedResponse = response?.results?.[response?.results?.length - 2]?.address_components?.[0].long_name;
                return mappedResponse;
            }
        }),
        getAddress: builder.query<string, Location>({
            query: (location) => ({
                url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.latitude},${location.longitude}&language=bg&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
                method: "GET",
            }),
            providesTags: [Tags.LOCATION],
            transformResponse: (response: any) => {
                const mappedResponse = response?.results?.[0]?.address_components?.[0].long_name;
                return mappedResponse;
            }
        }),
    }),
});

export const { useGetLocationQuery, useLazyGetLocationQuery, useLazyGetCityQuery, useLazyGetAddressQuery } = extendedApi;

export default extendedApi;
