import styled from "styled-components";
import Colors from "../utils/colors";
import { Image, Button, useDisclosure, Text } from "@chakra-ui/react";
import logo from "../assets/logo.svg";
import { FaBell, FaUser } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { CustomSearch } from "./customsearch";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import AuthModal from "./AuthModal";
import { useAuth } from "../utils/AuthProvider";
import { changeIndex } from "../store/homePageIndexSlice";
import { HOME_PAGE_CONTENT_INDEX } from "../utils/constants";
import { logOut } from "../api/actions";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isRegister, setIsRegister] = useState<boolean | undefined>(undefined);

  const [profileOpen, setProfileOpen] = useState(false);

  const openPage = useCallback(
    (page: string) => {
      navigate(page);
      setProfileOpen(false);
    },
    [navigate]
  );

  const user = useAuth();
  const headerRightButtons =
    user.token || localStorage.getItem("token") ? (
      <SocialButtonsRow>
        <Button
          size="md"
          height="50px"
          w={"250px"}
          color={Colors.primaryBlue}
          backgroundColor={Colors.primaryYellow}
          borderRadius={50}
          fontWeight={"bold"}
          onClick={() => navigate("/publish")}
        >
          Публикувай обява
        </Button>
        <Button
          colorScheme="white"
          leftIcon={<FaBell />}
          textColor={Colors.textGray}
          fontWeight={"bold"}
          fontSize={16}
          onClick={() => {
            // TODO this should open the notifications
          }}
        >
          Известия
        </Button>
        <Button
          colorScheme="white"
          leftIcon={<FaUser />}
          textColor={Colors.textGray}
          fontWeight={"bold"}
          fontSize={16}
          onClick={() => setProfileOpen((profileOpen) => !profileOpen)}
        >
          Профил
        </Button>
        {profileOpen && (
          <ProfileContainer>
            <Text fontSize={15} marginBottom={4} cursor="pointer">
              Обяви
            </Text>
            <Text fontSize={15} marginBottom={4} cursor="pointer">
              Услуги
            </Text>
            <Text fontSize={15} marginBottom={4} cursor="pointer" onClick={() => openPage("/profile")}>
              Настройки
            </Text>
            <Text fontSize={15} marginBottom={4} cursor="pointer" onClick={() => openPage("/budget")}>
              Плащания
            </Text>
            <Text
              fontSize={15}
              marginBottom={4}
              cursor="pointer"
              onClick={() => {
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                dispatch(logOut());
                navigate("/");
                setProfileOpen(false);
              }}
            >
              Изход
            </Text>
          </ProfileContainer>
        )}
      </SocialButtonsRow>
    ) : (
      <SocialButtonsRow>
        <Button
          colorScheme="white"
          leftIcon={<FaUser />}
          textColor={Colors.textGray}
          fontWeight={"bold"}
          fontSize={16}
          onClick={() => {
            setIsRegister(false);
            onOpen();
          }}
        >
          Вход
        </Button>
        <Button
          w={"200px"}
          h={"50px"}
          backgroundColor={"transparent"}
          border={`1px solid ${Colors.secondaryGray}`}
          textColor={Colors.textGray}
          borderRadius={"50px"}
          fontSize={18}
          fontWeight={"regular"}
          lineHeight={1}
          colorScheme="white"
          onClick={() => {
            setIsRegister(true);
            onOpen();
          }}
        >
          Създай акаунт
        </Button>
      </SocialButtonsRow>
    );

  return (
    <HeaderContainer>
      <AuthModal isOpen={isOpen} onClose={onClose} isRegister={isRegister} />
      <HeaderInnerContainer>
        <Row>
          <Image
            src={logo}
            onClick={() => {
              dispatch(changeIndex(HOME_PAGE_CONTENT_INDEX.HOME_PAGE_CONTENT));
              navigate("/");
            }}
            cursor={"pointer"}
          />
        </Row>
        <Row>
          <CustomSearch />
        </Row>
        <NavButtonsRow>{headerRightButtons}</NavButtonsRow>
      </HeaderInnerContainer>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header`
  width: 100%;
  height: 80px;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

const HeaderInnerContainer = styled.div`
  width: 94%;
  display: grid;
  grid-template-columns: 33% 33% 33%;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const NavButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const SocialButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 50px;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 5px;
  width: 200px;
  position: absolute;
  right: 47px;
  top: 85px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

export default Header;
