import { useEffect, useState } from "react";
import Colors from "../utils/colors";
import { Flex, Button } from "@chakra-ui/react";
import { faHelmetSafety, faUser, faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguage } from "../utils/useLanguage";
import { TAB_INDEX } from "../utils/constants";

export interface PageTabsProps {
  index: number;
  onTabChanged: (tabIndex: number) => void;
  leftTabTitle?: string;
  middleTabTitle?: string;
  rightTabTitle?: string;
}

function PageTabs({ onTabChanged, leftTabTitle, middleTabTitle, rightTabTitle, index }: PageTabsProps) {
  const [selected, setSelected] = useState(index);

  useEffect(() => {
    setSelected(index);
  }, [index]);

  const lang = useLanguage();
  if (!leftTabTitle) {
    leftTabTitle = lang.tabs_services;
  }

  if (!middleTabTitle) {
    middleTabTitle = lang.tabs_adds;
  }

  if (!rightTabTitle) {
    rightTabTitle = lang.tabs_articles;
  }

  const selectedButtonStyle = { backgroundColor: Colors.primaryBlue, borderRadius: "50px", width: "155px" };
  const nonSelectedButtonStyle = { width: "155px" };

  return (
    <Flex
      marginBottom={"12px"}
      border={`1px solid ${Colors.primaryBlue}`}
      borderRadius={"50px"}
      w={"fit-content"}
    >
      <Flex style={selected === 1 ? selectedButtonStyle : nonSelectedButtonStyle}>
        <Button
          width={"155px"}
          height={"50px"}
          colorScheme={"white"}
          fontWeight={"400"}
          textColor={selected === 1 ? "white" : Colors.textGray}
          leftIcon={<FontAwesomeIcon icon={faHelmetSafety} fontSize={"18px"} />}
          onClick={() => {
            setSelected(TAB_INDEX.SERVICES);
            onTabChanged(TAB_INDEX.SERVICES);
          }}
        >
          {leftTabTitle}
        </Button>
      </Flex>

      <Flex style={selected === 2 ? selectedButtonStyle : nonSelectedButtonStyle}>
        <Button
          width={"155px"}
          height={"50px"}
          colorScheme={"white"}
          fontWeight={"400"}
          textColor={selected === 2 ? "white" : Colors.textGray}
          leftIcon={<FontAwesomeIcon icon={faUser} fontSize={"18px"} />}
          onClick={() => {
            setSelected(TAB_INDEX.SERVICE_REQUEST);
            onTabChanged(TAB_INDEX.SERVICE_REQUEST);
          }}
        >
          {middleTabTitle}
        </Button>
      </Flex>
      <Flex style={selected === 3 ? selectedButtonStyle : nonSelectedButtonStyle}>
        <Button
          width={"155px"}
          height={"50px"}
          colorScheme={"white"}
          fontWeight={"400"}
          textColor={selected === 3 ? "white" : Colors.textGray}
          leftIcon={<FontAwesomeIcon icon={faNewspaper} fontSize={"18px"} />}
          onClick={() => {
            setSelected(TAB_INDEX.ARTICLES);
            onTabChanged(TAB_INDEX.ARTICLES);
          }}
        >
          {rightTabTitle}
        </Button>
      </Flex>
    </Flex>
  );
}

export default PageTabs;
