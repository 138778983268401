import { FetchArgs } from "@reduxjs/toolkit/query";
import { appApi } from "../api/rtk-query-init";
import { Tags, UserOut } from "../types";
import { getBaseAPIUrl } from "../store/utils";

const extendedApi = appApi.injectEndpoints({
    endpoints: builder => ({
        loginUser: builder.mutation({
            extraOptions: { maxRetries: 0 },
            invalidatesTags: [Tags.USER],
            query: ({ email, password }: UserOut): FetchArgs => ({
                url: `${getBaseAPIUrl()}users/login`,
                method: "POST",
                body: { email, password }
            }),
        }),
        registerUser: builder.mutation({
            extraOptions: { maxRetries: 0 },
            invalidatesTags: [Tags.USER],
            query: ({ email, password }: UserOut): FetchArgs => ({
                url: `${getBaseAPIUrl()}users/register`,
                method: "POST",
                body: { email, password, city: "София", address: "тестов адрес", phone: "0888888888", country: "Bulgaria", firstName: "Ivan", lastName: "Petrov" }
            }),
        }),
        loginCraftsMan: builder.mutation({
            extraOptions: { maxRetries: 0 },
            invalidatesTags: [Tags.USER],
            query: ({ email, password }: UserOut): FetchArgs => ({
                url: `${getBaseAPIUrl()}craftsmen/login`,
                method: "POST",
                body: { email, password }
            }),
        }),
        registerCraftsMan: builder.mutation({
            extraOptions: { maxRetries: 0 },
            invalidatesTags: [Tags.USER],
            query: ({ email, password }: UserOut): FetchArgs => ({
                url: `${getBaseAPIUrl()}craftsmen/register`,
                method: "POST",
                body: { email, password }
            }),
        }),
    }),
});

export const { useLoginUserMutation, useRegisterUserMutation, useLoginCraftsManMutation, useRegisterCraftsManMutation } = extendedApi;

export default extendedApi;
