import { Grid, GridItem, Flex, Image, useDisclosure, Text } from "@chakra-ui/react";
import styled from "styled-components";
import Colors from "../utils/colors";
import { useParams } from "react-router-dom";
import CraftsManHeader from "./components/CraftsManHeader";
import ImportantNote from "./components/ImportantNote";
import ReviewsList from "./components/ReviewsList";
import ServiceDescription from "./components/ServiceDescription";
import SimilarServices from "./components/SimilarServices";
import MoreServices from "./components/MoreServices";
import { useRequestServiceDetailsQuery, useRequestServicesQuery } from "./api/services";
import RequestServiceModal from "./components/RequestServiceModal";
import { useEffect, useMemo } from "react";
import { useAuth } from "../utils/AuthProvider";

function ServiceDetailsPage() {
  const { id } = useParams();
  const user = useAuth();
  const { data: service } = useRequestServiceDetailsQuery(id ?? "");
  const { data: moreServices } = useRequestServicesQuery(
    { craftsManId: service?.craftsMan?.id },
    { skip: !service || !service.craftsMan }
  );
  const { data: similarServices } = useRequestServicesQuery(
    { categoryId: service?.categoryId, subCategoryIds: service?.subcategoryIds },
    { skip: !service || !service.craftsMan }
  );
  const { isOpen: isServiceRequestModalOpen, onOpen, onClose: onServiceRequestModalClose } = useDisclosure();

  const moreServicesFiltered = useMemo(
    () => moreServices?.items?.filter((service) => service.id !== id),
    [moreServices, id]
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [id]);

  return (
    <Container>
      <Flex direction="column" alignItems="center" bg={Colors.white} padding={8}>
        <CraftsManHeader craftsMan={service?.craftsMan} />

        <Grid templateColumns="repeat(10, 1fr)" gap={6} width="100%">
          <GridItem colSpan={3} bg="white" padding={6}>
            <ImportantNote
              onActionClicked={onOpen}
              applyDisabled={user?.user?.isCraftsMan || user?.user?.id === service?.craftsMan?.id}
            />

            {service?.reviews && service?.reviews.length > 0 && <ReviewsList reviews={service?.reviews} />}
          </GridItem>

          {service && (
            <GridItem colSpan={7} bg="white" padding={6}>
              <ServiceDescription {...service} />
            </GridItem>
          )}
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" gap={6} width="100%">
          <GridItem colSpan={3} />
          <GridItem mt={10} colSpan={7}>
            {moreServices && moreServicesFiltered && moreServicesFiltered.length > 0 && (
              <MoreServices
                services={{ items: moreServicesFiltered, paginationInfo: moreServices?.paginationInfo }}
                ownerName={service?.craftsMan?.name ?? ""}
              />
            )}
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" gap={6} width="100%">
          <GridItem colSpan={3} />
          <GridItem mt={10} colSpan={7}>
            {similarServices ? (
              <SimilarServices
                similarServices={similarServices.items.filter((s) => s.id !== service?.id).slice(0, 6)}
              />
            ) : null}
          </GridItem>
        </Grid>
      </Flex>
      <RequestServiceModal
        isOpen={isServiceRequestModalOpen}
        onClose={onServiceRequestModalClose}
        service={service}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

export default ServiceDetailsPage;
