import React, { useCallback, useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Text,
  VStack,
  HStack,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { StripeCardElement } from "@stripe/stripe-js";
import { useLazyCreatePaymentIntentQuery } from "./api/Payments";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../utils/useAppRedux";
import { appApi } from "../api/rtk-query-init";
import { Tags } from "../types";
import { useGetCraftsManByIdQuery } from "../api/common/Craftsmen";
import { useAuth } from "../utils/AuthProvider";
import { setUser } from "../api/reducers";

const PaymentsPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const amount = searchParams.get("amount") || "0";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAuth();

  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [sendPaymentRequest, { data, isError, isSuccess }] = useLazyCreatePaymentIntentQuery();
  const { refetch } = useGetCraftsManByIdQuery(user?.user?.id ?? "");

  const toast = useToast({ position: "top" });

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);

      const numericAmount = parseFloat(amount.toString());
      if (!numericAmount || numericAmount <= 0) {
        setMessage("Моля, въведете валидна сума.");
        setLoading(false);
        return;
      }

      if (!elements) {
        setMessage("Stripe елементите не са заредени.");
        setLoading(false);
        return;
      }

      sendPaymentRequest(numericAmount);
    },
    [amount, elements, sendPaymentRequest]
  );

  useEffect(() => {
    if (isSuccess && elements) {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        setMessage("Елементът за карта не е намерен.");
        setLoading(false);
        return;
      }
      if (!stripe) {
        setMessage("Stripe не е зареден.");
        setLoading(false);
        return;
      }
      setLoading(false);
      const { clientSecret } = data;

      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: { card: cardElement as StripeCardElement },
        })
        .then((paymentResult) => {
          if (paymentResult.error) {
            toast({
              title: "Плащането не беше успешно",
              description: paymentResult.error.message,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setMessage(paymentResult.error.message || "");
          } else if (paymentResult.paymentIntent?.status === "succeeded") {
            toast({
              title: "Плащането е успешно",
              description: "Вашето плащане беше обработено успешно!",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            setMessage("Плащането е успешно!");
            dispatch(appApi.util.invalidateTags([Tags.TRANSACTIONS]));
            setTimeout(() => {
              refetch()
                .unwrap()
                .then((user) => {
                  dispatch(setUser(user));
                  navigate(-1);
                });
            }, 2000);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isError, isSuccess, elements]);

  return (
    <Box w="50%" mx="auto" mt={10} p={6} borderWidth={1} borderRadius="lg" boxShadow="lg">
      <Heading as="h3" size="lg" textAlign="center" mb={6}>
        Плащане
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Данни за картата</FormLabel>
            <Box
              borderWidth={1}
              p={3}
              borderRadius="md"
              borderColor="gray.300"
              _hover={{ borderColor: "teal.500" }}
            >
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      "::placeholder": { color: "#9ca3af" },
                    },
                    invalid: { color: "#fa755a" },
                  },
                }}
              />
            </Box>
          </FormControl>
          {loading ? (
            <HStack spacing={2}>
              <Spinner size="sm" />
              <Text>Обработване...</Text>
            </HStack>
          ) : (
            <Button type="submit" colorScheme="teal" size="lg" width="full" isDisabled={!stripe || !elements}>
              Платете {amount || 0} лв
            </Button>
          )}
          {message && <Text color={message.includes("успешно") ? "green.500" : "red.500"}>{message}</Text>}
        </VStack>
      </form>
    </Box>
  );
};

export default PaymentsPage;
