import { Text, Image, Flex, Box, Button } from "@chakra-ui/react";
import styled from "styled-components";
import background from "../../assets/background-service.svg";
import { UserOut } from "../../types/services";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const UserHeader = ({ user }: { user?: UserOut }) => {
  const navigate = useNavigate();

  if (!user) return null;

  return (
    <HeaderContainer>
      <Box
        width="100%"
        maxWidth={"99.5dvw"}
        height="100px"
        backgroundImage={background}
        display="flex"
        alignItems="center"
        paddingX={"10rem"}
      >
        <Flex direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <Button leftIcon={<FaArrowLeft />} colorScheme="gray" variant="ghost" onClick={() => navigate(-1)}>
            Виж всички обяви
          </Button>
          <Flex alignItems={"center"}>
            <ImageWrapper>
              <Image borderRadius="full" width={"2rem"} src={user.image} boxSize="2rem" alt="craftsMan" />
            </ImageWrapper>
            <Text fontWeight="bold" fontSize={15}>
              {user.name}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  background: ${background};
  margin-top: 2rem;
  height: 7rem;
  width: 99.5vw;
  margin-left: -15rem;
`;

const ImageWrapper = styled.div`
  margin-right: 1rem;
`;

export default UserHeader;
