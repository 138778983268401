import { appApi } from "../../api/rtk-query-init";
import { getBaseAPIUrl } from "../../store/utils";
import { ListResponse, Tags } from "../../types";
import { Service, ServiceApplicationIn, ServiceDetailsOut } from "../../types/services";
import { SearchParams } from "../types/SearchParams";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    requestServices: builder.query<ListResponse<Service>, SearchParams>({
      providesTags: [Tags.SERVICES],
      query: (params) => ({
        url: "services/query",
        method: "POST",
        body: { query: params ?? {} },
      }),
    }),
    requestServiceDetails: builder.query<ServiceDetailsOut, string>({
      providesTags: [Tags.SERVICES],
      query: (id: string) => `services/${id}`,
    }),
    applyForService: builder.mutation<void, ServiceApplicationIn & { serviceId: string }>({
      invalidatesTags: [Tags.APPLIED_SERVICES],
      query: ({ serviceId, ...serviceApplication }) => ({
        url: `services/${serviceId}/apply`,
        method: "PUT",
        body: serviceApplication,
      }),
    }),
  }),
});

export const { useRequestServicesQuery, useRequestServiceDetailsQuery, useApplyForServiceMutation } =
  extendedApi;

export default extendedApi;
