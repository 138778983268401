import { appApi } from "../rtk-query-init";
import { Tags, User } from "../../types";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCraftsManById: builder.query<User, string>({
      extraOptions: { maxRetries: 0 },
      providesTags: [Tags.USER],
      query: (id) => `craftsmen/${id}`,
    }),
  }),
});

export const { useGetCraftsManByIdQuery, useLazyGetCraftsManByIdQuery } = extendedApi;

export default extendedApi;
