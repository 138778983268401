import { appApi } from "../../api/rtk-query-init";
import { ImageIn } from "../../types/services";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadImage: builder.mutation<ImageIn, FormData>({
      query: (formData) => ({
        url: "/images",
        method: "POST",
        body: formData,
      }),
    }),
    removeImage: builder.mutation<void, string>({
      query: (path: string) => ({
        url: `/images/${encodeURIComponent(path)}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useUploadImageMutation, useRemoveImageMutation } = extendedApi;
