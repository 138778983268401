import { Text, Grid, GridItem, Flex, Button } from "@chakra-ui/react";
import Spacer from "../components/Spacer";
import styled from "styled-components";
import Colors from "../utils/colors";
import IconWithText from "../components/IconWithText";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import { useLanguage } from "../utils/useLanguage";
import { useCallback, useState } from "react";
import { usePublishServiceMutation, usePublishServiceRequestMutation } from "./api/PublishService";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/AuthProvider";
import { PublishServiceForm } from "./components/PublishServiceForm";
import { ServiceIn } from "../types/ServiceIn";

function PublishServicePage() {
  const lang = useLanguage();
  const user = useAuth();
  const navigate = useNavigate();

  // state
  const [service, setService] = useState<ServiceIn>({} as ServiceIn);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // queries
  const [publishServiceCall] = usePublishServiceMutation();
  const [publishServiceRequestCall] = usePublishServiceRequestMutation();

  const validateForm = useCallback(() => {
    let newErrors: { [key: string]: string } = {};

    if (!service?.title?.trim()) newErrors.title = "Заглавието е задължително";
    if (!service?.categoryId) newErrors.category = "Трябва да изберете поне една категория";
    if (!service?.city) newErrors.city = "Градът е задължителен";
    if (!service?.description?.trim()) newErrors.description = "Описанието е задължително";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [service?.categoryId, service?.city, service?.description, service?.title]);

  const publishService = useCallback(() => {
    if (!validateForm()) return;

    const apiCall = user.user?.isCraftsMan ? publishServiceCall : publishServiceRequestCall;
    apiCall(service)
      .unwrap()
      .then((res) => {
        navigate(user.user?.isCraftsMan ? `/service/${res.id}` : `/service-request/${res.id}`);
      })
      .catch((e) => {
        alert(e.data.error);
      });
  }, [
    navigate,
    publishServiceCall,
    publishServiceRequestCall,
    service,
    user.user?.isCraftsMan,
    validateForm,
  ]);

  const tips = user.user?.isCraftsMan ? lang.publishTips.handymen : lang.publishTips.client;

  return (
    <Container>
      <Grid templateColumns="repeat(20, 1fr)" gap={6}>
        <GridItem colSpan={6}>
          <Flex direction={"column"} alignItems={"left"} maxW={"95%"}>
            <Spacer />
            <Text as="b" fontSize={18} width={"100%"} textAlign={"left"} fontFamily={"Inter"}>
              {tips.publish_tips1}
            </Text>
            <Spacer />
            <IconWithText icon={faLightbulb} text={tips.publish_tips2} />
            <Spacer />
            <IconWithText icon={faLightbulb} text={tips.publish_tips3} />
            <Spacer />
            <IconWithText icon={faLightbulb} text={tips.publish_tips4} />
            <Spacer />
            <Spacer />
            <Text as="b" fontSize={18} width={"100%"} textAlign={"left"} fontFamily={"Inter"}>
              {tips.publish_tips5}
            </Text>
            <Spacer />
            <IconWithText icon={faLightbulb} text={tips.publish_tips6} />
            <Spacer />
            <Spacer />
            <Text as="b" fontSize={18} width={"100%"} textAlign={"left"} fontFamily={"Inter"}>
              {tips.publish_tips7}
            </Text>
            <Spacer />
            <Spacer />
            <IconWithText icon={faLightbulb} text={tips.publish_tips8} />
            <Spacer />
            <IconWithText icon={faLightbulb} text={tips.publish_tips9} />
            <Spacer />
            <IconWithText icon={faLightbulb} text={tips.publish_tips10} />
          </Flex>
        </GridItem>
        <GridItem colSpan={1} />
        <GridItem
          colSpan={13}
          bg={Colors.lightGray}
          borderRadius={25}
          paddingLeft={"30px"}
          paddingRight={"30px"}
        >
          <PublishServiceForm service={service} setService={setService} errors={errors} />

          <Flex width={"100%"} alignItems={"center"} justifyContent={"flex-end"}>
            <Button size="md" height="40px" w={"200px"} colorScheme="green" onClick={publishService}>
              {lang.publish}
            </Button>
          </Flex>
        </GridItem>
      </Grid>
    </Container>
  );
}

const Container = styled.div`
  padding: 2rem 0px;
`;

export default PublishServicePage;
