import { Avatar, Box, Flex, Text, Image } from "@chakra-ui/react";
import { ChakraIcon } from "../components/ChakraIcon";
import { faHelmetSafety, faStar, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Spacer from "../components/Spacer";
import Colors from "../utils/colors";
import { HomePageCardContent } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import placeholder from "../assets/placeholder.svg";

interface HomeCardProps {
  content: HomePageCardContent;
  onClick: (id: number) => void;
}

function HomeCard({ content: add, onClick }: HomeCardProps) {
  return (
    <Box maxW="sm" cursor={"pointer"} paddingBottom={"20px"} onClick={() => onClick(add.id)}>
      <Image
        borderRadius={12}
        height={"12.5rem"}
        width={"-webkit-fill-available"}
        objectFit="cover"
        src={add.image && add.image?.trim()?.length > 0 ? add.image : placeholder}
      />
      <Box padding={"12px 15px 12px 15px"}>
        <Flex flex="1" direction={"column"}>
          <Flex flex="1" justifyContent="center" alignItems="center">
            <Flex flex="1" gap={"1"} alignItems="center" flexWrap="wrap">
              <Avatar size={"sm"} name={`${add.user.firstName} ${add.user.lastName}`} src={add.user.image} />
              <Text fontSize={"12px"} fontWeight={"700"} color={Colors.textGray} marginLeft={"4px"}>
                {`${add.user.firstName} ${add.user.lastName}`}{" "}
              </Text>
              <FontAwesomeIcon icon={faHelmetSafety} color={Colors.textGray} fontSize={"14px"} />
            </Flex>
            <ChakraIcon icon={faStar} color={Colors.primaryYellow} />
            <Spacer horizontal size={4} />
            <Text fontWeight={"bold"} letterSpacing={1.1} fontSize={18} color={Colors.primaryBlue}>
              {add.rating}
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Box padding={"0px 15px 4px 15px"}>
        <Text fontWeight={"700"} fontSize={"18px"} noOfLines={2} lineHeight={1}>
          {add.title}
        </Text>
      </Box>
      <Flex flex="1" gap={"1"} alignItems="center" flexWrap="wrap" padding={"0px 15px 4px 15px"}>
        <FontAwesomeIcon icon={faLocationDot} color={Colors.textGray} fontSize={"14px"} />
        <Text fontSize={"12px"} color={Colors.textGray} marginLeft={"4px"}>
          {add.city}
        </Text>
      </Flex>
    </Box>
  );
}

export default HomeCard;
