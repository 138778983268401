import React from "react";
import { Image, Heading, Text, Flex, Icon, Button, Stack, Box } from "@chakra-ui/react";
import { FaEye, FaMapMarkerAlt } from "react-icons/fa";
import Colors from "../../utils/colors";
import Paginator from "../../components/Paginator";
import { Service } from "../../types/services";
import { ListResponse } from "../../types";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import placeholder from "../../assets/placeholder.svg";

const ServiceCard: React.FC<Service> = ({ image, title, city, description, id }) => {
  const navigate = useNavigate();
  return (
    <Flex
      borderRadius="lg"
      overflow="hidden"
      mb={10}
      flexDirection={"row"}
      backgroundColor={Colors.lightGray}
      p={5}
    >
      <Image
        src={image && image.trim().length > 0 ? image : placeholder}
        alt={title}
        minWidth="311px"
        minHeight="200px"
        width="311px"
        height="200px"
        objectFit="cover"
        borderRadius={6}
      />
      <Stack spacing={2} p={6} w="100%">
        <Heading as="h3" size="md">
          {title}
        </Heading>
        <Flex align="center" color="gray.500" fontSize="sm">
          <Icon as={FaMapMarkerAlt} mx={2} />
          {city}
        </Flex>
        <Text
          mt={2}
          marginLeft={"4px"}
          noOfLines={2}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
        />

        <Button
          leftIcon={<FaEye />}
          variant="outline"
          alignSelf="flex-end"
          colorScheme="gray"
          borderRadius="full"
          borderColor="gray.500"
          color="gray.500"
          _hover={{ bg: "gray.100" }}
          mr={4}
          mt={8}
          onClick={() => navigate(`/service/${id}`)}
        >
          Прегледай
        </Button>
      </Stack>
    </Flex>
  );
};

const MoreServices = ({ services, ownerName }: { services: ListResponse<Service>; ownerName: string }) => {
  return (
    <Box width="100%" margin="0 auto">
      <Text fontSize="22px" marginBottom="16px" color="gray.600" fontWeight="200" mb={4}>
        Още услуги от <strong>{ownerName}</strong>
      </Text>
      {services.items.map((service, index) => (
        <ServiceCard key={index} {...service} />
      ))}
      <Paginator
        totalPages={services.paginationInfo.totalPages}
        onPageChanged={(_) => {}}
        forcePageReset={false}
      />
    </Box>
  );
};

export default MoreServices;
