import "./App.css";
import styled from "styled-components";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./home/HomePage";
import PrivacyPolicy from "./privacy/PrivacyPolicy";
import ContactUs from "./contact/ContactUs";
import PublishServicePage from "./publishService/PublishServicePage";
import ServiceDetailsPage from "./serviceDetails/ServiceDetailsPage";
import SingleArticlePage from "./articles/components/SingleArticlePage";
import { useEffect } from "react";
import { useAppDispatch } from "./utils/useAppRedux";
import { setToken, setUser } from "./api/reducers";
import PrivateRoute from "./components/PrivateRoute";
import ServiceRequestDetailsPage from "./serviceRequestDetails/ServiceRequestDetailsPage";
import RequestServicePage from "./services/RequestServicePage";
import MyRequestedServicesPage from "./home/MyRequestedServicesPage";
import MyServicesPage from "./home/MyServicesPage";
import MyServiceRequestsPage from "./home/MyServiceRequestsPage";
import Registration from "./registration/Registration";
import { ProfilePage } from "./profile/ProfilePage";
import { BudgetPage } from "./budget/BudgetPage";
import ApplyForServiceRequestPage from "./serviceRequestDetails/components/ApplyForServiceRequestPage";
import MyAppliedServiceRequestsPage from "./home/MyAppliedServiceRequestsPage";
import { useGetUserProfileQuery } from "./home/api/Profile";
import PaymentsPage from "./payments/Payments";

function App() {
  const dispatch = useAppDispatch();
  const { data: userProfile } = useGetUserProfileQuery();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      dispatch(setToken(token));
      dispatch(setUser(userProfile ?? null));
    } else {
      dispatch(setToken(null));
      dispatch(setUser(null));
    }
  }, [dispatch, userProfile]);

  return (
    <Root>
      <Header />
      <MainContent>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/sign-up" element={<Registration />} />
          <Route path="/payments" element={<PaymentsPage />} />
          <Route path="/articles/article/:id" element={<SingleArticlePage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/service/:id" element={<ServiceDetailsPage />} />
          <Route path="/contact" element={<ContactUs />} />

          <Route element={<PrivateRoute />}>
            <Route path="/service-request/:id/apply" element={<ApplyForServiceRequestPage />} />
            <Route path="/request-service/:id" element={<RequestServicePage />} />
            <Route path="/services-mine" element={<MyServicesPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/budget" element={<BudgetPage />} />
            <Route path="/service-requests-mine" element={<MyServiceRequestsPage />} />
            <Route path="/publish" element={<PublishServicePage />} />
            <Route path="/services-requested" element={<MyRequestedServicesPage />} />
            <Route path="/applied-service-requests" element={<MyAppliedServiceRequestsPage />} />
          </Route>
          <Route path="/service-request/:id" element={<ServiceRequestDetailsPage />} />
        </Routes>
      </MainContent>
      <Footer />
    </Root>
  );
}

const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MainContent = styled.main`
  flex: 1;
  display: flex;
  width: 82rem;
  flex-direction: column;
  align-items: center;
  justify-content: "flex-start";
`;

export default App;
