import React, { useRef, useState } from "react";
import lodash_map from "lodash/map";
import { Portal } from "@chakra-ui/react";
import lodash_isNil from "lodash/isNil";
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  useOutsideClick,
  VStack,
  Select,
} from "@chakra-ui/react";
import { InputGroup, Input as InputComponent, InputRightElement } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { DateObj, useDayzed, RenderProps } from "dayzed";
import { format } from "date-fns";

const MONTH_NAMES_DEFAULT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const DAY_NAMES_DEFAULT = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const DATE_FORMAT_DEFAULT = "yyyy-MM-dd";

interface SingleDatepickerProps {
  disabled?: boolean;
  onDateTimeChange: (date: Date) => void;
  id?: string;
  name?: string;
  date?: Date;
  configs?: SingleDatepickerConfigs;
  minDate?: Date;
  maxDate?: Date;
}

interface SingleDatepickerConfigs {
  dateFormat: string;
  monthNames: string[];
  dayNames: string[];
}

const SingleDatepickerCalendar = (
  props: RenderProps & { configs: SingleDatepickerConfigs; minDate?: Date; maxDate?: Date }
) => {
  const { calendars, getDateProps, getBackProps, getForwardProps, configs, minDate, maxDate } = props;

  if (!calendars.length) {
    return null;
  }

  return (
    <HStack className="datepicker-calendar">
      {lodash_map(calendars, (calendar) => (
        <VStack key={`${calendar.month}${calendar.year}`}>
          <HStack>
            <Button {...getBackProps({ calendars, offset: 12 })} variant="ghost" size="sm">
              {"<<"}
            </Button>
            <Button {...getBackProps({ calendars })} variant="ghost" size="sm">
              {"<"}
            </Button>
            <Heading size="sm" textAlign="center">
              {configs.monthNames[calendar.month]} {calendar.year}
            </Heading>
            <Button {...getForwardProps({ calendars })} variant="ghost" size="sm">
              {">"}
            </Button>
            <Button {...getForwardProps({ calendars, offset: 12 })} variant="ghost" size="sm">
              {">>"}
            </Button>
          </HStack>
          <Divider />
          <SimpleGrid columns={7} spacing={2} textAlign="center">
            {lodash_map(configs.dayNames, (day) => (
              <Box key={`${calendar.month}${calendar.year}${day}`}>
                <Text fontSize="sm" fontWeight="semibold">
                  {day}
                </Text>
              </Box>
            ))}
            {lodash_map(calendar.weeks, (week, weekIndex) =>
              lodash_map(week, (dateObj: DateObj, index) => {
                const { date, today, selected } = dateObj;
                const isDisabled = (minDate && date < minDate) || (maxDate && date > maxDate);
                const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;

                return (
                  <Button
                    {...getDateProps({ dateObj })}
                    key={key}
                    size="sm"
                    variant="outline"
                    borderColor={today ? "purple.400" : "transparent"}
                    bg={selected ? "purple.200" : undefined}
                    color={isDisabled ? "gray.400" : "black"}
                    isDisabled={isDisabled}
                  >
                    {date.getDate()}
                  </Button>
                );
              })
            )}
          </SimpleGrid>
        </VStack>
      ))}
    </HStack>
  );
};

export const SingleDatepicker: React.FC<SingleDatepickerProps> = ({
  configs = {
    dateFormat: DATE_FORMAT_DEFAULT,
    monthNames: MONTH_NAMES_DEFAULT,
    dayNames: DAY_NAMES_DEFAULT,
  },
  minDate,
  maxDate,
  ...props
}) => {
  const { date, name, disabled, onDateTimeChange, id } = props;
  const ref = useRef<HTMLElement>(null);
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(date);
  const [selectedTime, setSelectedTime] = useState<string>("12:00");

  useOutsideClick({
    ref: ref,
    handler: () => setPopoverOpen(false),
  });

  const onDateSelected = (options: { selectable: boolean; date: Date }) => {
    const { selectable, date } = options;
    if (!selectable || (minDate && date < minDate) || (maxDate && date > maxDate)) return;
    if (!lodash_isNil(date)) {
      setSelectedDate(date);
      onDateTimeChange(date);
    }
  };

  const onTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!selectedDate) return;
    setSelectedTime(event.target.value);
    const [hours, minutes] = event.target.value.split(":").map(Number);
    const updatedDate = new Date(selectedDate);
    updatedDate.setHours(hours, minutes);
    onDateTimeChange(updatedDate);
  };

  const dayzedData = useDayzed({
    showOutsideDays: true,
    onDateSelected,
    selected: selectedDate,
    minDate,
    maxDate,
  });

  return (
    <Popover
      placement="bottom-start"
      isOpen={popoverOpen}
      onClose={() => setPopoverOpen(false)}
      initialFocusRef={initialFocusRef}
      isLazy
    >
      <PopoverTrigger>
        <InputGroup>
          <InputComponent
            id={id}
            autoComplete="off"
            background="white"
            borderRadius="50px"
            isDisabled={disabled}
            ref={initialFocusRef}
            onClick={() => setPopoverOpen(!popoverOpen)}
            name={name}
            value={`${selectedDate ? format(selectedDate, configs.dateFormat) : ""} ${
              selectedDate ? selectedTime : ""
            }`}
            readOnly
          />
          <InputRightElement color="gray.500" children={<CalendarIcon fontSize="sm" />} />
        </InputGroup>
      </PopoverTrigger>
      <Portal>
        <PopoverContent ref={ref} zIndex="1500">
          <PopoverBody>
            <SingleDatepickerCalendar {...dayzedData} configs={configs} minDate={minDate} maxDate={maxDate} />
            <Divider marginY={4} />
            <Text fontWeight="bold" mb={2}>
              Select Time
            </Text>
            <Select value={selectedTime} onChange={onTimeChange}>
              {Array.from({ length: 24 }, (_, hour) =>
                ["00", "30"].map((minute) => (
                  <option
                    key={`${hour}:${minute}`}
                    value={`${String(hour).padStart(2, "0")}:${minute}`}
                  >{`${String(hour).padStart(2, "0")}:${minute}`}</option>
                ))
              )}
            </Select>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
