import { Flex } from "@chakra-ui/react";
import Spacer from "../components/Spacer";
import HomePageContent from "./components/HomePageContent";
import { TopBar } from "./TopNavigationBar";

function HomePage() {
  return (
    <Flex flexDir={"column"} alignItems={"center"} padding="0px 0px 40px 0px">
      <Spacer size={30} />
      <TopBar />
      <Spacer size={60} />
      <Flex direction="column" justifyContent="space-between" width="80rem">
        <HomePageContent />
      </Flex>
    </Flex>
  );
}

export default HomePage;
