import { configureStore, Middleware } from "@reduxjs/toolkit";


import { appApi } from "../api/rtk-query-init";
import reducer from "./reducers";

export const createStore = () => {
    const store = configureStore({
        // @ts-ignore
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                immutableCheck: false,
                serializableCheck: false,
            }).concat([
                appApi.middleware as Middleware,
            ]),
        reducer: reducer,
    });

    return store;
};

const store = createStore();
export default store;