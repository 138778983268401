import { useEffect } from "react";
import socket from "../../utils/socket";
import { MessageOut } from "../types/ChatRoomOut";

export const useSocket = (onMessageReceived: (message: MessageOut) => void) => {
  useEffect(() => {
    socket.on("connect", () => {});
    socket.on("message", onMessageReceived);
    socket.on("error", (errorMessage) => console.log(errorMessage));

    return () => {
      socket.off("connect");
      socket.off("message");
      socket.off("error");
    };
  }, [onMessageReceived]);
};
