import { appApi } from "../../api/rtk-query-init";
import { Tags, Service, FilterQuery, ListResponse, HomePageCardContent } from "../../types";
import { ServiceIn } from "../../types/ServiceIn";
import { AppliedServiceOut } from "../types/AppliedServiceOut";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllServices: builder.query<ListResponse<HomePageCardContent>, FilterQuery>({
      query: (query) => ({
        url: "services/query",
        method: "POST",
        body: { query },
      }),
      providesTags: [Tags.SERVICES],
      transformResponse: (response: ListResponse<Service>) => {
        const mappedResponse = {
          ...response,
          items: response.items.map((item) => {
            return {
              id: item.id,
              image: item.image,
              title: item.title,
              description: item.description,
              rating: item.rating,
              user: { ...item.craftsMan },
              city: item.city,
            } as unknown as HomePageCardContent;
          }),
        };
        return mappedResponse;
      },
    }),
    getMyServices: builder.query<[], void>({
      extraOptions: { maxRetries: 0 },
      providesTags: [Tags.SERVICES],
      query: () => ({
        url: "services/mine",
        method: "GET",
      }),
    }),
    updateService: builder.mutation<void, ServiceIn>({
      extraOptions: { maxRetries: 0 },
      invalidatesTags: [Tags.SERVICES],
      query: (service: ServiceIn) => ({
        url: `services/${service.id}`,
        method: "PUT",
        body: service,
      }),
    }),
    getMyAppliedServices: builder.query<Array<AppliedServiceOut>, void>({
      extraOptions: { maxRetries: 0 },
      providesTags: [Tags.APPLIED_SERVICES],
      query: () => ({
        url: "services/mine:applied",
        method: "GET",
      }),
    }),
    deleteServiceById: builder.mutation<void, string>({
      extraOptions: { maxRetries: 0 },
      invalidatesTags: [Tags.SERVICES],
      query: (id: string) => ({
        url: `services/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllServicesQuery,
  useGetMyServicesQuery,
  useGetMyAppliedServicesQuery,
  useUpdateServiceMutation,
  useDeleteServiceByIdMutation,
} = extendedApi;

export default extendedApi;
