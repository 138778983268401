export const cities = [
  {
    "name_bg": "Абланица"
  },
  {
    "name_bg": "Абрит"
  },
  {
    "name_bg": "Мокрен"
  },
  {
    "name_bg": "Аврамово"
  },
  {
    "name_bg": "Аврен"
  },
  {
    "name_bg": "Агатово"
  },
  {
    "name_bg": "Азманите"
  },
  {
    "name_bg": "Върбен"
  },
  {
    "name_bg": "Изгрев"
  },
  {
    "name_bg": "Айрово"
  },
  {
    "name_bg": "Айтос"
  },
  {
    "name_bg": "Аканджиево"
  },
  {
    "name_bg": "Акациево"
  },
  {
    "name_bg": "Аксаково"
  },
  {
    "name_bg": "Аламовци"
  },
  {
    "name_bg": "Албанци"
  },
  {
    "name_bg": "Алваново"
  },
  {
    "name_bg": "Алдомировци"
  },
  {
    "name_bg": "Алеково"
  },
  {
    "name_bg": "Алеко Константиново"
  },
  {
    "name_bg": "Александрия"
  },
  {
    "name_bg": "Александрово"
  },
  {
    "name_bg": "Нови Искър"
  },
  {
    "name_bg": "Александър Стамболийски"
  },
  {
    "name_bg": "Самуилово"
  },
  {
    "name_bg": "Алиговска"
  },
  {
    "name_bg": "Алино"
  },
  {
    "name_bg": "Алтимир"
  },
  {
    "name_bg": "Алфатар"
  },
  {
    "name_bg": "Алцек"
  },
  {
    "name_bg": "Ангеларий"
  },
  {
    "name_bg": "Ангел войвода"
  },
  {
    "name_bg": "Ангелов"
  },
  {
    "name_bg": "Анево"
  },
  {
    "name_bg": "Антимово"
  },
  {
    "name_bg": "Антон"
  },
  {
    "name_bg": "Антоново"
  },
  {
    "name_bg": "Аплаци"
  },
  {
    "name_bg": "Априлово"
  },
  {
    "name_bg": "Априлци"
  },
  {
    "name_bg": "Арбанаси"
  },
  {
    "name_bg": "Арда"
  },
  {
    "name_bg": "Ардино"
  },
  {
    "name_bg": "Арзан"
  },
  {
    "name_bg": "Арковна"
  },
  {
    "name_bg": "Армените"
  },
  {
    "name_bg": "Армянковци"
  },
  {
    "name_bg": "Арнаутито"
  },
  {
    "name_bg": "Арпаджик"
  },
  {
    "name_bg": "Арчар"
  },
  {
    "name_bg": "Асен"
  },
  {
    "name_bg": "Асеновград"
  },
  {
    "name_bg": "Асеновец"
  },
  {
    "name_bg": "Асеново"
  },
  {
    "name_bg": "Асеновци"
  },
  {
    "name_bg": "Аспарухово"
  },
  {
    "name_bg": "Атолово"
  },
  {
    "name_bg": "Ауста"
  },
  {
    "name_bg": "Ахелой"
  },
  {
    "name_bg": "Ахматово"
  },
  {
    "name_bg": "Гълъбово"
  },
  {
    "name_bg": "Ахрянско"
  },
  {
    "name_bg": "Ахтопол"
  },
  {
    "name_bg": "Айдемир"
  },
  {
    "name_bg": "Баба Тонка"
  },
  {
    "name_bg": "Бабек"
  },
  {
    "name_bg": "Бабино"
  },
  {
    "name_bg": "Бабинска река"
  },
  {
    "name_bg": "Бабица"
  },
  {
    "name_bg": "Баблон"
  },
  {
    "name_bg": "Бабово"
  },
  {
    "name_bg": "Бабук"
  },
  {
    "name_bg": "Бабяк"
  },
  {
    "name_bg": "Багалевци"
  },
  {
    "name_bg": "Багра"
  },
  {
    "name_bg": "Багренци"
  },
  {
    "name_bg": "Багрилци"
  },
  {
    "name_bg": "Багрянка"
  },
  {
    "name_bg": "Бадевци"
  },
  {
    "name_bg": "Бадино"
  },
  {
    "name_bg": "Баевци"
  },
  {
    "name_bg": "Баждари"
  },
  {
    "name_bg": "Байкал"
  },
  {
    "name_bg": "Байкалско"
  },
  {
    "name_bg": "Байково"
  },
  {
    "name_bg": "Байлово"
  },
  {
    "name_bg": "Бакалите"
  },
  {
    "name_bg": "Бакьово"
  },
  {
    "name_bg": "Балабаново"
  },
  {
    "name_bg": "Балабанско"
  },
  {
    "name_bg": "Балабанчево"
  },
  {
    "name_bg": "Балалея"
  },
  {
    "name_bg": "Баланите"
  },
  {
    "name_bg": "Баланово"
  },
  {
    "name_bg": "Балван"
  },
  {
    "name_bg": "Балванците"
  },
  {
    "name_bg": "Балдево"
  },
  {
    "name_bg": "Балей"
  },
  {
    "name_bg": "Балик"
  },
  {
    "name_bg": "Балиновци"
  },
  {
    "name_bg": "Балкан"
  },
  {
    "name_bg": "Балканец"
  },
  {
    "name_bg": "Балкан махала"
  },
  {
    "name_bg": "Балкански"
  },
  {
    "name_bg": "Балканци"
  },
  {
    "name_bg": "Балуци"
  },
  {
    "name_bg": "Балчик"
  },
  {
    "name_bg": "Балша"
  },
  {
    "name_bg": "Бальовци"
  },
  {
    "name_bg": "Балювица"
  },
  {
    "name_bg": "Банари"
  },
  {
    "name_bg": "Бангейци"
  },
  {
    "name_bg": "Банево"
  },
  {
    "name_bg": "Баниска"
  },
  {
    "name_bg": "Баница"
  },
  {
    "name_bg": "Баничан"
  },
  {
    "name_bg": "Банище"
  },
  {
    "name_bg": "Банковец"
  },
  {
    "name_bg": "Банковци"
  },
  {
    "name_bg": "Банкя"
  },
  {
    "name_bg": "Баново"
  },
  {
    "name_bg": "Банско"
  },
  {
    "name_bg": "Банчовци"
  },
  {
    "name_bg": "Баня"
  },
  {
    "name_bg": "Бараково"
  },
  {
    "name_bg": "Бараци"
  },
  {
    "name_bg": "Детелина"
  },
  {
    "name_bg": "Барутин"
  },
  {
    "name_bg": "Горна Арда"
  },
  {
    "name_bg": "Басарбово"
  },
  {
    "name_bg": "Баскалци"
  },
  {
    "name_bg": "Бата"
  },
  {
    "name_bg": "Батак"
  },
  {
    "name_bg": "Батин"
  },
  {
    "name_bg": "Батишница"
  },
  {
    "name_bg": "Батово"
  },
  {
    "name_bg": "Батошево"
  },
  {
    "name_bg": "Батулия"
  },
  {
    "name_bg": "Батулци"
  },
  {
    "name_bg": "Баурене"
  },
  {
    "name_bg": "Бахалин"
  },
  {
    "name_bg": "Баховица"
  },
  {
    "name_bg": "Бахреци"
  },
  {
    "name_bg": "Бацова махала"
  },
  {
    "name_bg": "Бачево"
  },
  {
    "name_bg": "Бачково"
  },
  {
    "name_bg": "Букаците"
  },
  {
    "name_bg": "Башево"
  },
  {
    "name_bg": "Бащино"
  },
  {
    "name_bg": "Баячево"
  },
  {
    "name_bg": "Бдинци"
  },
  {
    "name_bg": "Беброво"
  },
  {
    "name_bg": "Беглеж"
  },
  {
    "name_bg": "Бегово"
  },
  {
    "name_bg": "Бегуновци"
  },
  {
    "name_bg": "Бегунци"
  },
  {
    "name_bg": "Беден"
  },
  {
    "name_bg": "Беджене"
  },
  {
    "name_bg": "Бедрово"
  },
  {
    "name_bg": "Бежаново"
  },
  {
    "name_bg": "Безводица"
  },
  {
    "name_bg": "Безводно"
  },
  {
    "name_bg": "Безден"
  },
  {
    "name_bg": "Безденица"
  },
  {
    "name_bg": "Безмер"
  },
  {
    "name_bg": "Бейковци"
  },
  {
    "name_bg": "Бекриите"
  },
  {
    "name_bg": "Каменец"
  },
  {
    "name_bg": "Бела"
  },
  {
    "name_bg": "Беланица"
  },
  {
    "name_bg": "Бела Рада"
  },
  {
    "name_bg": "Беласица"
  },
  {
    "name_bg": "Белащица"
  },
  {
    "name_bg": "Белгун"
  },
  {
    "name_bg": "Белев дол"
  },
  {
    "name_bg": "Белевехчево"
  },
  {
    "name_bg": "Белеврен"
  },
  {
    "name_bg": "Белене"
  },
  {
    "name_bg": "Беленци"
  },
  {
    "name_bg": "Бели брег"
  },
  {
    "name_bg": "Бели брод"
  },
  {
    "name_bg": "Бели бряг"
  },
  {
    "name_bg": "Бели вир"
  },
  {
    "name_bg": "Бели дол"
  },
  {
    "name_bg": "Бели извор"
  },
  {
    "name_bg": "Бели Искър"
  },
  {
    "name_bg": "Белила"
  },
  {
    "name_bg": "Белимел"
  },
  {
    "name_bg": "Белинци"
  },
  {
    "name_bg": "Бели Осъм"
  },
  {
    "name_bg": "Бели пласт"
  },
  {
    "name_bg": "Белица"
  },
  {
    "name_bg": "Белиш"
  },
  {
    "name_bg": "Бел камен"
  },
  {
    "name_bg": "Беловец"
  },
  {
    "name_bg": "Беловица"
  },
  {
    "name_bg": "Белово"
  },
  {
    "name_bg": "Белоградец"
  },
  {
    "name_bg": "Белоградчик"
  },
  {
    "name_bg": "Белозем"
  },
  {
    "name_bg": "Белокопитово"
  },
  {
    "name_bg": "Беломорци"
  },
  {
    "name_bg": "Беломъжите"
  },
  {
    "name_bg": "Бело поле"
  },
  {
    "name_bg": "Белополци"
  },
  {
    "name_bg": "Белополяне"
  },
  {
    "name_bg": "Белопопци"
  },
  {
    "name_bg": "Белослав"
  },
  {
    "name_bg": "Белотинци"
  },
  {
    "name_bg": "Долно Белотинци"
  },
  {
    "name_bg": "Белцов"
  },
  {
    "name_bg": "Белчевци"
  },
  {
    "name_bg": "Белчин"
  },
  {
    "name_bg": "Белчински бани"
  },
  {
    "name_bg": "Бельово"
  },
  {
    "name_bg": "Беляковец"
  },
  {
    "name_bg": "Беляново"
  },
  {
    "name_bg": "Бенковски"
  },
  {
    "name_bg": "Берайнци"
  },
  {
    "name_bg": "Беренде"
  },
  {
    "name_bg": "Беренде извор"
  },
  {
    "name_bg": "Бериево"
  },
  {
    "name_bg": "Берковица"
  },
  {
    "name_bg": "Берковски"
  },
  {
    "name_bg": "Берковци"
  },
  {
    "name_bg": "Бероново"
  },
  {
    "name_bg": "Берсин"
  },
  {
    "name_bg": "Беслен"
  },
  {
    "name_bg": "Беснурка"
  },
  {
    "name_bg": "Биволаре"
  },
  {
    "name_bg": "Биволяне"
  },
  {
    "name_bg": "Бижовци"
  },
  {
    "name_bg": "Биково"
  },
  {
    "name_bg": "Билинци"
  },
  {
    "name_bg": "Билка"
  },
  {
    "name_bg": "Билкини"
  },
  {
    "name_bg": "Било"
  },
  {
    "name_bg": "Билянска"
  },
  {
    "name_bg": "Бинкос"
  },
  {
    "name_bg": "Бисер"
  },
  {
    "name_bg": "Бисерци"
  },
  {
    "name_bg": "Бистра"
  },
  {
    "name_bg": "Бистренци"
  },
  {
    "name_bg": "Бистрец"
  },
  {
    "name_bg": "Бистрилица"
  },
  {
    "name_bg": "Бистрица"
  },
  {
    "name_bg": "Бистроглед"
  },
  {
    "name_bg": "Благово"
  },
  {
    "name_bg": "Благоевград"
  },
  {
    "name_bg": "Благоево"
  },
  {
    "name_bg": "Благун"
  },
  {
    "name_bg": "Блажиево"
  },
  {
    "name_bg": "Блатец"
  },
  {
    "name_bg": "Блатешница"
  },
  {
    "name_bg": "Блатино"
  },
  {
    "name_bg": "Блатница"
  },
  {
    "name_bg": "Блато"
  },
  {
    "name_bg": "Блатска"
  },
  {
    "name_bg": "Бленика"
  },
  {
    "name_bg": "Близнак"
  },
  {
    "name_bg": "Близнаци"
  },
  {
    "name_bg": "Близнец"
  },
  {
    "name_bg": "Блъсково"
  },
  {
    "name_bg": "Блъсковци"
  },
  {
    "name_bg": "Боазът"
  },
  {
    "name_bg": "Бобевци"
  },
  {
    "name_bg": "Бобешино"
  },
  {
    "name_bg": "Бобов дол"
  },
  {
    "name_bg": "Бобовец"
  },
  {
    "name_bg": "Бобораци"
  },
  {
    "name_bg": "Бобошево"
  },
  {
    "name_bg": "Бов"
  },
  {
    "name_bg": "Богатово"
  },
  {
    "name_bg": "Богдан"
  },
  {
    "name_bg": "Богданица"
  },
  {
    "name_bg": "Богданлия"
  },
  {
    "name_bg": "Богданов дол"
  },
  {
    "name_bg": "Богданово"
  },
  {
    "name_bg": "Богдановци"
  },
  {
    "name_bg": "Богданско"
  },
  {
    "name_bg": "Богданци"
  },
  {
    "name_bg": "Богданчовци"
  },
  {
    "name_bg": "Богойна"
  },
  {
    "name_bg": "Боголин"
  },
  {
    "name_bg": "Богомил"
  },
  {
    "name_bg": "Богомилово"
  },
  {
    "name_bg": "Богомилци"
  },
  {
    "name_bg": "Богомолско"
  },
  {
    "name_bg": "Богорово"
  },
  {
    "name_bg": "Богослов"
  },
  {
    "name_bg": "Богутево"
  },
  {
    "name_bg": "Богьовци"
  },
  {
    "name_bg": "Боденец"
  },
  {
    "name_bg": "Стамболово"
  },
  {
    "name_bg": "Бодрово"
  },
  {
    "name_bg": "Боево"
  },
  {
    "name_bg": "Боерица"
  },
  {
    "name_bg": "Божак"
  },
  {
    "name_bg": "Божан"
  },
  {
    "name_bg": "Божаново"
  },
  {
    "name_bg": "Бождово"
  },
  {
    "name_bg": "Божевци"
  },
  {
    "name_bg": "Боженица"
  },
  {
    "name_bg": "Боженците"
  },
  {
    "name_bg": "Божичен"
  },
  {
    "name_bg": "Божурец"
  },
  {
    "name_bg": "Божурица"
  },
  {
    "name_bg": "Божурище"
  },
  {
    "name_bg": "Божурка"
  },
  {
    "name_bg": "Божурлук"
  },
  {
    "name_bg": "Божурово"
  },
  {
    "name_bg": "Божурци"
  },
  {
    "name_bg": "Бозаджии"
  },
  {
    "name_bg": "Бозвелийско"
  },
  {
    "name_bg": "Боил"
  },
  {
    "name_bg": "Лом Черковна"
  },
  {
    "name_bg": "Бойковец"
  },
  {
    "name_bg": "Бойково"
  },
  {
    "name_bg": "Бойковци"
  },
  {
    "name_bg": "Бойник"
  },
  {
    "name_bg": "Бойница"
  },
  {
    "name_bg": "Бойно"
  },
  {
    "name_bg": "Бойновци"
  },
  {
    "name_bg": "Бойчеви колиби"
  },
  {
    "name_bg": "Бойчиновци"
  },
  {
    "name_bg": "Бойчовци"
  },
  {
    "name_bg": "Бокиловци"
  },
  {
    "name_bg": "Болтата"
  },
  {
    "name_bg": "Болярино"
  },
  {
    "name_bg": "Болярово"
  },
  {
    "name_bg": "Болярски извор"
  },
  {
    "name_bg": "Болярско"
  },
  {
    "name_bg": "Болярци"
  },
  {
    "name_bg": "Бонево"
  },
  {
    "name_bg": "Борец"
  },
  {
    "name_bg": "Борие"
  },
  {
    "name_bg": "Борика"
  },
  {
    "name_bg": "Борики"
  },
  {
    "name_bg": "Бориково"
  },
  {
    "name_bg": "Бориловец"
  },
  {
    "name_bg": "Борилово"
  },
  {
    "name_bg": "Борима"
  },
  {
    "name_bg": "Боримечково"
  },
  {
    "name_bg": "Борино"
  },
  {
    "name_bg": "Бориново"
  },
  {
    "name_bg": "Боринци"
  },
  {
    "name_bg": "Борислав"
  },
  {
    "name_bg": "Бориславци"
  },
  {
    "name_bg": "Борисово"
  },
  {
    "name_bg": "Борнарево"
  },
  {
    "name_bg": "Борован"
  },
  {
    "name_bg": "Боров дол"
  },
  {
    "name_bg": "Боровец"
  },
  {
    "name_bg": "Боровина"
  },
  {
    "name_bg": "Боровица"
  },
  {
    "name_bg": "Боровичене"
  },
  {
    "name_bg": "Борово"
  },
  {
    "name_bg": "Боровско"
  },
  {
    "name_bg": "Боровци"
  },
  {
    "name_bg": "Борското"
  },
  {
    "name_bg": "Борущица"
  },
  {
    "name_bg": "Борци"
  },
  {
    "name_bg": "Боряна"
  },
  {
    "name_bg": "Босевци"
  },
  {
    "name_bg": "Босилица"
  },
  {
    "name_bg": "Босилково"
  },
  {
    "name_bg": "Босилковци"
  },
  {
    "name_bg": "Босна"
  },
  {
    "name_bg": "Боснек"
  },
  {
    "name_bg": "Бостанци"
  },
  {
    "name_bg": "Бостина"
  },
  {
    "name_bg": "Ботевград"
  },
  {
    "name_bg": "Ботево"
  },
  {
    "name_bg": "Ботров"
  },
  {
    "name_bg": "Ботуня"
  },
  {
    "name_bg": "Ботурче"
  },
  {
    "name_bg": "Бохова"
  },
  {
    "name_bg": "Бохот"
  },
  {
    "name_bg": "Бочковци"
  },
  {
    "name_bg": "Бошуля"
  },
  {
    "name_bg": "Бояджик"
  },
  {
    "name_bg": "Боян"
  },
  {
    "name_bg": "Бояна"
  },
  {
    "name_bg": "Боян Ботево"
  },
  {
    "name_bg": "Бояново"
  },
  {
    "name_bg": "Бояновци"
  },
  {
    "name_bg": "Боянци"
  },
  {
    "name_bg": "Брадвари"
  },
  {
    "name_bg": "Бракница"
  },
  {
    "name_bg": "Бракьовци"
  },
  {
    "name_bg": "Браниполе"
  },
  {
    "name_bg": "Браница"
  },
  {
    "name_bg": "Браничево"
  },
  {
    "name_bg": "Бранище"
  },
  {
    "name_bg": "Бранковци"
  },
  {
    "name_bg": "Братан"
  },
  {
    "name_bg": "Братаница"
  },
  {
    "name_bg": "Братово"
  },
  {
    "name_bg": "Братушково"
  },
  {
    "name_bg": "Братя Даскалови"
  },
  {
    "name_bg": "Братя Кунчеви"
  },
  {
    "name_bg": "Брацигово"
  },
  {
    "name_bg": "Брегаре"
  },
  {
    "name_bg": "Брегово"
  },
  {
    "name_bg": "Брежани"
  },
  {
    "name_bg": "Брежниците"
  },
  {
    "name_bg": "Бреза"
  },
  {
    "name_bg": "Брезе"
  },
  {
    "name_bg": "Брезник"
  },
  {
    "name_bg": "Брезница"
  },
  {
    "name_bg": "Брезнишки извор"
  },
  {
    "name_bg": "Брезовдол"
  },
  {
    "name_bg": "Брезово"
  },
  {
    "name_bg": "Бреница"
  },
  {
    "name_bg": "Брест"
  },
  {
    "name_bg": "Брестак"
  },
  {
    "name_bg": "Брестака"
  },
  {
    "name_bg": "Бресте"
  },
  {
    "name_bg": "Брестник"
  },
  {
    "name_bg": "Брестница"
  },
  {
    "name_bg": "Брестова"
  },
  {
    "name_bg": "Брестовене"
  },
  {
    "name_bg": "Брестовец"
  },
  {
    "name_bg": "Брестовица"
  },
  {
    "name_bg": "Брестово"
  },
  {
    "name_bg": "Брод"
  },
  {
    "name_bg": "Бродилово"
  },
  {
    "name_bg": "Брош"
  },
  {
    "name_bg": "Брусарци"
  },
  {
    "name_bg": "Брусевци"
  },
  {
    "name_bg": "Брусен"
  },
  {
    "name_bg": "Брусино"
  },
  {
    "name_bg": "Брусник"
  },
  {
    "name_bg": "Брънеците"
  },
  {
    "name_bg": "Бръчковци"
  },
  {
    "name_bg": "Бръшлен"
  },
  {
    "name_bg": "Бръшлян"
  },
  {
    "name_bg": "Бръшляница"
  },
  {
    "name_bg": "Бръщен"
  },
  {
    "name_bg": "Бряговец"
  },
  {
    "name_bg": "Бряговица"
  },
  {
    "name_bg": "Брягово"
  },
  {
    "name_bg": "Бряст"
  },
  {
    "name_bg": "Брястовец"
  },
  {
    "name_bg": "Брястово"
  },
  {
    "name_bg": "Бубино"
  },
  {
    "name_bg": "Будилци"
  },
  {
    "name_bg": "Бузовград"
  },
  {
    "name_bg": "Бузяковци"
  },
  {
    "name_bg": "Буйновица"
  },
  {
    "name_bg": "Буйново"
  },
  {
    "name_bg": "Буйновци"
  },
  {
    "name_bg": "Бук"
  },
  {
    "name_bg": "Букак"
  },
  {
    "name_bg": "Буката"
  },
  {
    "name_bg": "Букова поляна"
  },
  {
    "name_bg": "Буковец"
  },
  {
    "name_bg": "Буковлък"
  },
  {
    "name_bg": "Буково"
  },
  {
    "name_bg": "Букоровци"
  },
  {
    "name_bg": "Булаир"
  },
  {
    "name_bg": "Буново"
  },
  {
    "name_bg": "Бураново"
  },
  {
    "name_bg": "Бургас"
  },
  {
    "name_bg": "Буря"
  },
  {
    "name_bg": "Бусинци"
  },
  {
    "name_bg": "Бусманци"
  },
  {
    "name_bg": "Бутан"
  },
  {
    "name_bg": "Бутово"
  },
  {
    "name_bg": "Бутроинци"
  },
  {
    "name_bg": "Бухово"
  },
  {
    "name_bg": "Буховци"
  },
  {
    "name_bg": "Бучино"
  },
  {
    "name_bg": "Бучин проход"
  },
  {
    "name_bg": "Бучуковци"
  },
  {
    "name_bg": "Бъдеще"
  },
  {
    "name_bg": "Бъзовец"
  },
  {
    "name_bg": "Бъзовица"
  },
  {
    "name_bg": "Бъзън"
  },
  {
    "name_bg": "Българаново"
  },
  {
    "name_bg": "Българево"
  },
  {
    "name_bg": "Българене"
  },
  {
    "name_bg": "Българи"
  },
  {
    "name_bg": "Българин"
  },
  {
    "name_bg": "Българка"
  },
  {
    "name_bg": "Българово"
  },
  {
    "name_bg": "Българска поляна"
  },
  {
    "name_bg": "Български извор"
  },
  {
    "name_bg": "Българско Сливово"
  },
  {
    "name_bg": "Българчево"
  },
  {
    "name_bg": "Бънзарето"
  },
  {
    "name_bg": "Бърдарево"
  },
  {
    "name_bg": "Бърдарите"
  },
  {
    "name_bg": "Бърдарски геран"
  },
  {
    "name_bg": "Бърдени"
  },
  {
    "name_bg": "Бърдо"
  },
  {
    "name_bg": "Бърдоква"
  },
  {
    "name_bg": "Бърза река"
  },
  {
    "name_bg": "Бързея"
  },
  {
    "name_bg": "Бързина"
  },
  {
    "name_bg": "Бързица"
  },
  {
    "name_bg": "Бързия"
  },
  {
    "name_bg": "Бъркач"
  },
  {
    "name_bg": "Бъркачево"
  },
  {
    "name_bg": "Бърложница"
  },
  {
    "name_bg": "Бърля"
  },
  {
    "name_bg": "Бърчево"
  },
  {
    "name_bg": "Бъта"
  },
  {
    "name_bg": "Бяга"
  },
  {
    "name_bg": "Бяла"
  },
  {
    "name_bg": "Бяла вода"
  },
  {
    "name_bg": "Бяла паланка"
  },
  {
    "name_bg": "Бяла поляна"
  },
  {
    "name_bg": "Бяла река"
  },
  {
    "name_bg": "Бяла Слатина"
  },
  {
    "name_bg": "Бяла черква"
  },
  {
    "name_bg": "Бял бряг"
  },
  {
    "name_bg": "Бялградец"
  },
  {
    "name_bg": "Бял извор"
  },
  {
    "name_bg": "Бялка"
  },
  {
    "name_bg": "Бял кладенец"
  },
  {
    "name_bg": "Бялково"
  },
  {
    "name_bg": "Бялковци"
  },
  {
    "name_bg": "Бяло поле"
  },
  {
    "name_bg": "Бунцево"
  },
  {
    "name_bg": "Биркова"
  },
  {
    "name_bg": "Бозьова"
  },
  {
    "name_bg": "Бутрева"
  },
  {
    "name_bg": "Бор"
  },
  {
    "name_bg": "Бабинци"
  },
  {
    "name_bg": "Бойчета"
  },
  {
    "name_bg": "Бурево"
  },
  {
    "name_bg": "Богатино"
  },
  {
    "name_bg": "Брежана"
  },
  {
    "name_bg": "Вазово"
  },
  {
    "name_bg": "Вакарел"
  },
  {
    "name_bg": "Ваклино"
  },
  {
    "name_bg": "Ваклиново"
  },
  {
    "name_bg": "Ваксево"
  },
  {
    "name_bg": "Валето"
  },
  {
    "name_bg": "Варана"
  },
  {
    "name_bg": "Варвара"
  },
  {
    "name_bg": "Вардим"
  },
  {
    "name_bg": "Вардун"
  },
  {
    "name_bg": "Варна"
  },
  {
    "name_bg": "Варненци"
  },
  {
    "name_bg": "Варник"
  },
  {
    "name_bg": "Варовник"
  },
  {
    "name_bg": "Васил Друмев"
  },
  {
    "name_bg": "Василево"
  },
  {
    "name_bg": "Василковска махала"
  },
  {
    "name_bg": "Васил Левски"
  },
  {
    "name_bg": "Василовци"
  },
  {
    "name_bg": "Васильово"
  },
  {
    "name_bg": "Васково"
  },
  {
    "name_bg": "Ведраре"
  },
  {
    "name_bg": "Ведрина"
  },
  {
    "name_bg": "Ведрово"
  },
  {
    "name_bg": "Везенково"
  },
  {
    "name_bg": "Векилски"
  },
  {
    "name_bg": "Веленци"
  },
  {
    "name_bg": "Велешани"
  },
  {
    "name_bg": "Велика"
  },
  {
    "name_bg": "Великан"
  },
  {
    "name_bg": "Великденче"
  },
  {
    "name_bg": "Великово"
  },
  {
    "name_bg": "Великовци"
  },
  {
    "name_bg": "Велико Търново"
  },
  {
    "name_bg": "Велинград"
  },
  {
    "name_bg": "Велино"
  },
  {
    "name_bg": "Велиново"
  },
  {
    "name_bg": "Величка"
  },
  {
    "name_bg": "Величково"
  },
  {
    "name_bg": "Велково"
  },
  {
    "name_bg": "Велковци"
  },
  {
    "name_bg": "Велчево"
  },
  {
    "name_bg": "Велчовци"
  },
  {
    "name_bg": "Вельово"
  },
  {
    "name_bg": "Велювци"
  },
  {
    "name_bg": "Велющец"
  },
  {
    "name_bg": "Венелин"
  },
  {
    "name_bg": "Венец"
  },
  {
    "name_bg": "Венковец"
  },
  {
    "name_bg": "Венчан"
  },
  {
    "name_bg": "Верен"
  },
  {
    "name_bg": "Веренци"
  },
  {
    "name_bg": "Веринско"
  },
  {
    "name_bg": "Верско"
  },
  {
    "name_bg": "Веселец"
  },
  {
    "name_bg": "Веселие"
  },
  {
    "name_bg": "Веселина"
  },
  {
    "name_bg": "Веселиново"
  },
  {
    "name_bg": "Веслец"
  },
  {
    "name_bg": "Ветово"
  },
  {
    "name_bg": "Ветрен"
  },
  {
    "name_bg": "Ветрен дол"
  },
  {
    "name_bg": "Ветрино"
  },
  {
    "name_bg": "Ветринци"
  },
  {
    "name_bg": "Ветрище"
  },
  {
    "name_bg": "Ветрово"
  },
  {
    "name_bg": "Ветрушка"
  },
  {
    "name_bg": "Вехтино"
  },
  {
    "name_bg": "Вехтово"
  },
  {
    "name_bg": "Вещица"
  },
  {
    "name_bg": "Виден"
  },
  {
    "name_bg": "Видин"
  },
  {
    "name_bg": "Видинци"
  },
  {
    "name_bg": "Видлица"
  },
  {
    "name_bg": "Видно"
  },
  {
    "name_bg": "Видрар"
  },
  {
    "name_bg": "Видраре"
  },
  {
    "name_bg": "Видрица"
  },
  {
    "name_bg": "Виево"
  },
  {
    "name_bg": "Визица"
  },
  {
    "name_bg": "Виларе"
  },
  {
    "name_bg": "Винарово"
  },
  {
    "name_bg": "Винарско"
  },
  {
    "name_bg": "Винево"
  },
  {
    "name_bg": "Виница"
  },
  {
    "name_bg": "Винище"
  },
  {
    "name_bg": "Виноград"
  },
  {
    "name_bg": "Виноградец"
  },
  {
    "name_bg": "Виногради"
  },
  {
    "name_bg": "Вирове"
  },
  {
    "name_bg": "Вировско"
  },
  {
    "name_bg": "Вис"
  },
  {
    "name_bg": "Вискяр"
  },
  {
    "name_bg": "Висок"
  },
  {
    "name_bg": "Висока"
  },
  {
    "name_bg": "Висока могила"
  },
  {
    "name_bg": "Висока поляна"
  },
  {
    "name_bg": "Високите"
  },
  {
    "name_bg": "Високовци"
  },
  {
    "name_bg": "Витановци"
  },
  {
    "name_bg": "Витина"
  },
  {
    "name_bg": "Вихрен"
  },
  {
    "name_bg": "Вичово"
  },
  {
    "name_bg": "Вишан"
  },
  {
    "name_bg": "Вишеград"
  },
  {
    "name_bg": "Вишлене"
  },
  {
    "name_bg": "Вишна"
  },
  {
    "name_bg": "Вишнево"
  },
  {
    "name_bg": "Вишовград"
  },
  {
    "name_bg": "Владая"
  },
  {
    "name_bg": "Владимир"
  },
  {
    "name_bg": "Владимирово"
  },
  {
    "name_bg": "Владимировци"
  },
  {
    "name_bg": "Владиня"
  },
  {
    "name_bg": "Владислав"
  },
  {
    "name_bg": "Владиславци"
  },
  {
    "name_bg": "Владиченци"
  },
  {
    "name_bg": "Владовци"
  },
  {
    "name_bg": "Владо Тричков"
  },
  {
    "name_bg": "Влайчовци"
  },
  {
    "name_bg": "Свети Влас"
  },
  {
    "name_bg": "Власатили"
  },
  {
    "name_bg": "Власатица"
  },
  {
    "name_bg": "Влахи"
  },
  {
    "name_bg": "Влахово"
  },
  {
    "name_bg": "Влаховци"
  },
  {
    "name_bg": "Водата"
  },
  {
    "name_bg": "Водач"
  },
  {
    "name_bg": "Воден"
  },
  {
    "name_bg": "Воденичане"
  },
  {
    "name_bg": "Воденичарово"
  },
  {
    "name_bg": "Воденичарско"
  },
  {
    "name_bg": "Воденци"
  },
  {
    "name_bg": "Водица"
  },
  {
    "name_bg": "Водна"
  },
  {
    "name_bg": "Водни пад"
  },
  {
    "name_bg": "Водно"
  },
  {
    "name_bg": "Воднянци"
  },
  {
    "name_bg": "Водолей"
  },
  {
    "name_bg": "Вождово"
  },
  {
    "name_bg": "Войвода"
  },
  {
    "name_bg": "Войводенец"
  },
  {
    "name_bg": "Войводино"
  },
  {
    "name_bg": "Войводиново"
  },
  {
    "name_bg": "Войводово"
  },
  {
    "name_bg": "Войкова лъка"
  },
  {
    "name_bg": "Войнеговци"
  },
  {
    "name_bg": "Войнежа"
  },
  {
    "name_bg": "Войника"
  },
  {
    "name_bg": "Войниково"
  },
  {
    "name_bg": "Войница"
  },
  {
    "name_bg": "Войници"
  },
  {
    "name_bg": "Войниците"
  },
  {
    "name_bg": "Войнишка"
  },
  {
    "name_bg": "Войново"
  },
  {
    "name_bg": "Войнягово"
  },
  {
    "name_bg": "Войсил"
  },
  {
    "name_bg": "Вокил"
  },
  {
    "name_bg": "Волно"
  },
  {
    "name_bg": "Воловарово"
  },
  {
    "name_bg": "Воловарци"
  },
  {
    "name_bg": "Волово"
  },
  {
    "name_bg": "Волуяк"
  },
  {
    "name_bg": "Вонеща вода"
  },
  {
    "name_bg": "Врабево"
  },
  {
    "name_bg": "Врабците"
  },
  {
    "name_bg": "Врабча"
  },
  {
    "name_bg": "Вракуповица"
  },
  {
    "name_bg": "Врани кон"
  },
  {
    "name_bg": "Враниловци"
  },
  {
    "name_bg": "Вранино"
  },
  {
    "name_bg": "Вранинци"
  },
  {
    "name_bg": "Вранско"
  },
  {
    "name_bg": "Враня"
  },
  {
    "name_bg": "Враняк"
  },
  {
    "name_bg": "Враня стена"
  },
  {
    "name_bg": "Вратарите"
  },
  {
    "name_bg": "Вратица"
  },
  {
    "name_bg": "Враца"
  },
  {
    "name_bg": "Врачанци"
  },
  {
    "name_bg": "Врачеш"
  },
  {
    "name_bg": "Врело"
  },
  {
    "name_bg": "Вресово"
  },
  {
    "name_bg": "Връв"
  },
  {
    "name_bg": "Връдловци"
  },
  {
    "name_bg": "Всемирци"
  },
  {
    "name_bg": "Вукан"
  },
  {
    "name_bg": "Вуково"
  },
  {
    "name_bg": "Въбел"
  },
  {
    "name_bg": "Въгларово"
  },
  {
    "name_bg": "Въглевци"
  },
  {
    "name_bg": "Въглен"
  },
  {
    "name_bg": "Възел"
  },
  {
    "name_bg": "Вълкан"
  },
  {
    "name_bg": "Вълков дол"
  },
  {
    "name_bg": "Вълкович"
  },
  {
    "name_bg": "Вълково"
  },
  {
    "name_bg": "Вълковци"
  },
  {
    "name_bg": "Вълкосел"
  },
  {
    "name_bg": "Вълнари"
  },
  {
    "name_bg": "Вълчан"
  },
  {
    "name_bg": "Вълчан дол"
  },
  {
    "name_bg": "Вълча поляна"
  },
  {
    "name_bg": "Вълчедръм"
  },
  {
    "name_bg": "Вълчек"
  },
  {
    "name_bg": "Вълче поле"
  },
  {
    "name_bg": "Вълчи дол"
  },
  {
    "name_bg": "Вълчи извор"
  },
  {
    "name_bg": "Вълчин"
  },
  {
    "name_bg": "Вълчитрън"
  },
  {
    "name_bg": "Вълчовци"
  },
  {
    "name_bg": "Върба"
  },
  {
    "name_bg": "Върбак"
  },
  {
    "name_bg": "Царева ливада"
  },
  {
    "name_bg": "Върбенци"
  },
  {
    "name_bg": "Върбешница"
  },
  {
    "name_bg": "Върбина"
  },
  {
    "name_bg": "Върбино"
  },
  {
    "name_bg": "Върбица"
  },
  {
    "name_bg": "Върбница"
  },
  {
    "name_bg": "Върбовка"
  },
  {
    "name_bg": "Върбово"
  },
  {
    "name_bg": "Върбовчец"
  },
  {
    "name_bg": "Върбяне"
  },
  {
    "name_bg": "Въргов дол"
  },
  {
    "name_bg": "Вързилковци"
  },
  {
    "name_bg": "Вързулица"
  },
  {
    "name_bg": "Върли дол"
  },
  {
    "name_bg": "Върлинка"
  },
  {
    "name_bg": "Върлино"
  },
  {
    "name_bg": "Въртоп"
  },
  {
    "name_bg": "Вършец"
  },
  {
    "name_bg": "Вършило"
  },
  {
    "name_bg": "Враненци"
  },
  {
    "name_bg": "Врата"
  },
  {
    "name_bg": "Вълчанка"
  },
  {
    "name_bg": "Валевци"
  },
  {
    "name_bg": "Габаре"
  },
  {
    "name_bg": "Габарево"
  },
  {
    "name_bg": "Габер"
  },
  {
    "name_bg": "Габерово"
  },
  {
    "name_bg": "Габра"
  },
  {
    "name_bg": "Габрака"
  },
  {
    "name_bg": "Габрене"
  },
  {
    "name_bg": "Габрешевци"
  },
  {
    "name_bg": "Габрина"
  },
  {
    "name_bg": "Габрица"
  },
  {
    "name_bg": "Габровдол"
  },
  {
    "name_bg": "Габровица"
  },
  {
    "name_bg": "Габровница"
  },
  {
    "name_bg": "Габрово"
  },
  {
    "name_bg": "Габровци"
  },
  {
    "name_bg": "Габър"
  },
  {
    "name_bg": "Габърница"
  },
  {
    "name_bg": "Гавраилово"
  },
  {
    "name_bg": "Гаврил Геново"
  },
  {
    "name_bg": "Гаганица"
  },
  {
    "name_bg": "Гагово"
  },
  {
    "name_bg": "Гайдари"
  },
  {
    "name_bg": "Гайкини"
  },
  {
    "name_bg": "Гайтанево"
  },
  {
    "name_bg": "Гайтаниново"
  },
  {
    "name_bg": "Гайтаните"
  },
  {
    "name_bg": "Гайтанци"
  },
  {
    "name_bg": "Галата"
  },
  {
    "name_bg": "Галатин"
  },
  {
    "name_bg": "Галиче"
  },
  {
    "name_bg": "Галище"
  },
  {
    "name_bg": "Галово"
  },
  {
    "name_bg": "Ганев дол"
  },
  {
    "name_bg": "Ганчовец"
  },
  {
    "name_bg": "Лакатник"
  },
  {
    "name_bg": "Орешец"
  },
  {
    "name_bg": "Кресна"
  },
  {
    "name_bg": "Хитрино"
  },
  {
    "name_bg": "Златарица"
  },
  {
    "name_bg": "Гарван"
  },
  {
    "name_bg": "Гарваново"
  },
  {
    "name_bg": "Гащевци"
  },
  {
    "name_bg": "Гега"
  },
  {
    "name_bg": "Гела"
  },
  {
    "name_bg": "Гелеменово"
  },
  {
    "name_bg": "Генерал Гешево"
  },
  {
    "name_bg": "Генерал Кантарджиево"
  },
  {
    "name_bg": "Генерал Киселово"
  },
  {
    "name_bg": "Генерал Колево"
  },
  {
    "name_bg": "Генерал Мариново"
  },
  {
    "name_bg": "Генералово"
  },
  {
    "name_bg": "Генерал Тошево"
  },
  {
    "name_bg": "Геновци"
  },
  {
    "name_bg": "Генчовци"
  },
  {
    "name_bg": "Геня"
  },
  {
    "name_bg": "Георги Дамяново"
  },
  {
    "name_bg": "Георги Добрево"
  },
  {
    "name_bg": "Гергевец"
  },
  {
    "name_bg": "Гергини"
  },
  {
    "name_bg": "Герман"
  },
  {
    "name_bg": "Геша"
  },
  {
    "name_bg": "Гешаново"
  },
  {
    "name_bg": "Гиген"
  },
  {
    "name_bg": "Искър"
  },
  {
    "name_bg": "Гигинци"
  },
  {
    "name_bg": "Гинци"
  },
  {
    "name_bg": "Гирчевци"
  },
  {
    "name_bg": "Гита"
  },
  {
    "name_bg": "Глава"
  },
  {
    "name_bg": "Главан"
  },
  {
    "name_bg": "Главановци"
  },
  {
    "name_bg": "Главанци"
  },
  {
    "name_bg": "Главатар"
  },
  {
    "name_bg": "Главатарци"
  },
  {
    "name_bg": "Главаци"
  },
  {
    "name_bg": "Главиница"
  },
  {
    "name_bg": "Глашатай"
  },
  {
    "name_bg": "Гледаци"
  },
  {
    "name_bg": "Гледачево"
  },
  {
    "name_bg": "Гледка"
  },
  {
    "name_bg": "Глогинка"
  },
  {
    "name_bg": "Глогино"
  },
  {
    "name_bg": "Глоговец"
  },
  {
    "name_bg": "Глоговица"
  },
  {
    "name_bg": "Глогово"
  },
  {
    "name_bg": "Глоджево"
  },
  {
    "name_bg": "Гложене"
  },
  {
    "name_bg": "Глумово"
  },
  {
    "name_bg": "Глумче"
  },
  {
    "name_bg": "Глутниците"
  },
  {
    "name_bg": "Глуфишево"
  },
  {
    "name_bg": "Глухар"
  },
  {
    "name_bg": "Глушка"
  },
  {
    "name_bg": "Глушник"
  },
  {
    "name_bg": "Гнездаре"
  },
  {
    "name_bg": "Гняздово"
  },
  {
    "name_bg": "Говедаре"
  },
  {
    "name_bg": "Говедарци"
  },
  {
    "name_bg": "Говежда"
  },
  {
    "name_bg": "Годеч"
  },
  {
    "name_bg": "Годешево"
  },
  {
    "name_bg": "Годлево"
  },
  {
    "name_bg": "Гоз"
  },
  {
    "name_bg": "Гоздевица"
  },
  {
    "name_bg": "Гоздейка"
  },
  {
    "name_bg": "Голак"
  },
  {
    "name_bg": "Големани"
  },
  {
    "name_bg": "Големаните"
  },
  {
    "name_bg": "Големаново"
  },
  {
    "name_bg": "Големанци"
  },
  {
    "name_bg": "Голема Раковица"
  },
  {
    "name_bg": "Голема Фуча"
  },
  {
    "name_bg": "Голям Върбовник"
  },
  {
    "name_bg": "Големи Българени"
  },
  {
    "name_bg": "Големи Станчовци"
  },
  {
    "name_bg": "Големо Бабино"
  },
  {
    "name_bg": "Големо Бучино"
  },
  {
    "name_bg": "Големо Малово"
  },
  {
    "name_bg": "Голямо Пещене"
  },
  {
    "name_bg": "Големо село"
  },
  {
    "name_bg": "Голем Цалим"
  },
  {
    "name_bg": "Голец"
  },
  {
    "name_bg": "Голеш"
  },
  {
    "name_bg": "Голешово"
  },
  {
    "name_bg": "Голица"
  },
  {
    "name_bg": "Голобрад"
  },
  {
    "name_bg": "Голобрадово"
  },
  {
    "name_bg": "Голяма бара"
  },
  {
    "name_bg": "Голяма Брестница"
  },
  {
    "name_bg": "Голяма вода"
  },
  {
    "name_bg": "Голяма долина"
  },
  {
    "name_bg": "Голяма Желязна"
  },
  {
    "name_bg": "Голяма Чинка"
  },
  {
    "name_bg": "Голям Девесил"
  },
  {
    "name_bg": "Голям Дервент"
  },
  {
    "name_bg": "Голям дол"
  },
  {
    "name_bg": "Голям извор"
  },
  {
    "name_bg": "Голям манастир"
  },
  {
    "name_bg": "Голямо Асеново"
  },
  {
    "name_bg": "Голямо Белово"
  },
  {
    "name_bg": "Голямо Буково"
  },
  {
    "name_bg": "Голямо Враново"
  },
  {
    "name_bg": "Голямо градище"
  },
  {
    "name_bg": "Голямо Доляне"
  },
  {
    "name_bg": "Голямо Дряново"
  },
  {
    "name_bg": "Голямо Каменяне"
  },
  {
    "name_bg": "Голямо Крушево"
  },
  {
    "name_bg": "Голямо ново"
  },
  {
    "name_bg": "Голямо Соколово"
  },
  {
    "name_bg": "Голямо църквище"
  },
  {
    "name_bg": "Голямо Чочовени"
  },
  {
    "name_bg": "Шивачево"
  },
  {
    "name_bg": "Голям Поровец"
  },
  {
    "name_bg": "Подгорец"
  },
  {
    "name_bg": "Голям чардак"
  },
  {
    "name_bg": "Голяновци"
  },
  {
    "name_bg": "Гомотарци"
  },
  {
    "name_bg": "Горан"
  },
  {
    "name_bg": "Горановци"
  },
  {
    "name_bg": "Гореме"
  },
  {
    "name_bg": "Горен Еневец"
  },
  {
    "name_bg": "Горен чифлик"
  },
  {
    "name_bg": "Горица"
  },
  {
    "name_bg": "Горичане"
  },
  {
    "name_bg": "Горичево"
  },
  {
    "name_bg": "Горна Бела речка"
  },
  {
    "name_bg": "Горна Бешовица"
  },
  {
    "name_bg": "Горна Брезница"
  },
  {
    "name_bg": "Горна Брестница"
  },
  {
    "name_bg": "Горна Вереница"
  },
  {
    "name_bg": "Горна Врабча"
  },
  {
    "name_bg": "Горна Глоговица"
  },
  {
    "name_bg": "Септемврийци"
  },
  {
    "name_bg": "Горна Гращица"
  },
  {
    "name_bg": "Горна Диканя"
  },
  {
    "name_bg": "Горна Златица"
  },
  {
    "name_bg": "Горна Кабда"
  },
  {
    "name_bg": "Горна Ковачица"
  },
  {
    "name_bg": "Горна Козница"
  },
  {
    "name_bg": "Горна Кремена"
  },
  {
    "name_bg": "Горна крепост"
  },
  {
    "name_bg": "Горна Крушица"
  },
  {
    "name_bg": "Горна кула"
  },
  {
    "name_bg": "Горна Липница"
  },
  {
    "name_bg": "Горна Лука"
  },
  {
    "name_bg": "Горна Малина"
  },
  {
    "name_bg": "Горна махала"
  },
  {
    "name_bg": "Горна Мелна"
  },
  {
    "name_bg": "Горна Митрополия"
  },
  {
    "name_bg": "Горна Оряховица"
  },
  {
    "name_bg": "Горна Рибница"
  },
  {
    "name_bg": "Горна Росица"
  },
  {
    "name_bg": "Горна Секирна"
  },
  {
    "name_bg": "Горна Студена"
  },
  {
    "name_bg": "Горна Сушица"
  },
  {
    "name_bg": "Горна Хаджийска"
  },
  {
    "name_bg": "Горна Хубавка"
  },
  {
    "name_bg": "Горни Богров"
  },
  {
    "name_bg": "Горни Вадин"
  },
  {
    "name_bg": "Горни Върпища"
  },
  {
    "name_bg": "Горни Главанак"
  },
  {
    "name_bg": "Горни Дамяновци"
  },
  {
    "name_bg": "Горни Домлян"
  },
  {
    "name_bg": "Горни Драгойча"
  },
  {
    "name_bg": "Горни Дъбник"
  },
  {
    "name_bg": "Горник"
  },
  {
    "name_bg": "Горни Коритен"
  },
  {
    "name_bg": "Горни край"
  },
  {
    "name_bg": "Горни Лом"
  },
  {
    "name_bg": "Горни Маренци"
  },
  {
    "name_bg": "Горни Окол"
  },
  {
    "name_bg": "Горни Радковци"
  },
  {
    "name_bg": "Горни Романци"
  },
  {
    "name_bg": "Горни Танчевци"
  },
  {
    "name_bg": "Горни Цибър"
  },
  {
    "name_bg": "Горни Цоневци"
  },
  {
    "name_bg": "Горни Юруци"
  },
  {
    "name_bg": "Горно Абланово"
  },
  {
    "name_bg": "Горно Александрово"
  },
  {
    "name_bg": "Горно Белево"
  },
  {
    "name_bg": "Горно Ботево"
  },
  {
    "name_bg": "Горнова могила"
  },
  {
    "name_bg": "Горно Войводино"
  },
  {
    "name_bg": "Горно вършило"
  },
  {
    "name_bg": "Горно Драглище"
  },
  {
    "name_bg": "Горно Дряново"
  },
  {
    "name_bg": "Горно Изворово"
  },
  {
    "name_bg": "Горно Камарци"
  },
  {
    "name_bg": "Горно Кобиле"
  },
  {
    "name_bg": "Горно Козарево"
  },
  {
    "name_bg": "Горно Къпиново"
  },
  {
    "name_bg": "Горно Луково"
  },
  {
    "name_bg": "Горно Новково"
  },
  {
    "name_bg": "Горно ново село"
  },
  {
    "name_bg": "Горно Озирово"
  },
  {
    "name_bg": "Горно Осеново"
  },
  {
    "name_bg": "Горно Павликене"
  },
  {
    "name_bg": "Горно Пещене"
  },
  {
    "name_bg": "Горно поле"
  },
  {
    "name_bg": "Горно Прахово"
  },
  {
    "name_bg": "Горно Сахране"
  },
  {
    "name_bg": "Горно село"
  },
  {
    "name_bg": "Горноселци"
  },
  {
    "name_bg": "Горнослав"
  },
  {
    "name_bg": "Горно Спанчево"
  },
  {
    "name_bg": "Горно трапе"
  },
  {
    "name_bg": "Горно Уйно"
  },
  {
    "name_bg": "Горно Хърсово"
  },
  {
    "name_bg": "Горно Церовене"
  },
  {
    "name_bg": "Горно Ябълково"
  },
  {
    "name_bg": "Горово"
  },
  {
    "name_bg": "Гороцвет"
  },
  {
    "name_bg": "Горочевци"
  },
  {
    "name_bg": "Горска"
  },
  {
    "name_bg": "Горска поляна"
  },
  {
    "name_bg": "Горски горен Тръмбеш"
  },
  {
    "name_bg": "Горски долен Тръмбеш"
  },
  {
    "name_bg": "Горски извор"
  },
  {
    "name_bg": "Горски Сеновец"
  },
  {
    "name_bg": "Горско"
  },
  {
    "name_bg": "Горско Абланово"
  },
  {
    "name_bg": "Горско Дюлево"
  },
  {
    "name_bg": "Горско Калугерово"
  },
  {
    "name_bg": "Горско Косово"
  },
  {
    "name_bg": "Горско ново село"
  },
  {
    "name_bg": "Горско Писарево"
  },
  {
    "name_bg": "Горско село"
  },
  {
    "name_bg": "Горско Сливово"
  },
  {
    "name_bg": "Горталово"
  },
  {
    "name_bg": "Горун"
  },
  {
    "name_bg": "Горунака"
  },
  {
    "name_bg": "Горчево"
  },
  {
    "name_bg": "Горяни"
  },
  {
    "name_bg": "Господиново"
  },
  {
    "name_bg": "Господинци"
  },
  {
    "name_bg": "Гостилица"
  },
  {
    "name_bg": "Гостиля"
  },
  {
    "name_bg": "Гостиня"
  },
  {
    "name_bg": "Гостун"
  },
  {
    "name_bg": "Гоце Делчев"
  },
  {
    "name_bg": "Градево"
  },
  {
    "name_bg": "Градежница"
  },
  {
    "name_bg": "Градец"
  },
  {
    "name_bg": "Градешница"
  },
  {
    "name_bg": "Стефан Стамболово"
  },
  {
    "name_bg": "Градина"
  },
  {
    "name_bg": "Градинарово"
  },
  {
    "name_bg": "Градини"
  },
  {
    "name_bg": "Градинка"
  },
  {
    "name_bg": "Градище"
  },
  {
    "name_bg": "Градница"
  },
  {
    "name_bg": "Градско"
  },
  {
    "name_bg": "Градсковски колиби"
  },
  {
    "name_bg": "Градът"
  },
  {
    "name_bg": "Грамада"
  },
  {
    "name_bg": "Грамаде"
  },
  {
    "name_bg": "Грамаждано"
  },
  {
    "name_bg": "Граматиково"
  },
  {
    "name_bg": "Граматици"
  },
  {
    "name_bg": "Гранит"
  },
  {
    "name_bg": "Гранитец"
  },
  {
    "name_bg": "Гранитово"
  },
  {
    "name_bg": "Граница"
  },
  {
    "name_bg": "Граничак"
  },
  {
    "name_bg": "Граничар"
  },
  {
    "name_bg": "Режанци"
  },
  {
    "name_bg": "Граф Игнатиево"
  },
  {
    "name_bg": "Грашево"
  },
  {
    "name_bg": "Гращица"
  },
  {
    "name_bg": "Греевци"
  },
  {
    "name_bg": "Гривица"
  },
  {
    "name_bg": "Гривка"
  },
  {
    "name_bg": "Гривяк"
  },
  {
    "name_bg": "Григорево"
  },
  {
    "name_bg": "Грозден"
  },
  {
    "name_bg": "Гроздьово"
  },
  {
    "name_bg": "Громшин"
  },
  {
    "name_bg": "Грохотно"
  },
  {
    "name_bg": "Боздуганово"
  },
  {
    "name_bg": "Средец"
  },
  {
    "name_bg": "Груево"
  },
  {
    "name_bg": "Гръблевци"
  },
  {
    "name_bg": "Грълска падина"
  },
  {
    "name_bg": "Грънчарово"
  },
  {
    "name_bg": "Губеш"
  },
  {
    "name_bg": "Губислав"
  },
  {
    "name_bg": "Гугутка"
  },
  {
    "name_bg": "Гудевица"
  },
  {
    "name_bg": "Гулийка"
  },
  {
    "name_bg": "Гулия"
  },
  {
    "name_bg": "Гулянци"
  },
  {
    "name_bg": "Гумощник"
  },
  {
    "name_bg": "Гурбановци"
  },
  {
    "name_bg": "Гургулица"
  },
  {
    "name_bg": "Гургулят"
  },
  {
    "name_bg": "Гурково"
  },
  {
    "name_bg": "Гурмазово"
  },
  {
    "name_bg": "Гусла"
  },
  {
    "name_bg": "Гуслар"
  },
  {
    "name_bg": "Гуцал"
  },
  {
    "name_bg": "Гъбене"
  },
  {
    "name_bg": "Гълъбец"
  },
  {
    "name_bg": "Гълъбинци"
  },
  {
    "name_bg": "Гълъбник"
  },
  {
    "name_bg": "Гълъбовци"
  },
  {
    "name_bg": "Гъмзово"
  },
  {
    "name_bg": "Гърбино"
  },
  {
    "name_bg": "Гърбище"
  },
  {
    "name_bg": "Гърдевци"
  },
  {
    "name_bg": "Гърло"
  },
  {
    "name_bg": "Гърляно"
  },
  {
    "name_bg": "Гърмен"
  },
  {
    "name_bg": "Гърнати"
  },
  {
    "name_bg": "Гърня"
  },
  {
    "name_bg": "Гърчиново"
  },
  {
    "name_bg": "Гъсково"
  },
  {
    "name_bg": "Гьоврен"
  },
  {
    "name_bg": "Гюешево"
  },
  {
    "name_bg": "Гюльовца"
  },
  {
    "name_bg": "Гюргево"
  },
  {
    "name_bg": "Гюргич"
  },
  {
    "name_bg": "Елин Пелин"
  },
  {
    "name_bg": "Горна Биркова"
  },
  {
    "name_bg": "Горна Дъбева"
  },
  {
    "name_bg": "Главник"
  },
  {
    "name_bg": "Горно Кирково"
  },
  {
    "name_bg": "Горна Василица"
  },
  {
    "name_bg": "Гецово"
  },
  {
    "name_bg": "Давери"
  },
  {
    "name_bg": "Давидково"
  },
  {
    "name_bg": "Давидово"
  },
  {
    "name_bg": "Дагоново"
  },
  {
    "name_bg": "Даевци"
  },
  {
    "name_bg": "Дайновци"
  },
  {
    "name_bg": "Дамяница"
  },
  {
    "name_bg": "Дамяново"
  },
  {
    "name_bg": "Даскал-Атанасово"
  },
  {
    "name_bg": "Даскалово"
  },
  {
    "name_bg": "Две могили"
  },
  {
    "name_bg": "Две тополи"
  },
  {
    "name_bg": "Дворище"
  },
  {
    "name_bg": "Дебел дял"
  },
  {
    "name_bg": "Дебелец"
  },
  {
    "name_bg": "Дебели лаг"
  },
  {
    "name_bg": "Дебели рът"
  },
  {
    "name_bg": "Дебелт"
  },
  {
    "name_bg": "Дебелцово"
  },
  {
    "name_bg": "Дебеляново"
  },
  {
    "name_bg": "Дебнево"
  },
  {
    "name_bg": "Дебово"
  },
  {
    "name_bg": "Дебочица"
  },
  {
    "name_bg": "Дебрен"
  },
  {
    "name_bg": "Дебрене"
  },
  {
    "name_bg": "Дебръщица"
  },
  {
    "name_bg": "Девене"
  },
  {
    "name_bg": "Девенци"
  },
  {
    "name_bg": "Девесилица"
  },
  {
    "name_bg": "Девесилово"
  },
  {
    "name_bg": "Деветак"
  },
  {
    "name_bg": "Деветаки"
  },
  {
    "name_bg": "Деветаците"
  },
  {
    "name_bg": "Деветинци"
  },
  {
    "name_bg": "Девин"
  },
  {
    "name_bg": "Девино"
  },
  {
    "name_bg": "Девня"
  },
  {
    "name_bg": "Дедец"
  },
  {
    "name_bg": "Дедина"
  },
  {
    "name_bg": "Дединци"
  },
  {
    "name_bg": "Дедово"
  },
  {
    "name_bg": "Деков"
  },
  {
    "name_bg": "Делвино"
  },
  {
    "name_bg": "Делейна"
  },
  {
    "name_bg": "Делова махала"
  },
  {
    "name_bg": "Делчево"
  },
  {
    "name_bg": "Делян"
  },
  {
    "name_bg": "Деляновци"
  },
  {
    "name_bg": "Денница"
  },
  {
    "name_bg": "Денчевци"
  },
  {
    "name_bg": "Дервишка могила"
  },
  {
    "name_bg": "Дерманци"
  },
  {
    "name_bg": "Дечковци"
  },
  {
    "name_bg": "Джамузовци"
  },
  {
    "name_bg": "Джанка"
  },
  {
    "name_bg": "Джебел"
  },
  {
    "name_bg": "Джелепско"
  },
  {
    "name_bg": "Джерман"
  },
  {
    "name_bg": "Джерово"
  },
  {
    "name_bg": "Джигурово"
  },
  {
    "name_bg": "Джинот"
  },
  {
    "name_bg": "Джинчовци"
  },
  {
    "name_bg": "Джулюница"
  },
  {
    "name_bg": "Джумриите"
  },
  {
    "name_bg": "Джурково"
  },
  {
    "name_bg": "Джурово"
  },
  {
    "name_bg": "Джуровци"
  },
  {
    "name_bg": "Зорница"
  },
  {
    "name_bg": "Дибич"
  },
  {
    "name_bg": "Дива Слатина"
  },
  {
    "name_bg": "Дивеци"
  },
  {
    "name_bg": "Дивля"
  },
  {
    "name_bg": "Дивотино"
  },
  {
    "name_bg": "Дивчовото"
  },
  {
    "name_bg": "Диманово"
  },
  {
    "name_bg": "Димиевци"
  },
  {
    "name_bg": "Димитриево"
  },
  {
    "name_bg": "Димитровград"
  },
  {
    "name_bg": "Димитровци"
  },
  {
    "name_bg": "Димитровче"
  },
  {
    "name_bg": "Победа"
  },
  {
    "name_bg": "Димово"
  },
  {
    "name_bg": "Димовци"
  },
  {
    "name_bg": "Димча"
  },
  {
    "name_bg": "Димчево"
  },
  {
    "name_bg": "Динево"
  },
  {
    "name_bg": "Динк"
  },
  {
    "name_bg": "Динката"
  },
  {
    "name_bg": "Динковица"
  },
  {
    "name_bg": "Динково"
  },
  {
    "name_bg": "Диня"
  },
  {
    "name_bg": "Дирало"
  },
  {
    "name_bg": "Дисманица"
  },
  {
    "name_bg": "Дичево"
  },
  {
    "name_bg": "Дичин"
  },
  {
    "name_bg": "Длъгнево"
  },
  {
    "name_bg": "Длъгня"
  },
  {
    "name_bg": "Длъжка поляна"
  },
  {
    "name_bg": "Длъжко"
  },
  {
    "name_bg": "Длъхчево-Сабляр"
  },
  {
    "name_bg": "Доборско"
  },
  {
    "name_bg": "Добравица"
  },
  {
    "name_bg": "Добралък"
  },
  {
    "name_bg": "Добра поляна"
  },
  {
    "name_bg": "Добрева череша"
  },
  {
    "name_bg": "Добрево"
  },
  {
    "name_bg": "Добревци"
  },
  {
    "name_bg": "Добрените"
  },
  {
    "name_bg": "Добри Войниково"
  },
  {
    "name_bg": "Добри дол"
  },
  {
    "name_bg": "Добри дял"
  },
  {
    "name_bg": "Добри лаки"
  },
  {
    "name_bg": "Добрин"
  },
  {
    "name_bg": "Добрина"
  },
  {
    "name_bg": "Добринище"
  },
  {
    "name_bg": "Добриново"
  },
  {
    "name_bg": "Добринци"
  },
  {
    "name_bg": "Добрич"
  },
  {
    "name_bg": "Добровница"
  },
  {
    "name_bg": "Доброво"
  },
  {
    "name_bg": "Доброглед"
  },
  {
    "name_bg": "Добродан"
  },
  {
    "name_bg": "Добролево"
  },
  {
    "name_bg": "Добромир"
  },
  {
    "name_bg": "Добромирка"
  },
  {
    "name_bg": "Добромирци"
  },
  {
    "name_bg": "Доброплодно"
  },
  {
    "name_bg": "Доброселец"
  },
  {
    "name_bg": "Доброславци"
  },
  {
    "name_bg": "Добростан"
  },
  {
    "name_bg": "Добротино"
  },
  {
    "name_bg": "Добротица"
  },
  {
    "name_bg": "Добротич"
  },
  {
    "name_bg": "Добруджанка"
  },
  {
    "name_bg": "Добруша"
  },
  {
    "name_bg": "Добърско"
  },
  {
    "name_bg": "Добърчин"
  },
  {
    "name_bg": "Доганово"
  },
  {
    "name_bg": "Дождевица"
  },
  {
    "name_bg": "Дойновци"
  },
  {
    "name_bg": "Дойранци"
  },
  {
    "name_bg": "Дойренци"
  },
  {
    "name_bg": "Докатичево"
  },
  {
    "name_bg": "Доктор Йосифово"
  },
  {
    "name_bg": "Докьовци"
  },
  {
    "name_bg": "Долен"
  },
  {
    "name_bg": "Долене"
  },
  {
    "name_bg": "Долен Еневец"
  },
  {
    "name_bg": "Долени"
  },
  {
    "name_bg": "Долни чифлик"
  },
  {
    "name_bg": "Долец"
  },
  {
    "name_bg": "Долие"
  },
  {
    "name_bg": "Долина"
  },
  {
    "name_bg": "Долистово"
  },
  {
    "name_bg": "Долище"
  },
  {
    "name_bg": "Долна баня"
  },
  {
    "name_bg": "Долна Бела речка"
  },
  {
    "name_bg": "Долна Бешовица"
  },
  {
    "name_bg": "Долна Вереница"
  },
  {
    "name_bg": "Долна Врабча"
  },
  {
    "name_bg": "Долна Градешница"
  },
  {
    "name_bg": "Долна Гращица"
  },
  {
    "name_bg": "Долна Диканя"
  },
  {
    "name_bg": "Долна Златица"
  },
  {
    "name_bg": "Долна Кабда"
  },
  {
    "name_bg": "Долна Козница"
  },
  {
    "name_bg": "Долна Кремена"
  },
  {
    "name_bg": "Долна крепост"
  },
  {
    "name_bg": "Долна Крушица"
  },
  {
    "name_bg": "Долна кула"
  },
  {
    "name_bg": "Долна Липница"
  },
  {
    "name_bg": "Долна Малина"
  },
  {
    "name_bg": "Долна махала"
  },
  {
    "name_bg": "Долна Мелна"
  },
  {
    "name_bg": "Долна Митрополия"
  },
  {
    "name_bg": "Долна Невля"
  },
  {
    "name_bg": "Долна Оряховица"
  },
  {
    "name_bg": "Долна Рибница"
  },
  {
    "name_bg": "Долна Рикса"
  },
  {
    "name_bg": "Долна Секирна"
  },
  {
    "name_bg": "Долна Студена"
  },
  {
    "name_bg": "Долна Хубавка"
  },
  {
    "name_bg": "Долни Богров"
  },
  {
    "name_bg": "Долни Бошняк"
  },
  {
    "name_bg": "Долни Вадин"
  },
  {
    "name_bg": "Долни Вит"
  },
  {
    "name_bg": "Долни Върпища"
  },
  {
    "name_bg": "Долни Главанак"
  },
  {
    "name_bg": "Долни Дамяновци"
  },
  {
    "name_bg": "Долни Драгойча"
  },
  {
    "name_bg": "Долни Дъбник"
  },
  {
    "name_bg": "Долни Коритен"
  },
  {
    "name_bg": "Долни Лом"
  },
  {
    "name_bg": "Долни Луковит"
  },
  {
    "name_bg": "Долни Маренци"
  },
  {
    "name_bg": "Долни Марян"
  },
  {
    "name_bg": "Долни Окол"
  },
  {
    "name_bg": "Долни Пасарел"
  },
  {
    "name_bg": "Долни Радковци"
  },
  {
    "name_bg": "Долни Раковец"
  },
  {
    "name_bg": "Долни Романци"
  },
  {
    "name_bg": "Долни Танчевци"
  },
  {
    "name_bg": "Долни Томчевци"
  },
  {
    "name_bg": "Долни Цибър"
  },
  {
    "name_bg": "Долни Юруци"
  },
  {
    "name_bg": "Долно Абланово"
  },
  {
    "name_bg": "Долно Белево"
  },
  {
    "name_bg": "Долно Ботево"
  },
  {
    "name_bg": "Долно Войводино"
  },
  {
    "name_bg": "Долно вършило"
  },
  {
    "name_bg": "Долно Драглище"
  },
  {
    "name_bg": "Долно Дряново"
  },
  {
    "name_bg": "Долно изворово"
  },
  {
    "name_bg": "Долно Камарци"
  },
  {
    "name_bg": "Долно Кобиле"
  },
  {
    "name_bg": "Долно Козарево"
  },
  {
    "name_bg": "Долно Къпиново"
  },
  {
    "name_bg": "Левски"
  },
  {
    "name_bg": "Долно Линево"
  },
  {
    "name_bg": "Долно Луково"
  },
  {
    "name_bg": "Долно Новково"
  },
  {
    "name_bg": "Долно ново село"
  },
  {
    "name_bg": "Долно Озирово"
  },
  {
    "name_bg": "Долно Осеново"
  },
  {
    "name_bg": "Паничерево"
  },
  {
    "name_bg": "Долно поле"
  },
  {
    "name_bg": "Долно Прахово"
  },
  {
    "name_bg": "Долно Ряхово"
  },
  {
    "name_bg": "Долно Сахране"
  },
  {
    "name_bg": "Долно село"
  },
  {
    "name_bg": "Долноселци"
  },
  {
    "name_bg": "Долнослав"
  },
  {
    "name_bg": "Долно Спанчево"
  },
  {
    "name_bg": "Долно Съдиево"
  },
  {
    "name_bg": "Долно Уйно"
  },
  {
    "name_bg": "Долно Церовене"
  },
  {
    "name_bg": "Долно Черковище"
  },
  {
    "name_bg": "Долно Шивачево"
  },
  {
    "name_bg": "Долно Ябълково"
  },
  {
    "name_bg": "Домище"
  },
  {
    "name_bg": "Домлян"
  },
  {
    "name_bg": "Дондуково"
  },
  {
    "name_bg": "Донино"
  },
  {
    "name_bg": "Донкино"
  },
  {
    "name_bg": "Донковци"
  },
  {
    "name_bg": "Дончево"
  },
  {
    "name_bg": "Дончовци"
  },
  {
    "name_bg": "Дорково"
  },
  {
    "name_bg": "Доситеево"
  },
  {
    "name_bg": "Доспат"
  },
  {
    "name_bg": "Доспей"
  },
  {
    "name_bg": "Доча"
  },
  {
    "name_bg": "Драбишна"
  },
  {
    "name_bg": "Драгана"
  },
  {
    "name_bg": "Драганица"
  },
  {
    "name_bg": "Драгановец"
  },
  {
    "name_bg": "Драганово"
  },
  {
    "name_bg": "Драгановци"
  },
  {
    "name_bg": "Драганосковци"
  },
  {
    "name_bg": "Драганци"
  },
  {
    "name_bg": "Драганчетата"
  },
  {
    "name_bg": "Драгаш войвода"
  },
  {
    "name_bg": "Драгиевци"
  },
  {
    "name_bg": "Драгижево"
  },
  {
    "name_bg": "Драгийци"
  },
  {
    "name_bg": "Драгиново"
  },
  {
    "name_bg": "Драгичево"
  },
  {
    "name_bg": "Драгневци"
  },
  {
    "name_bg": "Драговищица"
  },
  {
    "name_bg": "Драгово"
  },
  {
    "name_bg": "Драгодан"
  },
  {
    "name_bg": "Драгоданово"
  },
  {
    "name_bg": "Драгоево"
  },
  {
    "name_bg": "Драгоил"
  },
  {
    "name_bg": "Драгойново"
  },
  {
    "name_bg": "Драгойчинци"
  },
  {
    "name_bg": "Драгоман"
  },
  {
    "name_bg": "Драгомани"
  },
  {
    "name_bg": "Драгомир"
  },
  {
    "name_bg": "Драгомирово"
  },
  {
    "name_bg": "Драгор"
  },
  {
    "name_bg": "Драготинци"
  },
  {
    "name_bg": "Драгуш"
  },
  {
    "name_bg": "Драгушиново"
  },
  {
    "name_bg": "Дражево"
  },
  {
    "name_bg": "Дражинци"
  },
  {
    "name_bg": "Драка"
  },
  {
    "name_bg": "Драката"
  },
  {
    "name_bg": "Дралфа"
  },
  {
    "name_bg": "Драма"
  },
  {
    "name_bg": "Дрангово"
  },
  {
    "name_bg": "Драндарите"
  },
  {
    "name_bg": "Драчево"
  },
  {
    "name_bg": "Драшан"
  },
  {
    "name_bg": "Драшкова поляна"
  },
  {
    "name_bg": "Дреатин"
  },
  {
    "name_bg": "Дрен"
  },
  {
    "name_bg": "Дренково"
  },
  {
    "name_bg": "Дренов"
  },
  {
    "name_bg": "Дреновец"
  },
  {
    "name_bg": "Дреновица"
  },
  {
    "name_bg": "Дреново"
  },
  {
    "name_bg": "Дрента"
  },
  {
    "name_bg": "Дренци"
  },
  {
    "name_bg": "Дриново"
  },
  {
    "name_bg": "Дрипчево"
  },
  {
    "name_bg": "Дропла"
  },
  {
    "name_bg": "Дружба"
  },
  {
    "name_bg": "Дружево"
  },
  {
    "name_bg": "Дружинци"
  },
  {
    "name_bg": "Друмево"
  },
  {
    "name_bg": "Друмохар"
  },
  {
    "name_bg": "Друмче"
  },
  {
    "name_bg": "Дръмша"
  },
  {
    "name_bg": "Дръндар"
  },
  {
    "name_bg": "Дрянка"
  },
  {
    "name_bg": "Дрянковец"
  },
  {
    "name_bg": "Дрянова глава"
  },
  {
    "name_bg": "Дряновец"
  },
  {
    "name_bg": "Дряново"
  },
  {
    "name_bg": "Дуванлии"
  },
  {
    "name_bg": "Капитан Петко войвода"
  },
  {
    "name_bg": "Дуковци"
  },
  {
    "name_bg": "Дулово"
  },
  {
    "name_bg": "Дунавец"
  },
  {
    "name_bg": "Дунавци"
  },
  {
    "name_bg": "Дунево"
  },
  {
    "name_bg": "Бели Лом"
  },
  {
    "name_bg": "Дуранкулак"
  },
  {
    "name_bg": "Лудогорци"
  },
  {
    "name_bg": "Дуровци"
  },
  {
    "name_bg": "Дурча"
  },
  {
    "name_bg": "Духовец"
  },
  {
    "name_bg": "Душанци"
  },
  {
    "name_bg": "Душево"
  },
  {
    "name_bg": "Душевски колиби"
  },
  {
    "name_bg": "Душинково"
  },
  {
    "name_bg": "Душинци"
  },
  {
    "name_bg": "Душка"
  },
  {
    "name_bg": "Дъбен"
  },
  {
    "name_bg": "Дъбене"
  },
  {
    "name_bg": "Дъбник"
  },
  {
    "name_bg": "Дъбница"
  },
  {
    "name_bg": "Дъбова"
  },
  {
    "name_bg": "Дъбова махала"
  },
  {
    "name_bg": "Дъбован"
  },
  {
    "name_bg": "Дъбовец"
  },
  {
    "name_bg": "Долна Дъбева"
  },
  {
    "name_bg": "Дъбовик"
  },
  {
    "name_bg": "Дъбово"
  },
  {
    "name_bg": "Дъбрава"
  },
  {
    "name_bg": "Дъбравата"
  },
  {
    "name_bg": "Дъбравино"
  },
  {
    "name_bg": "Дъбравите"
  },
  {
    "name_bg": "Дъбравица"
  },
  {
    "name_bg": "Дъбравка"
  },
  {
    "name_bg": "Дъждино"
  },
  {
    "name_bg": "Дъждовник"
  },
  {
    "name_bg": "Дъждовница"
  },
  {
    "name_bg": "Дълбок дол"
  },
  {
    "name_bg": "Дълбоки"
  },
  {
    "name_bg": "Дълбок извор"
  },
  {
    "name_bg": "Дълга лука"
  },
  {
    "name_bg": "Дългач"
  },
  {
    "name_bg": "Дълги дел"
  },
  {
    "name_bg": "Дълги припек"
  },
  {
    "name_bg": "Дългоделци"
  },
  {
    "name_bg": "Дългопол"
  },
  {
    "name_bg": "Дълго поле"
  },
  {
    "name_bg": "Дънгово"
  },
  {
    "name_bg": "Дървари"
  },
  {
    "name_bg": "Държава"
  },
  {
    "name_bg": "Държавен"
  },
  {
    "name_bg": "Държаница"
  },
  {
    "name_bg": "Дърлевци"
  },
  {
    "name_bg": "Дърманци"
  },
  {
    "name_bg": "Дъскарите"
  },
  {
    "name_bg": "Дъскот"
  },
  {
    "name_bg": "Дъскотна"
  },
  {
    "name_bg": "Дюлево"
  },
  {
    "name_bg": "Дюлино"
  },
  {
    "name_bg": "Дюлица"
  },
  {
    "name_bg": "Дюля"
  },
  {
    "name_bg": "Дядово"
  },
  {
    "name_bg": "Дядовско"
  },
  {
    "name_bg": "Дядовци"
  },
  {
    "name_bg": "Дяково"
  },
  {
    "name_bg": "Дянково"
  },
  {
    "name_bg": "Друган"
  },
  {
    "name_bg": "Думници"
  },
  {
    "name_bg": "Дедино"
  },
  {
    "name_bg": "Долна Василица"
  },
  {
    "name_bg": "Драгомъж"
  },
  {
    "name_bg": "Дисевица"
  },
  {
    "name_bg": "Долно Големанци"
  },
  {
    "name_bg": "Дуня"
  },
  {
    "name_bg": "Девинци"
  },
  {
    "name_bg": "Евлогиево"
  },
  {
    "name_bg": "Евренозово"
  },
  {
    "name_bg": "Егрек"
  },
  {
    "name_bg": "Егълница"
  },
  {
    "name_bg": "Единаковци"
  },
  {
    "name_bg": "Едрево"
  },
  {
    "name_bg": "Ездимирци"
  },
  {
    "name_bg": "Езерец"
  },
  {
    "name_bg": "Езеро"
  },
  {
    "name_bg": "Езерово"
  },
  {
    "name_bg": "Езерче"
  },
  {
    "name_bg": "Екзарх Антимово"
  },
  {
    "name_bg": "Екзарх Йосиф"
  },
  {
    "name_bg": "Елена"
  },
  {
    "name_bg": "Еленино"
  },
  {
    "name_bg": "Еленов дол"
  },
  {
    "name_bg": "Еленово"
  },
  {
    "name_bg": "Еленска"
  },
  {
    "name_bg": "Еленците"
  },
  {
    "name_bg": "Елешница"
  },
  {
    "name_bg": "Елисейна"
  },
  {
    "name_bg": "Еловдол"
  },
  {
    "name_bg": "Елов дол"
  },
  {
    "name_bg": "Еловица"
  },
  {
    "name_bg": "Елховец"
  },
  {
    "name_bg": "Елхово"
  },
  {
    "name_bg": "Елшица"
  },
  {
    "name_bg": "Ельово"
  },
  {
    "name_bg": "Емен"
  },
  {
    "name_bg": "Белодол"
  },
  {
    "name_bg": "Емона"
  },
  {
    "name_bg": "Енево"
  },
  {
    "name_bg": "Енев рът"
  },
  {
    "name_bg": "Енина"
  },
  {
    "name_bg": "Еница"
  },
  {
    "name_bg": "Енчец"
  },
  {
    "name_bg": "Енчовци"
  },
  {
    "name_bg": "Еньовче"
  },
  {
    "name_bg": "Ерден"
  },
  {
    "name_bg": "Еремия"
  },
  {
    "name_bg": "Ерма река"
  },
  {
    "name_bg": "Еровете"
  },
  {
    "name_bg": "Ерул"
  },
  {
    "name_bg": "Есен"
  },
  {
    "name_bg": "Есеница"
  },
  {
    "name_bg": "Етрополе"
  },
  {
    "name_bg": "Ефрейтор Бакалово"
  },
  {
    "name_bg": "Ефрем"
  },
  {
    "name_bg": "Езерото"
  },
  {
    "name_bg": "Едрино"
  },
  {
    "name_bg": "Жабляно"
  },
  {
    "name_bg": "Жабокрът"
  },
  {
    "name_bg": "Жегларци"
  },
  {
    "name_bg": "Жеглица"
  },
  {
    "name_bg": "Жедна"
  },
  {
    "name_bg": "Железари"
  },
  {
    "name_bg": "Железарци"
  },
  {
    "name_bg": "Железино"
  },
  {
    "name_bg": "Железна"
  },
  {
    "name_bg": "Железник"
  },
  {
    "name_bg": "Железница"
  },
  {
    "name_bg": "Желен"
  },
  {
    "name_bg": "Желъд"
  },
  {
    "name_bg": "Желъдово"
  },
  {
    "name_bg": "Желю войвода"
  },
  {
    "name_bg": "Желява"
  },
  {
    "name_bg": "Желязковец"
  },
  {
    "name_bg": "Момина църква"
  },
  {
    "name_bg": "Желязно"
  },
  {
    "name_bg": "Желязово"
  },
  {
    "name_bg": "Женда"
  },
  {
    "name_bg": "Жеравино"
  },
  {
    "name_bg": "Жеравна"
  },
  {
    "name_bg": "Жерговец"
  },
  {
    "name_bg": "Жернов"
  },
  {
    "name_bg": "Живко"
  },
  {
    "name_bg": "Живково"
  },
  {
    "name_bg": "Жижево"
  },
  {
    "name_bg": "Жиленци"
  },
  {
    "name_bg": "Жилино"
  },
  {
    "name_bg": "Жинзифово"
  },
  {
    "name_bg": "Житарник"
  },
  {
    "name_bg": "Житен"
  },
  {
    "name_bg": "Житница"
  },
  {
    "name_bg": "Житосвят"
  },
  {
    "name_bg": "Жребево"
  },
  {
    "name_bg": "Жребино"
  },
  {
    "name_bg": "Жребичко"
  },
  {
    "name_bg": "Жълт бряг"
  },
  {
    "name_bg": "Жълтеш"
  },
  {
    "name_bg": "Жълти бряг"
  },
  {
    "name_bg": "Жълти рид"
  },
  {
    "name_bg": "Жълт камък"
  },
  {
    "name_bg": "Жълтопоп"
  },
  {
    "name_bg": "Жълтуша"
  },
  {
    "name_bg": "Жълтика"
  },
  {
    "name_bg": "Житуша"
  },
  {
    "name_bg": "Забел"
  },
  {
    "name_bg": "Заберново"
  },
  {
    "name_bg": "Забърдо"
  },
  {
    "name_bg": "Завала"
  },
  {
    "name_bg": "Завет"
  },
  {
    "name_bg": "Заветно"
  },
  {
    "name_bg": "Завидовци"
  },
  {
    "name_bg": "Завой"
  },
  {
    "name_bg": "Завоя"
  },
  {
    "name_bg": "Загоре"
  },
  {
    "name_bg": "Загориче"
  },
  {
    "name_bg": "Загорски"
  },
  {
    "name_bg": "Загорско"
  },
  {
    "name_bg": "Загорци"
  },
  {
    "name_bg": "Загражден"
  },
  {
    "name_bg": "Задруга"
  },
  {
    "name_bg": "Заевите"
  },
  {
    "name_bg": "Заимчево"
  },
  {
    "name_bg": "Зайчар"
  },
  {
    "name_bg": "Зайчари"
  },
  {
    "name_bg": "Зайчино"
  },
  {
    "name_bg": "Зайчино ореше"
  },
  {
    "name_bg": "Замфир"
  },
  {
    "name_bg": "Замфирово"
  },
  {
    "name_bg": "Занога"
  },
  {
    "name_bg": "Заноге"
  },
  {
    "name_bg": "Зараево"
  },
  {
    "name_bg": "Зарник"
  },
  {
    "name_bg": "Заселе"
  },
  {
    "name_bg": "Засмяно"
  },
  {
    "name_bg": "Зафирово"
  },
  {
    "name_bg": "Могилино"
  },
  {
    "name_bg": "Захари Стояново"
  },
  {
    "name_bg": "Зая"
  },
  {
    "name_bg": "Звегор"
  },
  {
    "name_bg": "Звезда"
  },
  {
    "name_bg": "Звездел"
  },
  {
    "name_bg": "Звезделина"
  },
  {
    "name_bg": "Звезден"
  },
  {
    "name_bg": "Звездец"
  },
  {
    "name_bg": "Звездица"
  },
  {
    "name_bg": "Звенимир"
  },
  {
    "name_bg": "Зверино"
  },
  {
    "name_bg": "Звиница"
  },
  {
    "name_bg": "Звънарка"
  },
  {
    "name_bg": "Звънарци"
  },
  {
    "name_bg": "Звънец"
  },
  {
    "name_bg": "Звъника"
  },
  {
    "name_bg": "Звъничево"
  },
  {
    "name_bg": "Звънче"
  },
  {
    "name_bg": "Згалево"
  },
  {
    "name_bg": "Згориград"
  },
  {
    "name_bg": "Згурово"
  },
  {
    "name_bg": "Здравец"
  },
  {
    "name_bg": "Здравковец"
  },
  {
    "name_bg": "Здравчец"
  },
  {
    "name_bg": "Зебил"
  },
  {
    "name_bg": "Зелена морава"
  },
  {
    "name_bg": "Зелендол"
  },
  {
    "name_bg": "Зелениград"
  },
  {
    "name_bg": "Зеленик"
  },
  {
    "name_bg": "Зеленика"
  },
  {
    "name_bg": "Зелениково"
  },
  {
    "name_bg": "Зелено дърво"
  },
  {
    "name_bg": "Земен"
  },
  {
    "name_bg": "Земенци"
  },
  {
    "name_bg": "Землен"
  },
  {
    "name_bg": "Зетьово"
  },
  {
    "name_bg": "Зидарово"
  },
  {
    "name_bg": "Зидарци"
  },
  {
    "name_bg": "Зимевица"
  },
  {
    "name_bg": "Зимен"
  },
  {
    "name_bg": "Зимзелен"
  },
  {
    "name_bg": "Зимница"
  },
  {
    "name_bg": "Зимовина"
  },
  {
    "name_bg": "Зиморница"
  },
  {
    "name_bg": "Златар"
  },
  {
    "name_bg": "Златари"
  },
  {
    "name_bg": "Златевци"
  },
  {
    "name_bg": "Злати войвода"
  },
  {
    "name_bg": "Златина"
  },
  {
    "name_bg": "Златиница"
  },
  {
    "name_bg": "Златирът"
  },
  {
    "name_bg": "Златитрап"
  },
  {
    "name_bg": "Златица"
  },
  {
    "name_bg": "Златия"
  },
  {
    "name_bg": "Златна ливада"
  },
  {
    "name_bg": "Златна нива"
  },
  {
    "name_bg": "Златна Панега"
  },
  {
    "name_bg": "Златовръх"
  },
  {
    "name_bg": "Златоград"
  },
  {
    "name_bg": "Златоклас"
  },
  {
    "name_bg": "Златолист"
  },
  {
    "name_bg": "Златополе"
  },
  {
    "name_bg": "Златосел"
  },
  {
    "name_bg": "Златоустово"
  },
  {
    "name_bg": "Златуша"
  },
  {
    "name_bg": "Злидол"
  },
  {
    "name_bg": "Злогош"
  },
  {
    "name_bg": "Злокучене"
  },
  {
    "name_bg": "Змеево"
  },
  {
    "name_bg": "Змеица"
  },
  {
    "name_bg": "Змейно"
  },
  {
    "name_bg": "Змейово"
  },
  {
    "name_bg": "Змиево"
  },
  {
    "name_bg": "Знаменосец"
  },
  {
    "name_bg": "Зограф"
  },
  {
    "name_bg": "Зойчене"
  },
  {
    "name_bg": "Зърнево"
  },
  {
    "name_bg": "Зарица"
  },
  {
    "name_bg": "Чучур"
  },
  {
    "name_bg": "Ивайло"
  },
  {
    "name_bg": "Ивайловград"
  },
  {
    "name_bg": "Иван Вазово"
  },
  {
    "name_bg": "Иван Димов"
  },
  {
    "name_bg": "Иванивановци"
  },
  {
    "name_bg": "Иванили"
  },
  {
    "name_bg": "Иванковци"
  },
  {
    "name_bg": "Иваново"
  },
  {
    "name_bg": "Ивановци"
  },
  {
    "name_bg": "Ивански"
  },
  {
    "name_bg": "Иванци"
  },
  {
    "name_bg": "Иванча"
  },
  {
    "name_bg": "Иван Шишманово"
  },
  {
    "name_bg": "Иваняне"
  },
  {
    "name_bg": "Иганово"
  },
  {
    "name_bg": "Иглика"
  },
  {
    "name_bg": "Игнатиево"
  },
  {
    "name_bg": "Игнатица"
  },
  {
    "name_bg": "Игнатово"
  },
  {
    "name_bg": "Игнатовци"
  },
  {
    "name_bg": "Игралище"
  },
  {
    "name_bg": "Идилево"
  },
  {
    "name_bg": "Избеглии"
  },
  {
    "name_bg": "Избул"
  },
  {
    "name_bg": "Извор"
  },
  {
    "name_bg": "Нови извор"
  },
  {
    "name_bg": "Изворище"
  },
  {
    "name_bg": "Извор махала"
  },
  {
    "name_bg": "Изворник"
  },
  {
    "name_bg": "Изворово"
  },
  {
    "name_bg": "Изворско"
  },
  {
    "name_bg": "Изворче"
  },
  {
    "name_bg": "Източник"
  },
  {
    "name_bg": "Илаков рът"
  },
  {
    "name_bg": "Смолево"
  },
  {
    "name_bg": "Илевци"
  },
  {
    "name_bg": "Илийно"
  },
  {
    "name_bg": "Илийско"
  },
  {
    "name_bg": "Илинден"
  },
  {
    "name_bg": "Илинденци"
  },
  {
    "name_bg": "Илиница"
  },
  {
    "name_bg": "Илиювци"
  },
  {
    "name_bg": "Илия"
  },
  {
    "name_bg": "Илия Блъсково"
  },
  {
    "name_bg": "Имренчево"
  },
  {
    "name_bg": "Индже войвода"
  },
  {
    "name_bg": "Генерал Инзово"
  },
  {
    "name_bg": "Иново"
  },
  {
    "name_bg": "Иречек"
  },
  {
    "name_bg": "Иречеково"
  },
  {
    "name_bg": "Иринеци"
  },
  {
    "name_bg": "Ирник"
  },
  {
    "name_bg": "Искра"
  },
  {
    "name_bg": "Искрец"
  },
  {
    "name_bg": "Искрица"
  },
  {
    "name_bg": "Исперих"
  },
  {
    "name_bg": "Исперихово"
  },
  {
    "name_bg": "Исьовци"
  },
  {
    "name_bg": "Ихтиман"
  },
  {
    "name_bg": "Ичера"
  },
  {
    "name_bg": "Йерусалимово"
  },
  {
    "name_bg": "Йоаким Груево"
  },
  {
    "name_bg": "Йовково"
  },
  {
    "name_bg": "Йововци"
  },
  {
    "name_bg": "Йовчевци"
  },
  {
    "name_bg": "Йоглав"
  },
  {
    "name_bg": "Йонково"
  },
  {
    "name_bg": "Йончово"
  },
  {
    "name_bg": "Йорданово"
  },
  {
    "name_bg": "Светлен"
  },
  {
    "name_bg": "Кабиле"
  },
  {
    "name_bg": "Каблешково"
  },
  {
    "name_bg": "Каварна"
  },
  {
    "name_bg": "Кавлак"
  },
  {
    "name_bg": "Кавракирово"
  },
  {
    "name_bg": "Кадиево"
  },
  {
    "name_bg": "Сушица"
  },
  {
    "name_bg": "Кадровица"
  },
  {
    "name_bg": "Невестино"
  },
  {
    "name_bg": "Казак"
  },
  {
    "name_bg": "Казанка"
  },
  {
    "name_bg": "Казанлък"
  },
  {
    "name_bg": "Казаците"
  },
  {
    "name_bg": "Казачево"
  },
  {
    "name_bg": "Казашка река"
  },
  {
    "name_bg": "Казашко"
  },
  {
    "name_bg": "Казимир"
  },
  {
    "name_bg": "Казичене"
  },
  {
    "name_bg": "Кайнарджа"
  },
  {
    "name_bg": "Калайджиево"
  },
  {
    "name_bg": "Калайджии"
  },
  {
    "name_bg": "Калейца"
  },
  {
    "name_bg": "Калековец"
  },
  {
    "name_bg": "Кален"
  },
  {
    "name_bg": "Каленик"
  },
  {
    "name_bg": "Каленовци"
  },
  {
    "name_bg": "Калиманци"
  },
  {
    "name_bg": "Калина"
  },
  {
    "name_bg": "Калинка"
  },
  {
    "name_bg": "Калино"
  },
  {
    "name_bg": "Калитиново"
  },
  {
    "name_bg": "Калище"
  },
  {
    "name_bg": "Калнище"
  },
  {
    "name_bg": "Калово"
  },
  {
    "name_bg": "Каломен"
  },
  {
    "name_bg": "Калотина"
  },
  {
    "name_bg": "Калотинци"
  },
  {
    "name_bg": "Калофер"
  },
  {
    "name_bg": "Калоян"
  },
  {
    "name_bg": "Калояновец"
  },
  {
    "name_bg": "Калояново"
  },
  {
    "name_bg": "Калоянци"
  },
  {
    "name_bg": "Калугерене"
  },
  {
    "name_bg": "Калугерово"
  },
  {
    "name_bg": "Калчево"
  },
  {
    "name_bg": "Калчовци"
  },
  {
    "name_bg": "Камбелевци"
  },
  {
    "name_bg": "Камбурово"
  },
  {
    "name_bg": "Камен"
  },
  {
    "name_bg": "Камена"
  },
  {
    "name_bg": "Каменар"
  },
  {
    "name_bg": "Каменари"
  },
  {
    "name_bg": "Каменарци"
  },
  {
    "name_bg": "Камен бряг"
  },
  {
    "name_bg": "Камен връх"
  },
  {
    "name_bg": "Камен дял"
  },
  {
    "name_bg": "Каменик"
  },
  {
    "name_bg": "Каменица"
  },
  {
    "name_bg": "Каменичка Скакавица"
  },
  {
    "name_bg": "Каменка"
  },
  {
    "name_bg": "Каменна река"
  },
  {
    "name_bg": "Каменна Рикса"
  },
  {
    "name_bg": "Камено"
  },
  {
    "name_bg": "Каменово"
  },
  {
    "name_bg": "Камено поле"
  },
  {
    "name_bg": "Каменско"
  },
  {
    "name_bg": "Каменци"
  },
  {
    "name_bg": "Каменяк"
  },
  {
    "name_bg": "Каменяне"
  },
  {
    "name_bg": "Камещица"
  },
  {
    "name_bg": "Камилски дол"
  },
  {
    "name_bg": "Камчия"
  },
  {
    "name_bg": "Кандилка"
  },
  {
    "name_bg": "Кандови"
  },
  {
    "name_bg": "Каниц"
  },
  {
    "name_bg": "Кантари"
  },
  {
    "name_bg": "Каняк"
  },
  {
    "name_bg": "Каолиново"
  },
  {
    "name_bg": "Капатово"
  },
  {
    "name_bg": "Къпиново"
  },
  {
    "name_bg": "Капитан Андреево"
  },
  {
    "name_bg": "Капитан Димитриево"
  },
  {
    "name_bg": "Капитан Димитрово"
  },
  {
    "name_bg": "Капитановци"
  },
  {
    "name_bg": "Капитан Петко"
  },
  {
    "name_bg": "Капище"
  },
  {
    "name_bg": "Карабунар"
  },
  {
    "name_bg": "Каравелово"
  },
  {
    "name_bg": "Каравельово"
  },
  {
    "name_bg": "Карагеоргиево"
  },
  {
    "name_bg": "Караджалово"
  },
  {
    "name_bg": "Караджово"
  },
  {
    "name_bg": "Караиванца"
  },
  {
    "name_bg": "Караиванци"
  },
  {
    "name_bg": "Караисен"
  },
  {
    "name_bg": "Карали"
  },
  {
    "name_bg": "Караманите"
  },
  {
    "name_bg": "Караманово"
  },
  {
    "name_bg": "Караманци"
  },
  {
    "name_bg": "Кара Михал"
  },
  {
    "name_bg": "Карамичевци"
  },
  {
    "name_bg": "Карамфил"
  },
  {
    "name_bg": "Каран Върбовка"
  },
  {
    "name_bg": "Карандили"
  },
  {
    "name_bg": "Караново"
  },
  {
    "name_bg": "Каранци"
  },
  {
    "name_bg": "Карапелит"
  },
  {
    "name_bg": "Караполци"
  },
  {
    "name_bg": "Караш"
  },
  {
    "name_bg": "Карбинци"
  },
  {
    "name_bg": "Карвуна"
  },
  {
    "name_bg": "Кардам"
  },
  {
    "name_bg": "Карлиево"
  },
  {
    "name_bg": "Карлово"
  },
  {
    "name_bg": "Карловско"
  },
  {
    "name_bg": "Карлуково"
  },
  {
    "name_bg": "Карнобат"
  },
  {
    "name_bg": "Касапско"
  },
  {
    "name_bg": "Касилаг"
  },
  {
    "name_bg": "Каснаково"
  },
  {
    "name_bg": "Каспичан"
  },
  {
    "name_bg": "Кастел"
  },
  {
    "name_bg": "Катерица"
  },
  {
    "name_bg": "Катранджии"
  },
  {
    "name_bg": "Катраница"
  },
  {
    "name_bg": "Катрище"
  },
  {
    "name_bg": "Катунец"
  },
  {
    "name_bg": "Катуница"
  },
  {
    "name_bg": "Катунище"
  },
  {
    "name_bg": "Катунци"
  },
  {
    "name_bg": "Кацелово"
  },
  {
    "name_bg": "Качулка"
  },
  {
    "name_bg": "Кашенци"
  },
  {
    "name_bg": "Кашина"
  },
  {
    "name_bg": "Каялоба"
  },
  {
    "name_bg": "Керека"
  },
  {
    "name_bg": "Керените"
  },
  {
    "name_bg": "Кермен"
  },
  {
    "name_bg": "Кесарево"
  },
  {
    "name_bg": "Кестен"
  },
  {
    "name_bg": "Кестеново"
  },
  {
    "name_bg": "Киевци"
  },
  {
    "name_bg": "Килифарево"
  },
  {
    "name_bg": "Кипилово"
  },
  {
    "name_bg": "Кипра"
  },
  {
    "name_bg": "Киревци"
  },
  {
    "name_bg": "Киреево"
  },
  {
    "name_bg": "Кирилово"
  },
  {
    "name_bg": "Киркова махала"
  },
  {
    "name_bg": "Кирково"
  },
  {
    "name_bg": "Кирово"
  },
  {
    "name_bg": "Кирчево"
  },
  {
    "name_bg": "Киселево"
  },
  {
    "name_bg": "Киселица"
  },
  {
    "name_bg": "Киселчово"
  },
  {
    "name_bg": "Киселковци"
  },
  {
    "name_bg": "Кисийците"
  },
  {
    "name_bg": "Кисьовци"
  },
  {
    "name_bg": "Китанчево"
  },
  {
    "name_bg": "Китен"
  },
  {
    "name_bg": "Китино"
  },
  {
    "name_bg": "Китка"
  },
  {
    "name_bg": "Китна"
  },
  {
    "name_bg": "Китница"
  },
  {
    "name_bg": "Кичево"
  },
  {
    "name_bg": "Киченица"
  },
  {
    "name_bg": "Кладенец"
  },
  {
    "name_bg": "Кладенци"
  },
  {
    "name_bg": "Кладни дял"
  },
  {
    "name_bg": "Кладница"
  },
  {
    "name_bg": "Кладоруб"
  },
  {
    "name_bg": "Клепало"
  },
  {
    "name_bg": "Кликач"
  },
  {
    "name_bg": "Климаш"
  },
  {
    "name_bg": "Климент"
  },
  {
    "name_bg": "Климентово"
  },
  {
    "name_bg": "Клисура"
  },
  {
    "name_bg": "Клисурица"
  },
  {
    "name_bg": "ман. Клисурски манастир"
  },
  {
    "name_bg": "Клокотница"
  },
  {
    "name_bg": "Клъшка река"
  },
  {
    "name_bg": "Ключ"
  },
  {
    "name_bg": "Кметовци"
  },
  {
    "name_bg": "Кметчета"
  },
  {
    "name_bg": "Кнежа"
  },
  {
    "name_bg": "Книжовник"
  },
  {
    "name_bg": "Княжева махала"
  },
  {
    "name_bg": "Княжево"
  },
  {
    "name_bg": "Кобилино"
  },
  {
    "name_bg": "Кобиляк"
  },
  {
    "name_bg": "Кобиляне"
  },
  {
    "name_bg": "Ковач"
  },
  {
    "name_bg": "Ковачевец"
  },
  {
    "name_bg": "Ковачевица"
  },
  {
    "name_bg": "Ковачево"
  },
  {
    "name_bg": "Ковачевци"
  },
  {
    "name_bg": "Ковачите"
  },
  {
    "name_bg": "Ковачица"
  },
  {
    "name_bg": "Ковил"
  },
  {
    "name_bg": "Коевци"
  },
  {
    "name_bg": "Кожари"
  },
  {
    "name_bg": "Кожинци"
  },
  {
    "name_bg": "Кожлювци"
  },
  {
    "name_bg": "Кожухарци"
  },
  {
    "name_bg": "Козаново"
  },
  {
    "name_bg": "Козар Белене"
  },
  {
    "name_bg": "Козаре"
  },
  {
    "name_bg": "Козаревец"
  },
  {
    "name_bg": "Козарево"
  },
  {
    "name_bg": "Козарка"
  },
  {
    "name_bg": "Козарско"
  },
  {
    "name_bg": "Кози рог"
  },
  {
    "name_bg": "Козица"
  },
  {
    "name_bg": "Козичино"
  },
  {
    "name_bg": "Козлево"
  },
  {
    "name_bg": "Козлец"
  },
  {
    "name_bg": "Козловец"
  },
  {
    "name_bg": "Козлодуй"
  },
  {
    "name_bg": "Козлодуйци"
  },
  {
    "name_bg": "Козма презвитер"
  },
  {
    "name_bg": "Козница"
  },
  {
    "name_bg": "Козяк"
  },
  {
    "name_bg": "Козя река"
  },
  {
    "name_bg": "Коиловци"
  },
  {
    "name_bg": "Койнаре"
  },
  {
    "name_bg": "Койчовци"
  },
  {
    "name_bg": "Кокаляне"
  },
  {
    "name_bg": "Кокиче"
  },
  {
    "name_bg": "Кoкорово"
  },
  {
    "name_bg": "Кокорово"
  },
  {
    "name_bg": "Кокорци"
  },
  {
    "name_bg": "Кокошане"
  },
  {
    "name_bg": "Колари"
  },
  {
    "name_bg": "Коларово"
  },
  {
    "name_bg": "Коларци"
  },
  {
    "name_bg": "Колена"
  },
  {
    "name_bg": "Колец"
  },
  {
    "name_bg": "Колибите"
  },
  {
    "name_bg": "Колишовци"
  },
  {
    "name_bg": "Колобър"
  },
  {
    "name_bg": "Колю Ганев"
  },
  {
    "name_bg": "Колю Мариново"
  },
  {
    "name_bg": "Комарево"
  },
  {
    "name_bg": "Комощица"
  },
  {
    "name_bg": "Комунари"
  },
  {
    "name_bg": "Комунига"
  },
  {
    "name_bg": "Комщица"
  },
  {
    "name_bg": "Конак"
  },
  {
    "name_bg": "Конаре"
  },
  {
    "name_bg": "Конарско"
  },
  {
    "name_bg": "Конарското"
  },
  {
    "name_bg": "Кондово"
  },
  {
    "name_bg": "Кондолово"
  },
  {
    "name_bg": "Кондофрей"
  },
  {
    "name_bg": "Коневец"
  },
  {
    "name_bg": "Конево"
  },
  {
    "name_bg": "Конници"
  },
  {
    "name_bg": "Коноп"
  },
  {
    "name_bg": "Константин"
  },
  {
    "name_bg": "Константиновец"
  },
  {
    "name_bg": "Константиново"
  },
  {
    "name_bg": "Контил"
  },
  {
    "name_bg": "Конуш"
  },
  {
    "name_bg": "Конче"
  },
  {
    "name_bg": "Коньово"
  },
  {
    "name_bg": "Коняво"
  },
  {
    "name_bg": "Копаница"
  },
  {
    "name_bg": "Копиловци"
  },
  {
    "name_bg": "Копитник"
  },
  {
    "name_bg": "Копрец"
  },
  {
    "name_bg": "Коприва"
  },
  {
    "name_bg": "Копривец"
  },
  {
    "name_bg": "Копривлен"
  },
  {
    "name_bg": "Копривщица"
  },
  {
    "name_bg": "Копринка"
  },
  {
    "name_bg": "Копчелиите"
  },
  {
    "name_bg": "Корен"
  },
  {
    "name_bg": "Кориите"
  },
  {
    "name_bg": "Коритата"
  },
  {
    "name_bg": "Коритен"
  },
  {
    "name_bg": "Корията"
  },
  {
    "name_bg": "Коркина"
  },
  {
    "name_bg": "Кормянско"
  },
  {
    "name_bg": "Корница"
  },
  {
    "name_bg": "Кортен"
  },
  {
    "name_bg": "Кос"
  },
  {
    "name_bg": "Косара"
  },
  {
    "name_bg": "Косарка"
  },
  {
    "name_bg": "Косача"
  },
  {
    "name_bg": "Косевци"
  },
  {
    "name_bg": "Косилка"
  },
  {
    "name_bg": "Косовец"
  },
  {
    "name_bg": "Косово"
  },
  {
    "name_bg": "Костадините"
  },
  {
    "name_bg": "Костанденец"
  },
  {
    "name_bg": "Костандово"
  },
  {
    "name_bg": "Коста Перчево"
  },
  {
    "name_bg": "Костел"
  },
  {
    "name_bg": "Костелево"
  },
  {
    "name_bg": "Костен"
  },
  {
    "name_bg": "Костена река"
  },
  {
    "name_bg": "Костенец"
  },
  {
    "name_bg": "Костенковци"
  },
  {
    "name_bg": "Костенци"
  },
  {
    "name_bg": "Кости"
  },
  {
    "name_bg": "Костиево"
  },
  {
    "name_bg": "Костилково"
  },
  {
    "name_bg": "Костинброд"
  },
  {
    "name_bg": "Костино"
  },
  {
    "name_bg": "Костичовци"
  },
  {
    "name_bg": "Костур"
  },
  {
    "name_bg": "Костурино"
  },
  {
    "name_bg": "Костуринци"
  },
  {
    "name_bg": "Котел"
  },
  {
    "name_bg": "Котеновци"
  },
  {
    "name_bg": "Котлари"
  },
  {
    "name_bg": "Котленци"
  },
  {
    "name_bg": "Котуци"
  },
  {
    "name_bg": "Кочан"
  },
  {
    "name_bg": "Кочани"
  },
  {
    "name_bg": "Кочево"
  },
  {
    "name_bg": "Кочериново"
  },
  {
    "name_bg": "Кочмар"
  },
  {
    "name_bg": "Кочово"
  },
  {
    "name_bg": "Кошава"
  },
  {
    "name_bg": "Кошарево"
  },
  {
    "name_bg": "Кошарица"
  },
  {
    "name_bg": "Кошарна"
  },
  {
    "name_bg": "Кошница"
  },
  {
    "name_bg": "Кошничари"
  },
  {
    "name_bg": "Кошов"
  },
  {
    "name_bg": "Кравино"
  },
  {
    "name_bg": "Краводер"
  },
  {
    "name_bg": "Крагулево"
  },
  {
    "name_bg": "Краево"
  },
  {
    "name_bg": "Краище"
  },
  {
    "name_bg": "Крайгорци"
  },
  {
    "name_bg": "Крайна"
  },
  {
    "name_bg": "Крайни дол"
  },
  {
    "name_bg": "Крайници"
  },
  {
    "name_bg": "Крайно село"
  },
  {
    "name_bg": "Крайново"
  },
  {
    "name_bg": "Крайполе"
  },
  {
    "name_bg": "Кракра"
  },
  {
    "name_bg": "Кралев дол"
  },
  {
    "name_bg": "Кралево"
  },
  {
    "name_bg": "Крали Марко"
  },
  {
    "name_bg": "Крамолин"
  },
  {
    "name_bg": "Кран"
  },
  {
    "name_bg": "Кранево"
  },
  {
    "name_bg": "Краново"
  },
  {
    "name_bg": "Крапец"
  },
  {
    "name_bg": "Крапчене"
  },
  {
    "name_bg": "Красава"
  },
  {
    "name_bg": "Красен"
  },
  {
    "name_bg": "Красен дол"
  },
  {
    "name_bg": "Красимир"
  },
  {
    "name_bg": "Красино"
  },
  {
    "name_bg": "Красново"
  },
  {
    "name_bg": "Красно градище"
  },
  {
    "name_bg": "Красноселци"
  },
  {
    "name_bg": "Крачимир"
  },
  {
    "name_bg": "Кремен"
  },
  {
    "name_bg": "Кремена"
  },
  {
    "name_bg": "Кремене"
  },
  {
    "name_bg": "Кременик"
  },
  {
    "name_bg": "Крепост"
  },
  {
    "name_bg": "Крепча"
  },
  {
    "name_bg": "Креслювци"
  },
  {
    "name_bg": "Стара Кресна"
  },
  {
    "name_bg": "Крета"
  },
  {
    "name_bg": "Крибул"
  },
  {
    "name_bg": "Крива бара"
  },
  {
    "name_bg": "Крива круша"
  },
  {
    "name_bg": "Крива река"
  },
  {
    "name_bg": "Кривина"
  },
  {
    "name_bg": "Кривини"
  },
  {
    "name_bg": "Кривица"
  },
  {
    "name_bg": "Кривня"
  },
  {
    "name_bg": "Криводол"
  },
  {
    "name_bg": "Кривонос"
  },
  {
    "name_bg": "Криво поле"
  },
  {
    "name_bg": "Крилатица"
  },
  {
    "name_bg": "Крилювци"
  },
  {
    "name_bg": "Крин"
  },
  {
    "name_bg": "Крислово"
  },
  {
    "name_bg": "Кричим"
  },
  {
    "name_bg": "Кромидово"
  },
  {
    "name_bg": "Крояч"
  },
  {
    "name_bg": "Кроячево"
  },
  {
    "name_bg": "Крум"
  },
  {
    "name_bg": "Крумовград"
  },
  {
    "name_bg": "Крумово"
  },
  {
    "name_bg": "Крумово градище"
  },
  {
    "name_bg": "Крумчевци"
  },
  {
    "name_bg": "Крупен"
  },
  {
    "name_bg": "Крупник"
  },
  {
    "name_bg": "Круша"
  },
  {
    "name_bg": "Крушаре"
  },
  {
    "name_bg": "Крушари"
  },
  {
    "name_bg": "Крушев дол"
  },
  {
    "name_bg": "Крушевец"
  },
  {
    "name_bg": "Крушево"
  },
  {
    "name_bg": "Крушевска"
  },
  {
    "name_bg": "Крушето"
  },
  {
    "name_bg": "Крушка"
  },
  {
    "name_bg": "Крушовене"
  },
  {
    "name_bg": "Крушовица"
  },
  {
    "name_bg": "Крушово"
  },
  {
    "name_bg": "Крушолак"
  },
  {
    "name_bg": "Крушуна"
  },
  {
    "name_bg": "Кръвеник"
  },
  {
    "name_bg": "Крън"
  },
  {
    "name_bg": "Крънджилица"
  },
  {
    "name_bg": "Крънча"
  },
  {
    "name_bg": "Кръстатица"
  },
  {
    "name_bg": "Кръстевич"
  },
  {
    "name_bg": "Кръстеняците"
  },
  {
    "name_bg": "Кръстец"
  },
  {
    "name_bg": "Кръстилци"
  },
  {
    "name_bg": "Кръстина"
  },
  {
    "name_bg": "Кръшно"
  },
  {
    "name_bg": "Кубадин"
  },
  {
    "name_bg": "Кубрат"
  },
  {
    "name_bg": "Кубратово"
  },
  {
    "name_bg": "Куделин"
  },
  {
    "name_bg": "Кузьово"
  },
  {
    "name_bg": "Куклен"
  },
  {
    "name_bg": "Кукля"
  },
  {
    "name_bg": "Кукорево"
  },
  {
    "name_bg": "Кукувица"
  },
  {
    "name_bg": "Кукурахцево"
  },
  {
    "name_bg": "Кукуряк"
  },
  {
    "name_bg": "Кула"
  },
  {
    "name_bg": "Кулата"
  },
  {
    "name_bg": "Кулина вода"
  },
  {
    "name_bg": "Куманите"
  },
  {
    "name_bg": "Куманово"
  },
  {
    "name_bg": "Кундево"
  },
  {
    "name_bg": "Кунино"
  },
  {
    "name_bg": "Купен"
  },
  {
    "name_bg": "Купците"
  },
  {
    "name_bg": "Курново"
  },
  {
    "name_bg": "Куртово"
  },
  {
    "name_bg": "Куртово Конаре"
  },
  {
    "name_bg": "Кутела"
  },
  {
    "name_bg": "Кутловица"
  },
  {
    "name_bg": "Кутугерци"
  },
  {
    "name_bg": "Куцаровци"
  },
  {
    "name_bg": "Куцина"
  },
  {
    "name_bg": "Куцово"
  },
  {
    "name_bg": "Кушла"
  },
  {
    "name_bg": "Къклица"
  },
  {
    "name_bg": "Къкрина"
  },
  {
    "name_bg": "Кълново"
  },
  {
    "name_bg": "Кънчево"
  },
  {
    "name_bg": "Къпинец"
  },
  {
    "name_bg": "Къпиновци"
  },
  {
    "name_bg": "Кърджали"
  },
  {
    "name_bg": "Кърналово"
  },
  {
    "name_bg": "Кърланово"
  },
  {
    "name_bg": "Кърнаре"
  },
  {
    "name_bg": "Кърпачево"
  },
  {
    "name_bg": "Кърпелево"
  },
  {
    "name_bg": "Къртипъня"
  },
  {
    "name_bg": "Къртожабене"
  },
  {
    "name_bg": "Кърчовско"
  },
  {
    "name_bg": "Кършалево"
  },
  {
    "name_bg": "Късак"
  },
  {
    "name_bg": "Кътина"
  },
  {
    "name_bg": "Къшин"
  },
  {
    "name_bg": "Къшле"
  },
  {
    "name_bg": "Кьолмен"
  },
  {
    "name_bg": "Кьосево"
  },
  {
    "name_bg": "Кьосевци"
  },
  {
    "name_bg": "Кюлевча"
  },
  {
    "name_bg": "Кюстендил"
  },
  {
    "name_bg": "Конска"
  },
  {
    "name_bg": "Кръстава"
  },
  {
    "name_bg": "Калипетрово"
  },
  {
    "name_bg": "Еленка"
  },
  {
    "name_bg": "Коньовец"
  },
  {
    "name_bg": "Костадинкино"
  },
  {
    "name_bg": "Кленовик"
  },
  {
    "name_bg": "Кошарите"
  },
  {
    "name_bg": "Кутово"
  },
  {
    "name_bg": "Лагерите"
  },
  {
    "name_bg": "Лагошевци"
  },
  {
    "name_bg": "Ладарево"
  },
  {
    "name_bg": "Лазарово"
  },
  {
    "name_bg": "Торос"
  },
  {
    "name_bg": "Лазарци"
  },
  {
    "name_bg": "Лале"
  },
  {
    "name_bg": "Лалково"
  },
  {
    "name_bg": "Ламбух"
  },
  {
    "name_bg": "Ласкар"
  },
  {
    "name_bg": "Ласкарево"
  },
  {
    "name_bg": "Латинка"
  },
  {
    "name_bg": "Лебед"
  },
  {
    "name_bg": "Лебница"
  },
  {
    "name_bg": "Лева река"
  },
  {
    "name_bg": "Левка"
  },
  {
    "name_bg": "Левочево"
  },
  {
    "name_bg": "Левуново"
  },
  {
    "name_bg": "Леденик"
  },
  {
    "name_bg": "Лелинци"
  },
  {
    "name_bg": "Ленище"
  },
  {
    "name_bg": "Ленково"
  },
  {
    "name_bg": "Леново"
  },
  {
    "name_bg": "Ленско"
  },
  {
    "name_bg": "Лепица"
  },
  {
    "name_bg": "Лесидрен"
  },
  {
    "name_bg": "Лесичарка"
  },
  {
    "name_bg": "Лесиче"
  },
  {
    "name_bg": "Лесичери"
  },
  {
    "name_bg": "Лесичово"
  },
  {
    "name_bg": "Леска"
  },
  {
    "name_bg": "Лесковдол"
  },
  {
    "name_bg": "Лесковец"
  },
  {
    "name_bg": "Лясково"
  },
  {
    "name_bg": "Лесново"
  },
  {
    "name_bg": "Лесово"
  },
  {
    "name_bg": "Лесура"
  },
  {
    "name_bg": "Летница"
  },
  {
    "name_bg": "Летовник"
  },
  {
    "name_bg": "Лехово"
  },
  {
    "name_bg": "Лехчево"
  },
  {
    "name_bg": "Лешко"
  },
  {
    "name_bg": "Лешниково"
  },
  {
    "name_bg": "Лешниковци"
  },
  {
    "name_bg": "Лешница"
  },
  {
    "name_bg": "Лещак"
  },
  {
    "name_bg": "Лещарка"
  },
  {
    "name_bg": "Лещен"
  },
  {
    "name_bg": "Леярово"
  },
  {
    "name_bg": "Ливада"
  },
  {
    "name_bg": "Ливаде"
  },
  {
    "name_bg": "Лик"
  },
  {
    "name_bg": "Лилеково"
  },
  {
    "name_bg": "Лилково"
  },
  {
    "name_bg": "Лиляк"
  },
  {
    "name_bg": "Лиляново"
  },
  {
    "name_bg": "Лиляч"
  },
  {
    "name_bg": "Лиляче"
  },
  {
    "name_bg": "Лимец"
  },
  {
    "name_bg": "Липен"
  },
  {
    "name_bg": "Липец"
  },
  {
    "name_bg": "Липинци"
  },
  {
    "name_bg": "Липник"
  },
  {
    "name_bg": "Липница"
  },
  {
    "name_bg": "Лисец"
  },
  {
    "name_bg": "Лиси връх"
  },
  {
    "name_bg": "Лисиците"
  },
  {
    "name_bg": "Лисия"
  },
  {
    "name_bg": "Лисово"
  },
  {
    "name_bg": "Листец"
  },
  {
    "name_bg": "Литаково"
  },
  {
    "name_bg": "Лобош"
  },
  {
    "name_bg": "Ловец"
  },
  {
    "name_bg": "Ловеч"
  },
  {
    "name_bg": "Ловнидол"
  },
  {
    "name_bg": "Ловци"
  },
  {
    "name_bg": "Ловчанци"
  },
  {
    "name_bg": "Лоза"
  },
  {
    "name_bg": "Лозарево"
  },
  {
    "name_bg": "Лозево"
  },
  {
    "name_bg": "Лозен"
  },
  {
    "name_bg": "Лозенградци"
  },
  {
    "name_bg": "Лозенец"
  },
  {
    "name_bg": "Лозеница"
  },
  {
    "name_bg": "Лозица"
  },
  {
    "name_bg": "Лозница"
  },
  {
    "name_bg": "Лозно"
  },
  {
    "name_bg": "Локвата"
  },
  {
    "name_bg": "Локорско"
  },
  {
    "name_bg": "Лом"
  },
  {
    "name_bg": "Ломец"
  },
  {
    "name_bg": "Ломница"
  },
  {
    "name_bg": "Ломци"
  },
  {
    "name_bg": "Лопушна"
  },
  {
    "name_bg": "Лопушня"
  },
  {
    "name_bg": "Лопян"
  },
  {
    "name_bg": "Луковит"
  },
  {
    "name_bg": "Луково"
  },
  {
    "name_bg": "Луличка"
  },
  {
    "name_bg": "Лъвино"
  },
  {
    "name_bg": "Лъвово"
  },
  {
    "name_bg": "Лъга"
  },
  {
    "name_bg": "Баните"
  },
  {
    "name_bg": "Лъжница"
  },
  {
    "name_bg": "Лъка"
  },
  {
    "name_bg": "Лъкавица"
  },
  {
    "name_bg": "Лъки"
  },
  {
    "name_bg": "Любен"
  },
  {
    "name_bg": "Любенец"
  },
  {
    "name_bg": "Любен Каравелово"
  },
  {
    "name_bg": "Любенова махала"
  },
  {
    "name_bg": "Любеново"
  },
  {
    "name_bg": "Любенци"
  },
  {
    "name_bg": "Любимец"
  },
  {
    "name_bg": "Любино"
  },
  {
    "name_bg": "Любичево"
  },
  {
    "name_bg": "Люблен"
  },
  {
    "name_bg": "Любница"
  },
  {
    "name_bg": "Любовище"
  },
  {
    "name_bg": "Любовка"
  },
  {
    "name_bg": "Любча"
  },
  {
    "name_bg": "Люлин"
  },
  {
    "name_bg": "Люлка"
  },
  {
    "name_bg": "Люляк"
  },
  {
    "name_bg": "Люляково"
  },
  {
    "name_bg": "Лютаджик"
  },
  {
    "name_bg": "Лютиброд"
  },
  {
    "name_bg": "Лютидол"
  },
  {
    "name_bg": "Лютово"
  },
  {
    "name_bg": "Лява река"
  },
  {
    "name_bg": "Лялинци"
  },
  {
    "name_bg": "Лясковец"
  },
  {
    "name_bg": "Лятно"
  },
  {
    "name_bg": "Ляхово"
  },
  {
    "name_bg": "Левци"
  },
  {
    "name_bg": "Левище"
  },
  {
    "name_bg": "Ловско"
  },
  {
    "name_bg": "Магарджица"
  },
  {
    "name_bg": "Мадан"
  },
  {
    "name_bg": "Мадара"
  },
  {
    "name_bg": "Маджаре"
  },
  {
    "name_bg": "Маджари"
  },
  {
    "name_bg": "Маджарово"
  },
  {
    "name_bg": "Маджерито"
  },
  {
    "name_bg": "Мазарачево"
  },
  {
    "name_bg": "Майор Узуново"
  },
  {
    "name_bg": "Майско"
  },
  {
    "name_bg": "Майсторово"
  },
  {
    "name_bg": "Мак"
  },
  {
    "name_bg": "Макариополско"
  },
  {
    "name_bg": "Македонци"
  },
  {
    "name_bg": "Маково"
  },
  {
    "name_bg": "Макоцево"
  },
  {
    "name_bg": "Макреш"
  },
  {
    "name_bg": "Мала Раковица"
  },
  {
    "name_bg": "Мала Фуча"
  },
  {
    "name_bg": "Мала църква"
  },
  {
    "name_bg": "Малево"
  },
  {
    "name_bg": "Маленово"
  },
  {
    "name_bg": "Мали Върбовник"
  },
  {
    "name_bg": "Мали Дреновец"
  },
  {
    "name_bg": "Мали извор"
  },
  {
    "name_bg": "Малина"
  },
  {
    "name_bg": "Малини"
  },
  {
    "name_bg": "Малиново"
  },
  {
    "name_bg": "Малка Арда"
  },
  {
    "name_bg": "Малка Верея"
  },
  {
    "name_bg": "Малка Желязна"
  },
  {
    "name_bg": "Малка поляна"
  },
  {
    "name_bg": "Малка Смолница"
  },
  {
    "name_bg": "Малка Черковна"
  },
  {
    "name_bg": "Малка Чинка"
  },
  {
    "name_bg": "Малки Българени"
  },
  {
    "name_bg": "Малки Воден"
  },
  {
    "name_bg": "Малки Вършец"
  },
  {
    "name_bg": "Малки Станчовци"
  },
  {
    "name_bg": "Малки Цалим"
  },
  {
    "name_bg": "Малки чифлик"
  },
  {
    "name_bg": "Малко Асеново"
  },
  {
    "name_bg": "Малко Брягово"
  },
  {
    "name_bg": "Малко Враново"
  },
  {
    "name_bg": "Малко градище"
  },
  {
    "name_bg": "Малко Дряново"
  },
  {
    "name_bg": "Малко Кадиево"
  },
  {
    "name_bg": "Малко Каменяне"
  },
  {
    "name_bg": "Малко Кирилово"
  },
  {
    "name_bg": "Малко Крушево"
  },
  {
    "name_bg": "Малко Попово"
  },
  {
    "name_bg": "Малко село"
  },
  {
    "name_bg": "Малко Тръново"
  },
  {
    "name_bg": "Малко Търново"
  },
  {
    "name_bg": "Малкоч"
  },
  {
    "name_bg": "Малко Чочовени"
  },
  {
    "name_bg": "Малко Шарково"
  },
  {
    "name_bg": "Мало Бучино"
  },
  {
    "name_bg": "Малоградец"
  },
  {
    "name_bg": "Мало Конаре"
  },
  {
    "name_bg": "Мало Крушево"
  },
  {
    "name_bg": "Мало Малово"
  },
  {
    "name_bg": "Маломир"
  },
  {
    "name_bg": "Маломирово"
  },
  {
    "name_bg": "Мало Пещене"
  },
  {
    "name_bg": "Малорад"
  },
  {
    "name_bg": "Мало село"
  },
  {
    "name_bg": "Малчика"
  },
  {
    "name_bg": "Малчовци"
  },
  {
    "name_bg": "Малък Девесил"
  },
  {
    "name_bg": "Малък извор"
  },
  {
    "name_bg": "Малък манастир"
  },
  {
    "name_bg": "Малък Поровец"
  },
  {
    "name_bg": "Малък Преславец"
  },
  {
    "name_bg": "Припек"
  },
  {
    "name_bg": "Малък чардак"
  },
  {
    "name_bg": "Мамарчево"
  },
  {
    "name_bg": "Манаселска река"
  },
  {
    "name_bg": "Манастир"
  },
  {
    "name_bg": "Манастирица"
  },
  {
    "name_bg": "Манастирище"
  },
  {
    "name_bg": "Манастирско"
  },
  {
    "name_bg": "Манастирци"
  },
  {
    "name_bg": "Мандра"
  },
  {
    "name_bg": "Мандрица"
  },
  {
    "name_bg": "Маневци"
  },
  {
    "name_bg": "Маноле"
  },
  {
    "name_bg": "Манолич"
  },
  {
    "name_bg": "Манолово"
  },
  {
    "name_bg": "Манолско Конаре"
  },
  {
    "name_bg": "Маноя"
  },
  {
    "name_bg": "Манушевци"
  },
  {
    "name_bg": "Манчево"
  },
  {
    "name_bg": "Марафелци"
  },
  {
    "name_bg": "Мараш"
  },
  {
    "name_bg": "Марговци"
  },
  {
    "name_bg": "Марикостиново"
  },
  {
    "name_bg": "Маринка"
  },
  {
    "name_bg": "Мариновци"
  },
  {
    "name_bg": "Марино поле"
  },
  {
    "name_bg": "Марица"
  },
  {
    "name_bg": "Симеоновград"
  },
  {
    "name_bg": "Марково"
  },
  {
    "name_bg": "Марково равнище"
  },
  {
    "name_bg": "Мартен"
  },
  {
    "name_bg": "Мартино"
  },
  {
    "name_bg": "Мартиново"
  },
  {
    "name_bg": "Марулево"
  },
  {
    "name_bg": "Маруцековци"
  },
  {
    "name_bg": "Марчево"
  },
  {
    "name_bg": "Марчино"
  },
  {
    "name_bg": "Марян"
  },
  {
    "name_bg": "Масларево"
  },
  {
    "name_bg": "Маслиново"
  },
  {
    "name_bg": "Матешовци"
  },
  {
    "name_bg": "Маточина"
  },
  {
    "name_bg": "Махалата"
  },
  {
    "name_bg": "Махалници"
  },
  {
    "name_bg": "Маца"
  },
  {
    "name_bg": "Медвен"
  },
  {
    "name_bg": "Медевци"
  },
  {
    "name_bg": "Меден бук"
  },
  {
    "name_bg": "Медени поляни"
  },
  {
    "name_bg": "Меден кладенец"
  },
  {
    "name_bg": "Медешевци"
  },
  {
    "name_bg": "Медковец"
  },
  {
    "name_bg": "Медникарово"
  },
  {
    "name_bg": "Медовене"
  },
  {
    "name_bg": "Медовец"
  },
  {
    "name_bg": "Медовина"
  },
  {
    "name_bg": "Медовница"
  },
  {
    "name_bg": "Медово"
  },
  {
    "name_bg": "Межда"
  },
  {
    "name_bg": "Межден"
  },
  {
    "name_bg": "Междени"
  },
  {
    "name_bg": "Мездра"
  },
  {
    "name_bg": "Мездрея"
  },
  {
    "name_bg": "Мезек"
  },
  {
    "name_bg": "Мелник"
  },
  {
    "name_bg": "Мелница"
  },
  {
    "name_bg": "Меляне"
  },
  {
    "name_bg": "Менгишево"
  },
  {
    "name_bg": "Мендово"
  },
  {
    "name_bg": "Теменуга"
  },
  {
    "name_bg": "Мененкьово"
  },
  {
    "name_bg": "Мерданя"
  },
  {
    "name_bg": "Меричлери"
  },
  {
    "name_bg": "Места"
  },
  {
    "name_bg": "Метлика"
  },
  {
    "name_bg": "Метличина"
  },
  {
    "name_bg": "Метличка"
  },
  {
    "name_bg": "Методиево"
  },
  {
    "name_bg": "Метохия"
  },
  {
    "name_bg": "Мечка"
  },
  {
    "name_bg": "Мечкарево"
  },
  {
    "name_bg": "Мечковица"
  },
  {
    "name_bg": "Мечковци"
  },
  {
    "name_bg": "Мечкул"
  },
  {
    "name_bg": "Мечово"
  },
  {
    "name_bg": "Мещица"
  },
  {
    "name_bg": "Мизия"
  },
  {
    "name_bg": "Мийковци"
  },
  {
    "name_bg": "Микре"
  },
  {
    "name_bg": "Миладиново"
  },
  {
    "name_bg": "Миладиновци"
  },
  {
    "name_bg": "Миланово"
  },
  {
    "name_bg": "Миле"
  },
  {
    "name_bg": "Милево"
  },
  {
    "name_bg": "Милевци"
  },
  {
    "name_bg": "Милино"
  },
  {
    "name_bg": "Милковица"
  },
  {
    "name_bg": "Милково"
  },
  {
    "name_bg": "Милковци"
  },
  {
    "name_bg": "Милкьовци"
  },
  {
    "name_bg": "Милославци"
  },
  {
    "name_bg": "Милчина лъка"
  },
  {
    "name_bg": "Миндя"
  },
  {
    "name_bg": "Миневци"
  },
  {
    "name_bg": "Минерални бани"
  },
  {
    "name_bg": "Минзухар"
  },
  {
    "name_bg": "Мирково"
  },
  {
    "name_bg": "Мировец"
  },
  {
    "name_bg": "Мирово"
  },
  {
    "name_bg": "Вратца"
  },
  {
    "name_bg": "Мировци"
  },
  {
    "name_bg": "Мировяне"
  },
  {
    "name_bg": "Миролюбово"
  },
  {
    "name_bg": "Мирчовци"
  },
  {
    "name_bg": "Мирянци"
  },
  {
    "name_bg": "Митино"
  },
  {
    "name_bg": "Митовска"
  },
  {
    "name_bg": "Митровци"
  },
  {
    "name_bg": "Монтана"
  },
  {
    "name_bg": "Михайлово"
  },
  {
    "name_bg": "Михайловци"
  },
  {
    "name_bg": "Михалич"
  },
  {
    "name_bg": "Михалково"
  },
  {
    "name_bg": "Михалци"
  },
  {
    "name_bg": "Михилци"
  },
  {
    "name_bg": "Михнево"
  },
  {
    "name_bg": "Миховци"
  },
  {
    "name_bg": "Мичковци"
  },
  {
    "name_bg": "Царево"
  },
  {
    "name_bg": "Мишевско"
  },
  {
    "name_bg": "Мишеморков хан"
  },
  {
    "name_bg": "Млада гвардия"
  },
  {
    "name_bg": "Младежко"
  },
  {
    "name_bg": "Младен"
  },
  {
    "name_bg": "Младиново"
  },
  {
    "name_bg": "Младово"
  },
  {
    "name_bg": "Мламолово"
  },
  {
    "name_bg": "Млекарево"
  },
  {
    "name_bg": "Млечево"
  },
  {
    "name_bg": "Млечино"
  },
  {
    "name_bg": "Могила"
  },
  {
    "name_bg": "Могилец"
  },
  {
    "name_bg": "Могилите"
  },
  {
    "name_bg": "Могилица"
  },
  {
    "name_bg": "Могилище"
  },
  {
    "name_bg": "Могилово"
  },
  {
    "name_bg": "Могиляне"
  },
  {
    "name_bg": "Мокреш"
  },
  {
    "name_bg": "Мокрище"
  },
  {
    "name_bg": "Момина клисура"
  },
  {
    "name_bg": "Момина сълза"
  },
  {
    "name_bg": "Момино"
  },
  {
    "name_bg": "Момино село"
  },
  {
    "name_bg": "Момин сбор"
  },
  {
    "name_bg": "Моминско"
  },
  {
    "name_bg": "Момково"
  },
  {
    "name_bg": "Царичино"
  },
  {
    "name_bg": "Момчилград"
  },
  {
    "name_bg": "Момчилово"
  },
  {
    "name_bg": "Момчиловци"
  },
  {
    "name_bg": "Морава"
  },
  {
    "name_bg": "Моравица"
  },
  {
    "name_bg": "Моравка"
  },
  {
    "name_bg": "Моровеците"
  },
  {
    "name_bg": "Горно Черковище"
  },
  {
    "name_bg": "Мортагоново"
  },
  {
    "name_bg": "Морянци"
  },
  {
    "name_bg": "Московец"
  },
  {
    "name_bg": "Мост"
  },
  {
    "name_bg": "Мостич"
  },
  {
    "name_bg": "Мостово"
  },
  {
    "name_bg": "Мочуре"
  },
  {
    "name_bg": "Мощанец"
  },
  {
    "name_bg": "Мракетинци"
  },
  {
    "name_bg": "Мрамор"
  },
  {
    "name_bg": "Мраморен"
  },
  {
    "name_bg": "Мрахори"
  },
  {
    "name_bg": "Мраченик"
  },
  {
    "name_bg": "Мрежичко"
  },
  {
    "name_bg": "Мръзеци"
  },
  {
    "name_bg": "Мугла"
  },
  {
    "name_bg": "Музга"
  },
  {
    "name_bg": "Мулдава"
  },
  {
    "name_bg": "Рупите"
  },
  {
    "name_bg": "Мурга"
  },
  {
    "name_bg": "Мургаш"
  },
  {
    "name_bg": "Мургово"
  },
  {
    "name_bg": "Мурено"
  },
  {
    "name_bg": "Мурсалево"
  },
  {
    "name_bg": "Муртинци"
  },
  {
    "name_bg": "Мусачево"
  },
  {
    "name_bg": "Мусево"
  },
  {
    "name_bg": "Муселиево"
  },
  {
    "name_bg": "Мусина"
  },
  {
    "name_bg": "Мусомища"
  },
  {
    "name_bg": "Мустрак"
  },
  {
    "name_bg": "Мухово"
  },
  {
    "name_bg": "Муця"
  },
  {
    "name_bg": "Мъглен"
  },
  {
    "name_bg": "Мъглене"
  },
  {
    "name_bg": "Мъглиж"
  },
  {
    "name_bg": "Мъглища"
  },
  {
    "name_bg": "Мъдрево"
  },
  {
    "name_bg": "Мъдрец"
  },
  {
    "name_bg": "Мъдрино"
  },
  {
    "name_bg": "Мърводол"
  },
  {
    "name_bg": "Мързян"
  },
  {
    "name_bg": "Мъртвината"
  },
  {
    "name_bg": "Мърчаево"
  },
  {
    "name_bg": "Мърчево"
  },
  {
    "name_bg": "Мътеница"
  },
  {
    "name_bg": "Малки Искър"
  },
  {
    "name_bg": "Малуша"
  },
  {
    "name_bg": "Микрево"
  },
  {
    "name_bg": "Малък дол"
  },
  {
    "name_bg": "Момин проход"
  },
  {
    "name_bg": "Малко Йонково"
  },
  {
    "name_bg": "Навъсен"
  },
  {
    "name_bg": "Надарево"
  },
  {
    "name_bg": "Надарци"
  },
  {
    "name_bg": "Надежден"
  },
  {
    "name_bg": "Преображенци"
  },
  {
    "name_bg": "Найден Герово"
  },
  {
    "name_bg": "Найденово"
  },
  {
    "name_bg": "Нане"
  },
  {
    "name_bg": "Нановица"
  },
  {
    "name_bg": "Нареченски бани"
  },
  {
    "name_bg": "Насалевци"
  },
  {
    "name_bg": "Наум"
  },
  {
    "name_bg": "Научене"
  },
  {
    "name_bg": "Нацовци"
  },
  {
    "name_bg": "Начево"
  },
  {
    "name_bg": "Небеска"
  },
  {
    "name_bg": "Невша"
  },
  {
    "name_bg": "Негован"
  },
  {
    "name_bg": "Неговановци"
  },
  {
    "name_bg": "Негованци"
  },
  {
    "name_bg": "Негушево"
  },
  {
    "name_bg": "Недан"
  },
  {
    "name_bg": "Неделево"
  },
  {
    "name_bg": "Неделино"
  },
  {
    "name_bg": "Неделище"
  },
  {
    "name_bg": "Неделкова Гращица"
  },
  {
    "name_bg": "Неделково"
  },
  {
    "name_bg": "Недоклан"
  },
  {
    "name_bg": "Недялковци"
  },
  {
    "name_bg": "Недялско"
  },
  {
    "name_bg": "Нейково"
  },
  {
    "name_bg": "Нейкьовец"
  },
  {
    "name_bg": "Нейчовци"
  },
  {
    "name_bg": "Ненково"
  },
  {
    "name_bg": "Неново"
  },
  {
    "name_bg": "Неновци"
  },
  {
    "name_bg": "Неофит Бозвелиево"
  },
  {
    "name_bg": "Неофит Рилски"
  },
  {
    "name_bg": "Непразненци"
  },
  {
    "name_bg": "Несебър"
  },
  {
    "name_bg": "Несла"
  },
  {
    "name_bg": "Нефела"
  },
  {
    "name_bg": "Нешевци"
  },
  {
    "name_bg": "Нивянин"
  },
  {
    "name_bg": "Никачковци"
  },
  {
    "name_bg": "Николаевка"
  },
  {
    "name_bg": "Николаево"
  },
  {
    "name_bg": "Никола Козлево"
  },
  {
    "name_bg": "Николово"
  },
  {
    "name_bg": "Николовци"
  },
  {
    "name_bg": "Николчовци"
  },
  {
    "name_bg": "Никопол"
  },
  {
    "name_bg": "Никудин"
  },
  {
    "name_bg": "Никюп"
  },
  {
    "name_bg": "Нисово"
  },
  {
    "name_bg": "Ничовци"
  },
  {
    "name_bg": "Нова бяла река"
  },
  {
    "name_bg": "Нова Върбовка"
  },
  {
    "name_bg": "Нова Загора"
  },
  {
    "name_bg": "Нова Камена"
  },
  {
    "name_bg": "Новаково"
  },
  {
    "name_bg": "Новаковци"
  },
  {
    "name_bg": "Нова ливада"
  },
  {
    "name_bg": "Нова Ловча"
  },
  {
    "name_bg": "Нова махала"
  },
  {
    "name_bg": "Нова Надежда"
  },
  {
    "name_bg": "Нова Попина"
  },
  {
    "name_bg": "Новачево"
  },
  {
    "name_bg": "Новачене"
  },
  {
    "name_bg": "Нова Черна"
  },
  {
    "name_bg": "Нова Шипка"
  },
  {
    "name_bg": "Новград"
  },
  {
    "name_bg": "Стамболийски"
  },
  {
    "name_bg": "Нови пазар"
  },
  {
    "name_bg": "Нови хан"
  },
  {
    "name_bg": "Нови чифлик"
  },
  {
    "name_bg": "Ново Ботево"
  },
  {
    "name_bg": "Ново бърдо"
  },
  {
    "name_bg": "Новогорци"
  },
  {
    "name_bg": "Ново градище"
  },
  {
    "name_bg": "Ново Делчево"
  },
  {
    "name_bg": "Ново Железаре"
  },
  {
    "name_bg": "Ново Кономлади"
  },
  {
    "name_bg": "Ново Лески"
  },
  {
    "name_bg": "Ново Оряхово"
  },
  {
    "name_bg": "Ново Паничарево"
  },
  {
    "name_bg": "Новосел"
  },
  {
    "name_bg": "Новоселец"
  },
  {
    "name_bg": "Новоселище"
  },
  {
    "name_bg": "Ново село"
  },
  {
    "name_bg": "Новоселци"
  },
  {
    "name_bg": "Новоселяне"
  },
  {
    "name_bg": "Ново Ходжово"
  },
  {
    "name_bg": "Ново Янково"
  },
  {
    "name_bg": "Ноевци"
  },
  {
    "name_bg": "Ножарево"
  },
  {
    "name_bg": "Ножарово"
  },
  {
    "name_bg": "Ножерите"
  },
  {
    "name_bg": "Носеите"
  },
  {
    "name_bg": "Ночево"
  },
  {
    "name_bg": "Нюшковци"
  },
  {
    "name_bg": "Николичевци"
  },
  {
    "name_bg": "Обединение"
  },
  {
    "name_bg": "Обел"
  },
  {
    "name_bg": "Обзор"
  },
  {
    "name_bg": "Обидим"
  },
  {
    "name_bg": "Обител"
  },
  {
    "name_bg": "Обичник"
  },
  {
    "name_bg": "Обнова"
  },
  {
    "name_bg": "Оборище"
  },
  {
    "name_bg": "Обретеник"
  },
  {
    "name_bg": "Оброчище"
  },
  {
    "name_bg": "Обручище"
  },
  {
    "name_bg": "Овен"
  },
  {
    "name_bg": "Овощарци"
  },
  {
    "name_bg": "Овощна"
  },
  {
    "name_bg": "Овощник"
  },
  {
    "name_bg": "Овчага"
  },
  {
    "name_bg": "Овча могила"
  },
  {
    "name_bg": "Овчари"
  },
  {
    "name_bg": "Овчарово"
  },
  {
    "name_bg": "Овчарци"
  },
  {
    "name_bg": "Овчево"
  },
  {
    "name_bg": "Овчеполци"
  },
  {
    "name_bg": "Овчи кладенец"
  },
  {
    "name_bg": "Оглед"
  },
  {
    "name_bg": "Огнен"
  },
  {
    "name_bg": "Огняново"
  },
  {
    "name_bg": "Огоя"
  },
  {
    "name_bg": "Оградна"
  },
  {
    "name_bg": "Огражден"
  },
  {
    "name_bg": "Одоровци"
  },
  {
    "name_bg": "Одраница"
  },
  {
    "name_bg": "Одринци"
  },
  {
    "name_bg": "Одърне"
  },
  {
    "name_bg": "Одърци"
  },
  {
    "name_bg": "Озърновци"
  },
  {
    "name_bg": "Околиите"
  },
  {
    "name_bg": "Окоп"
  },
  {
    "name_bg": "Окорш"
  },
  {
    "name_bg": "Оман"
  },
  {
    "name_bg": "Омарчево"
  },
  {
    "name_bg": "Омуртаг"
  },
  {
    "name_bg": "Оногур"
  },
  {
    "name_bg": "Опака"
  },
  {
    "name_bg": "Опан"
  },
  {
    "name_bg": "Опанец"
  },
  {
    "name_bg": "Опицвет"
  },
  {
    "name_bg": "Оплетня"
  },
  {
    "name_bg": "Опълченец"
  },
  {
    "name_bg": "Опълченско"
  },
  {
    "name_bg": "Орач"
  },
  {
    "name_bg": "Ореховица"
  },
  {
    "name_bg": "Орехово"
  },
  {
    "name_bg": "Ореш"
  },
  {
    "name_bg": "Орешак"
  },
  {
    "name_bg": "Орешари"
  },
  {
    "name_bg": "Ореше"
  },
  {
    "name_bg": "Орешене"
  },
  {
    "name_bg": "Орешино"
  },
  {
    "name_bg": "Орешник"
  },
  {
    "name_bg": "Орешница"
  },
  {
    "name_bg": "Оризаре"
  },
  {
    "name_bg": "Оризари"
  },
  {
    "name_bg": "Оризово"
  },
  {
    "name_bg": "Орлица"
  },
  {
    "name_bg": "Орлова могила"
  },
  {
    "name_bg": "Орлов дол"
  },
  {
    "name_bg": "Орловец"
  },
  {
    "name_bg": "Орлово"
  },
  {
    "name_bg": "Орловци"
  },
  {
    "name_bg": "Орляк"
  },
  {
    "name_bg": "Орляне"
  },
  {
    "name_bg": "Орсоя"
  },
  {
    "name_bg": "Орлинци"
  },
  {
    "name_bg": "Орцево"
  },
  {
    "name_bg": "Оряховец"
  },
  {
    "name_bg": "Оряховица"
  },
  {
    "name_bg": "Оряхово"
  },
  {
    "name_bg": "Оселна"
  },
  {
    "name_bg": "Осен"
  },
  {
    "name_bg": "Осенарите"
  },
  {
    "name_bg": "Осенец"
  },
  {
    "name_bg": "Осеновец"
  },
  {
    "name_bg": "Осеновлаг"
  },
  {
    "name_bg": "Осеново"
  },
  {
    "name_bg": "Осетеново"
  },
  {
    "name_bg": "Осиковица"
  },
  {
    "name_bg": "Осиково"
  },
  {
    "name_bg": "Осиковска Лакавица"
  },
  {
    "name_bg": "Осина"
  },
  {
    "name_bg": "Осларка"
  },
  {
    "name_bg": "Ослен Криводол"
  },
  {
    "name_bg": "Рибарци"
  },
  {
    "name_bg": "Осмар"
  },
  {
    "name_bg": "Осоица"
  },
  {
    "name_bg": "Остра могила"
  },
  {
    "name_bg": "Острец"
  },
  {
    "name_bg": "Остри пазлак"
  },
  {
    "name_bg": "Острица"
  },
  {
    "name_bg": "Остров"
  },
  {
    "name_bg": "Островец"
  },
  {
    "name_bg": "Островица"
  },
  {
    "name_bg": "Острово"
  },
  {
    "name_bg": "Островче"
  },
  {
    "name_bg": "Острокапци"
  },
  {
    "name_bg": "Остър камък"
  },
  {
    "name_bg": "Отец Кирилово"
  },
  {
    "name_bg": "Отец Паисиево"
  },
  {
    "name_bg": "Охлювец"
  },
  {
    "name_bg": "Оходен"
  },
  {
    "name_bg": "Охрид"
  },
  {
    "name_bg": "Очиндол"
  },
  {
    "name_bg": "Ошане"
  },
  {
    "name_bg": "Ошаните"
  },
  {
    "name_bg": "Ощава"
  },
  {
    "name_bg": "Очуша"
  },
  {
    "name_bg": "Орех"
  },
  {
    "name_bg": "Павел"
  },
  {
    "name_bg": "Павел баня"
  },
  {
    "name_bg": "Павелско"
  },
  {
    "name_bg": "Павлевци"
  },
  {
    "name_bg": "Павликени"
  },
  {
    "name_bg": "Паволче"
  },
  {
    "name_bg": "Падала"
  },
  {
    "name_bg": "Падало"
  },
  {
    "name_bg": "Падеш"
  },
  {
    "name_bg": "Падина"
  },
  {
    "name_bg": "Падине"
  },
  {
    "name_bg": "Пазарджик"
  },
  {
    "name_bg": "Пазарци"
  },
  {
    "name_bg": "Паисиево"
  },
  {
    "name_bg": "Паисий"
  },
  {
    "name_bg": "Пайдушко"
  },
  {
    "name_bg": "Паламарца"
  },
  {
    "name_bg": "Палат"
  },
  {
    "name_bg": "Палатово"
  },
  {
    "name_bg": "Палаузово"
  },
  {
    "name_bg": "Палилула"
  },
  {
    "name_bg": "Палици"
  },
  {
    "name_bg": "Памидово"
  },
  {
    "name_bg": "Памукчии"
  },
  {
    "name_bg": "Панагюрище"
  },
  {
    "name_bg": "Панайот Волово"
  },
  {
    "name_bg": "Панайот Хитово"
  },
  {
    "name_bg": "Панаретовци"
  },
  {
    "name_bg": "Паницово"
  },
  {
    "name_bg": "Паничарево"
  },
  {
    "name_bg": "Паничери"
  },
  {
    "name_bg": "Паничино"
  },
  {
    "name_bg": "Паничково"
  },
  {
    "name_bg": "Пановци"
  },
  {
    "name_bg": "Панчарево"
  },
  {
    "name_bg": "Панчево"
  },
  {
    "name_bg": "Папрат"
  },
  {
    "name_bg": "Папратлива"
  },
  {
    "name_bg": "Парамун"
  },
  {
    "name_bg": "Парил"
  },
  {
    "name_bg": "Партизани"
  },
  {
    "name_bg": "Партизанин"
  },
  {
    "name_bg": "Парчовци"
  },
  {
    "name_bg": "Паскалевец"
  },
  {
    "name_bg": "Паскалево"
  },
  {
    "name_bg": "Паспал"
  },
  {
    "name_bg": "Пастра"
  },
  {
    "name_bg": "Пастух"
  },
  {
    "name_bg": "Паталеница"
  },
  {
    "name_bg": "Патица"
  },
  {
    "name_bg": "Патреш"
  },
  {
    "name_bg": "Патрешко"
  },
  {
    "name_bg": "Патриарх Евтимово"
  },
  {
    "name_bg": "Пауново"
  },
  {
    "name_bg": "Пашинци"
  },
  {
    "name_bg": "Пашкул"
  },
  {
    "name_bg": "Пашови"
  },
  {
    "name_bg": "Пашово"
  },
  {
    "name_bg": "Певец"
  },
  {
    "name_bg": "Певците"
  },
  {
    "name_bg": "Пейковци"
  },
  {
    "name_bg": "Пейна"
  },
  {
    "name_bg": "Пейовци"
  },
  {
    "name_bg": "Пейчиново"
  },
  {
    "name_bg": "Пелатиково"
  },
  {
    "name_bg": "Плевун"
  },
  {
    "name_bg": "Пелин"
  },
  {
    "name_bg": "Пелишат"
  },
  {
    "name_bg": "Пенковци"
  },
  {
    "name_bg": "Пенкьовци"
  },
  {
    "name_bg": "Пеньово"
  },
  {
    "name_bg": "Пепелина"
  },
  {
    "name_bg": "Пепелище"
  },
  {
    "name_bg": "Периловец"
  },
  {
    "name_bg": "Перник"
  },
  {
    "name_bg": "Перперек"
  },
  {
    "name_bg": "Перуника"
  },
  {
    "name_bg": "Перущица"
  },
  {
    "name_bg": "Песнопой"
  },
  {
    "name_bg": "Песочница"
  },
  {
    "name_bg": "Петелово"
  },
  {
    "name_bg": "Пет кладенци"
  },
  {
    "name_bg": "Петково"
  },
  {
    "name_bg": "Петковци"
  },
  {
    "name_bg": "Петко Славейков"
  },
  {
    "name_bg": "Петлешково"
  },
  {
    "name_bg": "Петлино"
  },
  {
    "name_bg": "Пет могили"
  },
  {
    "name_bg": "Петокладенци"
  },
  {
    "name_bg": "Петревене"
  },
  {
    "name_bg": "Петрелик"
  },
  {
    "name_bg": "Петрино"
  },
  {
    "name_bg": "Петрич"
  },
  {
    "name_bg": "Петров дол"
  },
  {
    "name_bg": "Петрово"
  },
  {
    "name_bg": "Петровци"
  },
  {
    "name_bg": "Петърница"
  },
  {
    "name_bg": "Петърч"
  },
  {
    "name_bg": "Пецовци"
  },
  {
    "name_bg": "Печеница"
  },
  {
    "name_bg": "Печинска"
  },
  {
    "name_bg": "Пешаково"
  },
  {
    "name_bg": "Пещера"
  },
  {
    "name_bg": "Пещерна"
  },
  {
    "name_bg": "Пещерско"
  },
  {
    "name_bg": "Пиперево"
  },
  {
    "name_bg": "Пиперица"
  },
  {
    "name_bg": "Пиперково"
  },
  {
    "name_bg": "Пиперков чифлик"
  },
  {
    "name_bg": "Пирамидата"
  },
  {
    "name_bg": "Пиргово"
  },
  {
    "name_bg": "Пирдоп"
  },
  {
    "name_bg": "Пирин"
  },
  {
    "name_bg": "Пиринец"
  },
  {
    "name_bg": "Пирне"
  },
  {
    "name_bg": "Писанец"
  },
  {
    "name_bg": "Писаница"
  },
  {
    "name_bg": "Писарево"
  },
  {
    "name_bg": "Писарово"
  },
  {
    "name_bg": "Писменово"
  },
  {
    "name_bg": "Питово"
  },
  {
    "name_bg": "Пишурка"
  },
  {
    "name_bg": "Пищане"
  },
  {
    "name_bg": "Пищигово"
  },
  {
    "name_bg": "Пиявец"
  },
  {
    "name_bg": "Плазище"
  },
  {
    "name_bg": "Плаково"
  },
  {
    "name_bg": "Плакудер"
  },
  {
    "name_bg": "Плана"
  },
  {
    "name_bg": "Планинец"
  },
  {
    "name_bg": "Планиница"
  },
  {
    "name_bg": "Планиново"
  },
  {
    "name_bg": "Планинци"
  },
  {
    "name_bg": "Плачидол"
  },
  {
    "name_bg": "Плачка"
  },
  {
    "name_bg": "Плачковци"
  },
  {
    "name_bg": "Плевен"
  },
  {
    "name_bg": "Пленимир"
  },
  {
    "name_bg": "Плетена"
  },
  {
    "name_bg": "Плешивец"
  },
  {
    "name_bg": "Плешинци"
  },
  {
    "name_bg": "Плиска"
  },
  {
    "name_bg": "Пловдив"
  },
  {
    "name_bg": "Пловдивци"
  },
  {
    "name_bg": "Пловка"
  },
  {
    "name_bg": "Плодовитово"
  },
  {
    "name_bg": "Плоска могила"
  },
  {
    "name_bg": "Плоски"
  },
  {
    "name_bg": "Плъзище"
  },
  {
    "name_bg": "Плъстина"
  },
  {
    "name_bg": "Побит камък"
  },
  {
    "name_bg": "Побък"
  },
  {
    "name_bg": "Повалиръж"
  },
  {
    "name_bg": "Повет"
  },
  {
    "name_bg": "Подайва"
  },
  {
    "name_bg": "Подвис"
  },
  {
    "name_bg": "Подвръх"
  },
  {
    "name_bg": "Подгоре"
  },
  {
    "name_bg": "Подгорие"
  },
  {
    "name_bg": "Подгорица"
  },
  {
    "name_bg": "Подгумер"
  },
  {
    "name_bg": "Подем"
  },
  {
    "name_bg": "Подкова"
  },
  {
    "name_bg": "Подкрепа"
  },
  {
    "name_bg": "Подлес"
  },
  {
    "name_bg": "Подрумче"
  },
  {
    "name_bg": "Подслон"
  },
  {
    "name_bg": "Пожарево"
  },
  {
    "name_bg": "Пожерник"
  },
  {
    "name_bg": "Поибрене"
  },
  {
    "name_bg": "Покрайна"
  },
  {
    "name_bg": "Покрован"
  },
  {
    "name_bg": "Покровник"
  },
  {
    "name_bg": "Полена"
  },
  {
    "name_bg": "Поленица"
  },
  {
    "name_bg": "Полетинци"
  },
  {
    "name_bg": "Полетковци"
  },
  {
    "name_bg": "Полето"
  },
  {
    "name_bg": "Поликраище"
  },
  {
    "name_bg": "Полковник Дяково"
  },
  {
    "name_bg": "Полковник Желязово"
  },
  {
    "name_bg": "Полковник Ламбриново"
  },
  {
    "name_bg": "Полковник Савово"
  },
  {
    "name_bg": "Полковник Свещарово"
  },
  {
    "name_bg": "Полковник Серафимово"
  },
  {
    "name_bg": "Полковник Таслаково"
  },
  {
    "name_bg": "Полковник Чолаково"
  },
  {
    "name_bg": "Полска Скакавица"
  },
  {
    "name_bg": "Полски Градец"
  },
  {
    "name_bg": "Полски извор"
  },
  {
    "name_bg": "Полски Сеновец"
  },
  {
    "name_bg": "Полски Тръмбеш"
  },
  {
    "name_bg": "Полско Косово"
  },
  {
    "name_bg": "Полско Пъдарево"
  },
  {
    "name_bg": "Поляна"
  },
  {
    "name_bg": "Полянец"
  },
  {
    "name_bg": "Поляново"
  },
  {
    "name_bg": "Полянци"
  },
  {
    "name_bg": "Поляците"
  },
  {
    "name_bg": "Помеждин"
  },
  {
    "name_bg": "Помен"
  },
  {
    "name_bg": "Поморие"
  },
  {
    "name_bg": "Помощица"
  },
  {
    "name_bg": "Помощник"
  },
  {
    "name_bg": "Понор"
  },
  {
    "name_bg": "Попари"
  },
  {
    "name_bg": "Попгергевци"
  },
  {
    "name_bg": "Попгригорово"
  },
  {
    "name_bg": "Попгруево"
  },
  {
    "name_bg": "Попина"
  },
  {
    "name_bg": "Попинци"
  },
  {
    "name_bg": "Попица"
  },
  {
    "name_bg": "Попкралево"
  },
  {
    "name_bg": "Поповец"
  },
  {
    "name_bg": "Поповица"
  },
  {
    "name_bg": "Попович"
  },
  {
    "name_bg": "Попово"
  },
  {
    "name_bg": "Поповци"
  },
  {
    "name_bg": "Поповяне"
  },
  {
    "name_bg": "Попрайковци"
  },
  {
    "name_bg": "Попрелка"
  },
  {
    "name_bg": "Попрусаново"
  },
  {
    "name_bg": "Попрусевци"
  },
  {
    "name_bg": "Попска"
  },
  {
    "name_bg": "Попско"
  },
  {
    "name_bg": "Пордим"
  },
  {
    "name_bg": "Порой"
  },
  {
    "name_bg": "Поройна"
  },
  {
    "name_bg": "Поройно"
  },
  {
    "name_bg": "Пороминово"
  },
  {
    "name_bg": "Портитовци"
  },
  {
    "name_bg": "Поручик Кърджиево"
  },
  {
    "name_bg": "Поручик Чунчево"
  },
  {
    "name_bg": "Посабина"
  },
  {
    "name_bg": "Посев"
  },
  {
    "name_bg": "Постник"
  },
  {
    "name_bg": "Потока"
  },
  {
    "name_bg": "Потоп"
  },
  {
    "name_bg": "Поточарка"
  },
  {
    "name_bg": "Поточе"
  },
  {
    "name_bg": "Поточница"
  },
  {
    "name_bg": "Поцърненци"
  },
  {
    "name_bg": "Правда"
  },
  {
    "name_bg": "Правдино"
  },
  {
    "name_bg": "Правдолюб"
  },
  {
    "name_bg": "Правенци"
  },
  {
    "name_bg": "Правец"
  },
  {
    "name_bg": "Правешка Лакавица"
  },
  {
    "name_bg": "Правище"
  },
  {
    "name_bg": "Право бърдо"
  },
  {
    "name_bg": "Православ"
  },
  {
    "name_bg": "Православен"
  },
  {
    "name_bg": "Праужда"
  },
  {
    "name_bg": "Прахали"
  },
  {
    "name_bg": "Превала"
  },
  {
    "name_bg": "Преколница"
  },
  {
    "name_bg": "Прекръсте"
  },
  {
    "name_bg": "Прелез"
  },
  {
    "name_bg": "Прелом"
  },
  {
    "name_bg": "Пресека"
  },
  {
    "name_bg": "Преселенци"
  },
  {
    "name_bg": "Преселец"
  },
  {
    "name_bg": "Преселка"
  },
  {
    "name_bg": "Пресиян"
  },
  {
    "name_bg": "Велики Преслав"
  },
  {
    "name_bg": "Преславен"
  },
  {
    "name_bg": "Преславец"
  },
  {
    "name_bg": "Преславци"
  },
  {
    "name_bg": "Преспа"
  },
  {
    "name_bg": "Престой"
  },
  {
    "name_bg": "Пресяк"
  },
  {
    "name_bg": "Пресяка"
  },
  {
    "name_bg": "Прибой"
  },
  {
    "name_bg": "Прилеп"
  },
  {
    "name_bg": "Прилепци"
  },
  {
    "name_bg": "Приморско"
  },
  {
    "name_bg": "Приморци"
  },
  {
    "name_bg": "Генерал Тодоров"
  },
  {
    "name_bg": "Присад"
  },
  {
    "name_bg": "Присадец"
  },
  {
    "name_bg": "Приселци"
  },
  {
    "name_bg": "Присово"
  },
  {
    "name_bg": "Присойна"
  },
  {
    "name_bg": "Пристое"
  },
  {
    "name_bg": "Пробуда"
  },
  {
    "name_bg": "Провадия"
  },
  {
    "name_bg": "Проглед"
  },
  {
    "name_bg": "Продановци"
  },
  {
    "name_bg": "Проданча"
  },
  {
    "name_bg": "Пролаз"
  },
  {
    "name_bg": "Пролазница"
  },
  {
    "name_bg": "Пролез"
  },
  {
    "name_bg": "Пролеша"
  },
  {
    "name_bg": "Пролом"
  },
  {
    "name_bg": "Пропаст"
  },
  {
    "name_bg": "Просена"
  },
  {
    "name_bg": "Просеник"
  },
  {
    "name_bg": "Просечен"
  },
  {
    "name_bg": "Просторно"
  },
  {
    "name_bg": "Протопопинци"
  },
  {
    "name_bg": "Професор Златарски"
  },
  {
    "name_bg": "Професор Иширково"
  },
  {
    "name_bg": "Прохлада"
  },
  {
    "name_bg": "Проход"
  },
  {
    "name_bg": "Прохорово"
  },
  {
    "name_bg": "Пряпорец"
  },
  {
    "name_bg": "Птичар"
  },
  {
    "name_bg": "Птичево"
  },
  {
    "name_bg": "Пудрия"
  },
  {
    "name_bg": "Пушево"
  },
  {
    "name_bg": "Пчела"
  },
  {
    "name_bg": "Пчелари"
  },
  {
    "name_bg": "Пчеларово"
  },
  {
    "name_bg": "Пчелин"
  },
  {
    "name_bg": "Пчелина"
  },
  {
    "name_bg": "Пчелино"
  },
  {
    "name_bg": "Пчелиново"
  },
  {
    "name_bg": "Пчелище"
  },
  {
    "name_bg": "Пчелник"
  },
  {
    "name_bg": "Пчелно"
  },
  {
    "name_bg": "Пшеничево"
  },
  {
    "name_bg": "Пъдарево"
  },
  {
    "name_bg": "Пъдарино"
  },
  {
    "name_bg": "Пъдарско"
  },
  {
    "name_bg": "Пъдарци"
  },
  {
    "name_bg": "Пънчево"
  },
  {
    "name_bg": "Първан"
  },
  {
    "name_bg": "Първенец"
  },
  {
    "name_bg": "Първенци"
  },
  {
    "name_bg": "Първица"
  },
  {
    "name_bg": "Първомай"
  },
  {
    "name_bg": "Първомайци"
  },
  {
    "name_bg": "Пържиграх"
  },
  {
    "name_bg": "Пърличево"
  },
  {
    "name_bg": "Пъровци"
  },
  {
    "name_bg": "Пъртевци"
  },
  {
    "name_bg": "Пърша"
  },
  {
    "name_bg": "Пъстрен"
  },
  {
    "name_bg": "Пъстрово"
  },
  {
    "name_bg": "Пъстрогор"
  },
  {
    "name_bg": "Пъстроок"
  },
  {
    "name_bg": "Пътниково"
  },
  {
    "name_bg": "Пясъчево"
  },
  {
    "name_bg": "Планинско"
  },
  {
    "name_bg": "Поток"
  },
  {
    "name_bg": "Палатик"
  },
  {
    "name_bg": "Пресока"
  },
  {
    "name_bg": "Паничище"
  },
  {
    "name_bg": "Прогрес"
  },
  {
    "name_bg": "Панагюрски колонии"
  },
  {
    "name_bg": "Полковник Минково"
  },
  {
    "name_bg": "Пороище"
  },
  {
    "name_bg": "Рабиша"
  },
  {
    "name_bg": "Рабово"
  },
  {
    "name_bg": "Раброво"
  },
  {
    "name_bg": "Равадиново"
  },
  {
    "name_bg": "Равда"
  },
  {
    "name_bg": "Равен"
  },
  {
    "name_bg": "Равна"
  },
  {
    "name_bg": "Равна гора"
  },
  {
    "name_bg": "Равнец"
  },
  {
    "name_bg": "Равнил"
  },
  {
    "name_bg": "Равнища"
  },
  {
    "name_bg": "Равнище"
  },
  {
    "name_bg": "Равнината"
  },
  {
    "name_bg": "Равно"
  },
  {
    "name_bg": "Равново"
  },
  {
    "name_bg": "Равногор"
  },
  {
    "name_bg": "Равно нивище"
  },
  {
    "name_bg": "Равно поле"
  },
  {
    "name_bg": "Равно село"
  },
  {
    "name_bg": "Радан войвода"
  },
  {
    "name_bg": "Раданово"
  },
  {
    "name_bg": "Раданчето"
  },
  {
    "name_bg": "Радево"
  },
  {
    "name_bg": "Радевци"
  },
  {
    "name_bg": "Радецки"
  },
  {
    "name_bg": "Радибош"
  },
  {
    "name_bg": "Радиево"
  },
  {
    "name_bg": "Радилово"
  },
  {
    "name_bg": "Радинград"
  },
  {
    "name_bg": "Радино"
  },
  {
    "name_bg": "Радиново"
  },
  {
    "name_bg": "Радишево"
  },
  {
    "name_bg": "Радковци"
  },
  {
    "name_bg": "Радко Димитриево"
  },
  {
    "name_bg": "Радловци"
  },
  {
    "name_bg": "Раднево"
  },
  {
    "name_bg": "Радовене"
  },
  {
    "name_bg": "Радовец"
  },
  {
    "name_bg": "Радово"
  },
  {
    "name_bg": "Радовци"
  },
  {
    "name_bg": "Радоевци"
  },
  {
    "name_bg": "Радойново"
  },
  {
    "name_bg": "Радомир"
  },
  {
    "name_bg": "Радомирци"
  },
  {
    "name_bg": "Радотина"
  },
  {
    "name_bg": "Радуил"
  },
  {
    "name_bg": "Радуй"
  },
  {
    "name_bg": "Радуловци"
  },
  {
    "name_bg": "Радунци"
  },
  {
    "name_bg": "Раевци"
  },
  {
    "name_bg": "Разбоище"
  },
  {
    "name_bg": "Разбойна"
  },
  {
    "name_bg": "Развигорово"
  },
  {
    "name_bg": "Разград"
  },
  {
    "name_bg": "Раздел"
  },
  {
    "name_bg": "Разделна"
  },
  {
    "name_bg": "Разделци"
  },
  {
    "name_bg": "Раздол"
  },
  {
    "name_bg": "Княжевско"
  },
  {
    "name_bg": "Разлив"
  },
  {
    "name_bg": "Разлог"
  },
  {
    "name_bg": "Разсоха"
  },
  {
    "name_bg": "Райкова могила"
  },
  {
    "name_bg": "Райковци"
  },
  {
    "name_bg": "Райнино"
  },
  {
    "name_bg": "Райново"
  },
  {
    "name_bg": "Райновци"
  },
  {
    "name_bg": "Райнушковци"
  },
  {
    "name_bg": "Райово"
  },
  {
    "name_bg": "Ракево"
  },
  {
    "name_bg": "Ракиловци"
  },
  {
    "name_bg": "Ракита"
  },
  {
    "name_bg": "Ракитна"
  },
  {
    "name_bg": "Ракитница"
  },
  {
    "name_bg": "Ракитово"
  },
  {
    "name_bg": "Раклиново"
  },
  {
    "name_bg": "Раклица"
  },
  {
    "name_bg": "Раковица"
  },
  {
    "name_bg": "Раково"
  },
  {
    "name_bg": "Раковски"
  },
  {
    "name_bg": "Раковсково"
  },
  {
    "name_bg": "Ралево"
  },
  {
    "name_bg": "Ралевци"
  },
  {
    "name_bg": "Ралиновци"
  },
  {
    "name_bg": "Ралица"
  },
  {
    "name_bg": "Раличево"
  },
  {
    "name_bg": "Раненци"
  },
  {
    "name_bg": "Рани лист"
  },
  {
    "name_bg": "Рани луг"
  },
  {
    "name_bg": "Расник"
  },
  {
    "name_bg": "Расово"
  },
  {
    "name_bg": "Растник"
  },
  {
    "name_bg": "Раховци"
  },
  {
    "name_bg": "Рачевци"
  },
  {
    "name_bg": "Рачовци"
  },
  {
    "name_bg": "Рашевци"
  },
  {
    "name_bg": "Рашка Гращица"
  },
  {
    "name_bg": "Рашково"
  },
  {
    "name_bg": "Рашовите"
  },
  {
    "name_bg": "Рашовица"
  },
  {
    "name_bg": "Раювци"
  },
  {
    "name_bg": "Раяновци"
  },
  {
    "name_bg": "Раянци"
  },
  {
    "name_bg": "Ребревци"
  },
  {
    "name_bg": "Ребро"
  },
  {
    "name_bg": "Реброво"
  },
  {
    "name_bg": "Ребърково"
  },
  {
    "name_bg": "Редешковци"
  },
  {
    "name_bg": "Редина"
  },
  {
    "name_bg": "Режинци"
  },
  {
    "name_bg": "Резач"
  },
  {
    "name_bg": "Резбарци"
  },
  {
    "name_bg": "Резово"
  },
  {
    "name_bg": "Река"
  },
  {
    "name_bg": "Рекичка"
  },
  {
    "name_bg": "Рельово"
  },
  {
    "name_bg": "Репляна"
  },
  {
    "name_bg": "Реселец"
  },
  {
    "name_bg": "Ресен"
  },
  {
    "name_bg": "Ресилово"
  },
  {
    "name_bg": "Речани"
  },
  {
    "name_bg": "Речица"
  },
  {
    "name_bg": "Реяновци"
  },
  {
    "name_bg": "Рибарица"
  },
  {
    "name_bg": "Рибен"
  },
  {
    "name_bg": "Рибен дол"
  },
  {
    "name_bg": "Рибино"
  },
  {
    "name_bg": "Рибник"
  },
  {
    "name_bg": "Рибница"
  },
  {
    "name_bg": "Рибново"
  },
  {
    "name_bg": "Ридино"
  },
  {
    "name_bg": "Ридово"
  },
  {
    "name_bg": "Рила"
  },
  {
    "name_bg": "Рилски манастир"
  },
  {
    "name_bg": "Рилци"
  },
  {
    "name_bg": "Рисиманово"
  },
  {
    "name_bg": "Ритя"
  },
  {
    "name_bg": "Риш"
  },
  {
    "name_bg": "Робово"
  },
  {
    "name_bg": "Ровина"
  },
  {
    "name_bg": "Рогач"
  },
  {
    "name_bg": "Рогачево"
  },
  {
    "name_bg": "Роглец"
  },
  {
    "name_bg": "Рогозари"
  },
  {
    "name_bg": "Рогозен"
  },
  {
    "name_bg": "Рогозина"
  },
  {
    "name_bg": "Рогозиново"
  },
  {
    "name_bg": "Рогозче"
  },
  {
    "name_bg": "Рогош"
  },
  {
    "name_bg": "Родина"
  },
  {
    "name_bg": "Родопи"
  },
  {
    "name_bg": "Родопско"
  },
  {
    "name_bg": "Рожден"
  },
  {
    "name_bg": "Рожен"
  },
  {
    "name_bg": "Роза"
  },
  {
    "name_bg": "Розино"
  },
  {
    "name_bg": "Розовец"
  },
  {
    "name_bg": "Розово"
  },
  {
    "name_bg": "Роман"
  },
  {
    "name_bg": "Ропот"
  },
  {
    "name_bg": "Атия"
  },
  {
    "name_bg": "Росен"
  },
  {
    "name_bg": "Росеново"
  },
  {
    "name_bg": "Росина"
  },
  {
    "name_bg": "Росица"
  },
  {
    "name_bg": "Росно"
  },
  {
    "name_bg": "Росоман"
  },
  {
    "name_bg": "Рояк"
  },
  {
    "name_bg": "Рударци"
  },
  {
    "name_bg": "Рудина"
  },
  {
    "name_bg": "Рудник"
  },
  {
    "name_bg": "Рудозем"
  },
  {
    "name_bg": "Руевци"
  },
  {
    "name_bg": "Руен"
  },
  {
    "name_bg": "Руец"
  },
  {
    "name_bg": "Ружинци"
  },
  {
    "name_bg": "Ружица"
  },
  {
    "name_bg": "Руйно"
  },
  {
    "name_bg": "Руйчовци"
  },
  {
    "name_bg": "Руманя"
  },
  {
    "name_bg": "Румелия"
  },
  {
    "name_bg": "Румянцево"
  },
  {
    "name_bg": "Руня"
  },
  {
    "name_bg": "Рупките"
  },
  {
    "name_bg": "Рупци"
  },
  {
    "name_bg": "Рупча"
  },
  {
    "name_bg": "Русалина"
  },
  {
    "name_bg": "Русалско"
  },
  {
    "name_bg": "Русаля"
  },
  {
    "name_bg": "Русе"
  },
  {
    "name_bg": "Русиновци"
  },
  {
    "name_bg": "Руска Бела"
  },
  {
    "name_bg": "Русковци"
  },
  {
    "name_bg": "Русокастро"
  },
  {
    "name_bg": "Рустан"
  },
  {
    "name_bg": "Руховци"
  },
  {
    "name_bg": "Ручей"
  },
  {
    "name_bg": "Ръжавец"
  },
  {
    "name_bg": "Ръжана"
  },
  {
    "name_bg": "Раждавица"
  },
  {
    "name_bg": "Ръждак"
  },
  {
    "name_bg": "Ръжево"
  },
  {
    "name_bg": "Ръжево Конаре"
  },
  {
    "name_bg": "Ръжена"
  },
  {
    "name_bg": "Ръженово"
  },
  {
    "name_bg": "Ръжица"
  },
  {
    "name_bg": "Ръсово"
  },
  {
    "name_bg": "Рът"
  },
  {
    "name_bg": "Рътлина"
  },
  {
    "name_bg": "Ряхово"
  },
  {
    "name_bg": "Ряховците"
  },
  {
    "name_bg": "Рохлева"
  },
  {
    "name_bg": "Рязковци"
  },
  {
    "name_bg": "Рожденско"
  },
  {
    "name_bg": "Сава"
  },
  {
    "name_bg": "Савин"
  },
  {
    "name_bg": "Савино"
  },
  {
    "name_bg": "Савойски"
  },
  {
    "name_bg": "Садина"
  },
  {
    "name_bg": "Садовец"
  },
  {
    "name_bg": "Садовик"
  },
  {
    "name_bg": "Садовица"
  },
  {
    "name_bg": "Садово"
  },
  {
    "name_bg": "Сажденик"
  },
  {
    "name_bg": "Сакарци"
  },
  {
    "name_bg": "Саласука"
  },
  {
    "name_bg": "Салаш"
  },
  {
    "name_bg": "Салманово"
  },
  {
    "name_bg": "Самовила"
  },
  {
    "name_bg": "Самоводене"
  },
  {
    "name_bg": "Самодива"
  },
  {
    "name_bg": "Самокитка"
  },
  {
    "name_bg": "Самоков"
  },
  {
    "name_bg": "Самораново"
  },
  {
    "name_bg": "Самотино"
  },
  {
    "name_bg": "Самсиите"
  },
  {
    "name_bg": "Самуил"
  },
  {
    "name_bg": "Самуилова крепост"
  },
  {
    "name_bg": "Санадиново"
  },
  {
    "name_bg": "Сандански"
  },
  {
    "name_bg": "Сандрово"
  },
  {
    "name_bg": "Сан-Стефано"
  },
  {
    "name_bg": "Сапарева баня"
  },
  {
    "name_bg": "Сапарево"
  },
  {
    "name_bg": "Сараево"
  },
  {
    "name_bg": "Саранско"
  },
  {
    "name_bg": "Саранци"
  },
  {
    "name_bg": "Сарая"
  },
  {
    "name_bg": "Сатовча"
  },
  {
    "name_bg": "Сбор"
  },
  {
    "name_bg": "Петко Каравелово"
  },
  {
    "name_bg": "Сборино"
  },
  {
    "name_bg": "Сборище"
  },
  {
    "name_bg": "Сваленик"
  },
  {
    "name_bg": "Сватбаре"
  },
  {
    "name_bg": "Свежен"
  },
  {
    "name_bg": "Света Петка"
  },
  {
    "name_bg": "Свети Никола"
  },
  {
    "name_bg": "Светлина"
  },
  {
    "name_bg": "Светля"
  },
  {
    "name_bg": "Световрачене"
  },
  {
    "name_bg": "Светослав"
  },
  {
    "name_bg": "Светославци"
  },
  {
    "name_bg": "Светулка"
  },
  {
    "name_bg": "Свещари"
  },
  {
    "name_bg": "Свидня"
  },
  {
    "name_bg": "Свиленград"
  },
  {
    "name_bg": "Свинарски дол"
  },
  {
    "name_bg": "Свирачи"
  },
  {
    "name_bg": "Свирково"
  },
  {
    "name_bg": "Свирци"
  },
  {
    "name_bg": "Свирчово"
  },
  {
    "name_bg": "Свищов"
  },
  {
    "name_bg": "Свобода"
  },
  {
    "name_bg": "Свободен"
  },
  {
    "name_bg": "Свободиново"
  },
  {
    "name_bg": "Свободица"
  },
  {
    "name_bg": "Своге"
  },
  {
    "name_bg": "Своде"
  },
  {
    "name_bg": "Севар"
  },
  {
    "name_bg": "Севдалина"
  },
  {
    "name_bg": "Северняк"
  },
  {
    "name_bg": "Северци"
  },
  {
    "name_bg": "Севлиево"
  },
  {
    "name_bg": "Седелец"
  },
  {
    "name_bg": "Седефче"
  },
  {
    "name_bg": "Седларево"
  },
  {
    "name_bg": "Седлари"
  },
  {
    "name_bg": "Седловина"
  },
  {
    "name_bg": "Седянковци"
  },
  {
    "name_bg": "Сейдол"
  },
  {
    "name_bg": "Сейковци"
  },
  {
    "name_bg": "Сеймените"
  },
  {
    "name_bg": "Секирка"
  },
  {
    "name_bg": "Секулово"
  },
  {
    "name_bg": "Селиминово"
  },
  {
    "name_bg": "Селище"
  },
  {
    "name_bg": "Селищен дол"
  },
  {
    "name_bg": "Селска поляна"
  },
  {
    "name_bg": "Селце"
  },
  {
    "name_bg": "Селци"
  },
  {
    "name_bg": "Селча"
  },
  {
    "name_bg": "Селянин"
  },
  {
    "name_bg": "Семерджиево"
  },
  {
    "name_bg": "Семерджиите"
  },
  {
    "name_bg": "Семерци"
  },
  {
    "name_bg": "Семковци"
  },
  {
    "name_bg": "Семчиново"
  },
  {
    "name_bg": "Сенник"
  },
  {
    "name_bg": "Сеново"
  },
  {
    "name_bg": "Сеноклас"
  },
  {
    "name_bg": "Сенокос"
  },
  {
    "name_bg": "Септември"
  },
  {
    "name_bg": "Сеслав"
  },
  {
    "name_bg": "Сестримо"
  },
  {
    "name_bg": "Богородица"
  },
  {
    "name_bg": "Сестринско"
  },
  {
    "name_bg": "Сечен камък"
  },
  {
    "name_bg": "Сечище"
  },
  {
    "name_bg": "Сива река"
  },
  {
    "name_bg": "Сивино"
  },
  {
    "name_bg": "Сив кладенец"
  },
  {
    "name_bg": "Сигмен"
  },
  {
    "name_bg": "Силен"
  },
  {
    "name_bg": "Силистра"
  },
  {
    "name_bg": "Симеоновец"
  },
  {
    "name_bg": "Цар Симеоново"
  },
  {
    "name_bg": "Симеоново"
  },
  {
    "name_bg": "Симитли"
  },
  {
    "name_bg": "Синаговци"
  },
  {
    "name_bg": "Синапово"
  },
  {
    "name_bg": "Синдел"
  },
  {
    "name_bg": "Синделци"
  },
  {
    "name_bg": "Синеморец"
  },
  {
    "name_bg": "Сини вир"
  },
  {
    "name_bg": "Синигер"
  },
  {
    "name_bg": "Синитово"
  },
  {
    "name_bg": "Синчец"
  },
  {
    "name_bg": "Синьо бърдо"
  },
  {
    "name_bg": "Синьо камене"
  },
  {
    "name_bg": "Синя вода"
  },
  {
    "name_bg": "Сипей"
  },
  {
    "name_bg": "Сираково"
  },
  {
    "name_bg": "Сирищник"
  },
  {
    "name_bg": "Ситово"
  },
  {
    "name_bg": "Скала"
  },
  {
    "name_bg": "Скалак"
  },
  {
    "name_bg": "Скалина"
  },
  {
    "name_bg": "Скалица"
  },
  {
    "name_bg": "Скалище"
  },
  {
    "name_bg": "Скална глава"
  },
  {
    "name_bg": "Скалско"
  },
  {
    "name_bg": "Скандалото"
  },
  {
    "name_bg": "Склаве"
  },
  {
    "name_bg": "Скобелево"
  },
  {
    "name_bg": "Скомля"
  },
  {
    "name_bg": "Скорците"
  },
  {
    "name_bg": "Скравена"
  },
  {
    "name_bg": "Скребатно"
  },
  {
    "name_bg": "Скрино"
  },
  {
    "name_bg": "Скриняно"
  },
  {
    "name_bg": "Скрът"
  },
  {
    "name_bg": "Скутаре"
  },
  {
    "name_bg": "Скърбино"
  },
  {
    "name_bg": "Славеево"
  },
  {
    "name_bg": "Славейно"
  },
  {
    "name_bg": "Славейково"
  },
  {
    "name_bg": "Славовица"
  },
  {
    "name_bg": "Славотин"
  },
  {
    "name_bg": "Славщица"
  },
  {
    "name_bg": "Славяни"
  },
  {
    "name_bg": "Славянин"
  },
  {
    "name_bg": "Славяново"
  },
  {
    "name_bg": "Славянци"
  },
  {
    "name_bg": "Сладка вода"
  },
  {
    "name_bg": "Сладкодум"
  },
  {
    "name_bg": "Сладун"
  },
  {
    "name_bg": "Сладък кладенец"
  },
  {
    "name_bg": "Слаковци"
  },
  {
    "name_bg": "Сламино"
  },
  {
    "name_bg": "Слана бара"
  },
  {
    "name_bg": "Сланотрън"
  },
  {
    "name_bg": "Слатина"
  },
  {
    "name_bg": "Слатино"
  },
  {
    "name_bg": "Слащен"
  },
  {
    "name_bg": "Сливак"
  },
  {
    "name_bg": "Сливарка"
  },
  {
    "name_bg": "Сливарово"
  },
  {
    "name_bg": "Сливата"
  },
  {
    "name_bg": "Сливек"
  },
  {
    "name_bg": "Сливен"
  },
  {
    "name_bg": "Сливито"
  },
  {
    "name_bg": "Сливка"
  },
  {
    "name_bg": "Сливница"
  },
  {
    "name_bg": "Сливовик"
  },
  {
    "name_bg": "Сливовица"
  },
  {
    "name_bg": "Сливовник"
  },
  {
    "name_bg": "Сливово"
  },
  {
    "name_bg": "Сливо поле"
  },
  {
    "name_bg": "Слишовци"
  },
  {
    "name_bg": "Слокощица"
  },
  {
    "name_bg": "Сломер"
  },
  {
    "name_bg": "Слънчево"
  },
  {
    "name_bg": "Слънчовец"
  },
  {
    "name_bg": "Слънчоглед"
  },
  {
    "name_bg": "Смилец"
  },
  {
    "name_bg": "Смиловци"
  },
  {
    "name_bg": "Смилян"
  },
  {
    "name_bg": "Смин"
  },
  {
    "name_bg": "Смирненски"
  },
  {
    "name_bg": "Смирненци"
  },
  {
    "name_bg": "Смиров дол"
  },
  {
    "name_bg": "Смоличано"
  },
  {
    "name_bg": "Смолница"
  },
  {
    "name_bg": "Смолско"
  },
  {
    "name_bg": "Смолча"
  },
  {
    "name_bg": "Смолян"
  },
  {
    "name_bg": "Смоляновци"
  },
  {
    "name_bg": "Смочан"
  },
  {
    "name_bg": "Смочево"
  },
  {
    "name_bg": "Смядово"
  },
  {
    "name_bg": "Снежа"
  },
  {
    "name_bg": "Снежина"
  },
  {
    "name_bg": "Снежинка"
  },
  {
    "name_bg": "Сноп"
  },
  {
    "name_bg": "Снягово"
  },
  {
    "name_bg": "Совата"
  },
  {
    "name_bg": "Соволяно"
  },
  {
    "name_bg": "Созопол"
  },
  {
    "name_bg": "Сокол"
  },
  {
    "name_bg": "Соколаре"
  },
  {
    "name_bg": "Соколарци"
  },
  {
    "name_bg": "Соколенци"
  },
  {
    "name_bg": "Соколец"
  },
  {
    "name_bg": "Соколино"
  },
  {
    "name_bg": "Соколите"
  },
  {
    "name_bg": "Соколица"
  },
  {
    "name_bg": "Соколник"
  },
  {
    "name_bg": "Соколово"
  },
  {
    "name_bg": "Соколовци"
  },
  {
    "name_bg": "Соколско"
  },
  {
    "name_bg": "Соколяне"
  },
  {
    "name_bg": "Солари"
  },
  {
    "name_bg": "Солища"
  },
  {
    "name_bg": "Солище"
  },
  {
    "name_bg": "Солник"
  },
  {
    "name_bg": "Сомовит"
  },
  {
    "name_bg": "Сопица"
  },
  {
    "name_bg": "Сопово"
  },
  {
    "name_bg": "Сопот"
  },
  {
    "name_bg": "Сопота"
  },
  {
    "name_bg": "Сопотот"
  },
  {
    "name_bg": "Полковник Иваново"
  },
  {
    "name_bg": "Сотиря"
  },
  {
    "name_bg": "Софийци"
  },
  {
    "name_bg": "София"
  },
  {
    "name_bg": "Софрониево"
  },
  {
    "name_bg": "Модрен"
  },
  {
    "name_bg": "Спанци"
  },
  {
    "name_bg": "Спанчевци"
  },
  {
    "name_bg": "Спасово"
  },
  {
    "name_bg": "Спасовци"
  },
  {
    "name_bg": "Спатово"
  },
  {
    "name_bg": "Спахиево"
  },
  {
    "name_bg": "Сполука"
  },
  {
    "name_bg": "Срацимир"
  },
  {
    "name_bg": "Срацимирово"
  },
  {
    "name_bg": "Сребриново"
  },
  {
    "name_bg": "Сребърна"
  },
  {
    "name_bg": "Средина"
  },
  {
    "name_bg": "Срединка"
  },
  {
    "name_bg": "Средище"
  },
  {
    "name_bg": "Средковец"
  },
  {
    "name_bg": "Средна махала"
  },
  {
    "name_bg": "Среднево"
  },
  {
    "name_bg": "Средни колиби"
  },
  {
    "name_bg": "Средни рът"
  },
  {
    "name_bg": "Средногорово"
  },
  {
    "name_bg": "Средногорци"
  },
  {
    "name_bg": "Средно градище"
  },
  {
    "name_bg": "Средно село"
  },
  {
    "name_bg": "Средня"
  },
  {
    "name_bg": "Средогрив"
  },
  {
    "name_bg": "Средок"
  },
  {
    "name_bg": "Средорек"
  },
  {
    "name_bg": "Средоселци"
  },
  {
    "name_bg": "Средска"
  },
  {
    "name_bg": "Средско"
  },
  {
    "name_bg": "Срем"
  },
  {
    "name_bg": "Срънско"
  },
  {
    "name_bg": "Ставерци"
  },
  {
    "name_bg": "Стаевци"
  },
  {
    "name_bg": "Стайновци"
  },
  {
    "name_bg": "Стайчин дол"
  },
  {
    "name_bg": "Стайчовци"
  },
  {
    "name_bg": "Стакевци"
  },
  {
    "name_bg": "Сталево"
  },
  {
    "name_bg": "Сталийска махала"
  },
  {
    "name_bg": "Хан Аспарухово"
  },
  {
    "name_bg": "Стан"
  },
  {
    "name_bg": "Станево"
  },
  {
    "name_bg": "Станец"
  },
  {
    "name_bg": "Станинци"
  },
  {
    "name_bg": "Дупница"
  },
  {
    "name_bg": "Логодаж"
  },
  {
    "name_bg": "Становец"
  },
  {
    "name_bg": "Станча"
  },
  {
    "name_bg": "Станчов хан"
  },
  {
    "name_bg": "Станьовци"
  },
  {
    "name_bg": "Станянци"
  },
  {
    "name_bg": "Стара Загора"
  },
  {
    "name_bg": "Стара река"
  },
  {
    "name_bg": "Стара речка"
  },
  {
    "name_bg": "Старейшино"
  },
  {
    "name_bg": "Старилковци"
  },
  {
    "name_bg": "Стари чал"
  },
  {
    "name_bg": "Старово"
  },
  {
    "name_bg": "Старо Железаре"
  },
  {
    "name_bg": "Старозагорски бани"
  },
  {
    "name_bg": "Старо място"
  },
  {
    "name_bg": "Старо Оряхово"
  },
  {
    "name_bg": "Старопатица"
  },
  {
    "name_bg": "Старосел"
  },
  {
    "name_bg": "Староселец"
  },
  {
    "name_bg": "Старо селище"
  },
  {
    "name_bg": "Старо село"
  },
  {
    "name_bg": "Староселци"
  },
  {
    "name_bg": "Старцево"
  },
  {
    "name_bg": "Старчево"
  },
  {
    "name_bg": "Стар читак"
  },
  {
    "name_bg": "Старчище"
  },
  {
    "name_bg": "Стеврек"
  },
  {
    "name_bg": "Стежерово"
  },
  {
    "name_bg": "Стенско"
  },
  {
    "name_bg": "Стефан Караджа"
  },
  {
    "name_bg": "Стефан Караджово"
  },
  {
    "name_bg": "Стефаново"
  },
  {
    "name_bg": "Стикъл"
  },
  {
    "name_bg": "Стоб"
  },
  {
    "name_bg": "Стоево"
  },
  {
    "name_bg": "Стоевци"
  },
  {
    "name_bg": "Стожа"
  },
  {
    "name_bg": "Стожер"
  },
  {
    "name_bg": "Стоил войвода"
  },
  {
    "name_bg": "Стоилово"
  },
  {
    "name_bg": "Стойките"
  },
  {
    "name_bg": "Стойково"
  },
  {
    "name_bg": "Стойново"
  },
  {
    "name_bg": "Стойчевци"
  },
  {
    "name_bg": "Стойчовци"
  },
  {
    "name_bg": "Стоките"
  },
  {
    "name_bg": "Столетово"
  },
  {
    "name_bg": "Столник"
  },
  {
    "name_bg": "Столът"
  },
  {
    "name_bg": "Стоманево"
  },
  {
    "name_bg": "Стоманеците"
  },
  {
    "name_bg": "Стоманци"
  },
  {
    "name_bg": "Стоян-Заимово"
  },
  {
    "name_bg": "Стоян Михайловски"
  },
  {
    "name_bg": "Стояново"
  },
  {
    "name_bg": "Радювене"
  },
  {
    "name_bg": "Стояновци"
  },
  {
    "name_bg": "Страдалово"
  },
  {
    "name_bg": "Стража"
  },
  {
    "name_bg": "Стражата"
  },
  {
    "name_bg": "Стражевци"
  },
  {
    "name_bg": "Стражица"
  },
  {
    "name_bg": "Стражница"
  },
  {
    "name_bg": "Стралджа"
  },
  {
    "name_bg": "Странджа"
  },
  {
    "name_bg": "Странджево"
  },
  {
    "name_bg": "Странско"
  },
  {
    "name_bg": "Страхил"
  },
  {
    "name_bg": "Страхил войвода"
  },
  {
    "name_bg": "Страхилица"
  },
  {
    "name_bg": "Страхилово"
  },
  {
    "name_bg": "Страцин"
  },
  {
    "name_bg": "Страшимир"
  },
  {
    "name_bg": "Страшимирово"
  },
  {
    "name_bg": "Стрезимировци"
  },
  {
    "name_bg": "Стрелец"
  },
  {
    "name_bg": "Стрелково"
  },
  {
    "name_bg": "Стрелци"
  },
  {
    "name_bg": "Стрелча"
  },
  {
    "name_bg": "Стремово"
  },
  {
    "name_bg": "Стремци"
  },
  {
    "name_bg": "Стрижба"
  },
  {
    "name_bg": "Строево"
  },
  {
    "name_bg": "Стройно"
  },
  {
    "name_bg": "Стройновци"
  },
  {
    "name_bg": "Струиндол"
  },
  {
    "name_bg": "Струино"
  },
  {
    "name_bg": "Струма"
  },
  {
    "name_bg": "Струмешница"
  },
  {
    "name_bg": "Струмяни"
  },
  {
    "name_bg": "Струпец"
  },
  {
    "name_bg": "Струя"
  },
  {
    "name_bg": "Стръмци"
  },
  {
    "name_bg": "Стряма"
  },
  {
    "name_bg": "Стубел"
  },
  {
    "name_bg": "Студена"
  },
  {
    "name_bg": "Студенец"
  },
  {
    "name_bg": "Студен извор"
  },
  {
    "name_bg": "Студеница"
  },
  {
    "name_bg": "Студен кладенец"
  },
  {
    "name_bg": "Студено буче"
  },
  {
    "name_bg": "Стъргел"
  },
  {
    "name_bg": "Стърмен"
  },
  {
    "name_bg": "Стърница"
  },
  {
    "name_bg": "Суворово"
  },
  {
    "name_bg": "Сугарево"
  },
  {
    "name_bg": "Сулица"
  },
  {
    "name_bg": "Султани"
  },
  {
    "name_bg": "Царевци"
  },
  {
    "name_bg": "Сумер"
  },
  {
    "name_bg": "Сунгурларе"
  },
  {
    "name_bg": "Сусам"
  },
  {
    "name_bg": "Суха река"
  },
  {
    "name_bg": "Сухаче"
  },
  {
    "name_bg": "Сухиндол"
  },
  {
    "name_bg": "Сухово"
  },
  {
    "name_bg": "Суходол"
  },
  {
    "name_bg": "Сухозем"
  },
  {
    "name_bg": "Сухолоевци"
  },
  {
    "name_bg": "Сухострел"
  },
  {
    "name_bg": "Сушево"
  },
  {
    "name_bg": "Сушина"
  },
  {
    "name_bg": "Събковци"
  },
  {
    "name_bg": "Съботковци"
  },
  {
    "name_bg": "Събрано"
  },
  {
    "name_bg": "Съдиево"
  },
  {
    "name_bg": "Съдийско поле"
  },
  {
    "name_bg": "Съединение"
  },
  {
    "name_bg": "Сърнак"
  },
  {
    "name_bg": "Сърневец"
  },
  {
    "name_bg": "Сърнево"
  },
  {
    "name_bg": "Сърнегор"
  },
  {
    "name_bg": "Сърненци"
  },
  {
    "name_bg": "Сърнец"
  },
  {
    "name_bg": "Сърнино"
  },
  {
    "name_bg": "Сърница"
  },
  {
    "name_bg": "Сърпово"
  },
  {
    "name_bg": "Сърцево"
  },
  {
    "name_bg": "Сяново"
  },
  {
    "name_bg": "Сяровци"
  },
  {
    "name_bg": "Сярци"
  },
  {
    "name_bg": "Селановци"
  },
  {
    "name_bg": "Сини връх"
  },
  {
    "name_bg": "Стойковци"
  },
  {
    "name_bg": "Седларци"
  },
  {
    "name_bg": "Сенце"
  },
  {
    "name_bg": "Сипец"
  },
  {
    "name_bg": "Сливенци"
  },
  {
    "name_bg": "Стражец"
  },
  {
    "name_bg": "Табан"
  },
  {
    "name_bg": "Табачка"
  },
  {
    "name_bg": "Табашка"
  },
  {
    "name_bg": "Таваличево"
  },
  {
    "name_bg": "Вълчаново"
  },
  {
    "name_bg": "Таймище"
  },
  {
    "name_bg": "Тамарино"
  },
  {
    "name_bg": "Смолник"
  },
  {
    "name_bg": "Татарево"
  },
  {
    "name_bg": "Татари"
  },
  {
    "name_bg": "Татково"
  },
  {
    "name_bg": "Татул"
  },
  {
    "name_bg": "Твърдинци"
  },
  {
    "name_bg": "Твърдица"
  },
  {
    "name_bg": "Текето"
  },
  {
    "name_bg": "Телериг"
  },
  {
    "name_bg": "Телиш"
  },
  {
    "name_bg": "Телчарка"
  },
  {
    "name_bg": "Батановци"
  },
  {
    "name_bg": "Тенево"
  },
  {
    "name_bg": "Тепава"
  },
  {
    "name_bg": "Теплен"
  },
  {
    "name_bg": "Тервел"
  },
  {
    "name_bg": "Терзийско"
  },
  {
    "name_bg": "Терзиите"
  },
  {
    "name_bg": "Тертер"
  },
  {
    "name_bg": "Тетевен"
  },
  {
    "name_bg": "Тетово"
  },
  {
    "name_bg": "Тешел"
  },
  {
    "name_bg": "Тешово"
  },
  {
    "name_bg": "Тикале"
  },
  {
    "name_bg": "Тимарево"
  },
  {
    "name_bg": "Тинтява"
  },
  {
    "name_bg": "Типченица"
  },
  {
    "name_bg": "Титевци"
  },
  {
    "name_bg": "Тиховец"
  },
  {
    "name_bg": "Тихомир"
  },
  {
    "name_bg": "Тихомирово"
  },
  {
    "name_bg": "Тича"
  },
  {
    "name_bg": "Тишаново"
  },
  {
    "name_bg": "Тишевица"
  },
  {
    "name_bg": "Тияновци"
  },
  {
    "name_bg": "Тлачене"
  },
  {
    "name_bg": "Тодореците"
  },
  {
    "name_bg": "Тодор Икономово"
  },
  {
    "name_bg": "Тодоричене"
  },
  {
    "name_bg": "Тодорово"
  },
  {
    "name_bg": "Тодоровци"
  },
  {
    "name_bg": "Тодювци"
  },
  {
    "name_bg": "Токачка"
  },
  {
    "name_bg": "Толовица"
  },
  {
    "name_bg": "Томбето"
  },
  {
    "name_bg": "Томпсън"
  },
  {
    "name_bg": "Томчевци"
  },
  {
    "name_bg": "Тонско дабе"
  },
  {
    "name_bg": "Топола"
  },
  {
    "name_bg": "Тополи"
  },
  {
    "name_bg": "Тополи дол"
  },
  {
    "name_bg": "Тополица"
  },
  {
    "name_bg": "Тополка"
  },
  {
    "name_bg": "Тополница"
  },
  {
    "name_bg": "Тополовград"
  },
  {
    "name_bg": "Тополовец"
  },
  {
    "name_bg": "Тополово"
  },
  {
    "name_bg": "Тополчане"
  },
  {
    "name_bg": "Тополяне"
  },
  {
    "name_bg": "Топузево"
  },
  {
    "name_bg": "Топузи"
  },
  {
    "name_bg": "Топчии"
  },
  {
    "name_bg": "Топчийско"
  },
  {
    "name_bg": "Торбалъжите"
  },
  {
    "name_bg": "Тотлебен"
  },
  {
    "name_bg": "Точилари"
  },
  {
    "name_bg": "Тошевци"
  },
  {
    "name_bg": "Траве"
  },
  {
    "name_bg": "Травник"
  },
  {
    "name_bg": "Трайково"
  },
  {
    "name_bg": "Тракиец"
  },
  {
    "name_bg": "Тракийци"
  },
  {
    "name_bg": "Тракия"
  },
  {
    "name_bg": "Трапесковци"
  },
  {
    "name_bg": "Трапище"
  },
  {
    "name_bg": "Трапоклово"
  },
  {
    "name_bg": "Требище"
  },
  {
    "name_bg": "Трекляно"
  },
  {
    "name_bg": "Трем"
  },
  {
    "name_bg": "Трескавец"
  },
  {
    "name_bg": "Триводици"
  },
  {
    "name_bg": "Тригорци"
  },
  {
    "name_bg": "Триград"
  },
  {
    "name_bg": "Три кладенци"
  },
  {
    "name_bg": "Трилистник"
  },
  {
    "name_bg": "Три могили"
  },
  {
    "name_bg": "Трифоново"
  },
  {
    "name_bg": "Троица"
  },
  {
    "name_bg": "Тросково"
  },
  {
    "name_bg": "Троян"
  },
  {
    "name_bg": "Трояново"
  },
  {
    "name_bg": "Труд"
  },
  {
    "name_bg": "Трудовец"
  },
  {
    "name_bg": "Тръбач"
  },
  {
    "name_bg": "Трън"
  },
  {
    "name_bg": "Трънак"
  },
  {
    "name_bg": "Трънито"
  },
  {
    "name_bg": "Тръница"
  },
  {
    "name_bg": "Трънково"
  },
  {
    "name_bg": "Трънковци"
  },
  {
    "name_bg": "Трънчовица"
  },
  {
    "name_bg": "Тръстеник"
  },
  {
    "name_bg": "Тръстика"
  },
  {
    "name_bg": "Тръстиково"
  },
  {
    "name_bg": "Трявна"
  },
  {
    "name_bg": "Туден"
  },
  {
    "name_bg": "Тулово"
  },
  {
    "name_bg": "Тумбалово"
  },
  {
    "name_bg": "Тумбевци"
  },
  {
    "name_bg": "Турия"
  },
  {
    "name_bg": "Туркинча"
  },
  {
    "name_bg": "Туроковци"
  },
  {
    "name_bg": "Турян"
  },
  {
    "name_bg": "Тутракан"
  },
  {
    "name_bg": "Тутраканци"
  },
  {
    "name_bg": "Туховища"
  },
  {
    "name_bg": "Тученица"
  },
  {
    "name_bg": "Тушовица"
  },
  {
    "name_bg": "Тъжа"
  },
  {
    "name_bg": "Тъкач"
  },
  {
    "name_bg": "Тънки рът"
  },
  {
    "name_bg": "Тънково"
  },
  {
    "name_bg": "Тънкото"
  },
  {
    "name_bg": "Тъпчилещово"
  },
  {
    "name_bg": "Търговище"
  },
  {
    "name_bg": "Търкашени"
  },
  {
    "name_bg": "Търнава"
  },
  {
    "name_bg": "Търнак"
  },
  {
    "name_bg": "Търнене"
  },
  {
    "name_bg": "Търничени"
  },
  {
    "name_bg": "Търновлаг"
  },
  {
    "name_bg": "Търновца"
  },
  {
    "name_bg": "Търновци"
  },
  {
    "name_bg": "Търносливка"
  },
  {
    "name_bg": "Търняне"
  },
  {
    "name_bg": "Търсино"
  },
  {
    "name_bg": "Търхово"
  },
  {
    "name_bg": "Търън"
  },
  {
    "name_bg": "Тюленово"
  },
  {
    "name_bg": "Тюркмен"
  },
  {
    "name_bg": "Тютюнче"
  },
  {
    "name_bg": "Тянево"
  },
  {
    "name_bg": "Тънка бара"
  },
  {
    "name_bg": "Търна"
  },
  {
    "name_bg": "Угледно"
  },
  {
    "name_bg": "Углярци"
  },
  {
    "name_bg": "Угорелец"
  },
  {
    "name_bg": "Угорялковци"
  },
  {
    "name_bg": "Угърчин"
  },
  {
    "name_bg": "Узово"
  },
  {
    "name_bg": "Узунджово"
  },
  {
    "name_bg": "Узуните"
  },
  {
    "name_bg": "Умаревци"
  },
  {
    "name_bg": "Урвата"
  },
  {
    "name_bg": "Уровене"
  },
  {
    "name_bg": "Уручевци"
  },
  {
    "name_bg": "Усойка"
  },
  {
    "name_bg": "Устина"
  },
  {
    "name_bg": "Устрем"
  },
  {
    "name_bg": "Устрен"
  },
  {
    "name_bg": "Ухловица"
  },
  {
    "name_bg": "Ушевци"
  },
  {
    "name_bg": "Уши"
  },
  {
    "name_bg": "Ушинци"
  },
  {
    "name_bg": "Узуново"
  },
  {
    "name_bg": "Фабрика"
  },
  {
    "name_bg": "Фазаново"
  },
  {
    "name_bg": "Факия"
  },
  {
    "name_bg": "Фатово"
  },
  {
    "name_bg": "Велислав"
  },
  {
    "name_bg": "Фелдфебел Денково"
  },
  {
    "name_bg": "Филаретово"
  },
  {
    "name_bg": "Филипово"
  },
  {
    "name_bg": "Филиповци"
  },
  {
    "name_bg": "Флорентин"
  },
  {
    "name_bg": "Фотиново"
  },
  {
    "name_bg": "Фролош"
  },
  {
    "name_bg": "Фурен"
  },
  {
    "name_bg": "Фъргово"
  },
  {
    "name_bg": "Фърговци"
  },
  {
    "name_bg": "Фъревци"
  },
  {
    "name_bg": "Фъртуни"
  },
  {
    "name_bg": "Хаджидимитрово"
  },
  {
    "name_bg": "Хаджи Димитър"
  },
  {
    "name_bg": "Хаджидимово"
  },
  {
    "name_bg": "Хаджиево"
  },
  {
    "name_bg": "Хаджиите"
  },
  {
    "name_bg": "Хаджийско"
  },
  {
    "name_bg": "Хайредин"
  },
  {
    "name_bg": "Брезен"
  },
  {
    "name_bg": "Халваджийско"
  },
  {
    "name_bg": "Халовски колиби"
  },
  {
    "name_bg": "Ханово"
  },
  {
    "name_bg": "Харачерите"
  },
  {
    "name_bg": "Харваловци"
  },
  {
    "name_bg": "Харманли"
  },
  {
    "name_bg": "Хасково"
  },
  {
    "name_bg": "Хасовица"
  },
  {
    "name_bg": "Хвойна"
  },
  {
    "name_bg": "Хвостяне"
  },
  {
    "name_bg": "Хвърчил"
  },
  {
    "name_bg": "Хераково"
  },
  {
    "name_bg": "Хирево"
  },
  {
    "name_bg": "Хисар"
  },
  {
    "name_bg": "Хисаря"
  },
  {
    "name_bg": "Хитово"
  },
  {
    "name_bg": "Цар Калоян"
  },
  {
    "name_bg": "Хлевене"
  },
  {
    "name_bg": "Хлябово"
  },
  {
    "name_bg": "Ходжовци"
  },
  {
    "name_bg": "Хотанца"
  },
  {
    "name_bg": "Хотница"
  },
  {
    "name_bg": "Хотово"
  },
  {
    "name_bg": "Храбрино"
  },
  {
    "name_bg": "Храброво"
  },
  {
    "name_bg": "Храбърско"
  },
  {
    "name_bg": "Храсна"
  },
  {
    "name_bg": "Храстово"
  },
  {
    "name_bg": "Християново"
  },
  {
    "name_bg": "Христовци"
  },
  {
    "name_bg": "Христо Даново"
  },
  {
    "name_bg": "Хрищени"
  },
  {
    "name_bg": "Хромица"
  },
  {
    "name_bg": "Хубавене"
  },
  {
    "name_bg": "Хума"
  },
  {
    "name_bg": "Хухла"
  },
  {
    "name_bg": "Хъневци"
  },
  {
    "name_bg": "Хърлец"
  },
  {
    "name_bg": "Хърсово"
  },
  {
    "name_bg": "Горно Краище"
  },
  {
    "name_bg": "Цаконица"
  },
  {
    "name_bg": "Цалапица"
  },
  {
    "name_bg": "Цани Гинчево"
  },
  {
    "name_bg": "Цапарево"
  },
  {
    "name_bg": "Цар Асен"
  },
  {
    "name_bg": "Царацово"
  },
  {
    "name_bg": "Царева поляна"
  },
  {
    "name_bg": "Царев брод"
  },
  {
    "name_bg": "Царев дол"
  },
  {
    "name_bg": "Царевец"
  },
  {
    "name_bg": "Царимир"
  },
  {
    "name_bg": "Царино"
  },
  {
    "name_bg": "Царичина"
  },
  {
    "name_bg": "Хан Крум"
  },
  {
    "name_bg": "Цар-Петрово"
  },
  {
    "name_bg": "Цар Самуил"
  },
  {
    "name_bg": "Цацаровци"
  },
  {
    "name_bg": "Цвеклювци"
  },
  {
    "name_bg": "Цветино"
  },
  {
    "name_bg": "Цветкова бара"
  },
  {
    "name_bg": "Цветница"
  },
  {
    "name_bg": "Цвятковци"
  },
  {
    "name_bg": "Цвятово"
  },
  {
    "name_bg": "Цегриловци"
  },
  {
    "name_bg": "Целина"
  },
  {
    "name_bg": "Ценино"
  },
  {
    "name_bg": "Ценович"
  },
  {
    "name_bg": "Ценово"
  },
  {
    "name_bg": "Цепераните"
  },
  {
    "name_bg": "Церетелево"
  },
  {
    "name_bg": "Церецел"
  },
  {
    "name_bg": "Церковски"
  },
  {
    "name_bg": "Церова кория"
  },
  {
    "name_bg": "Церовец"
  },
  {
    "name_bg": "Церовица"
  },
  {
    "name_bg": "Церовище"
  },
  {
    "name_bg": "Церово"
  },
  {
    "name_bg": "Циклово"
  },
  {
    "name_bg": "Цирка"
  },
  {
    "name_bg": "Цонево"
  },
  {
    "name_bg": "Цонковци"
  },
  {
    "name_bg": "Црешново"
  },
  {
    "name_bg": "Цръклевци"
  },
  {
    "name_bg": "Црънча"
  },
  {
    "name_bg": "Църварица"
  },
  {
    "name_bg": "Цървена ябълка"
  },
  {
    "name_bg": "Цървендол"
  },
  {
    "name_bg": "Цървеняно"
  },
  {
    "name_bg": "Цървище"
  },
  {
    "name_bg": "Църква"
  },
  {
    "name_bg": "Църквица"
  },
  {
    "name_bg": "Църквище"
  },
  {
    "name_bg": "Чавдар"
  },
  {
    "name_bg": "Чавдарци"
  },
  {
    "name_bg": "Чавеи"
  },
  {
    "name_bg": "Чавка"
  },
  {
    "name_bg": "Чайка"
  },
  {
    "name_bg": "Чакаларово"
  },
  {
    "name_bg": "Чакали"
  },
  {
    "name_bg": "Чакалите"
  },
  {
    "name_bg": "Чакалци"
  },
  {
    "name_bg": "Кременец"
  },
  {
    "name_bg": "Чал"
  },
  {
    "name_bg": "Чала"
  },
  {
    "name_bg": "Чалъкови"
  },
  {
    "name_bg": "Чамла"
  },
  {
    "name_bg": "Царски извор"
  },
  {
    "name_bg": "Чарган"
  },
  {
    "name_bg": "Чарда"
  },
  {
    "name_bg": "Чеганци"
  },
  {
    "name_bg": "Чеканец"
  },
  {
    "name_bg": "Чеканци"
  },
  {
    "name_bg": "Чеканчево"
  },
  {
    "name_bg": "Сини рид"
  },
  {
    "name_bg": "Челник"
  },
  {
    "name_bg": "Челопек"
  },
  {
    "name_bg": "Челопеч"
  },
  {
    "name_bg": "Челопечене"
  },
  {
    "name_bg": "Челюстница"
  },
  {
    "name_bg": "Чемиш"
  },
  {
    "name_bg": "Чепеларе"
  },
  {
    "name_bg": "Чепино"
  },
  {
    "name_bg": "Чепинци"
  },
  {
    "name_bg": "Чеплетен"
  },
  {
    "name_bg": "Чепърлинци"
  },
  {
    "name_bg": "Червен"
  },
  {
    "name_bg": "Червена"
  },
  {
    "name_bg": "Червена вода"
  },
  {
    "name_bg": "Червенаково"
  },
  {
    "name_bg": "Червена могила"
  },
  {
    "name_bg": "Червен брег"
  },
  {
    "name_bg": "Червен бряг"
  },
  {
    "name_bg": "Червенковци"
  },
  {
    "name_bg": "Червенци"
  },
  {
    "name_bg": "Черганово"
  },
  {
    "name_bg": "Черенча"
  },
  {
    "name_bg": "Черепово"
  },
  {
    "name_bg": "Череша"
  },
  {
    "name_bg": "Черешица"
  },
  {
    "name_bg": "Черешките"
  },
  {
    "name_bg": "Черешница"
  },
  {
    "name_bg": "Черешовица"
  },
  {
    "name_bg": "Черешово"
  },
  {
    "name_bg": "Черешовска река"
  },
  {
    "name_bg": "Черкаски"
  },
  {
    "name_bg": "Черковица"
  },
  {
    "name_bg": "Черковище"
  },
  {
    "name_bg": "Черковна"
  },
  {
    "name_bg": "Черково"
  },
  {
    "name_bg": "Черна"
  },
  {
    "name_bg": "Черна вода"
  },
  {
    "name_bg": "Черна гора"
  },
  {
    "name_bg": "Черна могила"
  },
  {
    "name_bg": "Черна нива"
  },
  {
    "name_bg": "Черна скала"
  },
  {
    "name_bg": "Чернево"
  },
  {
    "name_bg": "Черневци"
  },
  {
    "name_bg": "Черни бряг"
  },
  {
    "name_bg": "Черни Вит"
  },
  {
    "name_bg": "Черни връх"
  },
  {
    "name_bg": "Чернигово"
  },
  {
    "name_bg": "Черни дял"
  },
  {
    "name_bg": "Черни Осъм"
  },
  {
    "name_bg": "Черни рид"
  },
  {
    "name_bg": "Черница"
  },
  {
    "name_bg": "Черниче"
  },
  {
    "name_bg": "Черничево"
  },
  {
    "name_bg": "Черничино"
  },
  {
    "name_bg": "Черновръх"
  },
  {
    "name_bg": "Черноглавци"
  },
  {
    "name_bg": "Черногор"
  },
  {
    "name_bg": "Черногорово"
  },
  {
    "name_bg": "Черноград"
  },
  {
    "name_bg": "Чернодъб"
  },
  {
    "name_bg": "Чернозем"
  },
  {
    "name_bg": "Черноземен"
  },
  {
    "name_bg": "Чернокапци"
  },
  {
    "name_bg": "Чернолик"
  },
  {
    "name_bg": "Черно море"
  },
  {
    "name_bg": "Черноморец"
  },
  {
    "name_bg": "Черноморци"
  },
  {
    "name_bg": "Черноок"
  },
  {
    "name_bg": "Чернооки"
  },
  {
    "name_bg": "Чернооково"
  },
  {
    "name_bg": "Черноочене"
  },
  {
    "name_bg": "Черно поле"
  },
  {
    "name_bg": "Черньово"
  },
  {
    "name_bg": "Черньовци"
  },
  {
    "name_bg": "Честименско"
  },
  {
    "name_bg": "Четирци"
  },
  {
    "name_bg": "Чехларе"
  },
  {
    "name_bg": "Чешлянци"
  },
  {
    "name_bg": "Чешма"
  },
  {
    "name_bg": "Чешнегирово"
  },
  {
    "name_bg": "Чибаовци"
  },
  {
    "name_bg": "Чилик"
  },
  {
    "name_bg": "Чилнов"
  },
  {
    "name_bg": "Чинтулово"
  },
  {
    "name_bg": "Чипровци"
  },
  {
    "name_bg": "Чирен"
  },
  {
    "name_bg": "Чирпан"
  },
  {
    "name_bg": "Чистово"
  },
  {
    "name_bg": "Читаковци"
  },
  {
    "name_bg": "Чифлик"
  },
  {
    "name_bg": "Чичево"
  },
  {
    "name_bg": "Чичил"
  },
  {
    "name_bg": "Чобанка"
  },
  {
    "name_bg": "Чокманово"
  },
  {
    "name_bg": "Чокоба"
  },
  {
    "name_bg": "Чомаково"
  },
  {
    "name_bg": "Чомаковци"
  },
  {
    "name_bg": "Чорбаджийско"
  },
  {
    "name_bg": "Чорул"
  },
  {
    "name_bg": "Чубра"
  },
  {
    "name_bg": "Чубрика"
  },
  {
    "name_bg": "Чудинци"
  },
  {
    "name_bg": "Чудомир"
  },
  {
    "name_bg": "Чуйпетлово"
  },
  {
    "name_bg": "Тодорчета"
  },
  {
    "name_bg": "Чукарка"
  },
  {
    "name_bg": "Чукарово"
  },
  {
    "name_bg": "Чуката"
  },
  {
    "name_bg": "Чукилите"
  },
  {
    "name_bg": "Чуковезер"
  },
  {
    "name_bg": "Чуковец"
  },
  {
    "name_bg": "Чуково"
  },
  {
    "name_bg": "Чупрене"
  },
  {
    "name_bg": "Чурек"
  },
  {
    "name_bg": "Чурен"
  },
  {
    "name_bg": "Чурилово"
  },
  {
    "name_bg": "Чуричени"
  },
  {
    "name_bg": "Чурка"
  },
  {
    "name_bg": "Чуруково"
  },
  {
    "name_bg": "Чучулига"
  },
  {
    "name_bg": "Чучулигово"
  },
  {
    "name_bg": "Черна Места"
  },
  {
    "name_bg": "Чолакова"
  },
  {
    "name_bg": "Чарково"
  },
  {
    "name_bg": "Червена локва"
  },
  {
    "name_bg": "Червена скала"
  },
  {
    "name_bg": "Черешка"
  },
  {
    "name_bg": "Черник"
  },
  {
    "name_bg": "Чоба"
  },
  {
    "name_bg": "Шабла"
  },
  {
    "name_bg": "Шаново"
  },
  {
    "name_bg": "Шарани"
  },
  {
    "name_bg": "Шаренска"
  },
  {
    "name_bg": "Шарково"
  },
  {
    "name_bg": "Шатрово"
  },
  {
    "name_bg": "Шейново"
  },
  {
    "name_bg": "Шереметя"
  },
  {
    "name_bg": "Дъбовица"
  },
  {
    "name_bg": "Шиварово"
  },
  {
    "name_bg": "Шиливери"
  },
  {
    "name_bg": "Шилковци"
  },
  {
    "name_bg": "Шипикова махала"
  },
  {
    "name_bg": "Шипка"
  },
  {
    "name_bg": "Шипковица"
  },
  {
    "name_bg": "Шипково"
  },
  {
    "name_bg": "Шипок"
  },
  {
    "name_bg": "Шипот"
  },
  {
    "name_bg": "Шипочане"
  },
  {
    "name_bg": "Шипочано"
  },
  {
    "name_bg": "Шипчените"
  },
  {
    "name_bg": "Широка лъка"
  },
  {
    "name_bg": "Широка поляна"
  },
  {
    "name_bg": "Широки дол"
  },
  {
    "name_bg": "Широково"
  },
  {
    "name_bg": "Широко поле"
  },
  {
    "name_bg": "Шишенци"
  },
  {
    "name_bg": "Шишковица"
  },
  {
    "name_bg": "Шишковци"
  },
  {
    "name_bg": "Цар Шишманово"
  },
  {
    "name_bg": "Шишманово"
  },
  {
    "name_bg": "Шишманци"
  },
  {
    "name_bg": "Шияково"
  },
  {
    "name_bg": "Шкорпиловци"
  },
  {
    "name_bg": "Шодековци"
  },
  {
    "name_bg": "Алендарова"
  },
  {
    "name_bg": "Шопите"
  },
  {
    "name_bg": "Шопци"
  },
  {
    "name_bg": "Шубеци"
  },
  {
    "name_bg": "Шума"
  },
  {
    "name_bg": "Шумата"
  },
  {
    "name_bg": "Шумен"
  },
  {
    "name_bg": "Шуменци"
  },
  {
    "name_bg": "Шумнатица"
  },
  {
    "name_bg": "Шушня"
  },
  {
    "name_bg": "Шемшево"
  },
  {
    "name_bg": "Щерна"
  },
  {
    "name_bg": "Щипско"
  },
  {
    "name_bg": "Щит"
  },
  {
    "name_bg": "Щръклево"
  },
  {
    "name_bg": "Щърково"
  },
  {
    "name_bg": "Ъглен"
  },
  {
    "name_bg": "Югово"
  },
  {
    "name_bg": "Юделник"
  },
  {
    "name_bg": "Върхари"
  },
  {
    "name_bg": "Юлиево"
  },
  {
    "name_bg": "Юнак"
  },
  {
    "name_bg": "Юнаци"
  },
  {
    "name_bg": "Юнаците"
  },
  {
    "name_bg": "Юнец"
  },
  {
    "name_bg": "Юпер"
  },
  {
    "name_bg": "Юруково"
  },
  {
    "name_bg": "Юндола"
  },
  {
    "name_bg": "Ябланица"
  },
  {
    "name_bg": "Ябланово"
  },
  {
    "name_bg": "Ябълковец"
  },
  {
    "name_bg": "Ябълково"
  },
  {
    "name_bg": "Ябълковци"
  },
  {
    "name_bg": "Ябълчево"
  },
  {
    "name_bg": "Ябълчени"
  },
  {
    "name_bg": "Явор"
  },
  {
    "name_bg": "Яворец"
  },
  {
    "name_bg": "Яворница"
  },
  {
    "name_bg": "Яворовец"
  },
  {
    "name_bg": "Яворово"
  },
  {
    "name_bg": "Яврово"
  },
  {
    "name_bg": "Ягнило"
  },
  {
    "name_bg": "Ягода"
  },
  {
    "name_bg": "Ягодина"
  },
  {
    "name_bg": "Ягодово"
  },
  {
    "name_bg": "Яздач"
  },
  {
    "name_bg": "Язовец"
  },
  {
    "name_bg": "Яким Груево"
  },
  {
    "name_bg": "Якимово"
  },
  {
    "name_bg": "Яковица"
  },
  {
    "name_bg": "Яково"
  },
  {
    "name_bg": "Яковци"
  },
  {
    "name_bg": "Якоруда"
  },
  {
    "name_bg": "Ялботина"
  },
  {
    "name_bg": "Ялово"
  },
  {
    "name_bg": "Ямбол"
  },
  {
    "name_bg": "Ямино"
  },
  {
    "name_bg": "Ямна"
  },
  {
    "name_bg": "Яна"
  },
  {
    "name_bg": "Янино"
  },
  {
    "name_bg": "Янково"
  },
  {
    "name_bg": "Янковци"
  },
  {
    "name_bg": "Яново"
  },
  {
    "name_bg": "Янтра"
  },
  {
    "name_bg": "Яньовец"
  },
  {
    "name_bg": "Ярджиловци"
  },
  {
    "name_bg": "Яребица"
  },
  {
    "name_bg": "Яребична"
  },
  {
    "name_bg": "Яребично"
  },
  {
    "name_bg": "Яребковица"
  },
  {
    "name_bg": "Ярловица"
  },
  {
    "name_bg": "Ярлово"
  },
  {
    "name_bg": "Ярловци"
  },
  {
    "name_bg": "Ярославци"
  },
  {
    "name_bg": "Ясен"
  },
  {
    "name_bg": "Ясените"
  },
  {
    "name_bg": "Ясенково"
  },
  {
    "name_bg": "Ясеновец"
  },
  {
    "name_bg": "Ясеново"
  },
  {
    "name_bg": "Ясна поляна"
  },
  {
    "name_bg": "Ясно поле"
  },
  {
    "name_bg": "Ястреб"
  },
  {
    "name_bg": "Ястребино"
  },
  {
    "name_bg": "Ястребна"
  },
  {
    "name_bg": "Ястребово"
  },
  {
    "name_bg": "Яхиново"
  }
]

export const citiesBG = [
  "Абланица",
  "Абланица",
  "Абрит",
  "Мокрен",
  "Аврамово",
  "Аврамово",
  "Аврен",
  "Аврен",
  "Агатово",
  "Азманите",
  "Върбен",
  "Изгрев",
  "Айрово",
  "Айтос",
  "Аканджиево",
  "Акациево",
  "Аксаково",
  "Аламовци",
  "Албанци",
  "Алваново",
  "Алдомировци",
  "Алеково",
  "Алеково",
  "Алеко Константиново",
  "Александрия",
  "Александрово",
  "Александрово",
  "Александрово",
  "Александрово",
  "Александрово",
  "Александрово",
  "Александрово",
  "Александрово",
  "Нови Искър",
  "Александър Стамболийски",
  "Самуилово",
  "Алиговска",
  "Алино",
  "Алтимир",
  "Алфатар",
  "Алцек",
  "Ангеларий",
  "Ангел войвода",
  "Ангелов",
  "Анево",
  "Антимово",
  "Антон",
  "Антоново",
  "Аплаци",
  "Априлово",
  "Априлово",
  "Априлово",
  "Априлци",
  "Арбанаси",
  "Арда",
  "Ардино",
  "Арзан",
  "Арковна",
  "Армените",
  "Армянковци",
  "Арнаутито",
  "Арпаджик",
  "Арчар",
  "Асен",
  "Асеновград",
  "Асеновец",
  "Асеново",
  "Асеново",
  "Асеново",
  "Асеновци",
  "Аспарухово",
  "Аспарухово",
  "Аспарухово",
  "Аспарухово",
  "Атолово",
  "Ауста",
  "Ахелой",
  "Ахматово",
  "Гълъбово",
  "Ахрянско",
  "Ахтопол",
  "Абланица",
  "Айдемир",
  "Априлци",
  "Антимово",
  "Баба Тонка",
  "Бабек",
  "Бабино",
  "Бабинска река",
  "Бабица",
  "Баблон",
  "Бабово",
  "Бабук",
  "Бабяк",
  "Багалевци",
  "Багра",
  "Багренци",
  "Багрилци",
  "Багрянка",
  "Бадевци",
  "Бадино",
  "Баевци",
  "Баевци",
  "Баждари",
  "Байкал",
  "Байкалско",
  "Байково",
  "Байлово",
  "Бакалите",
  "Бакьово",
  "Балабаново",
  "Балабанско",
  "Балабанчево",
  "Балалея",
  "Баланите",
  "Баланово",
  "Балван",
  "Балванците",
  "Балдево",
  "Балей",
  "Балик",
  "Балиновци",
  "Балкан",
  "Балканец",
  "Балкан махала",
  "Балкански",
  "Балканци",
  "Балканци",
  "Балуци",
  "Балчик",
  "Балша",
  "Бальовци",
  "Балювица",
  "Банари",
  "Бангейци",
  "Банево",
  "Баниска",
  "Баница",
  "Баничан",
  "Банище",
  "Банковец",
  "Банковци",
  "Банкя",
  "Банкя",
  "Баново",
  "Банско",
  "Банчовци",
  "Баня",
  "Баня",
  "Баня",
  "Баня",
  "Баня",
  "Бараково",
  "Бараци",
  "Детелина",
  "Барутин",
  "Горна Арда",
  "Басарбово",
  "Баскалци",
  "Бата",
  "Батак",
  "Батак",
  "Батин",
  "Батишница",
  "Батово",
  "Батошево",
  "Батулия",
  "Батулци",
  "Баурене",
  "Бахалин",
  "Баховица",
  "Бахреци",
  "Бацова махала",
  "Бачево",
  "Бачково",
  "Букаците",
  "Башево",
  "Бащино",
  "Бащино",
  "Бащино",
  "Баячево",
  "Бдинци",
  "Беброво",
  "Беглеж",
  "Бегово",
  "Бегуновци",
  "Бегунци",
  "Беден",
  "Беджене",
  "Бедрово",
  "Бежаново",
  "Бежаново",
  "Безводица",
  "Безводно",
  "Безден",
  "Безденица",
  "Безмер",
  "Безмер",
  "Бейковци",
  "Бекриите",
  "Каменец",
  "Бела",
  "Беланица",
  "Бела Рада",
  "Беласица",
  "Белащица",
  "Белгун",
  "Белев дол",
  "Белевехчево",
  "Белеврен",
  "Белене",
  "Беленци",
  "Бели брег",
  "Бели брод",
  "Бели бряг",
  "Бели вир",
  "Бели дол",
  "Бели извор",
  "Бели Искър",
  "Белила",
  "Белимел",
  "Белинци",
  "Бели Осъм",
  "Бели пласт",
  "Белица",
  "Белица",
  "Белица",
  "Белица",
  "Белица",
  "Белиш",
  "Бел камен",
  "Беловец",
  "Беловица",
  "Белово",
  "Белоградец",
  "Белоградчик",
  "Белозем",
  "Белокопитово",
  "Беломорци",
  "Беломъжите",
  "Бело поле",
  "Бело поле",
  "Белополци",
  "Белополяне",
  "Белопопци",
  "Белослав",
  "Белотинци",
  "Долно Белотинци",
  "Белцов",
  "Белчевци",
  "Белчин",
  "Белчински бани",
  "Бельово",
  "Беляковец",
  "Беляново",
  "Бенковски",
  "Бенковски",
  "Бенковски",
  "Бенковски",
  "Бенковски",
  "Бенковски",
  "Берайнци",
  "Беренде",
  "Беренде",
  "Беренде извор",
  "Бериево",
  "Берковица",
  "Берковски",
  "Берковци",
  "Бероново",
  "Берсин",
  "Беслен",
  "Беснурка",
  "Биволаре",
  "Биволяне",
  "Бижовци",
  "Бижовци",
  "Биково",
  "Билинци",
  "Билка",
  "Билкини",
  "Било",
  "Билянска",
  "Бинкос",
  "Бисер",
  "Бисерци",
  "Бистра",
  "Бистра",
  "Бистренци",
  "Бистрец",
  "Бистрец",
  "Бистрилица",
  "Бистрица",
  "Бистрица",
  "Бистрица",
  "Бистроглед",
  "Благово",
  "Благово",
  "Благоевград",
  "Благоево",
  "Благоево",
  "Благун",
  "Блажиево",
  "Блатец",
  "Блатец",
  "Блатешница",
  "Блатино",
  "Блатница",
  "Блато",
  "Блатска",
  "Бленика",
  "Близнак",
  "Близнаци",
  "Близнаци",
  "Близнец",
  "Блъсково",
  "Блъсковци",
  "Боазът",
  "Бобевци",
  "Бобешино",
  "Бобов дол",
  "Бобовец",
  "Бобораци",
  "Бобошево",
  "Бов",
  "Богатово",
  "Богдан",
  "Богдан",
  "Богданица",
  "Богданлия",
  "Богданов дол",
  "Богданово",
  "Богданово",
  "Богдановци",
  "Богданско",
  "Богданци",
  "Богданци",
  "Богданчовци",
  "Богойна",
  "Боголин",
  "Богомил",
  "Богомилово",
  "Богомилци",
  "Богомолско",
  "Богорово",
  "Богорово",
  "Богослов",
  "Богутево",
  "Богьовци",
  "Боденец",
  "Стамболово",
  "Бодрово",
  "Боево",
  "Боерица",
  "Божак",
  "Божан",
  "Божаново",
  "Бождово",
  "Божевци",
  "Боженица",
  "Боженците",
  "Божичен",
  "Божурец",
  "Божурица",
  "Божурище",
  "Божурка",
  "Божурлук",
  "Божурово",
  "Божурово",
  "Божурово",
  "Божурци",
  "Бозаджии",
  "Бозвелийско",
  "Боил",
  "Лом Черковна",
  "Бойковец",
  "Бойково",
  "Бойковци",
  "Бойник",
  "Бойница",
  "Бойно",
  "Бойновци",
  "Бойчеви колиби",
  "Бойчиновци",
  "Бойчовци",
  "Бокиловци",
  "Болтата",
  "Болярино",
  "Болярово",
  "Болярски извор",
  "Болярско",
  "Болярци",
  "Болярци",
  "Болярци",
  "Бонево",
  "Борец",
  "Борие",
  "Борика",
  "Борика",
  "Борики",
  "Бориково",
  "Бориловец",
  "Борилово",
  "Борима",
  "Боримечково",
  "Борино",
  "Бориново",
  "Боринци",
  "Борислав",
  "Бориславци",
  "Борисово",
  "Борисово",
  "Борнарево",
  "Борован",
  "Боров дол",
  "Боровец",
  "Боровина",
  "Боровица",
  "Боровичене",
  "Борово",
  "Борово",
  "Борово",
  "Борово",
  "Боровско",
  "Боровци",
  "Борското",
  "Борущица",
  "Борци",
  "Боряна",
  "Босевци",
  "Босилица",
  "Босилково",
  "Босилково",
  "Босилковци",
  "Босна",
  "Боснек",
  "Бостанци",
  "Бостина",
  "Ботевград",
  "Ботево",
  "Ботево",
  "Ботево",
  "Ботево",
  "Ботево",
  "Ботров",
  "Ботуня",
  "Ботурче",
  "Бохова",
  "Бохот",
  "Бочковци",
  "Бошуля",
  "Бояджик",
  "Боян",
  "Бояна",
  "Боян Ботево",
  "Бояново",
  "Бояново",
  "Бояновци",
  "Боянци",
  "Брадвари",
  "Бракница",
  "Бракьовци",
  "Браниполе",
  "Браница",
  "Браничево",
  "Бранище",
  "Бранковци",
  "Бранковци",
  "Братан",
  "Братаница",
  "Братово",
  "Братово",
  "Братушково",
  "Братя Даскалови",
  "Братя Кунчеви",
  "Брацигово",
  "Брегаре",
  "Брегово",
  "Брежани",
  "Брежниците",
  "Бреза",
  "Брезе",
  "Брезе",
  "Брезник",
  "Брезница",
  "Брезнишки извор",
  "Брезовдол",
  "Брезово",
  "Брезово",
  "Бреница",
  "Бреница",
  "Брест",
  "Брест",
  "Брестак",
  "Брестака",
  "Бресте",
  "Брестник",
  "Брестница",
  "Брестница",
  "Брестова",
  "Брестовене",
  "Брестовец",
  "Брестовица",
  "Брестовица",
  "Брестово",
  "Брестово",
  "Брод",
  "Бродилово",
  "Брош",
  "Брусарци",
  "Брусевци",
  "Брусен",
  "Брусен",
  "Брусино",
  "Брусник",
  "Брънеците",
  "Бръчковци",
  "Бръшлен",
  "Бръшлян",
  "Бръшляница",
  "Бръщен",
  "Бряговец",
  "Бряговица",
  "Брягово",
  "Брягово",
  "Бряст",
  "Брястовец",
  "Брястово",
  "Брястово",
  "Брястово",
  "Бубино",
  "Будилци",
  "Бузовград",
  "Бузяковци",
  "Буйновица",
  "Буйново",
  "Буйново",
  "Буйновци",
  "Бук",
  "Букак",
  "Буката",
  "Букова поляна",
  "Буковец",
  "Буковец",
  "Буковец",
  "Буковец",
  "Буковец",
  "Буковлък",
  "Буково",
  "Буково",
  "Букоровци",
  "Булаир",
  "Буново",
  "Буново",
  "Бураново",
  "Бургас",
  "Буря",
  "Бусинци",
  "Бусманци",
  "Бутан",
  "Бутово",
  "Бутроинци",
  "Бухово",
  "Буховци",
  "Бучино",
  "Бучин проход",
  "Бучуковци",
  "Бъдеще",
  "Бъзовец",
  "Бъзовец",
  "Бъзовица",
  "Бъзън",
  "Българаново",
  "Българево",
  "Българене",
  "Българене",
  "Българене",
  "Българи",
  "Българин",
  "Българка",
  "Българово",
  "Българска поляна",
  "Български извор",
  "Българско Сливово",
  "Българчево",
  "Бънзарето",
  "Бърдарево",
  "Бърдарите",
  "Бърдарски геран",
  "Бърдени",
  "Бърдо",
  "Бърдоква",
  "Бърза река",
  "Бързея",
  "Бързина",
  "Бързица",
  "Бързия",
  "Бъркач",
  "Бъркачево",
  "Бърложница",
  "Бърля",
  "Бърчево",
  "Бъта",
  "Бяга",
  "Бяла",
  "Бяла",
  "Бяла",
  "Бяла вода",
  "Бяла вода",
  "Бяла паланка",
  "Бяла поляна",
  "Бяла река",
  "Бяла река",
  "Бяла река",
  "Бяла река",
  "Бяла Слатина",
  "Бяла черква",
  "Бял бряг",
  "Бялградец",
  "Бял извор",
  "Бял извор",
  "Бялка",
  "Бял кладенец",
  "Бял кладенец",
  "Бялково",
  "Бялковци",
  "Бяло поле",
  "Бунцево",
  "Биркова",
  "Бозьова",
  "Бутрева",
  "Бор",
  "Бабинци",
  "Бойчета",
  "Бурево",
  "Богатино",
  "Брежана",
  "Брегово",
  "Белица",
  "Вазово",
  "Вакарел",
  "Ваклино",
  "Ваклиново",
  "Ваксево",
  "Валето",
  "Варана",
  "Варвара",
  "Варвара",
  "Вардим",
  "Вардун",
  "Варна",
  "Варненци",
  "Варник",
  "Варовник",
  "Васил Друмев",
  "Василево",
  "Василковска махала",
  "Васил Левски",
  "Васил Левски",
  "Васил Левски",
  "Васил Левски",
  "Василовци",
  "Василовци",
  "Васильово",
  "Васково",
  "Ведраре",
  "Ведрина",
  "Ведрово",
  "Везенково",
  "Векилски",
  "Веленци",
  "Велешани",
  "Велика",
  "Великан",
  "Великденче",
  "Великденче",
  "Великово",
  "Великово",
  "Великовци",
  "Велико Търново",
  "Велинград",
  "Велино",
  "Велиново",
  "Величка",
  "Величково",
  "Величково",
  "Велково",
  "Велковци",
  "Велковци",
  "Велковци",
  "Велчево",
  "Велчево",
  "Велчовци",
  "Вельово",
  "Велювци",
  "Велющец",
  "Венелин",
  "Венец",
  "Венец",
  "Венец",
  "Венковец",
  "Венчан",
  "Верен",
  "Веренци",
  "Веринско",
  "Верско",
  "Веселец",
  "Веселец",
  "Веселие",
  "Веселина",
  "Веселина",
  "Веселиново",
  "Веселиново",
  "Веслец",
  "Ветово",
  "Ветрен",
  "Ветрен",
  "Ветрен",
  "Ветрен",
  "Ветрен дол",
  "Ветрино",
  "Ветринци",
  "Ветрище",
  "Ветрово",
  "Ветрушка",
  "Вехтино",
  "Вехтово",
  "Вещица",
  "Виден",
  "Видин",
  "Видинци",
  "Видлица",
  "Видно",
  "Видрар",
  "Видраре",
  "Видрица",
  "Виево",
  "Визица",
  "Виларе",
  "Винарово",
  "Винарово",
  "Винарско",
  "Винево",
  "Виница",
  "Винище",
  "Виноград",
  "Виноградец",
  "Виногради",
  "Вирове",
  "Вировско",
  "Вис",
  "Вискяр",
  "Висок",
  "Висока",
  "Висока могила",
  "Висока поляна",
  "Висока поляна",
  "Високите",
  "Високовци",
  "Витановци",
  "Витина",
  "Вихрен",
  "Вичово",
  "Вишан",
  "Вишеград",
  "Вишлене",
  "Вишна",
  "Вишнево",
  "Вишовград",
  "Владая",
  "Владимир",
  "Владимирово",
  "Владимирово",
  "Владимирово",
  "Владимировци",
  "Владиня",
  "Владислав",
  "Владиславци",
  "Владиченци",
  "Владовци",
  "Владо Тричков",
  "Влайчовци",
  "Свети Влас",
  "Власатили",
  "Власатица",
  "Влахи",
  "Влахово",
  "Влаховци",
  "Водата",
  "Водач",
  "Воден",
  "Воден",
  "Воден",
  "Воденичане",
  "Воденичарово",
  "Воденичарско",
  "Воденци",
  "Водица",
  "Водица",
  "Водна",
  "Водни пад",
  "Водно",
  "Водно",
  "Воднянци",
  "Воднянци",
  "Водолей",
  "Вождово",
  "Войвода",
  "Войводенец",
  "Войводино",
  "Войводиново",
  "Войводово",
  "Войводово",
  "Войкова лъка",
  "Войнеговци",
  "Войнежа",
  "Войника",
  "Войниково",
  "Войница",
  "Войници",
  "Войниците",
  "Войнишка",
  "Войново",
  "Войново",
  "Войнягово",
  "Войсил",
  "Вокил",
  "Волно",
  "Воловарово",
  "Воловарци",
  "Волово",
  "Волуяк",
  "Вонеща вода",
  "Врабево",
  "Врабците",
  "Врабча",
  "Вракуповица",
  "Врани кон",
  "Враниловци",
  "Вранино",
  "Вранинци",
  "Вранско",
  "Враня",
  "Враняк",
  "Враня стена",
  "Вратарите",
  "Вратица",
  "Враца",
  "Врачанци",
  "Врачеш",
  "Врело",
  "Вресово",
  "Връв",
  "Връдловци",
  "Всемирци",
  "Вукан",
  "Вуково",
  "Въбел",
  "Въгларово",
  "Въглевци",
  "Въглен",
  "Въглен",
  "Възел",
  "Вълкан",
  "Вълков дол",
  "Вълкович",
  "Вълково",
  "Вълковци",
  "Вълкосел",
  "Вълнари",
  "Вълчан",
  "Вълчан дол",
  "Вълча поляна",
  "Вълчедръм",
  "Вълчек",
  "Вълче поле",
  "Вълчи дол",
  "Вълчи извор",
  "Вълчин",
  "Вълчитрън",
  "Вълчовци",
  "Вълчовци",
  "Върба",
  "Върба",
  "Върбак",
  "Царева ливада",
  "Върбен",
  "Върбенци",
  "Върбешница",
  "Върбина",
  "Върбино",
  "Върбица",
  "Върбица",
  "Върбица",
  "Върбица",
  "Върбница",
  "Върбовка",
  "Върбово",
  "Върбово",
  "Върбово",
  "Върбовчец",
  "Върбяне",
  "Въргов дол",
  "Вързилковци",
  "Вързулица",
  "Върли дол",
  "Върли дол",
  "Върлинка",
  "Върлино",
  "Въртоп",
  "Вършец",
  "Вършило",
  "Враненци",
  "Врата",
  "Вълчанка",
  "Валевци",
  "Габаре",
  "Габарево",
  "Габер",
  "Габер",
  "Габерово",
  "Габерово",
  "Габра",
  "Габрака",
  "Габрене",
  "Габрешевци",
  "Габрина",
  "Габрица",
  "Габрица",
  "Габровдол",
  "Габровица",
  "Габровница",
  "Габровница",
  "Габрово",
  "Габрово",
  "Габрово",
  "Габровци",
  "Габър",
  "Габърница",
  "Гавраилово",
  "Гаврил Геново",
  "Гаганица",
  "Гагово",
  "Гайдари",
  "Гайкини",
  "Гайтанево",
  "Гайтаниново",
  "Гайтаните",
  "Гайтанци",
  "Галата",
  "Галатин",
  "Галиче",
  "Галище",
  "Галово",
  "Ганев дол",
  "Ганчовец",
  "Бов",
  "Лакатник",
  "Орешец",
  "Кресна",
  "Хитрино",
  "Златарица",
  "Гарван",
  "Гарван",
  "Гарваново",
  "Гащевци",
  "Гега",
  "Гела",
  "Гелеменово",
  "Генерал Гешево",
  "Генерал Кантарджиево",
  "Генерал Киселово",
  "Генерал Колево",
  "Генерал Колево",
  "Генерал Мариново",
  "Генералово",
  "Генерал Тошево",
  "Генерал Тошево",
  "Геновци",
  "Генчовци",
  "Геня",
  "Георги Дамяново",
  "Георги Добрево",
  "Гергевец",
  "Гергини",
  "Герман",
  "Геша",
  "Гешаново",
  "Гиген",
  "Искър",
  "Гигинци",
  "Гинци",
  "Гирчевци",
  "Гита",
  "Глава",
  "Главан",
  "Главан",
  "Главановци",
  "Главановци",
  "Главанци",
  "Главатар",
  "Главатарци",
  "Главаци",
  "Главиница",
  "Главиница",
  "Глашатай",
  "Гледаци",
  "Гледачево",
  "Гледка",
  "Глогинка",
  "Глогино",
  "Глоговец",
  "Глоговица",
  "Глогово",
  "Глоджево",
  "Гложене",
  "Глумово",
  "Глумче",
  "Глутниците",
  "Глуфишево",
  "Глухар",
  "Глушка",
  "Глушник",
  "Гнездаре",
  "Гняздово",
  "Говедаре",
  "Говедарци",
  "Говежда",
  "Годеч",
  "Годешево",
  "Годлево",
  "Гоз",
  "Гоздевица",
  "Гоздейка",
  "Голак",
  "Големани",
  "Големаните",
  "Големаново",
  "Големанци",
  "Голема Раковица",
  "Голема Фуча",
  "Голям Върбовник",
  "Големи Българени",
  "Големи Станчовци",
  "Големо Бабино",
  "Големо Бучино",
  "Големо Малово",
  "Голямо Пещене",
  "Големо село",
  "Голем Цалим",
  "Голец",
  "Голеш",
  "Голеш",
  "Голешово",
  "Голица",
  "Голобрад",
  "Голобрадово",
  "Гълъбово",
  "Голяма бара",
  "Голяма Брестница",
  "Голяма вода",
  "Голяма долина",
  "Голяма Желязна",
  "Голяма Чинка",
  "Голям Девесил",
  "Голям Дервент",
  "Голям дол",
  "Голям извор",
  "Голям извор",
  "Голям извор",
  "Голям манастир",
  "Голямо Асеново",
  "Голямо Белово",
  "Голямо Буково",
  "Голямо Враново",
  "Голямо градище",
  "Голямо Доляне",
  "Голямо Дряново",
  "Голямо Каменяне",
  "Голямо Крушево",
  "Голямо ново",
  "Голямо Соколово",
  "Голямо църквище",
  "Голямо Чочовени",
  "Шивачево",
  "Голям Поровец",
  "Подгорец",
  "Голям чардак",
  "Голяновци",
  "Гомотарци",
  "Горан",
  "Горановци",
  "Горановци",
  "Гореме",
  "Горен Еневец",
  "Горен чифлик",
  "Горица",
  "Горица",
  "Горица",
  "Горичане",
  "Горичево",
  "Горна Бела речка",
  "Горна Бешовица",
  "Горна Брезница",
  "Горна Брестница",
  "Горна Вереница",
  "Горна Врабча",
  "Горна Глоговица",
  "Септемврийци",
  "Горна Гращица",
  "Горна Диканя",
  "Горна Златица",
  "Горна Кабда",
  "Горна Ковачица",
  "Горна Козница",
  "Горна Кремена",
  "Горна крепост",
  "Горна Крушица",
  "Горна кула",
  "Горна Липница",
  "Горна Лука",
  "Горна Малина",
  "Горна махала",
  "Горна Мелна",
  "Горна Митрополия",
  "Горна Оряховица",
  "Горна Рибница",
  "Горна Росица",
  "Горна Секирна",
  "Горна Студена",
  "Горна Сушица",
  "Горна Хаджийска",
  "Горна Хубавка",
  "Горни Богров",
  "Горни Вадин",
  "Горни Върпища",
  "Горни Главанак",
  "Горни Дамяновци",
  "Горни Домлян",
  "Горни Драгойча",
  "Горни Дъбник",
  "Горник",
  "Горни Коритен",
  "Горни край",
  "Горни Лом",
  "Горни Маренци",
  "Горни Окол",
  "Горни Радковци",
  "Горни Романци",
  "Горни Танчевци",
  "Горни Цибър",
  "Горни Цоневци",
  "Горни Юруци",
  "Горно Абланово",
  "Горно Александрово",
  "Горно Белево",
  "Горно Ботево",
  "Горнова могила",
  "Горно Войводино",
  "Горно вършило",
  "Горно Драглище",
  "Горно Дряново",
  "Горно Изворово",
  "Горно Камарци",
  "Горно Кобиле",
  "Горно Козарево",
  "Горно Къпиново",
  "Горно Луково",
  "Горно Новково",
  "Горно ново село",
  "Горно Озирово",
  "Горно Осеново",
  "Горно Павликене",
  "Горно Пещене",
  "Горно поле",
  "Горно Прахово",
  "Горно Сахране",
  "Горно село",
  "Горноселци",
  "Горнослав",
  "Горно Спанчево",
  "Горно трапе",
  "Горно Уйно",
  "Горно Хърсово",
  "Горно Церовене",
  "Горно Ябълково",
  "Горово",
  "Горово",
  "Гороцвет",
  "Горочевци",
  "Горска",
  "Горска поляна",
  "Горски горен Тръмбеш",
  "Горски долен Тръмбеш",
  "Горски извор",
  "Горски извор",
  "Горски Сеновец",
  "Горско",
  "Горско Абланово",
  "Горско Дюлево",
  "Горско Калугерово",
  "Горско Косово",
  "Горско ново село",
  "Горско Писарево",
  "Горско село",
  "Горско Сливово",
  "Горталово",
  "Горун",
  "Горунака",
  "Горчево",
  "Горяни",
  "Господиново",
  "Господиново",
  "Господинци",
  "Гостилица",
  "Гостиля",
  "Гостиня",
  "Гостун",
  "Гоце Делчев",
  "Градево",
  "Градежница",
  "Градец",
  "Градец",
  "Градец",
  "Градешница",
  "Стефан Стамболово",
  "Градина",
  "Градина",
  "Градина",
  "Градинарово",
  "Градини",
  "Градинка",
  "Градище",
  "Градище",
  "Градище",
  "Градница",
  "Градница",
  "Градско",
  "Градсковски колиби",
  "Градът",
  "Грамада",
  "Грамаде",
  "Грамаде",
  "Грамаждано",
  "Граматиково",
  "Граматици",
  "Гранит",
  "Гранитец",
  "Гранитово",
  "Гранитово",
  "Граница",
  "Граничак",
  "Граничар",
  "Граничар",
  "Режанци",
  "Граф Игнатиево",
  "Грашево",
  "Гращица",
  "Греевци",
  "Гривица",
  "Гривка",
  "Гривяк",
  "Григорево",
  "Грозден",
  "Гроздьово",
  "Громшин",
  "Грохотно",
  "Боздуганово",
  "Средец",
  "Груево",
  "Гръблевци",
  "Грълска падина",
  "Грънчарово",
  "Губеш",
  "Губислав",
  "Гугутка",
  "Гудевица",
  "Гулийка",
  "Гулия",
  "Гулянци",
  "Гумощник",
  "Гурбановци",
  "Гургулица",
  "Гургулят",
  "Гурково",
  "Гурково",
  "Гурково",
  "Гурмазово",
  "Гусла",
  "Гуслар",
  "Гуцал",
  "Гъбене",
  "Гълъбец",
  "Гълъбец",
  "Гълъбинци",
  "Гълъбник",
  "Гълъбово",
  "Гълъбово",
  "Гълъбовци",
  "Гъмзово",
  "Гърбино",
  "Гърбище",
  "Гърдевци",
  "Гърло",
  "Гърляно",
  "Гърмен",
  "Гърнати",
  "Гърня",
  "Гърчиново",
  "Гъсково",
  "Гьоврен",
  "Гюешево",
  "Гюльовца",
  "Гюргево",
  "Гюргич",
  "Елин Пелин",
  "Гложене",
  "Горна Биркова",
  "Горна Дъбева",
  "Главник",
  "Горно Кирково",
  "Горна Василица",
  "Горица",
  "Гецово",
  "Давери",
  "Давидково",
  "Давидово",
  "Давидово",
  "Дагоново",
  "Даевци",
  "Дайновци",
  "Дамяница",
  "Дамяново",
  "Даскал-Атанасово",
  "Даскалово",
  "Две могили",
  "Две тополи",
  "Дворище",
  "Дворище",
  "Дебел дял",
  "Дебелец",
  "Дебели лаг",
  "Дебели рът",
  "Дебелт",
  "Дебелцово",
  "Дебеляново",
  "Дебнево",
  "Дебово",
  "Дебочица",
  "Дебрен",
  "Дебрене",
  "Дебрене",
  "Дебръщица",
  "Девене",
  "Девенци",
  "Девесилица",
  "Девесилово",
  "Деветак",
  "Деветаки",
  "Деветаците",
  "Деветинци",
  "Девин",
  "Девино",
  "Девня",
  "Дедец",
  "Дедина",
  "Дединци",
  "Дедово",
  "Деков",
  "Делвино",
  "Делвино",
  "Делейна",
  "Делова махала",
  "Делчево",
  "Дебелец",
  "Делчево",
  "Делян",
  "Делян",
  "Деляновци",
  "Денница",
  "Денница",
  "Денчевци",
  "Дервишка могила",
  "Дерманци",
  "Детелина",
  "Дечковци",
  "Джамузовци",
  "Джанка",
  "Джебел",
  "Джелепско",
  "Джерман",
  "Джерово",
  "Джигурово",
  "Джинот",
  "Джинчовци",
  "Джулюница",
  "Джулюница",
  "Джумриите",
  "Джурково",
  "Джурово",
  "Джуровци",
  "Зорница",
  "Дибич",
  "Дива Слатина",
  "Дивеци",
  "Дивля",
  "Дивотино",
  "Дивчовото",
  "Диманово",
  "Димиевци",
  "Димитриево",
  "Димитровград",
  "Димитровци",
  "Димитровче",
  "Победа",
  "Димово",
  "Димово",
  "Димовци",
  "Димовци",
  "Димча",
  "Димчево",
  "Динево",
  "Динк",
  "Динката",
  "Динковица",
  "Динково",
  "Динково",
  "Диня",
  "Дирало",
  "Дисманица",
  "Дичево",
  "Дичин",
  "Длъгнево",
  "Длъгня",
  "Длъжка поляна",
  "Длъжко",
  "Длъхчево-Сабляр",
  "Доборско",
  "Добравица",
  "Добралък",
  "Добра поляна",
  "Добрева череша",
  "Добрево",
  "Добревци",
  "Добревци",
  "Добревци",
  "Добрените",
  "Добри Войниково",
  "Добри дол",
  "Добри дол",
  "Добри дол",
  "Добри дол",
  "Добри дял",
  "Добри лаки",
  "Добрин",
  "Добрина",
  "Добринище",
  "Добриново",
  "Добриново",
  "Добринци",
  "Добрич",
  "Добрич",
  "Добровница",
  "Доброво",
  "Доброглед",
  "Добродан",
  "Добролево",
  "Добромир",
  "Добромирка",
  "Добромирци",
  "Доброплодно",
  "Доброселец",
  "Доброславци",
  "Добростан",
  "Добротино",
  "Добротица",
  "Добротица",
  "Добротич",
  "Добруджанка",
  "Добруша",
  "Добърско",
  "Добърчин",
  "Доганово",
  "Дождевица",
  "Дойновци",
  "Дойранци",
  "Дойранци",
  "Дойренци",
  "Докатичево",
  "Доктор Йосифово",
  "Докьовци",
  "Долен",
  "Долен",
  "Долене",
  "Долен Еневец",
  "Долени",
  "Долни чифлик",
  "Долец",
  "Долец",
  "Долие",
  "Долина",
  "Долина",
  "Долистово",
  "Долище",
  "Долище",
  "Долна баня",
  "Долна Бела речка",
  "Долна Бешовица",
  "Долна Вереница",
  "Долна Врабча",
  "Долна Градешница",
  "Долна Гращица",
  "Долна Диканя",
  "Долна Златица",
  "Долна Кабда",
  "Долна Козница",
  "Долна Кремена",
  "Долна крепост",
  "Долна Крушица",
  "Долна кула",
  "Долна Липница",
  "Долна Малина",
  "Долна махала",
  "Долна Мелна",
  "Долна Митрополия",
  "Долна Невля",
  "Долна Оряховица",
  "Долна Рибница",
  "Долна Рикса",
  "Долна Секирна",
  "Долна Студена",
  "Долна Хубавка",
  "Долни Богров",
  "Долни Бошняк",
  "Долни Вадин",
  "Долни Вит",
  "Долни Върпища",
  "Долни Главанак",
  "Долни Дамяновци",
  "Долни Драгойча",
  "Долни Дъбник",
  "Долни Коритен",
  "Долни Лом",
  "Долни Луковит",
  "Долни Маренци",
  "Долни Марян",
  "Долни Окол",
  "Долни Пасарел",
  "Долни Радковци",
  "Долни Раковец",
  "Долни Романци",
  "Долни Танчевци",
  "Долни Томчевци",
  "Долни Цибър",
  "Долни Юруци",
  "Долно Абланово",
  "Долно Белево",
  "Долно Ботево",
  "Долно Войводино",
  "Долно вършило",
  "Долно Драглище",
  "Долно Дряново",
  "Долно изворово",
  "Долно Камарци",
  "Долно Кобиле",
  "Долно Козарево",
  "Долно Къпиново",
  "Левски",
  "Долно Линево",
  "Долно Луково",
  "Долно Новково",
  "Долно ново село",
  "Долно ново село",
  "Долно Озирово",
  "Долно Осеново",
  "Паничерево",
  "Долно поле",
  "Долно Прахово",
  "Долно Ряхово",
  "Долно Сахране",
  "Долно село",
  "Долноселци",
  "Долнослав",
  "Долно Спанчево",
  "Долно Съдиево",
  "Долно Уйно",
  "Долно Церовене",
  "Долно Черковище",
  "Долно Шивачево",
  "Долно Ябълково",
  "Домище",
  "Домлян",
  "Дондуково",
  "Донино",
  "Донкино",
  "Донковци",
  "Дончево",
  "Дончовци",
  "Дорково",
  "Доситеево",
  "Доспат",
  "Доспей",
  "Доча",
  "Драбишна",
  "Драгана",
  "Драганица",
  "Драгановец",
  "Драганово",
  "Драганово",
  "Драганово",
  "Драганово",
  "Драгановци",
  "Драгановци",
  "Драганосковци",
  "Драганци",
  "Драганчетата",
  "Драгаш войвода",
  "Драгиевци",
  "Драгижево",
  "Драгийци",
  "Драгиново",
  "Драгичево",
  "Драгневци",
  "Драгневци",
  "Драговищица",
  "Драговищица",
  "Драгово",
  "Драгодан",
  "Драгоданово",
  "Драгоево",
  "Драгоил",
  "Драгойново",
  "Драгойчинци",
  "Драгоман",
  "Драгомани",
  "Драгомир",
  "Драгомирово",
  "Драгомирово",
  "Драгор",
  "Драготинци",
  "Драгуш",
  "Драгушиново",
  "Дражево",
  "Дражинци",
  "Драка",
  "Драката",
  "Дралфа",
  "Драма",
  "Дрангово",
  "Дрангово",
  "Дрангово",
  "Драндарите",
  "Драчево",
  "Драшан",
  "Драшкова поляна",
  "Дреатин",
  "Дрен",
  "Дренково",
  "Дренов",
  "Дреновец",
  "Дреновица",
  "Дреново",
  "Дреново",
  "Дрента",
  "Дренци",
  "Дриново",
  "Дрипчево",
  "Дропла",
  "Дропла",
  "Дружба",
  "Дружево",
  "Дружинци",
  "Друмево",
  "Друмохар",
  "Друмче",
  "Дръмша",
  "Дръндар",
  "Дрянка",
  "Дрянковец",
  "Дрянова глава",
  "Дряновец",
  "Дряновец",
  "Дряновец",
  "Дряновец",
  "Дряново",
  "Дряново",
  "Дряново",
  "Дряново",
  "Дуванлии",
  "Капитан Петко войвода",
  "Дуковци",
  "Дулово",
  "Дунавец",
  "Дунавци",
  "Дунавци",
  "Дунавци",
  "Дунево",
  "Бели Лом",
  "Дуранкулак",
  "Лудогорци",
  "Дуровци",
  "Дурча",
  "Духовец",
  "Душанци",
  "Душево",
  "Душевски колиби",
  "Душинково",
  "Душинци",
  "Душка",
  "Дъбен",
  "Дъбене",
  "Дъбник",
  "Дъбница",
  "Дъбова",
  "Дъбова",
  "Дъбова махала",
  "Дъбован",
  "Дъбовец",
  "Долна Дъбева",
  "Дъбовик",
  "Дъбово",
  "Дъбово",
  "Дъбрава",
  "Дъбрава",
  "Дъбрава",
  "Дъбравата",
  "Дъбравино",
  "Дъбравите",
  "Дъбравица",
  "Дъбравка",
  "Дъждино",
  "Дъждовник",
  "Дъждовница",
  "Дълбок дол",
  "Дълбоки",
  "Дълбок извор",
  "Дълга лука",
  "Дългач",
  "Дълги дел",
  "Дълги припек",
  "Дългоделци",
  "Дългопол",
  "Дълго поле",
  "Дълго поле",
  "Дънгово",
  "Дървари",
  "Държава",
  "Държавен",
  "Държаница",
  "Дърлевци",
  "Дърманци",
  "Дъскарите",
  "Дъскот",
  "Дъскотна",
  "Дюлево",
  "Дюлево",
  "Дюлино",
  "Дюлица",
  "Дюля",
  "Дядово",
  "Дядовско",
  "Дядовци",
  "Дяково",
  "Дянково",
  "Друган",
  "Думници",
  "Дедино",
  "Долна Василица",
  "Драгомъж",
  "Дисевица",
  "Долно Големанци",
  "Дуня",
  "Дуня",
  "Девинци",
  "Девинци",
  "Евлогиево",
  "Евренозово",
  "Егрек",
  "Егълница",
  "Единаковци",
  "Едрево",
  "Ездимирци",
  "Езерец",
  "Езеро",
  "Езерово",
  "Езерово",
  "Езерче",
  "Екзарх Антимово",
  "Екзарх Йосиф",
  "Елена",
  "Елена",
  "Еленино",
  "Еленов дол",
  "Еленово",
  "Еленово",
  "Еленово",
  "Еленска",
  "Еленците",
  "Елешница",
  "Елин Пелин",
  "Елисейна",
  "Еловдол",
  "Елов дол",
  "Еловица",
  "Еловица",
  "Елховец",
  "Елхово",
  "Елхово",
  "Елшица",
  "Ельово",
  "Емен",
  "Белодол",
  "Емона",
  "Енево",
  "Енево",
  "Енев рът",
  "Енина",
  "Еница",
  "Енчец",
  "Енчовци",
  "Еньовче",
  "Ерден",
  "Еремия",
  "Ерма река",
  "Еровете",
  "Ерул",
  "Есен",
  "Есеница",
  "Етрополе",
  "Ефрейтор Бакалово",
  "Ефрем",
  "Езерото",
  "Едрино",
  "Жабляно",
  "Жабокрът",
  "Жегларци",
  "Жеглица",
  "Жедна",
  "Железари",
  "Железари",
  "Железарци",
  "Железино",
  "Железна",
  "Железник",
  "Железник",
  "Железница",
  "Железница",
  "Желен",
  "Желъд",
  "Желъдово",
  "Желю войвода",
  "Желява",
  "Желязковец",
  "Момина църква",
  "Желязно",
  "Желязово",
  "Женда",
  "Жеравино",
  "Жеравна",
  "Жерговец",
  "Жернов",
  "Живко",
  "Живково",
  "Живково",
  "Жижево",
  "Жиленци",
  "Жилино",
  "Жинзифово",
  "Житарник",
  "Ветрен",
  "Житен",
  "Житен",
  "Житница",
  "Житница",
  "Житница",
  "Житница",
  "Житосвят",
  "Жребево",
  "Жребино",
  "Жребичко",
  "Жълт бряг",
  "Жълтеш",
  "Жълти бряг",
  "Жълти рид",
  "Жълт камък",
  "Жълтопоп",
  "Жълтуша",
  "Жълтика",
  "Житуша",
  "Забел",
  "Заберново",
  "Забърдо",
  "Завала",
  "Завет",
  "Завет",
  "Заветно",
  "Завидовци",
  "Завой",
  "Завоя",
  "Загоре",
  "Загориче",
  "Загорски",
  "Загорско",
  "Загорци",
  "Загорци",
  "Загорци",
  "Загражден",
  "Загражден",
  "Задруга",
  "Заевите",
  "Заимчево",
  "Зайчар",
  "Зайчари",
  "Зайчино",
  "Зайчино ореше",
  "Замфир",
  "Замфирово",
  "Занога",
  "Заноге",
  "Зараево",
  "Зарник",
  "Заселе",
  "Засмяно",
  "Зафирово",
  "Могилино",
  "Захари Стояново",
  "Захари Стояново",
  "Зая",
  "Звегор",
  "Звезда",
  "Звезда",
  "Звездел",
  "Звезделина",
  "Звезден",
  "Звездец",
  "Звездица",
  "Звенимир",
  "Зверино",
  "Звиница",
  "Звънарка",
  "Звънарци",
  "Звънец",
  "Звъника",
  "Звъничево",
  "Звънче",
  "Згалево",
  "Згориград",
  "Згурово",
  "Здравец",
  "Здравец",
  "Здравец",
  "Здравец",
  "Здравковец",
  "Здравчец",
  "Зебил",
  "Зелена морава",
  "Зелендол",
  "Зелениград",
  "Зеленик",
  "Зеленика",
  "Зелениково",
  "Зелениково",
  "Зелено дърво",
  "Земен",
  "Земенци",
  "Землен",
  "Зетьово",
  "Зетьово",
  "Зидарово",
  "Зидарци",
  "Зимевица",
  "Зимен",
  "Зимзелен",
  "Зимница",
  "Зимница",
  "Зимница",
  "Зимовина",
  "Зиморница",
  "Златар",
  "Златари",
  "Златарица",
  "Златевци",
  "Злати войвода",
  "Златина",
  "Златиница",
  "Златирът",
  "Златитрап",
  "Златица",
  "Златия",
  "Златия",
  "Златна ливада",
  "Златна нива",
  "Златна Панега",
  "Златовръх",
  "Златоград",
  "Златоклас",
  "Златолист",
  "Златолист",
  "Златополе",
  "Златосел",
  "Златоустово",
  "Златуша",
  "Злидол",
  "Злогош",
  "Злокучене",
  "Злокучене",
  "Змеево",
  "Змеица",
  "Змейно",
  "Змейово",
  "Змиево",
  "Знаменосец",
  "Зограф",
  "Зойчене",
  "Зорница",
  "Зорница",
  "Зорница",
  "Зорница",
  "Зорница",
  "Зърнево",
  "Зарица",
  "Звездица",
  "Здравец",
  "Чучур",
  "Ивайло",
  "Ивайловград",
  "Иван Вазово",
  "Иван Димов",
  "Иванивановци",
  "Иванили",
  "Иванковци",
  "Иваново",
  "Иваново",
  "Иваново",
  "Иваново",
  "Ивановци",
  "Ивановци",
  "Ивановци",
  "Ивански",
  "Иванци",
  "Иванча",
  "Иванча",
  "Иван Шишманово",
  "Иваняне",
  "Иганово",
  "Иглика",
  "Иглика",
  "Иглика",
  "Игнатиево",
  "Игнатица",
  "Игнатово",
  "Игнатовци",
  "Игнатовци",
  "Игралище",
  "Идилево",
  "Избеглии",
  "Избул",
  "Извор",
  "Извор",
  "Извор",
  "Нови извор",
  "Извор",
  "Извор",
  "Изворище",
  "Извор махала",
  "Изворник",
  "Изворово",
  "Изворово",
  "Изворово",
  "Изворово",
  "Изворско",
  "Изворче",
  "Изгрев",
  "Изгрев",
  "Изгрев",
  "Изгрев",
  "Изгрев",
  "Изгрев",
  "Изгрев",
  "Източник",
  "Илаков рът",
  "Смолево",
  "Илевци",
  "Илийно",
  "Илийско",
  "Илинден",
  "Илинден",
  "Илинденци",
  "Илиница",
  "Илиювци",
  "Илия",
  "Илия Блъсково",
  "Имренчево",
  "Индже войвода",
  "Генерал Инзово",
  "Иново",
  "Иречек",
  "Иречеково",
  "Иринеци",
  "Ирник",
  "Искра",
  "Искра",
  "Искра",
  "Искра",
  "Искрец",
  "Искрица",
  "Искър",
  "Исперих",
  "Исперихово",
  "Исьовци",
  "Ихтиман",
  "Ичера",
  "Иваново",
  "Искра",
  "Йерусалимово",
  "Йоаким Груево",
  "Йовково",
  "Йововци",
  "Йовчевци",
  "Йоглав",
  "Йонково",
  "Йончово",
  "Елешница",
  "Йорданово",
  "Светлен",
  "Кабиле",
  "Каблешково",
  "Каблешково",
  "Каблешково",
  "Каварна",
  "Кавлак",
  "Кавракирово",
  "Кадиево",
  "Сушица",
  "Кадровица",
  "Невестино",
  "Казак",
  "Казанка",
  "Казанлък",
  "Казаците",
  "Казачево",
  "Казашка река",
  "Казашко",
  "Казимир",
  "Казичене",
  "Кайнарджа",
  "Калайджиево",
  "Калайджии",
  "Калейца",
  "Калековец",
  "Кален",
  "Каленик",
  "Каленик",
  "Каленовци",
  "Калиманци",
  "Калиманци",
  "Калина",
  "Калина",
  "Калинка",
  "Калино",
  "Калитиново",
  "Калище",
  "Калнище",
  "Калово",
  "Каломен",
  "Калотина",
  "Калотинци",
  "Калофер",
  "Калоян",
  "Калояновец",
  "Калояново",
  "Калояново",
  "Калоянци",
  "Калугерене",
  "Калугерово",
  "Калугерово",
  "Калугерово",
  "Калчево",
  "Калчовци",
  "Камбелевци",
  "Камбурово",
  "Камен",
  "Камен",
  "Камен",
  "Камена",
  "Каменар",
  "Каменар",
  "Каменар",
  "Каменари",
  "Каменарци",
  "Камен бряг",
  "Камен връх",
  "Камен дял",
  "Каменец",
  "Каменец",
  "Каменик",
  "Каменица",
  "Каменица",
  "Каменичка Скакавица",
  "Каменка",
  "Каменна река",
  "Каменна Рикса",
  "Камено",
  "Каменово",
  "Каменово",
  "Камено поле",
  "Каменско",
  "Каменци",
  "Каменяк",
  "Каменяк",
  "Каменяне",
  "Камещица",
  "Камилски дол",
  "Камчия",
  "Кандилка",
  "Кандови",
  "Каниц",
  "Кантари",
  "Каняк",
  "Каолиново",
  "Капатово",
  "Къпиново",
  "Капитан Андреево",
  "Капитан Димитриево",
  "Капитан Димитрово",
  "Капитановци",
  "Капитан Петко",
  "Капище",
  "Карабунар",
  "Каравелово",
  "Каравелово",
  "Каравелово",
  "Каравельово",
  "Карагеоргиево",
  "Караджалово",
  "Караджово",
  "Караиванца",
  "Караиванци",
  "Караисен",
  "Карали",
  "Караманите",
  "Караманово",
  "Караманци",
  "Кара Михал",
  "Карамичевци",
  "Карамфил",
  "Каран Върбовка",
  "Карандили",
  "Караново",
  "Караново",
  "Каранци",
  "Карапелит",
  "Караполци",
  "Караш",
  "Карбинци",
  "Карвуна",
  "Кардам",
  "Кардам",
  "Карлиево",
  "Карлово",
  "Карловско",
  "Карлуково",
  "Карнобат",
  "Касапско",
  "Касилаг",
  "Каснаково",
  "Каспичан",
  "Каспичан",
  "Каспичан",
  "Кастел",
  "Катерица",
  "Катранджии",
  "Катраница",
  "Катрище",
  "Катунец",
  "Катуница",
  "Катунище",
  "Катунци",
  "Кацелово",
  "Качулка",
  "Кашенци",
  "Кашина",
  "Каялоба",
  "Керека",
  "Керените",
  "Кермен",
  "Кесарево",
  "Кестен",
  "Кестеново",
  "Киевци",
  "Килифарево",
  "Кипилово",
  "Кипра",
  "Киревци",
  "Киреево",
  "Кирилово",
  "Кирилово",
  "Киркова махала",
  "Кирково",
  "Кирово",
  "Кирчево",
  "Киселево",
  "Киселица",
  "Киселчово",
  "Киселковци",
  "Кисийците",
  "Кисьовци",
  "Китанчево",
  "Китен",
  "Китен",
  "Китино",
  "Китка",
  "Китна",
  "Китница",
  "Кичево",
  "Киченица",
  "Кладенец",
  "Кладенец",
  "Кладенци",
  "Кладенци",
  "Кладни дял",
  "Кладница",
  "Кладоруб",
  "Клепало",
  "Кликач",
  "Климаш",
  "Климент",
  "Климент",
  "Климентово",
  "Клисура",
  "Клисура",
  "Клисура",
  "Клисура",
  "Клисурица",
  "ман. Клисурски манастир",
  "Клокотница",
  "Клъшка река",
  "Ключ",
  "Кметовци",
  "Кметчета",
  "Кнежа",
  "Книжовник",
  "Княжева махала",
  "Княжево",
  "Кобилино",
  "Кобиляк",
  "Кобиляне",
  "Ковач",
  "Ковачевец",
  "Ковачевица",
  "Ковачево",
  "Ковачево",
  "Ковачево",
  "Ковачевци",
  "Ковачевци",
  "Ковачите",
  "Ковачица",
  "Ковил",
  "Коевци",
  "Коевци",
  "Кожари",
  "Кожинци",
  "Кожлювци",
  "Кожухарци",
  "Козаново",
  "Козар Белене",
  "Козаре",
  "Козаревец",
  "Козаревец",
  "Козарево",
  "Козарка",
  "Козарско",
  "Кози рог",
  "Козица",
  "Козица",
  "Козичино",
  "Козлево",
  "Козлец",
  "Козловец",
  "Козлодуй",
  "Козлодуйци",
  "Козма презвитер",
  "Козница",
  "Козяк",
  "Козя река",
  "Коиловци",
  "Койнаре",
  "Койчовци",
  "Кокаляне",
  "Кокиче",
  "Кoкорово",
  "Кокорово",
  "Кокорци",
  "Кокошане",
  "Колари",
  "Коларово",
  "Коларово",
  "Коларово",
  "Коларово",
  "Коларци",
  "Колена",
  "Колец",
  "Колибите",
  "Колишовци",
  "Колобър",
  "Колю Ганев",
  "Колю Мариново",
  "Комарево",
  "Комарево",
  "Комарево",
  "Комарево",
  "Комощица",
  "Комунари",
  "Комунига",
  "Комщица",
  "Конак",
  "Конаре",
  "Конаре",
  "Конарско",
  "Конарското",
  "Кондово",
  "Кондолово",
  "Кондофрей",
  "Коневец",
  "Конево",
  "Конево",
  "Конево",
  "Конници",
  "Коноп",
  "Константин",
  "Константиновец",
  "Константиново",
  "Константиново",
  "Контил",
  "Конуш",
  "Конуш",
  "Конче",
  "Коньово",
  "Коняво",
  "Копаница",
  "Копиловци",
  "Копиловци",
  "Копитник",
  "Копрец",
  "Коприва",
  "Копривец",
  "Копривлен",
  "Копривщица",
  "Копринка",
  "Копчелиите",
  "Корен",
  "Кориите",
  "Коритата",
  "Коритен",
  "Корията",
  "Коркина",
  "Кормянско",
  "Корница",
  "Кортен",
  "Кос",
  "Косара",
  "Косарка",
  "Косача",
  "Косевци",
  "Косилка",
  "Косовец",
  "Косово",
  "Косово",
  "Косово",
  "Косово",
  "Костадините",
  "Костанденец",
  "Костандово",
  "Коста Перчево",
  "Костел",
  "Костелево",
  "Костен",
  "Костена река",
  "Костенец",
  "Костенец",
  "Костенковци",
  "Костенци",
  "Кости",
  "Костиево",
  "Костилково",
  "Костинброд",
  "Костино",
  "Костичовци",
  "Костур",
  "Костурино",
  "Костуринци",
  "Котел",
  "Котеновци",
  "Котлари",
  "Котленци",
  "Котуци",
  "Кочан",
  "Кочани",
  "Кочево",
  "Кочериново",
  "Кочмар",
  "Кочово",
  "Кошава",
  "Кошарево",
  "Кошарица",
  "Кошарна",
  "Кошница",
  "Кошничари",
  "Кошов",
  "Кравино",
  "Краводер",
  "Крагулево",
  "Краево",
  "Краище",
  "Краище",
  "Крайгорци",
  "Крайна",
  "Крайни дол",
  "Крайници",
  "Крайно село",
  "Крайново",
  "Крайполе",
  "Кракра",
  "Кралев дол",
  "Кралево",
  "Кралево",
  "Крали Марко",
  "Крамолин",
  "Кран",
  "Кранево",
  "Краново",
  "Крапец",
  "Крапец",
  "Крапчене",
  "Красава",
  "Красен",
  "Красен",
  "Красен дол",
  "Красимир",
  "Красино",
  "Красново",
  "Красно градище",
  "Красноселци",
  "Крачимир",
  "Кремен",
  "Кремена",
  "Кремене",
  "Кременик",
  "Крепост",
  "Крепча",
  "Креслювци",
  "Стара Кресна",
  "Крета",
  "Крета",
  "Крибул",
  "Крива бара",
  "Крива бара",
  "Крива круша",
  "Крива река",
  "Кривина",
  "Кривина",
  "Кривини",
  "Кривица",
  "Кривня",
  "Кривня",
  "Криводол",
  "Кривонос",
  "Криво поле",
  "Крилатица",
  "Крилювци",
  "Крин",
  "Крислово",
  "Кричим",
  "Кромидово",
  "Крояч",
  "Кроячево",
  "Крум",
  "Крумовград",
  "Крумово",
  "Крумово",
  "Крумово",
  "Крумово",
  "Крумово градище",
  "Крумчевци",
  "Крупен",
  "Крупник",
  "Круша",
  "Круша",
  "Крушаре",
  "Крушари",
  "Крушев дол",
  "Крушевец",
  "Крушево",
  "Крушево",
  "Крушево",
  "Крушевска",
  "Крушето",
  "Крушка",
  "Крушовене",
  "Крушовица",
  "Крушовица",
  "Крушовица",
  "Крушово",
  "Крушово",
  "Крушолак",
  "Крушуна",
  "Кръвеник",
  "Крън",
  "Крънджилица",
  "Крънча",
  "Кръстатица",
  "Кръстевич",
  "Кръстеняците",
  "Кръстец",
  "Кръстилци",
  "Кръстина",
  "Кръшно",
  "Кубадин",
  "Кубрат",
  "Кубратово",
  "Куделин",
  "Кузьово",
  "Куклен",
  "Кукля",
  "Кукорево",
  "Кукувица",
  "Кукурахцево",
  "Кукуряк",
  "Кула",
  "Кулата",
  "Кулина вода",
  "Куманите",
  "Куманово",
  "Кундево",
  "Кунино",
  "Купен",
  "Купен",
  "Купците",
  "Курново",
  "Куртово",
  "Куртово Конаре",
  "Кутела",
  "Кутловица",
  "Кутугерци",
  "Куцаровци",
  "Куцина",
  "Куцово",
  "Кушла",
  "Къклица",
  "Къкрина",
  "Кълново",
  "Кънчево",
  "Къпинец",
  "Къпиново",
  "Къпиновци",
  "Кърджали",
  "Кърналово",
  "Кърланово",
  "Кърнаре",
  "Кърпачево",
  "Кърпелево",
  "Къртипъня",
  "Къртожабене",
  "Кърчовско",
  "Кършалево",
  "Късак",
  "Кътина",
  "Къшин",
  "Къшле",
  "Кьолмен",
  "Кьосево",
  "Кьосевци",
  "Кюлевча",
  "Кюстендил",
  "Конска",
  "Кръстава",
  "Калипетрово",
  "Еленка",
  "Кремен",
  "Коньовец",
  "Костадинкино",
  "Кленовик",
  "Климентово",
  "Кошарите",
  "Кутово",
  "Крайна",
  "Крайна",
  "Лагерите",
  "Лагошевци",
  "Ладарево",
  "Лазарово",
  "Торос",
  "Лазарци",
  "Лакатник",
  "Лале",
  "Лалково",
  "Ламбух",
  "Ласкар",
  "Ласкарево",
  "Латинка",
  "Лебед",
  "Лебница",
  "Лева река",
  "Левка",
  "Левочево",
  "Левски",
  "Левски",
  "Левуново",
  "Леденик",
  "Лелинци",
  "Ленище",
  "Ленково",
  "Леново",
  "Ленско",
  "Лепица",
  "Лесидрен",
  "Лесичарка",
  "Лесиче",
  "Лесичери",
  "Лесичово",
  "Леска",
  "Леска",
  "Лесковдол",
  "Лесковец",
  "Лесковец",
  "Лесковец",
  "Лясково",
  "Лесново",
  "Лесово",
  "Лесура",
  "Летница",
  "Летница",
  "Летовник",
  "Лехово",
  "Лехчево",
  "Лешко",
  "Лешниково",
  "Лешниковци",
  "Лешница",
  "Лешница",
  "Лещак",
  "Лещарка",
  "Лещен",
  "Леярово",
  "Ливада",
  "Ливаде",
  "Лик",
  "Лилеково",
  "Лилково",
  "Лиляк",
  "Лиляново",
  "Лиляч",
  "Лиляче",
  "Лимец",
  "Липен",
  "Липец",
  "Липинци",
  "Липник",
  "Липница",
  "Липница",
  "Лисец",
  "Лисец",
  "Лисец",
  "Лиси връх",
  "Лисиците",
  "Лисия",
  "Лисово",
  "Листец",
  "Листец",
  "Литаково",
  "Лобош",
  "Ловец",
  "Ловец",
  "Ловец",
  "Ловеч",
  "Ловнидол",
  "Ловци",
  "Ловчанци",
  "Лоза",
  "Лозарево",
  "Лозево",
  "Лозен",
  "Лозен",
  "Лозен",
  "Лозен",
  "Лозенградци",
  "Лозенец",
  "Лозенец",
  "Лозенец",
  "Лозеница",
  "Лозица",
  "Лозица",
  "Лозница",
  "Лозница",
  "Лозно",
  "Локвата",
  "Локорско",
  "Лом",
  "Ломец",
  "Ломница",
  "Ломница",
  "Ломница",
  "Ломци",
  "Лопушна",
  "Лопушня",
  "Лопян",
  "Луковит",
  "Луково",
  "Луличка",
  "Лъвино",
  "Лъвово",
  "Лъга",
  "Баните",
  "Лъжница",
  "Лъка",
  "Лъка",
  "Лъкавица",
  "Лъки",
  "Лъки",
  "Любен",
  "Любен",
  "Любенец",
  "Любен Каравелово",
  "Любенова махала",
  "Любеново",
  "Любеново",
  "Любеново",
  "Любенци",
  "Любимец",
  "Любино",
  "Любичево",
  "Люблен",
  "Любница",
  "Любовище",
  "Любовка",
  "Любча",
  "Люлин",
  "Люлин",
  "Люлка",
  "Люляк",
  "Люляково",
  "Люляково",
  "Люляково",
  "Лютаджик",
  "Лютиброд",
  "Лютидол",
  "Лютово",
  "Лява река",
  "Лялинци",
  "Лясковец",
  "Лясковец",
  "Лясково",
  "Лясково",
  "Лясково",
  "Лясково",
  "Лясково",
  "Лятно",
  "Ляхово",
  "Ляхово",
  "Левци",
  "Левище",
  "Ловско",
  "Магарджица",
  "Мадан",
  "Мадан",
  "Мадара",
  "Маджаре",
  "Маджари",
  "Маджарово",
  "Маджерито",
  "Мазарачево",
  "Майор Узуново",
  "Майско",
  "Майсторово",
  "Мак",
  "Макариополско",
  "Македонци",
  "Маково",
  "Макоцево",
  "Макреш",
  "Мала Раковица",
  "Мала Фуча",
  "Мала църква",
  "Малево",
  "Малево",
  "Маленово",
  "Мали Върбовник",
  "Мали Дреновец",
  "Мали извор",
  "Малина",
  "Малина",
  "Малини",
  "Малиново",
  "Малка Арда",
  "Малка Верея",
  "Малка Желязна",
  "Малка поляна",
  "Малка Смолница",
  "Малка Черковна",
  "Малка Чинка",
  "Малки Българени",
  "Малки Воден",
  "Малки Вършец",
  "Малки Станчовци",
  "Малки Цалим",
  "Малки чифлик",
  "Малко Асеново",
  "Малко Брягово",
  "Малко Враново",
  "Малко градище",
  "Малко Дряново",
  "Малко Кадиево",
  "Малко Каменяне",
  "Малко Кирилово",
  "Малко Крушево",
  "Малко Попово",
  "Малко село",
  "Малко Тръново",
  "Малко Търново",
  "Малкоч",
  "Малко Чочовени",
  "Малко Шарково",
  "Мало Бучино",
  "Малоградец",
  "Мало Конаре",
  "Мало Крушево",
  "Мало Малово",
  "Маломир",
  "Маломир",
  "Маломирово",
  "Мало Пещене",
  "Малорад",
  "Мало село",
  "Малчика",
  "Малчовци",
  "Малчовци",
  "Малък Девесил",
  "Малък извор",
  "Малък извор",
  "Малък манастир",
  "Малък Поровец",
  "Малък Преславец",
  "Припек",
  "Малък чардак",
  "Мамарчево",
  "Манаселска река",
  "Манастир",
  "Манастир",
  "Манастир",
  "Манастирица",
  "Манастирище",
  "Манастирище",
  "Манастирско",
  "Манастирци",
  "Мандра",
  "Мандрица",
  "Маневци",
  "Маноле",
  "Манолич",
  "Манолово",
  "Манолско Конаре",
  "Маноя",
  "Манушевци",
  "Манчево",
  "Марафелци",
  "Мараш",
  "Марговци",
  "Марикостиново",
  "Маринка",
  "Мариновци",
  "Марино поле",
  "Марино поле",
  "Марица",
  "Симеоновград",
  "Марково",
  "Марково",
  "Марково",
  "Марково равнище",
  "Мартен",
  "Мартино",
  "Мартиново",
  "Марулево",
  "Маруцековци",
  "Марчево",
  "Марчино",
  "Марян",
  "Масларево",
  "Маслиново",
  "Матешовци",
  "Маточина",
  "Махалата",
  "Махалници",
  "Маца",
  "Медвен",
  "Медевци",
  "Меден бук",
  "Медени поляни",
  "Меден кладенец",
  "Медешевци",
  "Медковец",
  "Медникарово",
  "Медовене",
  "Медовец",
  "Медовина",
  "Медовница",
  "Медово",
  "Медово",
  "Медово",
  "Межда",
  "Межден",
  "Междени",
  "Мездра",
  "Мездрея",
  "Мезек",
  "Мелник",
  "Мелница",
  "Меляне",
  "Менгишево",
  "Мендово",
  "Теменуга",
  "Мененкьово",
  "Мерданя",
  "Меричлери",
  "Места",
  "Метлика",
  "Метличина",
  "Метличина",
  "Метличка",
  "Методиево",
  "Методиево",
  "Метохия",
  "Мечка",
  "Мечка",
  "Мечкарево",
  "Мечковица",
  "Мечковци",
  "Мечкул",
  "Мечово",
  "Мещица",
  "Мизия",
  "Мийковци",
  "Микре",
  "Миладиново",
  "Миладиновци",
  "Миладиновци",
  "Миладиновци",
  "Миланово",
  "Миланово",
  "Миле",
  "Милево",
  "Елхово",
  "Милевци",
  "Милино",
  "Милковица",
  "Милково",
  "Милковци",
  "Милкьовци",
  "Милославци",
  "Милчина лъка",
  "Миндя",
  "Миневци",
  "Минерални бани",
  "Минзухар",
  "Мирково",
  "Мировец",
  "Мирово",
  "Вратца",
  "Мирово",
  "Мирово",
  "Мировци",
  "Мировяне",
  "Миролюбово",
  "Мирчовци",
  "Мирянци",
  "Митино",
  "Митовска",
  "Митровци",
  "Монтана",
  "Михайлово",
  "Михайлово",
  "Михайловци",
  "Михалич",
  "Михалич",
  "Михалково",
  "Михалци",
  "Михилци",
  "Михнево",
  "Миховци",
  "Мичковци",
  "Царево",
  "Мишевско",
  "Мишеморков хан",
  "Млада гвардия",
  "Младежко",
  "Младен",
  "Младиново",
  "Младово",
  "Мламолово",
  "Млекарево",
  "Млечево",
  "Млечино",
  "Могила",
  "Могила",
  "Могила",
  "Могилец",
  "Могилите",
  "Могилица",
  "Могилище",
  "Могилово",
  "Могиляне",
  "Мокреш",
  "Мокреш",
  "Мокрище",
  "Момина клисура",
  "Момина сълза",
  "Момино",
  "Момино",
  "Момино село",
  "Момин сбор",
  "Моминско",
  "Момково",
  "Царичино",
  "Момчилград",
  "Момчилово",
  "Момчиловци",
  "Морава",
  "Моравица",
  "Моравица",
  "Моравка",
  "Моровеците",
  "Горно Черковище",
  "Мортагоново",
  "Морянци",
  "Московец",
  "Мост",
  "Мостич",
  "Мостово",
  "Мочуре",
  "Мощанец",
  "Мракетинци",
  "Мрамор",
  "Мрамор",
  "Мрамор",
  "Мраморен",
  "Мрахори",
  "Мраченик",
  "Мрежичко",
  "Мрежичко",
  "Мръзеци",
  "Мугла",
  "Музга",
  "Мулдава",
  "Рупите",
  "Мурга",
  "Мургаш",
  "Мургово",
  "Мурено",
  "Мурсалево",
  "Муртинци",
  "Мусачево",
  "Мусачево",
  "Мусево",
  "Муселиево",
  "Мусина",
  "Мусомища",
  "Мустрак",
  "Мухово",
  "Муця",
  "Мъглен",
  "Мъглене",
  "Мъглиж",
  "Мъглища",
  "Мъдрево",
  "Мъдрец",
  "Мъдрец",
  "Мъдрино",
  "Мърводол",
  "Мързян",
  "Мъртвината",
  "Мърчаево",
  "Мърчево",
  "Мътеница",
  "Малки Искър",
  "Малуша",
  "Микрево",
  "Малък дол",
  "Момин проход",
  "Малко Йонково",
  "Навъсен",
  "Надарево",
  "Надарци",
  "Надежден",
  "Преображенци",
  "Найден Герово",
  "Найденово",
  "Нане",
  "Нановица",
  "Нареченски бани",
  "Насалевци",
  "Наум",
  "Научене",
  "Нацовци",
  "Начево",
  "Небеска",
  "Невестино",
  "Невестино",
  "Невша",
  "Негован",
  "Неговановци",
  "Негованци",
  "Негушево",
  "Недан",
  "Неделево",
  "Неделино",
  "Неделище",
  "Неделкова Гращица",
  "Неделково",
  "Недоклан",
  "Недялковци",
  "Недялковци",
  "Недялско",
  "Нейково",
  "Нейково",
  "Нейкьовец",
  "Нейчовци",
  "Ненково",
  "Неново",
  "Неновци",
  "Неофит Бозвелиево",
  "Неофит Рилски",
  "Непразненци",
  "Несебър",
  "Несла",
  "Нефела",
  "Нешевци",
  "Нивянин",
  "Никачковци",
  "Николаевка",
  "Николаево",
  "Николаево",
  "Николаево",
  "Николаево",
  "Николаево",
  "Николаево",
  "Никола Козлево",
  "Николово",
  "Николово",
  "Николово",
  "Николовци",
  "Николчовци",
  "Николчовци",
  "Никопол",
  "Никудин",
  "Никюп",
  "Нисово",
  "Ничовци",
  "Нова бяла река",
  "Нова Върбовка",
  "Нова Загора",
  "Нова Камена",
  "Новаково",
  "Новаково",
  "Новаковци",
  "Нова ливада",
  "Нова Ловча",
  "Нова махала",
  "Нова махала",
  "Нова Надежда",
  "Нова Попина",
  "Новачево",
  "Новачене",
  "Новачене",
  "Нова Черна",
  "Нова Шипка",
  "Новград",
  "Стамболийски",
  "Нови пазар",
  "Нови пазар",
  "Нови пазар",
  "Нови хан",
  "Нови чифлик",
  "Ново Ботево",
  "Ново бърдо",
  "Новогорци",
  "Ново градище",
  "Ново Делчево",
  "Ново Железаре",
  "Ново Кономлади",
  "Ново Лески",
  "Ново Оряхово",
  "Ново Паничарево",
  "Новосел",
  "Новоселец",
  "Новоселище",
  "Ново село",
  "Ново село",
  "Ново село",
  "Априлци",
  "Ново село",
  "Ново село",
  "Ново село",
  "Ново село",
  "Константиново",
  "Новоселци",
  "Новоселяне",
  "Ново Ходжово",
  "Ново Янково",
  "Ноевци",
  "Ножарево",
  "Ножарово",
  "Ножерите",
  "Носеите",
  "Ночево",
  "Нюшковци",
  "Николичевци",
  "Обединение",
  "Обел",
  "Обзор",
  "Обидим",
  "Обител",
  "Обичник",
  "Обнова",
  "Оборище",
  "Оборище",
  "Обретеник",
  "Оброчище",
  "Обручище",
  "Овен",
  "Овощарци",
  "Овощна",
  "Овощник",
  "Овчага",
  "Овча могила",
  "Овчари",
  "Овчарово",
  "Овчарово",
  "Овчарово",
  "Овчарово",
  "Овчарци",
  "Овчарци",
  "Овчево",
  "Овчеполци",
  "Овчи кладенец",
  "Оглед",
  "Огнен",
  "Огняново",
  "Огняново",
  "Огняново",
  "Огняново",
  "Огоя",
  "Оградна",
  "Огражден",
  "Одоровци",
  "Одраница",
  "Одринци",
  "Одринци",
  "Одърне",
  "Одърци",
  "Озърновци",
  "Околиите",
  "Окоп",
  "Окорш",
  "Оман",
  "Омарчево",
  "Омарчево",
  "Омуртаг",
  "Оногур",
  "Опака",
  "Опан",
  "Опанец",
  "Опанец",
  "Опицвет",
  "Оплетня",
  "Опълченец",
  "Опълченско",
  "Орач",
  "Ореховица",
  "Орехово",
  "Ореш",
  "Орешак",
  "Орешак",
  "Орешари",
  "Ореше",
  "Орешене",
  "Орешене",
  "Орешец",
  "Орешец",
  "Орешец",
  "Орешино",
  "Орешец",
  "Орешник",
  "Орешница",
  "Оризаре",
  "Оризари",
  "Оризари",
  "Оризово",
  "Орлица",
  "Орлова могила",
  "Орлов дол",
  "Орловец",
  "Орлово",
  "Орлово",
  "Орловци",
  "Орляк",
  "Орляне",
  "Орсоя",
  "Орлинци",
  "Орцево",
  "Оряховец",
  "Оряховица",
  "Оряхово",
  "Оряхово",
  "Оселна",
  "Оселна",
  "Осен",
  "Осен",
  "Осен",
  "Осенарите",
  "Осенец",
  "Осеновец",
  "Осеновлаг",
  "Осеново",
  "Осеново",
  "Осетеново",
  "Осиковица",
  "Осиково",
  "Осиково",
  "Осиково",
  "Осиковска Лакавица",
  "Осина",
  "Осларка",
  "Ослен Криводол",
  "Рибарци",
  "Осмар",
  "Осоица",
  "Остра могила",
  "Остра могила",
  "Острец",
  "Остри пазлак",
  "Острица",
  "Острица",
  "Остров",
  "Островец",
  "Островица",
  "Острово",
  "Островче",
  "Острокапци",
  "Остър камък",
  "Отец Кирилово",
  "Отец Паисиево",
  "Охлювец",
  "Оходен",
  "Охрид",
  "Очиндол",
  "Ошане",
  "Ошаните",
  "Ощава",
  "Очуша",
  "Орех",
  "Павел",
  "Павел баня",
  "Павелско",
  "Павлевци",
  "Павликени",
  "Паволче",
  "Падала",
  "Падало",
  "Падеш",
  "Падина",
  "Падина",
  "Падина",
  "Падине",
  "Пазарджик",
  "Пазарци",
  "Паисиево",
  "Паисий",
  "Пайдушко",
  "Паламарца",
  "Палат",
  "Палатово",
  "Палаузово",
  "Палилула",
  "Палици",
  "Памидово",
  "Памукчии",
  "Памукчии",
  "Панагюрище",
  "Панайот Волово",
  "Панайот Хитово",
  "Панаретовци",
  "Паницово",
  "Паничарево",
  "Паничери",
  "Паничино",
  "Паничково",
  "Пановци",
  "Панчарево",
  "Панчево",
  "Папрат",
  "Папратлива",
  "Парамун",
  "Парил",
  "Партизани",
  "Партизанин",
  "Парчовци",
  "Паскалевец",
  "Паскалево",
  "Паспал",
  "Пастра",
  "Пастух",
  "Паталеница",
  "Патица",
  "Патреш",
  "Патрешко",
  "Патриарх Евтимово",
  "Пауново",
  "Пашинци",
  "Пашкул",
  "Пашови",
  "Пашово",
  "Певец",
  "Певците",
  "Пейковци",
  "Пейна",
  "Пейовци",
  "Пейчиново",
  "Пелатиково",
  "Плевун",
  "Пелин",
  "Пелишат",
  "Искър",
  "Пенковци",
  "Пенкьовци",
  "Пеньово",
  "Пепелина",
  "Пепелище",
  "Периловец",
  "Перник",
  "Перперек",
  "Перуника",
  "Перущица",
  "Песнопой",
  "Песнопой",
  "Песочница",
  "Петелово",
  "Пет кладенци",
  "Петково",
  "Петково",
  "Петковци",
  "Петковци",
  "Петко Славейков",
  "Петлешково",
  "Петлино",
  "Пет могили",
  "Пет могили",
  "Петокладенци",
  "Петревене",
  "Петрелик",
  "Петрино",
  "Петрич",
  "Петрич",
  "Петров дол",
  "Петров дол",
  "Петрово",
  "Петрово",
  "Петровци",
  "Петърница",
  "Петърч",
  "Пецовци",
  "Печеница",
  "Печинска",
  "Пешаково",
  "Пещера",
  "Пещера",
  "Пещера",
  "Генчовци",
  "Пещерна",
  "Пещерско",
  "Буково",
  "Пиперево",
  "Пиперица",
  "Пиперково",
  "Пиперков чифлик",
  "Пирамидата",
  "Пиргово",
  "Пирдоп",
  "Пирин",
  "Пиринец",
  "Пирне",
  "Писанец",
  "Писаница",
  "Писарево",
  "Писарево",
  "Писарово",
  "Писарово",
  "Писменово",
  "Питово",
  "Пишурка",
  "Пищане",
  "Пищигово",
  "Пиявец",
  "Плазище",
  "Плаково",
  "Плакудер",
  "Плана",
  "Планинец",
  "Планиница",
  "Планиница",
  "Планиново",
  "Планинци",
  "Планинци",
  "Плачидол",
  "Плачка",
  "Плачковци",
  "Плевен",
  "Пленимир",
  "Плетена",
  "Плешивец",
  "Плешинци",
  "Плиска",
  "Пловдив",
  "Пловдивци",
  "Пловка",
  "Плодовитово",
  "Плоска могила",
  "Плоски",
  "Плъзище",
  "Плъстина",
  "Победа",
  "Победа",
  "Побит камък",
  "Побит камък",
  "Побък",
  "Повалиръж",
  "Повет",
  "Подайва",
  "Подвис",
  "Подвис",
  "Подвръх",
  "Подгоре",
  "Подгорие",
  "Подгорица",
  "Подгумер",
  "Подем",
  "Подкова",
  "Подкрепа",
  "Подлес",
  "Подрумче",
  "Подслон",
  "Подслон",
  "Пожарево",
  "Пожарево",
  "Пожерник",
  "Поибрене",
  "Покрайна",
  "Покрован",
  "Покровник",
  "Полена",
  "Поленица",
  "Полетинци",
  "Полетковци",
  "Полето",
  "Поликраище",
  "Полковник Дяково",
  "Полковник Желязово",
  "Полковник Ламбриново",
  "Полковник Савово",
  "Полковник Свещарово",
  "Полковник Серафимово",
  "Полковник Таслаково",
  "Полковник Чолаково",
  "Полска Скакавица",
  "Полски Градец",
  "Полски извор",
  "Полски Сеновец",
  "Полски Тръмбеш",
  "Полско Косово",
  "Полско Пъдарево",
  "Поляна",
  "Поляна",
  "Поляна",
  "Полянец",
  "Поляново",
  "Поляново",
  "Полянци",
  "Поляците",
  "Помеждин",
  "Помен",
  "Поморие",
  "Помощица",
  "Помощник",
  "Понор",
  "Попари",
  "Попгергевци",
  "Попгригорово",
  "Попгруево",
  "Попина",
  "Попинци",
  "Попица",
  "Попкралево",
  "Поповец",
  "Поповица",
  "Попович",
  "Попово",
  "Попово",
  "Поповци",
  "Поповци",
  "Поповци",
  "Поповяне",
  "Попрайковци",
  "Попрелка",
  "Попрусаново",
  "Попрусевци",
  "Попска",
  "Попска",
  "Попско",
  "Пордим",
  "Порой",
  "Поройна",
  "Поройно",
  "Поройно",
  "Пороминово",
  "Портитовци",
  "Поручик Кърджиево",
  "Поручик Чунчево",
  "Посабина",
  "Посев",
  "Постник",
  "Потока",
  "Потоп",
  "Поточарка",
  "Поточе",
  "Поточница",
  "Поцърненци",
  "Правда",
  "Правда",
  "Правдино",
  "Правдолюб",
  "Правенци",
  "Правец",
  "Правешка Лакавица",
  "Правище",
  "Право бърдо",
  "Православ",
  "Православен",
  "Праужда",
  "Прахали",
  "Превала",
  "Преколница",
  "Прекръсте",
  "Прелез",
  "Прелом",
  "Пресека",
  "Преселенци",
  "Преселец",
  "Преселка",
  "Пресиян",
  "Велики Преслав",
  "Преславен",
  "Преславец",
  "Преславци",
  "Преспа",
  "Преспа",
  "Престой",
  "Пресяк",
  "Пресяка",
  "Прибой",
  "Прилеп",
  "Прилеп",
  "Прилепци",
  "Приморско",
  "Приморци",
  "Припек",
  "Припек",
  "Генерал Тодоров",
  "Присад",
  "Присад",
  "Присадец",
  "Приселци",
  "Приселци",
  "Присово",
  "Присойна",
  "Пристое",
  "Пробуда",
  "Провадия",
  "Проглед",
  "Продановци",
  "Продановци",
  "Проданча",
  "Пролаз",
  "Пролазница",
  "Пролез",
  "Пролеша",
  "Пролом",
  "Пропаст",
  "Просена",
  "Просеник",
  "Просечен",
  "Просторно",
  "Просторно",
  "Протопопинци",
  "Професор Златарски",
  "Професор Иширково",
  "Прохлада",
  "Проход",
  "Прохорово",
  "Пряпорец",
  "Пряпорец",
  "Птичар",
  "Птичево",
  "Пудрия",
  "Пушево",
  "Пчела",
  "Пчелари",
  "Пчеларово",
  "Пчеларово",
  "Пчелин",
  "Пчелин",
  "Пчелина",
  "Пчелино",
  "Пчелиново",
  "Пчелище",
  "Пчелник",
  "Пчелник",
  "Пчелно",
  "Пшеничево",
  "Пъдарево",
  "Пъдарино",
  "Пъдарско",
  "Пъдарци",
  "Пънчево",
  "Първан",
  "Първенец",
  "Първенец",
  "Първенци",
  "Първица",
  "Първомай",
  "Първомай",
  "Първомайци",
  "Пържиграх",
  "Пърличево",
  "Пъровци",
  "Пъртевци",
  "Пърша",
  "Пъстрен",
  "Пъстрово",
  "Пъстрогор",
  "Пъстроок",
  "Пътниково",
  "Пясъчево",
  "Побит камък",
  "Продановци",
  "Планинско",
  "Поток",
  "Палатик",
  "Пресока",
  "Пресока",
  "Паничище",
  "Прогрес",
  "Панагюрски колонии",
  "Полковник Минково",
  "Пороище",
  "Рабиша",
  "Рабово",
  "Раброво",
  "Равадиново",
  "Равда",
  "Равен",
  "Равна",
  "Равна",
  "Равна",
  "Равна гора",
  "Равна гора",
  "Равна гора",
  "Равнец",
  "Равнец",
  "Равнил",
  "Равнища",
  "Равнище",
  "Равнината",
  "Равно",
  "Равново",
  "Равногор",
  "Равно нивище",
  "Равно поле",
  "Равно село",
  "Радан войвода",
  "Раданово",
  "Раданчето",
  "Радево",
  "Радево",
  "Радевци",
  "Радецки",
  "Радибош",
  "Радиево",
  "Радилово",
  "Радинград",
  "Радино",
  "Радиново",
  "Радишево",
  "Радковци",
  "Радко Димитриево",
  "Радловци",
  "Раднево",
  "Радовене",
  "Радовец",
  "Радово",
  "Радовци",
  "Радовци",
  "Радоевци",
  "Радойново",
  "Радомир",
  "Радомирци",
  "Радотина",
  "Радуил",
  "Радуй",
  "Радуловци",
  "Радунци",
  "Раевци",
  "Разбоище",
  "Разбойна",
  "Разбойна",
  "Развигорово",
  "Разград",
  "Разград",
  "Раздел",
  "Раздел",
  "Разделна",
  "Разделна",
  "Разделци",
  "Раздол",
  "Княжевско",
  "Разлив",
  "Разлог",
  "Разсоха",
  "Райкова могила",
  "Райковци",
  "Райнино",
  "Райново",
  "Райновци",
  "Райновци",
  "Райнушковци",
  "Райово",
  "Ракево",
  "Ракиловци",
  "Ракита",
  "Ракита",
  "Ракитна",
  "Ракитница",
  "Ракитово",
  "Раклиново",
  "Раклица",
  "Раковица",
  "Раково",
  "Раково",
  "Раковски",
  "Раковски",
  "Раковски",
  "Раковсково",
  "Ралево",
  "Ралевци",
  "Ралиновци",
  "Ралица",
  "Ралица",
  "Раличево",
  "Раненци",
  "Рани лист",
  "Рани луг",
  "Расник",
  "Расово",
  "Растник",
  "Раховци",
  "Рачевци",
  "Рачовци",
  "Рашевци",
  "Рашка Гращица",
  "Рашково",
  "Рашовите",
  "Рашовица",
  "Раювци",
  "Раяновци",
  "Раяновци",
  "Раянци",
  "Ребревци",
  "Ребро",
  "Реброво",
  "Ребърково",
  "Редешковци",
  "Редина",
  "Режинци",
  "Резач",
  "Резбарци",
  "Резово",
  "Река",
  "Рекичка",
  "Рельово",
  "Репляна",
  "Реселец",
  "Ресен",
  "Ресилово",
  "Речани",
  "Речица",
  "Реяновци",
  "Рибарица",
  "Рибарица",
  "Рибен",
  "Рибен дол",
  "Рибино",
  "Рибник",
  "Рибница",
  "Рибново",
  "Ридино",
  "Ридово",
  "Рила",
  "Рилски манастир",
  "Рилци",
  "Рисиманово",
  "Ритя",
  "Риш",
  "Робово",
  "Ровина",
  "Рогач",
  "Рогачево",
  "Роглец",
  "Рогозари",
  "Рогозен",
  "Рогозина",
  "Рогозиново",
  "Рогозче",
  "Рогош",
  "Родина",
  "Родопи",
  "Родопско",
  "Рожден",
  "Рожен",
  "Роза",
  "Розино",
  "Розино",
  "Розовец",
  "Розово",
  "Розово",
  "Роман",
  "Ропот",
  "Атия",
  "Росен",
  "Росен",
  "Росен",
  "Росеново",
  "Росеново",
  "Росина",
  "Росица",
  "Росица",
  "Росица",
  "Росно",
  "Росоман",
  "Рояк",
  "Лозен",
  "Рударци",
  "Рудина",
  "Рудина",
  "Рудник",
  "Рудник",
  "Рудозем",
  "Руевци",
  "Руен",
  "Руен",
  "Руец",
  "Ружинци",
  "Ружица",
  "Ружица",
  "Руйно",
  "Руйчовци",
  "Руманя",
  "Румелия",
  "Румянцево",
  "Руня",
  "Рупките",
  "Рупци",
  "Рупци",
  "Рупча",
  "Русалина",
  "Русалско",
  "Русаля",
  "Русе",
  "Русиновци",
  "Руска Бела",
  "Русковци",
  "Русокастро",
  "Рустан",
  "Руховци",
  "Ручей",
  "Ръжавец",
  "Ръжана",
  "Раждавица",
  "Ръждак",
  "Ръжево",
  "Ръжево Конаре",
  "Ръжена",
  "Ръженово",
  "Ръжица",
  "Ръсово",
  "Рът",
  "Рътлина",
  "Ряхово",
  "Ряховците",
  "Рохлева",
  "Рязковци",
  "Ракитница",
  "Рожденско",
  "Сава",
  "Савин",
  "Савино",
  "Савойски",
  "Садина",
  "Садовец",
  "Садовик",
  "Садовица",
  "Садово",
  "Садово",
  "Садово",
  "Садово",
  "Сажденик",
  "Сакарци",
  "Саласука",
  "Салаш",
  "Салманово",
  "Самовила",
  "Самоводене",
  "Самодива",
  "Самокитка",
  "Самоков",
  "Самораново",
  "Самотино",
  "Самсиите",
  "Самуил",
  "Самуилова крепост",
  "Самуилово",
  "Самуилово",
  "Самуилово",
  "Санадиново",
  "Сандански",
  "Сандрово",
  "Сан-Стефано",
  "Сапарева баня",
  "Сапарево",
  "Сараево",
  "Саранско",
  "Саранци",
  "Сарая",
  "Сатовча",
  "Сбор",
  "Сбор",
  "Петко Каравелово",
  "Сборино",
  "Сборище",
  "Сваленик",
  "Сватбаре",
  "Свежен",
  "Света Петка",
  "Свети Никола",
  "Светлен",
  "Светлина",
  "Светлина",
  "Светлина",
  "Светля",
  "Световрачене",
  "Светослав",
  "Светослав",
  "Светославци",
  "Светулка",
  "Свещари",
  "Свидня",
  "Свиленград",
  "Свинарски дол",
  "Свирачи",
  "Свирково",
  "Свирци",
  "Свирчово",
  "Свищов",
  "Свобода",
  "Свобода",
  "Свобода",
  "Свобода",
  "Свобода",
  "Свободен",
  "Свободиново",
  "Свободица",
  "Своге",
  "Своде",
  "Севар",
  "Севдалина",
  "Северняк",
  "Северци",
  "Севлиево",
  "Седелец",
  "Седефче",
  "Седларево",
  "Седлари",
  "Седловина",
  "Седянковци",
  "Сейдол",
  "Сейковци",
  "Сеймените",
  "Секирка",
  "Секулово",
  "Селиминово",
  "Селище",
  "Селище",
  "Селищен дол",
  "Селска поляна",
  "Селце",
  "Селце",
  "Селци",
  "Селча",
  "Селянин",
  "Семерджиево",
  "Семерджиите",
  "Семерци",
  "Семковци",
  "Семчиново",
  "Сенник",
  "Сеново",
  "Сеноклас",
  "Сенокос",
  "Сенокос",
  "Септември",
  "Септемврийци",
  "Септемврийци",
  "Сеслав",
  "Сестримо",
  "Богородица",
  "Сестринско",
  "Сечен камък",
  "Сечище",
  "Сива река",
  "Сивино",
  "Сив кладенец",
  "Сигмен",
  "Силен",
  "Силистра",
  "Симеоновец",
  "Цар Симеоново",
  "Симеоново",
  "Симитли",
  "Синаговци",
  "Синапово",
  "Синдел",
  "Синделци",
  "Синеморец",
  "Сини вир",
  "Синигер",
  "Синитово",
  "Синчец",
  "Синьо бърдо",
  "Синьо камене",
  "Синя вода",
  "Сипей",
  "Сираково",
  "Сираково",
  "Сираково",
  "Сирищник",
  "Ситово",
  "Ситово",
  "Ситово",
  "Скала",
  "Скала",
  "Скалак",
  "Скалак",
  "Скалина",
  "Скалица",
  "Скалище",
  "Скална глава",
  "Скалско",
  "Скандалото",
  "Склаве",
  "Скобелево",
  "Скобелево",
  "Скобелево",
  "Скобелево",
  "Скобелево",
  "Скомля",
  "Скорците",
  "Скравена",
  "Скребатно",
  "Скрино",
  "Скриняно",
  "Скрът",
  "Скутаре",
  "Скърбино",
  "Славеево",
  "Славеево",
  "Славейно",
  "Славейково",
  "Славейково",
  "Славейково",
  "Славовица",
  "Славовица",
  "Славотин",
  "Славщица",
  "Славяни",
  "Славянин",
  "Славяново",
  "Славяново",
  "Славяново",
  "Славянци",
  "Сладка вода",
  "Сладкодум",
  "Сладун",
  "Сладък кладенец",
  "Слаковци",
  "Сламино",
  "Слана бара",
  "Сланотрън",
  "Слатина",
  "Слатина",
  "Слатина",
  "Слатина",
  "Слатино",
  "Слатино",
  "Слащен",
  "Сливак",
  "Сливарка",
  "Сливарово",
  "Сливата",
  "Сливек",
  "Сливен",
  "Сливито",
  "Сливка",
  "Сливница",
  "Сливница",
  "Сливовик",
  "Сливовица",
  "Сливовник",
  "Сливово",
  "Сливово",
  "Сливово",
  "Сливо поле",
  "Слишовци",
  "Слокощица",
  "Сломер",
  "Слънчево",
  "Слънчовец",
  "Слънчоглед",
  "Смилец",
  "Смилец",
  "Смиловци",
  "Смилян",
  "Смин",
  "Смирненски",
  "Смирненски",
  "Смирненци",
  "Смиров дол",
  "Смоличано",
  "Смолница",
  "Смолско",
  "Смолча",
  "Смолян",
  "Смоляновци",
  "Смочан",
  "Смочево",
  "Смядово",
  "Снежа",
  "Снежина",
  "Снежинка",
  "Сноп",
  "Снягово",
  "Снягово",
  "Совата",
  "Соволяно",
  "Созопол",
  "Сокол",
  "Сокол",
  "Соколаре",
  "Соколарци",
  "Соколенци",
  "Соколец",
  "Соколино",
  "Соколите",
  "Соколица",
  "Соколник",
  "Соколово",
  "Соколово",
  "Соколово",
  "Соколово",
  "Соколовци",
  "Соколско",
  "Соколяне",
  "Солари",
  "Солища",
  "Солище",
  "Солник",
  "Сомовит",
  "Сопица",
  "Сопово",
  "Сопот",
  "Сопот",
  "Сопота",
  "Сопотот",
  "Полковник Иваново",
  "Сотиря",
  "Софийци",
  "София",
  "Софрониево",
  "Модрен",
  "Спанци",
  "Спанчевци",
  "Спасово",
  "Спасово",
  "Спасовци",
  "Спатово",
  "Спахиево",
  "Сполука",
  "Срацимир",
  "Срацимирово",
  "Сребриново",
  "Сребърна",
  "Средец",
  "Средец",
  "Средина",
  "Срединка",
  "Средище",
  "Средковец",
  "Средна махала",
  "Среднево",
  "Средни колиби",
  "Средни рът",
  "Средногорово",
  "Средногорци",
  "Средно градище",
  "Средно село",
  "Средно село",
  "Средня",
  "Средогрив",
  "Средок",
  "Средорек",
  "Средорек",
  "Средоселци",
  "Средска",
  "Средско",
  "Срем",
  "Срънско",
  "Ставерци",
  "Стаевци",
  "Стайновци",
  "Стайчин дол",
  "Стайчовци",
  "Стакевци",
  "Сталево",
  "Сталийска махала",
  "Стамболийски",
  "Стамболово",
  "Стамболово",
  "Стамболово",
  "Хан Аспарухово",
  "Стан",
  "Станево",
  "Станец",
  "Станинци",
  "Дупница",
  "Логодаж",
  "Становец",
  "Станча",
  "Станчов хан",
  "Станьовци",
  "Станянци",
  "Стара Загора",
  "Стара река",
  "Стара река",
  "Стара речка",
  "Старейшино",
  "Старилковци",
  "Стари чал",
  "Старово",
  "Старо Железаре",
  "Старозагорски бани",
  "Старо място",
  "Старо Оряхово",
  "Старопатица",
  "Старосел",
  "Староселец",
  "Старо селище",
  "Старо село",
  "Старо село",
  "Старо село",
  "Старо село",
  "Староселци",
  "Старцево",
  "Старчево",
  "Стар читак",
  "Старчище",
  "Стеврек",
  "Стежерово",
  "Стенско",
  "Стефан Караджа",
  "Стефан Караджа",
  "Стефан Караджа",
  "Стефан Караджово",
  "Стефаново",
  "Стефаново",
  "Стефаново",
  "Стефаново",
  "Стикъл",
  "Стоб",
  "Стоево",
  "Стоевци",
  "Стожа",
  "Стожер",
  "Стоил войвода",
  "Стоилово",
  "Стойките",
  "Стойково",
  "Стойново",
  "Стойчевци",
  "Стойчовци",
  "Стоките",
  "Столетово",
  "Столетово",
  "Столник",
  "Столът",
  "Стоманево",
  "Стоманеците",
  "Стоманци",
  "Стоян-Заимово",
  "Стоян Михайловски",
  "Стояново",
  "Радювене",
  "Стояново",
  "Стояновци",
  "Стояновци",
  "Страдалово",
  "Стража",
  "Стража",
  "Стражата",
  "Стражевци",
  "Стражица",
  "Стражица",
  "Стражница",
  "Стралджа",
  "Странджа",
  "Странджево",
  "Странско",
  "Страхил",
  "Страхил войвода",
  "Страхилица",
  "Страхилово",
  "Страцин",
  "Страшимир",
  "Страшимирово",
  "Стрезимировци",
  "Стрелец",
  "Стрелец",
  "Стрелково",
  "Стрелци",
  "Стрелци",
  "Стрелча",
  "Стремово",
  "Стремци",
  "Стрижба",
  "Строево",
  "Стройно",
  "Стройновци",
  "Струиндол",
  "Струино",
  "Струма",
  "Струмешница",
  "Струмяни",
  "Струпец",
  "Струпец",
  "Струя",
  "Стръмци",
  "Стряма",
  "Стубел",
  "Студена",
  "Студена",
  "Студена",
  "Студенец",
  "Студенец",
  "Студен извор",
  "Студеница",
  "Студен кладенец",
  "Студено буче",
  "Стъргел",
  "Стърмен",
  "Стърница",
  "Суворово",
  "Сугарево",
  "Сулица",
  "Султани",
  "Царевци",
  "Сумер",
  "Сунгурларе",
  "Сусам",
  "Суха река",
  "Суха река",
  "Сухаче",
  "Сухиндол",
  "Сухово",
  "Суходол",
  "Суходол",
  "Сухозем",
  "Сухолоевци",
  "Сухострел",
  "Сушево",
  "Сушина",
  "Сушица",
  "Сушица",
  "Събковци",
  "Съботковци",
  "Събрано",
  "Съдиево",
  "Съдиево",
  "Съдийско поле",
  "Старо село",
  "Съединение",
  "Съединение",
  "Съединение",
  "Съединение",
  "Сърнак",
  "Сърневец",
  "Сърнево",
  "Сърнево",
  "Сърнегор",
  "Сърненци",
  "Сърнец",
  "Сърнино",
  "Сърнино",
  "Сърница",
  "Сърница",
  "Сърпово",
  "Сърцево",
  "Сяново",
  "Сяровци",
  "Сярци",
  "Селановци",
  "Сини връх",
  "Стойковци",
  "Седларци",
  "Сенце",
  "Селище",
  "Сипец",
  "Сливенци",
  "Стражец",
  "Табан",
  "Табачка",
  "Табашка",
  "Таваличево",
  "Вълчаново",
  "Таймище",
  "Тамарино",
  "Смолник",
  "Татарево",
  "Татарево",
  "Татари",
  "Татково",
  "Татул",
  "Твърдинци",
  "Твърдица",
  "Твърдица",
  "Твърдица",
  "Текето",
  "Телериг",
  "Телиш",
  "Телчарка",
  "Батановци",
  "Теменуга",
  "Тенево",
  "Тепава",
  "Теплен",
  "Тервел",
  "Тервел",
  "Терзийско",
  "Терзийско",
  "Терзиите",
  "Тертер",
  "Тетевен",
  "Тетово",
  "Тешел",
  "Тешово",
  "Тикале",
  "Тимарево",
  "Тинтява",
  "Типченица",
  "Титевци",
  "Тиховец",
  "Тихомир",
  "Тихомирово",
  "Тича",
  "Тишаново",
  "Тишевица",
  "Тияновци",
  "Тлачене",
  "Тодореците",
  "Тодор Икономово",
  "Тодоричене",
  "Тодорово",
  "Тодорово",
  "Тодоровци",
  "Тодоровци",
  "Тодювци",
  "Токачка",
  "Добрич",
  "Толовица",
  "Томбето",
  "Томпсън",
  "Томчевци",
  "Тонско дабе",
  "Топола",
  "Тополи",
  "Тополи дол",
  "Тополица",
  "Тополка",
  "Тополница",
  "Тополница",
  "Тополовград",
  "Тополовец",
  "Тополово",
  "Тополово",
  "Тополчане",
  "Тополчане",
  "Тополяне",
  "Топузево",
  "Топузи",
  "Топчии",
  "Топчийско",
  "Торбалъжите",
  "Тотлебен",
  "Точилари",
  "Тошевци",
  "Траве",
  "Травник",
  "Трайково",
  "Тракиец",
  "Тракийци",
  "Тракия",
  "Трапесковци",
  "Трапище",
  "Трапоклово",
  "Требище",
  "Трекляно",
  "Трем",
  "Трескавец",
  "Триводици",
  "Тригорци",
  "Триград",
  "Три кладенци",
  "Трилистник",
  "Три могили",
  "Три могили",
  "Трифоново",
  "Троица",
  "Тросково",
  "Троян",
  "Троян",
  "Трояново",
  "Трояново",
  "Труд",
  "Трудовец",
  "Тръбач",
  "Трън",
  "Трънак",
  "Трънито",
  "Тръница",
  "Трънково",
  "Трънково",
  "Трънковци",
  "Трънчовица",
  "Тръстеник",
  "Тръстеник",
  "Тръстика",
  "Тръстиково",
  "Тръстиково",
  "Трявна",
  "Туден",
  "Тулово",
  "Тумбалово",
  "Тумбевци",
  "Турия",
  "Туркинча",
  "Туроковци",
  "Турян",
  "Тутракан",
  "Тутраканци",
  "Туховища",
  "Тученица",
  "Тушовица",
  "Тъжа",
  "Тъкач",
  "Тънки рът",
  "Тънково",
  "Тънково",
  "Тънкото",
  "Тъпчилещово",
  "Търговище",
  "Търговище",
  "Търкашени",
  "Търнава",
  "Търнава",
  "Търнак",
  "Търнене",
  "Търничени",
  "Търновлаг",
  "Търновца",
  "Търновци",
  "Търновци",
  "Търносливка",
  "Търняне",
  "Търсино",
  "Търхово",
  "Търън",
  "Тюленово",
  "Тюркмен",
  "Тютюнче",
  "Тянево",
  "Тянево",
  "Тънка бара",
  "Търна",
  "Тополовец",
  "Угледно",
  "Углярци",
  "Угорелец",
  "Угорялковци",
  "Угърчин",
  "Узово",
  "Узунджово",
  "Узуните",
  "Умаревци",
  "Урвата",
  "Уровене",
  "Уручевци",
  "Усойка",
  "Устина",
  "Устрем",
  "Устрен",
  "Ухловица",
  "Ушевци",
  "Уши",
  "Ушинци",
  "Узуново",
  "Фабрика",
  "Фазаново",
  "Факия",
  "Фатово",
  "Велислав",
  "Фелдфебел Денково",
  "Филаретово",
  "Върбица",
  "Филипово",
  "Филипово",
  "Филиповци",
  "Флорентин",
  "Фотиново",
  "Фотиново",
  "Фролош",
  "Фурен",
  "Фъргово",
  "Фърговци",
  "Фъревци",
  "Фъртуни",
  "Хаджидимитрово",
  "Хаджидимитрово",
  "Хаджидимитрово",
  "Хаджи Димитър",
  "Хаджидимово",
  "Хаджиево",
  "Хаджиите",
  "Хаджийско",
  "Хайредин",
  "Брезен",
  "Халваджийско",
  "Халовски колиби",
  "Ханово",
  "Харачерите",
  "Харваловци",
  "Харманли",
  "Хасково",
  "Хасовица",
  "Хвойна",
  "Хвостяне",
  "Хвърчил",
  "Хераково",
  "Хирево",
  "Хисар",
  "Хисаря",
  "Хитово",
  "Цар Калоян",
  "Хлевене",
  "Хлябово",
  "Ходжовци",
  "Хотанца",
  "Хотница",
  "Хотово",
  "Храбрино",
  "Храброво",
  "Храброво",
  "Храбърско",
  "Храсна",
  "Храстово",
  "Християново",
  "Христовци",
  "Христовци",
  "Христо Даново",
  "Хрищени",
  "Хромица",
  "Хубавене",
  "Хума",
  "Хухла",
  "Хъневци",
  "Хърлец",
  "Хърсово",
  "Хърсово",
  "Хърсово",
  "Горно Краище",
  "Цаконица",
  "Цалапица",
  "Цани Гинчево",
  "Цапарево",
  "Цар Асен",
  "Цар Асен",
  "Цар Асен",
  "Царацово",
  "Царева поляна",
  "Царев брод",
  "Царев дол",
  "Царевец",
  "Царевец",
  "Царевец",
  "Царевец",
  "Царевци",
  "Царимир",
  "Царино",
  "Царичина",
  "Цар Калоян",
  "Хан Крум",
  "Цар-Петрово",
  "Цар Самуил",
  "Цацаровци",
  "Цацаровци",
  "Цацаровци",
  "Цвеклювци",
  "Цветино",
  "Цветкова бара",
  "Цветница",
  "Цвятковци",
  "Цвятово",
  "Цегриловци",
  "Целина",
  "Ценино",
  "Ценович",
  "Ценово",
  "Ценово",
  "Цепераните",
  "Церетелево",
  "Церецел",
  "Церковски",
  "Церова кория",
  "Церовец",
  "Церовица",
  "Церовище",
  "Церово",
  "Церово",
  "Церово",
  "Циклово",
  "Цирка",
  "Цонево",
  "Цонковци",
  "Црешново",
  "Цръклевци",
  "Црънча",
  "Црънча",
  "Църварица",
  "Цървена ябълка",
  "Цървендол",
  "Цървеняно",
  "Цървище",
  "Църква",
  "Църквица",
  "Църквица",
  "Църквище",
  "Чавдар",
  "Чавдар",
  "Чавдарци",
  "Чавдарци",
  "Чавеи",
  "Чавка",
  "Чайка",
  "Чайка",
  "Чакаларово",
  "Чакали",
  "Чакалите",
  "Чакалци",
  "Кременец",
  "Чал",
  "Чала",
  "Чалъкови",
  "Боровица",
  "Чамла",
  "Царски извор",
  "Чарган",
  "Чарда",
  "Чеганци",
  "Чеканец",
  "Чеканец",
  "Чеканци",
  "Чеканчево",
  "Сини рид",
  "Челник",
  "Челопек",
  "Челопеч",
  "Челопечене",
  "Челюстница",
  "Чемиш",
  "Чепеларе",
  "Чепино",
  "Чепинци",
  "Чепинци",
  "Чеплетен",
  "Чепърлинци",
  "Червен",
  "Червен",
  "Червена",
  "Червена вода",
  "Червенаково",
  "Червена могила",
  "Червен брег",
  "Червен бряг",
  "Червенковци",
  "Червенци",
  "Черганово",
  "Черенча",
  "Черепово",
  "Череша",
  "Черешица",
  "Черешките",
  "Черешница",
  "Черешовица",
  "Черешово",
  "Черешово",
  "Черешово",
  "Черешовска река",
  "Черкаски",
  "Черковица",
  "Черковище",
  "Черковна",
  "Черковна",
  "Черковна",
  "Черковна",
  "Черково",
  "Черна",
  "Черна",
  "Черна вода",
  "Черна гора",
  "Черна могила",
  "Черна могила",
  "Черна нива",
  "Черна скала",
  "Чернево",
  "Черневци",
  "Черни бряг",
  "Черни Вит",
  "Черни връх",
  "Черни връх",
  "Черни връх",
  "Чернигово",
  "Черни дял",
  "Черни Осъм",
  "Черни рид",
  "Черница",
  "Черниче",
  "Черничево",
  "Черничево",
  "Черничино",
  "Черновръх",
  "Черноглавци",
  "Черногор",
  "Черногорово",
  "Черногорово",
  "Черноград",
  "Чернодъб",
  "Чернозем",
  "Черноземен",
  "Чернокапци",
  "Чернолик",
  "Черно море",
  "Черноморец",
  "Черноморци",
  "Черноок",
  "Чернооки",
  "Чернооково",
  "Чернооково",
  "Черноочене",
  "Черно поле",
  "Черньово",
  "Черньовци",
  "Честименско",
  "Четирци",
  "Чехларе",
  "Чешлянци",
  "Чешма",
  "Чешнегирово",
  "Чибаовци",
  "Чилик",
  "Чилнов",
  "Чинтулово",
  "Чипровци",
  "Чирен",
  "Чирпан",
  "Чистово",
  "Читаковци",
  "Чифлик",
  "Чифлик",
  "Чифлик",
  "Чичево",
  "Чичил",
  "Чобанка",
  "Чокманово",
  "Чокоба",
  "Чомаково",
  "Чомаковци",
  "Чорбаджийско",
  "Чорул",
  "Чубра",
  "Чубрика",
  "Чудинци",
  "Чудомир",
  "Чуйпетлово",
  "Тодорчета",
  "Чукарка",
  "Чукарово",
  "Чуката",
  "Чукилите",
  "Чуковезер",
  "Чуковец",
  "Чуковец",
  "Чуково",
  "Чуково",
  "Стражец",
  "Чупрене",
  "Чурек",
  "Чурен",
  "Чурилово",
  "Чуричени",
  "Чурка",
  "Чуруково",
  "Чучулига",
  "Чучулигово",
  "Черна Места",
  "Чолакова",
  "Чарково",
  "Червена локва",
  "Червена скала",
  "Черешка",
  "Черна гора",
  "Черник",
  "Чоба",
  "Шабла",
  "Шаново",
  "Шарани",
  "Шаренска",
  "Шарково",
  "Шатрово",
  "Шейново",
  "Шереметя",
  "Дъбовица",
  "Шиварово",
  "Шиливери",
  "Шилковци",
  "Шипикова махала",
  "Шипка",
  "Шипковица",
  "Шипково",
  "Шипок",
  "Шипот",
  "Шипочане",
  "Шипочано",
  "Шипчените",
  "Широка лъка",
  "Широка поляна",
  "Широки дол",
  "Широково",
  "Широко поле",
  "Шишенци",
  "Шишковица",
  "Шишковци",
  "Цар Шишманово",
  "Шишманово",
  "Шишманци",
  "Шияково",
  "Шкорпиловци",
  "Шодековци",
  "Алендарова",
  "Шопите",
  "Шопци",
  "Шубеци",
  "Шума",
  "Шумата",
  "Шумен",
  "Шуменци",
  "Шумнатица",
  "Шушня",
  "Шемшево",
  "Щерна",
  "Щипско",
  "Щит",
  "Щръклево",
  "Щърково",
  "Ъглен",
  "Югово",
  "Юделник",
  "Върхари",
  "Юлиево",
  "Юнак",
  "Юнаци",
  "Юнаците",
  "Юнец",
  "Юпер",
  "Юруково",
  "Юндола",
  "Ябланица",
  "Ябланица",
  "Ябланово",
  "Ябълковец",
  "Ябълково",
  "Ябълково",
  "Ябълковци",
  "Ябълчево",
  "Ябълчени",
  "Явор",
  "Яворец",
  "Яворница",
  "Яворовец",
  "Яворово",
  "Яврово",
  "Яворово",
  "Ягнило",
  "Ягода",
  "Ягодина",
  "Ягодово",
  "Ягодово",
  "Яздач",
  "Язовец",
  "Яким Груево",
  "Якимово",
  "Яковица",
  "Яково",
  "Яковци",
  "Якоруда",
  "Ялботина",
  "Ялово",
  "Ямбол",
  "Ямино",
  "Ямна",
  "Яна",
  "Янино",
  "Янково",
  "Янковци",
  "Яново",
  "Янтра",
  "Янтра",
  "Яньовец",
  "Ярджиловци",
  "Яребица",
  "Яребица",
  "Яребична",
  "Яребично",
  "Яребковица",
  "Ярловица",
  "Ярлово",
  "Ярловци",
  "Ярославци",
  "Ясен",
  "Ясен",
  "Ясените",
  "Ясенково",
  "Ясеновец",
  "Ясеново",
  "Ясеново",
  "Ясна поляна",
  "Ясно поле",
  "Ястреб",
  "Ястребино",
  "Ястребна",
  "Ястребово",
  "Ястребово",
  "Яхиново"
]