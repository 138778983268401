import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {userName: '', password: ''},
  reducers: {
    register(state, action) {
      state.userName = action.payload.userName
      state.password = action.payload.password
    },
  }
})

export const { register } = userSlice.actions
export default userSlice.reducer