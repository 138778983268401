import { appApi } from "../../api/rtk-query-init";
import { User } from "../../types";
import { CraftsManIn } from "../../types/CraftsManIn";

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    registerCraftsman: builder.mutation<{ payload: { user: User; token: string } }, CraftsManIn>({
      query: (body: CraftsManIn) => ({
        url: "craftsmen/register",
        method: "POST",
        body,
      }),
    }),
    checkEmail: builder.query<{ available: boolean }, string>({
      query: (email: string) => ({
        url: "craftsmen/check-email",
        method: "POST",
        body: { email },
      }),
    }),
  }),
});

export const { useRegisterCraftsmanMutation, useLazyCheckEmailQuery } = extendedApi;

export default extendedApi;
