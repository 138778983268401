import styled from "styled-components";
import Colors from "../utils/colors";
import { Text, Image, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../utils/useLanguage";
import footerbg from "../assets/footerbg.svg";
import logowhite from "../assets/logowhite.svg";
import Spacer from "./Spacer";
import facebook from "../assets/facebook.svg";
import instagram from "../assets/instagram.svg";
import twitter from "../assets/twitter.svg";
import footerpic from "../assets/footerpic.svg";
import { useDispatch } from "react-redux";
import { HOME_PAGE_CONTENT_INDEX } from "../utils/constants";
import { changeIndex } from "../store/homePageIndexSlice";

function Footer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = useLanguage();
  return (
    <FooterContainer>
      <FooterColumn>
        <FooterInnerContainer>
          <Column>
            <Image
              src={logowhite}
              onClick={() => navigate("/")}
              cursor={"pointer"}
              w={"311px"}
              h={"58px"}
            />
            <Spacer />
            <Text color={"white"}>
              © 2024 craft-365.com Всички права запазени
            </Text>
            <Spacer />
            <SocialButtonsRow>
              <Button
                size={"lg"}
                backgroundImage={facebook}
                backgroundSize={"100%"}
                variant={"unstyled"}
                marginRight={"20px"}
              ></Button>
              <Button
                size={"lg"}
                backgroundImage={instagram}
                backgroundSize={"100%"}
                variant={"unstyled"}
                marginRight={"20px"}
              ></Button>
              <Button
                size={"lg"}
                backgroundImage={twitter}
                backgroundSize={"100%"}
                variant={"unstyled"}
                marginRight={"20px"}
              ></Button>
            </SocialButtonsRow>
          </Column>
          <Column>
            <Text
              color={Colors.secondaryGray}
              fontSize={"18px"}
              fontWeight={"bold"}
            >
              {lang.for_clients}
            </Text>
            <Spacer />
            <ButtonLink onClick={() => navigate("/services")}>
              {lang.footer_how_it_works}
            </ButtonLink>
            <Spacer size={10} />
            <ButtonLink
              onClick={() => {
                navigate("/home");
                dispatch(changeIndex(HOME_PAGE_CONTENT_INDEX.PUBLISH));
              }}
            >
              {lang.publish_service_footer}
            </ButtonLink>
            <Spacer size={10} />
            <ButtonLink
              onClick={() => {
                navigate("/home");
                dispatch(
                  changeIndex(HOME_PAGE_CONTENT_INDEX.HOME_PAGE_CONTENT)
                );
              }}
            >
              {lang.all_services}
            </ButtonLink>
            <Spacer size={10} />
            <ButtonLink>{lang.all_articles}</ButtonLink>
          </Column>
          <Column>
            <Text
              color={Colors.secondaryGray}
              fontSize={"18px"}
              fontWeight={"bold"}
            >
              {lang.for_handymen}
            </Text>
            <Spacer />
            <ButtonLink>{lang.footer_how_it_works}</ButtonLink>
            <Spacer size={10} />
            <ButtonLink
              onClick={() => {
                navigate("/home");
              }}
            >
              {lang.publish_add_footer}
            </ButtonLink>
            <Spacer size={10} />
            <ButtonLink
              onClick={() => {
                navigate("/home");
              }}
            >
              {lang.all_adds}
            </ButtonLink>
            <Spacer size={10} />
            <ButtonLink>{lang.qna}</ButtonLink>
          </Column>
          <Column>
            <Text
              color={Colors.secondaryGray}
              fontSize={"18px"}
              fontWeight={"bold"}
            >
              {lang.about_us}
            </Text>
            <Spacer />
            <ButtonLink>{lang.our_vision}</ButtonLink>
            <Spacer size={10} />
            <ButtonLink>{lang.team}</ButtonLink>
            <Spacer size={10} />
            <ButtonLink>{lang.footer_partners}</ButtonLink>
            <Spacer size={10} />
            <ButtonLink onClick={() => navigate("/contact")}>
              {lang.footer_contacts}
            </ButtonLink>
          </Column>
        </FooterInnerContainer>
        <Image src={footerpic} position={"absolute"} bottom={"20px"} />
        <Row>
          <ButtonLink>{lang.terms_of_use}</ButtonLink>
          <Spacer horizontal size={8} />
          <Text fontSize={"18px"} color={Colors.secondaryGray}>
            {"-"}
          </Text>
          <Spacer horizontal size={8} />
          <ButtonLink>{lang.personal_data_protection}</ButtonLink>
          <Spacer horizontal size={8} />
          <Text fontSize={"18px"} color={Colors.secondaryGray}>
            {"-"}
          </Text>
          <Spacer horizontal size={8} />
          <ButtonLink>{lang.cookies_policy}</ButtonLink>
        </Row>
      </FooterColumn>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  width: 100%;
  height: 260px;
  background-image: url(${footerbg});
  display: flex;
  justify-content: center;
  background-size: cover;
`;

const FooterInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 20;
`;

const FooterColumn = styled.div`
  width: 82rem;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin-top: 40px;
`;

const ButtonLink = styled.p`
  font-size: 14px;
  font-weight: regular;
  color: ${Colors.secondaryGray};
  cursor: pointer;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify_content: flex-start;
`;

const SocialButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 5px;
  justify-content: center;
`;

export default Footer;
