import { Text, Image, Flex, Spacer, Button, Box } from "@chakra-ui/react";
import styled from "styled-components";
import { ChakraIcon } from "../../components/ChakraIcon";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../utils/colors";
import background from "../../assets/background-service.svg";
import { CraftsManOut } from "../../types/services";

const craftsManHeader = ({ craftsMan }: { craftsMan?: CraftsManOut }) => {
  if (!craftsMan) return null;

  const maxTagsToShow = 7;
  const tagsToShow = craftsMan.subCategories?.slice(0, maxTagsToShow) ?? [];
  const remainingTagsCount = (craftsMan?.subCategories?.length ?? 0) - maxTagsToShow;

  return (
    <HeaderContainer>
      <Box
        width="99.5dvw"
        maxWidth={"99.5dvw"}
        height="240px"
        backgroundImage={background}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Flex direction="row" alignItems="center" justifyContent="space-between" width="75rem">
          <Flex alignItems={"center"}>
            <ImageWrapper>
              <Image
                borderRadius="full"
                width={"5rem"}
                src={craftsMan.image}
                boxSize="5rem"
                alt="craftsMan"
              />
            </ImageWrapper>
            <Flex direction="column" alignItems="flex-start">
              <Text fontWeight="bold" fontSize={22}>
                {craftsMan.name}
              </Text>
              <Text fontSize={16} marginBottom="10px">
                {craftsMan.category}
              </Text>
              <Text fontSize={14} color={Colors.textGray} noOfLines={2}>
                {craftsMan.description}
              </Text>
              <Flex marginTop="10px" wrap="wrap">
                {tagsToShow.map((tag: string, index: number) => (
                  <Button
                    key={index}
                    size="sm"
                    variant="outline"
                    borderColor={Colors.borderGray}
                    margin="2px"
                  >
                    {tag}
                  </Button>
                ))}
                {remainingTagsCount > 0 && (
                  <Button size="sm" variant="outline" borderColor={Colors.borderGray} margin="2px">
                    +{remainingTagsCount}
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent={"center"}
            borderLeft={"1px solid gray"}
            padding={"1.1rem"}
            height={"11rem"}
          >
            <Flex direction="row" alignItems="center">
              <ChakraIcon icon={faStar} color="yellow.500" />
              <Text fontWeight="bold" fontSize={22} marginLeft="4px">
                {craftsMan.rating || 5}
              </Text>
            </Flex>
            <Text fontSize={16} color={Colors.textGray}>
              Обща оценка
            </Text>
            {craftsMan.reviews?.length ? (
              <Text cursor={"pointer"} fontSize={16} fontWeight={"bold"} color={Colors.textGray}>
                (виж {craftsMan.reviews?.length} отзива)
              </Text>
            ) : null}
          </Flex>
        </Flex>
      </Box>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  background: ${background};
`;

const ImageWrapper = styled.div`
  margin-right: 1rem;
`;

export default craftsManHeader;
