

import { Box, Text, Image, SimpleGrid, Grid, GridItem, Flex } from "@chakra-ui/react";
import Spacer from "../components/Spacer";
import styled from "styled-components";
import Colors from "../utils/colors";


function PrivacyPolicy() {


    return (
        <Container>

            <Text fontSize={28} fontWeight={"bold"}>Общи условия на сайта Maistor.bg</Text>

            <Spacer />

            <Grid
                templateColumns='repeat(10, 1fr)'

                gap={6}>

                <GridItem colSpan={10} bg="white" borderRadius={6} border={"1px solid"} borderColor={Colors.borderGray}>
                    <Box flex={1}>

                        <Box padding={6}>
                            <Text>
                                Моля прочетете Универсалните условия за използването от Ваша страна на продуктите, софтуера, услугите и уебсайтовете от групата на МAIS-
                                TOR.BG на http://maistor.bg/ и допълнителните условия за използването на сайта MAISTOR.BG намиращи се на тази страница. Универсалните
                                условия, заедно с допълнителните условия, представляват споразумение с правнообвързваща сила между Вас и MAISTOR.BG по отношение на
                                използването на услугите от Ваша страна. Важно е да отделите време да ги прочетете внимателно.
                            </Text>
                            <Spacer />
                            <Text>Допълнителни условия</Text>
                            <Spacer />
                            <Text as={"b"}>УСЛОВИЯ ЗА ПОЛЗВАНЕ НА УСЛУГАТА ЗА КОМЕНТАРИ В MAISTOR.BG</Text>
                            <Text>
                                MAISTOR.BG е новинарски сайт, предоставящ услуга за публикуване на коментари под статия. Ползването на услугата не изисква регистрация
                                за потребителите и е безплатна до три коментара за 24 часа. За публикуване на следващ коментар през този времеви период е нужно да се
                                регистрирате в сайта. За да публикувате коментар под статия в MAISTOR.BG без да сте регистриран потребител, е необходимо само да
                                въведете код за сигурност.
                            </Text>
                            <Spacer />
                            <Text>
                                Авторските права върху коментарите в Dnes.bg принадлежат на техните автори, като MAISTOR.BG си запазва правото да използва
                                публикуваните коментари в MAISTOR.BG за свои нужди, включително с рекламна или друга търговска цел.
                            </Text>
                            <Spacer />
                            <Text>
                                Публикувайки коментар в MAISTOR.BG, Вие се съгласявате, че няма да публикувате текстове с порнографски характер, подтикващи към
                                насилие, етническа и/или религиозна нетърпимост, думи с обидно съдържание и всякакви материали, които са в разрез с българското
                                законодателство (вкл. Закона за авторското право и сродните му права) и правото на Европейския съюз. Вие се съгласявате също така да не
                                публикувате каквито и да е материали или връзки с други уеб сайтове, чрез които се нарушава правото на интелектуална собственост.
                            </Text>
                            <Spacer />
                            <Text as={"b"}>
                                Потребителите на MAISTOR.BG се съгласяват, че МAISTOR.BG не носи отговорност за съдържанието и достоверността на
                                публикуваните в сайта коментари.
                            </Text>
                            <Spacer />
                            <Text>
                                MAISTOR.BG си запазва правото да изтрива коментари, които са в противоречие с настоящите Общи условия, както и коментари, които са
                                написани на латиница.
                            </Text>
                            <Spacer />
                            <Text>
                                Трябва да знаете, че IP адресите, от които са направени всички коментари, се записват и могат да бъдат използвани в определени случаи за
                                разкриване самоличността на лицата, които са публикували съответните коментари, в нарушение на настоящите Общи условия. <span style={{fontWeight:"bold"}}>MAISTOR.BG
                                си запазва правото да уведоми</span> и окаже пълно съдействие на компетентните държавни органи, включително прокуратурата, следствието и
                                полицейските органи, за всеки коментар, който нарушава законодателството на Р България и правото на Европейския съюз.
                            </Text>
                            <Spacer />
                            <Text>
                                MAISTOR.BGHе носи каквато и да е отговорност в случай на загубена информация или на невъзможност за запазване на информация,
                                въведена с коментарите на сайта.
                            </Text>
                            <Spacer />
                            <Text>
                                Публикуването на коментар в MAISTOR.BG означава, че сте запознат с настоящите Общи условия, съгласен сте с тях и се задължавате да ги
                                спазвате.
                            </Text>
                            <Spacer />
                            <Text as={"b"}>
                                Във връзка с използването на услугите на сайта МAISTOR.BG ние обработваме лични данни при условията на Политиката за защита
                                на личните данни, с която можете да се запознаете тук. Политиката е неразделна част от тези условия.
                            </Text>
                        </Box>

                    </Box>
                </GridItem>
            </Grid>
        </Container >
    );

}

const Container = styled.div`
    padding: 40px 0px 40px 0px
`;


export default PrivacyPolicy;