import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalBody,
  Button,
  ModalOverlay,
  Text,
  ModalHeader,
  Flex,
  Input,
  IconButton,
  Box,
} from "@chakra-ui/react";
import Colors from "../utils/colors";
import Spacer from "../components/Spacer";
import ImageOrVideoSelectButton from "../components/ImageOrVideoSelectButton";
import { UploadedContent } from "../components/UploadedContent";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useRequestCategoriesQuery } from "../api/common/Categories";
import CategoryDropDownComponent, {
  CategoryDropDownStyleVariant,
} from "../home/components/CategoryDropDownComponent";
import { useUploadContent } from "../hooks/useUploadContent";
import { useLanguage } from "../utils/useLanguage";
import { PublishedServiceRequest } from "../types/PublishedServiceRequest";
import { useEditServiceRequestMutation } from "./api/servicesRequests";
import { OutputFormat, setDefaults } from "react-geocode";
import RichTextEditor from "../components/RichTextEditor";
import CityDropDownComponent from "../components/CityDropdownComponent";

export interface EditServiceRequestDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceRequest?: PublishedServiceRequest;
}

setDefaults({
  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  language: "bg",
  region: "bg",
  outputFormat: OutputFormat.JSON,
});

function EditServiceRequestDetailsModal({
  isOpen,
  onClose,
  serviceRequest,
}: EditServiceRequestDetailsModalProps) {
  const lang = useLanguage();
  const navigate = useNavigate();

  const [selected, setSelected] = useState("");

  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    serviceRequest?.category.id ?? null
  );
  const [selectedSubCategories, setSelectedSubcategories] = useState<string[]>(
    serviceRequest?.subCategories?.map((s) => s.id) ?? []
  );
  const [title, setTitle] = useState(serviceRequest?.title ?? "");
  const [description, setDescription] = useState(serviceRequest?.description ?? "");
  const [city, setCity] = useState(serviceRequest?.city ?? "");
  const [location, setLocation] = useState(serviceRequest?.location);

  const { data: categories } = useRequestCategoriesQuery();
  const [editServiceRequest] = useEditServiceRequestMutation();

  const { images, videos, uploadImage, uploadVideo, removeImage, removeVideo } = useUploadContent(
    serviceRequest?.images,
    serviceRequest?.videos
  );
  const publishService = useCallback(() => {
    editServiceRequest({
      id: serviceRequest?.id ?? "",
      body: {
        images,
        videos,
        title,
        description,
        city,
        categoryId: selectedCategoryId ?? "",
        subCategories: selectedSubCategories,
        location,
      },
    })
      .unwrap()
      .then((res) => navigate(`/service-request/${res.id}`))
      .catch((e) => {
        console.error(e);
        alert(e.data.error);
      });
  }, [
    city,
    description,
    editServiceRequest,
    images,
    location,
    navigate,
    selectedCategoryId,
    selectedSubCategories,
    serviceRequest?.id,
    title,
    videos,
  ]);

  useEffect(() => {
    if (serviceRequest?.title) {
      setTitle(serviceRequest?.title);
    }
    if (serviceRequest?.description) {
      setDescription(serviceRequest?.description);
    }
    if (serviceRequest?.city) {
      setCity(serviceRequest?.city);
    }
    if (serviceRequest?.category.id) {
      setSelectedCategoryId(serviceRequest?.category.id);
    }
    if (serviceRequest?.subCategories) {
      setSelectedSubcategories(serviceRequest?.subCategories?.map((s) => s.id) ?? []);
    }
  }, [
    serviceRequest?.category.id,
    serviceRequest?.city,
    serviceRequest?.description,
    serviceRequest?.subCategories,
    serviceRequest?.title,
  ]);

  if (!serviceRequest) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered variant={"authDialog"}>
      <ModalOverlay />
      <ModalContent backgroundColor={Colors.lightGray} w="828px" minW="828px">
        <IconButton
          aria-label="Close video player"
          icon={<FaTimes />}
          onClick={onClose}
          position="absolute"
          top="20px"
          right="20px"
          zIndex={1}
          background="rgba(1, 1, 1, 0.2)"
          _hover={{ background: "rgba(0, 255, 0, 0.4)" }}
          size="sm"
          color="white"
        />
        <ModalHeader backgroundColor={Colors.lightGray} borderTopRadius={"25px"}>
          <Flex w={"100%"} alignItems={"center"} justifyContent={"center"}>
            <Text fontSize={"24px"} fontFamily={"Inter"} as={"b"} color={Colors.textGray}>
              Редактиране на заявка за услуга
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Box flex={1}>
            <Box padding={4}>
              <Text as={"b"} fontSize={12} ml={2} fontFamily={"Inter"} color={"black"}>
                {lang.title + "*"}
              </Text>
              <Input
                value={title}
                placeholder={lang.publish_title_placeholder}
                border={"1px solid"}
                borderColor={Colors.textGray}
                borderRadius={"50px"}
                size="lg"
                paddingLeft={"30px"}
                backgroundColor={"white"}
                onChange={(e) => setTitle(e.target.value)}
              />
              <Spacer />
              <Text as={"b"} fontSize={12} ml={2} fontFamily={"Inter"} color={"black"}>
                {lang.categories + "*"}
              </Text>
              <Flex flex={"1 1 0%"} flexWrap={"wrap"}>
                {categories?.map((category, index) => (
                  <Box width={"50%"} paddingLeft={index % 2 !== 0 ? "10px" : "0px"} key={category.id}>
                    <CategoryDropDownComponent
                      variant={CategoryDropDownStyleVariant.PUBLISH_PAGE_VARIANT}
                      category={category}
                      selectedSubCategoryIds={selectedSubCategories}
                      onSelected={(categoryid, subcategoryid, checked) => {
                        setSelectedCategoryId(categoryid);
                        if (checked) {
                          setSelectedSubcategories([...selectedSubCategories, subcategoryid]);
                        } else {
                          if (selectedSubCategories.length === 1) {
                            setSelectedCategoryId(null);
                          }
                          setSelectedSubcategories(selectedSubCategories.filter((t) => t !== subcategoryid));
                        }
                      }}
                      onClick={(text, isSelected) => setSelected(isSelected ? "" : text)}
                      disabled={selectedCategoryId !== category.id && selectedCategoryId !== null}
                      isSelected={selected === category.name}
                    />
                  </Box>
                ))}
              </Flex>
              <Spacer />
              <Flex flexDir={"row"} justifyContent={"space-between"} w={"100%"}>
                <Flex flexDir={"column"} w={"100%"}>
                  <Text as={"b"} fontSize={12} fontFamily={"Inter"} color={"black"}>
                    {lang.city + "*"}
                  </Text>
                  <CityDropDownComponent
                    variant={CategoryDropDownStyleVariant.PUBLISH_PAGE_VARIANT}
                    isExpanded={selected === "city"}
                    city={city}
                    onExpand={() => {
                      setSelected(selected === "city" ? "" : "city");
                    }}
                    onSelectCity={(city) => setCity(city)}
                    onSelectLocation={(location) => setLocation(location)}
                  />
                </Flex>
              </Flex>
              <Spacer />
              <Text as={"b"} fontSize={12} fontFamily={"Inter"} ml={2} color={"black"}>
                {lang.description + "*"}
              </Text>
              <Flex
                flexDirection="column"
                border={"1px solid"}
                borderColor={Colors.textGray}
                borderRadius={8}
                backgroundColor={"white"}
              >
                <RichTextEditor
                  value={description}
                  onChange={(description: string) => setDescription(description)}
                />
                <Box width={"100%"} height={"1px"} marginBottom={1} backgroundColor={Colors.textGray} />
                <UploadedContent
                  images={images}
                  videos={videos}
                  onRemoveImage={removeImage}
                  onRemoveVideo={removeVideo}
                />
              </Flex>
              <Spacer />
              <Flex justifyContent={"space-between"}>
                <ImageOrVideoSelectButton onContent={(files) => files.forEach(uploadImage)} />
                <ImageOrVideoSelectButton isVideo={true} onContent={(files) => files.forEach(uploadVideo)} />
              </Flex>
              <Spacer />
              <Flex width={"100%"} alignItems={"center"} justifyContent={"flex-end"}>
                <Button size="md" height="40px" w={"200px"} colorScheme="green" onClick={publishService}>
                  Редактирай
                </Button>
              </Flex>
              <Spacer />
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default EditServiceRequestDetailsModal;
