import { useAuth } from "../utils/AuthProvider";
import HomeActionButtonsHeader from "./components/HomeActionButtonsHeader";
import HomePageNotLoggedInHeader from "./components/HomePageNotLoggedInHeader";

export const TopBar = () => {
  const user = useAuth();

  return user.user ? (
    <HomeActionButtonsHeader />
  ) : (
    <HomePageNotLoggedInHeader />
  );
};
