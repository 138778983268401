import { Box, Text, Image, Flex, Icon, VStack } from "@chakra-ui/react";
import { FaStar } from "react-icons/fa";
import Colors from "../../utils/colors";
import { ReviewOut } from "../../types/services";
import { timeAgo } from "../../store/utils";

const ReviewCard = ({ review }: { review: ReviewOut }) => {
  if (!review) return null;

  return (
    <Box borderRadius="md" padding="16px" backgroundColor={Colors.lightGray} width="100%" marginBottom="16px">
      <Flex alignItems="center" marginBottom="8px">
        <Image
          borderRadius="full"
          src={review?.reviewer?.image}
          boxSize="3rem"
          alt={review.reviewer.firstName}
          marginRight="8px"
        />
        <Flex direction="column">
          <Text fontWeight="bold" color="gray.700">
            {review.reviewer.firstName} {review.reviewer.lastName}
          </Text>
          <Flex alignItems="center">
            <Icon as={FaStar} color="yellow.500" marginRight="4px" />
            <Text fontWeight="bold" fontSize="16px" marginRight="8px">
              {review.rating}
            </Text>
            <Text color="gray.500" fontSize="14px">
              {timeAgo(review.createdAt)}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Text color="gray.700" fontSize="14px" fontStyle={"italic"}>
        {review.comment}
      </Text>
    </Box>
  );
};

const ReviewsList = ({ reviews }: any) => {
  if (!reviews) return null;

  return (
    <Box width="100%" maxW="600px" margin="40px auto">
      <Text fontWeight="bold" fontSize="22px" marginBottom="16px" color="black">
        {`Отзиви (${reviews.length})`}
      </Text>
      <VStack spacing="16px">
        {reviews.slice(0, 3).map((review: ReviewOut, index: number) => (
          <ReviewCard key={index} review={review} />
        ))}
      </VStack>
    </Box>
  );
};

export default ReviewsList;
