import { Grid, GridItem, Flex } from "@chakra-ui/react";
import styled from "styled-components";
import Colors from "../utils/colors";
import { useNavigate, useParams } from "react-router-dom";
import ImportantNote from "./components/ImportantNote";
import { useRequestServiceRequestDetailQuery, useRequestServiceRequestsQuery } from "./api/servicesRequests";
import ServiceRequestDescription from "./components/ServiceRequestDescription";
import { useMemo } from "react";
import MoreServiceRequests from "./components/MoreServiceRequests";
import SimilarServiceRequests from "./components/SimilarServiceRequests";
import UserHeader from "./components/UserHeader";
import { useAuth } from "../utils/AuthProvider";

function ServiceRequestDetailsPage() {
  const { id } = useParams();
  const user = useAuth();
  const { data: serviceRequest } = useRequestServiceRequestDetailQuery(id ?? "");

  const { data: moreServiceRequests } = useRequestServiceRequestsQuery(
    { userId: serviceRequest?.owner?.id },
    { skip: !serviceRequest?.owner?.id }
  );

  const { data: similarServiceRequests } = useRequestServiceRequestsQuery(
    { categoryId: serviceRequest?.category?.id },
    { skip: !serviceRequest?.category?.id }
  );

  const navigate = useNavigate();

  const moreServiceRequestsFiltered = useMemo(
    () => moreServiceRequests?.items?.filter((service) => service.id !== serviceRequest?.id),
    [moreServiceRequests, serviceRequest?.id]
  );

  const similarServiceRequestsFiltered = useMemo(
    () => similarServiceRequests?.items?.filter((service) => service.id !== serviceRequest?.id),
    [similarServiceRequests, serviceRequest?.id]
  );

  return (
    <Container>
      <UserHeader user={serviceRequest?.owner} />

      <Flex direction="column" alignItems="center" bg={Colors.white} padding={0} marginTop={4}>
        <Grid templateColumns="repeat(10, 1fr)" gap={6} width="100%">
          <GridItem colSpan={3} bg="white" padding={6}>
            <ImportantNote
              onRequestClicked={() => navigate(`/service-request/${id}/apply`)}
              applyDisabled={!user?.user?.isCraftsMan || user?.user?.id === serviceRequest?.owner?.id}
            />
          </GridItem>
          {serviceRequest && (
            <GridItem colSpan={7} bg="white" padding={6}>
              <ServiceRequestDescription {...serviceRequest} />
            </GridItem>
          )}
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" gap={6} width="100%">
          <GridItem colSpan={3} />
          <GridItem mt={10} colSpan={7}>
            {moreServiceRequests && (
              <MoreServiceRequests
                services={moreServiceRequestsFiltered}
                ownerName={serviceRequest?.owner?.name ?? ""}
              />
            )}
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" gap={6} width="100%">
          <GridItem colSpan={3} />
          <GridItem mt={10} colSpan={7}>
            {similarServiceRequestsFiltered && similarServiceRequestsFiltered.length > 0 && (
              <SimilarServiceRequests similarServiceRequests={similarServiceRequestsFiltered} />
            )}
          </GridItem>
        </Grid>
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

export default ServiceRequestDetailsPage;
