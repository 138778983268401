import { createSlice } from '@reduxjs/toolkit'
import { HOME_PAGE_CONTENT_INDEX } from '../utils/constants'

const homePageIndexSlice = createSlice({
    name: 'homePageIndex',
    initialState: { index: HOME_PAGE_CONTENT_INDEX.HOME_PAGE_CONTENT },
    reducers: {
        changeIndex(state, action) {
            state.index = action.payload
        },
    }
})

export const { changeIndex } = homePageIndexSlice.actions
export default homePageIndexSlice.reducer