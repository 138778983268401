import { Box, Input, Flex, Text } from "@chakra-ui/react";
import { useState, useEffect, useCallback } from "react";
import CityDropDownComponent from "../../components/CityDropdownComponent";
import ImageOrVideoSelectButton from "../../components/ImageOrVideoSelectButton";
import { UploadedContent } from "../../components/UploadedContent";
import CategoryDropDownComponent, {
  CategoryDropDownStyleVariant,
} from "../../home/components/CategoryDropDownComponent";
import Colors from "../../utils/colors";
import { useLanguage } from "../../utils/useLanguage";
import { useRequestCategoriesQuery } from "../../api/common/Categories";
import { useUploadContent } from "../../hooks/useUploadContent";
import { ServiceIn } from "../../types/ServiceIn";
import { deepEqual } from "../../store/utils";
import RichTextEditor from "../../components/RichTextEditor";

export const PublishServiceForm = ({
  service,
  setService,
  errors,
}: {
  service: ServiceIn;
  setService: (setService: any) => void;
  errors: { [key: string]: string };
}) => {
  const [expandedDropDown, setExpandedDropDown] = useState<string | null>(null);

  const lang = useLanguage();
  const { data: categories } = useRequestCategoriesQuery();
  const {
    images: updatedImages,
    videos: updatedVideos,
    uploadImage,
    uploadVideo,
    removeImage,
    removeVideo,
  } = useUploadContent(service.images, service.videos);

  const setServiceData = useCallback(
    (key: keyof ServiceIn, value: ServiceIn[keyof ServiceIn]) => {
      setService((service: ServiceIn) => ({ ...service, [key]: value }));
    },
    [setService]
  );

  useEffect(() => {
    if (!deepEqual(updatedImages, service.images)) {
      setServiceData("images", updatedImages);
    }
  }, [service.images, setServiceData, updatedImages]);

  useEffect(() => {
    if (!deepEqual(updatedVideos, service.videos)) {
      setServiceData("videos", updatedVideos);
    }
  }, [service.videos, setServiceData, updatedVideos]);

  return (
    <Box flex={1}>
      <Box padding={6}>
        <Text as={"b"} fontSize={12} fontFamily={"Inter"} color={"black"}>
          {lang.title + "*"}
        </Text>
        <Input
          value={service.title}
          placeholder={lang.publish_title_placeholder}
          border={"1px solid"}
          borderColor={Colors.textGray}
          borderRadius={"50px"}
          size="lg"
          paddingLeft={"30px"}
          backgroundColor={"white"}
          onChange={(e) => setServiceData("title", e.target.value)}
        />
        {errors.title && <Text color="red.500">{errors.title}</Text>}

        <Text as={"b"} fontSize={12} fontFamily={"Inter"} color={"black"} mt={"1rem"}>
          {lang.categories + "*"}
        </Text>
        <Flex flexWrap={"wrap"}>
          {categories?.map((category, index) => (
            <Box width={"50%"} paddingLeft={index % 2 !== 0 ? "10px" : "0px"} key={category.id}>
              <CategoryDropDownComponent
                variant={CategoryDropDownStyleVariant.PUBLISH_PAGE_VARIANT}
                selectedSubCategoryIds={service.subCategoryIds ?? []}
                category={category}
                onClick={(text, isSelected) => {
                  setExpandedDropDown(isSelected ? null : text);
                }}
                disabled={service.categoryId !== category.id && service.categoryId !== undefined}
                isSelected={expandedDropDown === category.name}
                onSelected={(categoryid, subcategoryid, checked) => {
                  if (checked) {
                    setServiceData("categoryId", categoryid);
                    setServiceData("subCategoryIds", [...(service.subCategoryIds ?? []), subcategoryid]);
                  } else {
                    if ((service.subCategoryIds ?? []).length === 1) {
                      setServiceData("categoryId", undefined);
                    }
                    setServiceData(
                      "subCategoryIds",
                      (service.subCategoryIds ?? []).filter((id) => id !== subcategoryid)
                    );
                  }
                }}
              />
            </Box>
          ))}
        </Flex>
        {errors.category && <Text color="red.500">{errors.category}</Text>}

        <Text as={"b"} fontSize={12} fontFamily={"Inter"} color={"black"}>
          {lang.city + "*"}
        </Text>
        <CityDropDownComponent
          variant={CategoryDropDownStyleVariant.PUBLISH_PAGE_VARIANT}
          isExpanded={expandedDropDown === "city"}
          city={service.city}
          onExpand={() => {
            setExpandedDropDown(expandedDropDown === "city" ? null : "city");
          }}
          onSelectCity={(city) => setServiceData("city", city)}
          onSelectLocation={(location) => setServiceData("location", location)}
        />
        {errors.city && <Text color="red.500">{errors.city}</Text>}

        <Text as={"b"} fontSize={12} fontFamily={"Inter"} color={"black"}>
          {lang.description + "*"}
        </Text>
        <RichTextEditor
          value={service.description}
          onChange={(description) => setServiceData("description", description)}
        />
        {errors.description && <Text color="red.500">{errors.description}</Text>}

        <UploadedContent
          images={service.images}
          videos={service.videos}
          onRemoveImage={removeImage}
          onRemoveVideo={removeVideo}
        />

        <Flex justifyContent={"space-between"} mt={3}>
          <ImageOrVideoSelectButton onContent={(files) => files.forEach(uploadImage)} />
          <ImageOrVideoSelectButton isVideo={true} onContent={(files) => files.forEach(uploadVideo)} />
        </Flex>
      </Box>
    </Box>
  );
};
