import { Box, Flex, Textarea, Button } from "@chakra-ui/react";
import Spacer from "../components/Spacer";
import styled from "styled-components";
import Colors from "../utils/colors";
import { useState } from "react";
import RequestServiceHeader from "./components/RequestServiceHeader";
import { useApplyForServiceRequestMutation } from "./api/RequestService";
import { useParams } from "react-router-dom";

function RequestServicePage() {
  let { id } = useParams();
  const [description, setDescription] = useState("");
  const [apply] = useApplyForServiceRequestMutation();

  return (
    <Container>
      <Spacer />
      <Box w={"100%"} flex={1} padding={6}>
        <RequestServiceHeader title={""} image={""} city={""} categories={""} />
        <Box
          w={"100%"}
          bg={Colors.lightGray}
          paddingLeft={"30px"}
          paddingRight={"30px"}
          borderBottomRadius={25}
        >
          <Spacer />
          <Textarea
            w={"100%"}
            h={"26rem"}
            resize={"none"}
            placeholder={
              "Въведи детайлно описание на дейностите, които могат да бъдат извършени по тази обява. Сподели информация за професионален опит и методи на работа."
            }
            border={"1px solid"}
            borderColor={Colors.textGray}
            borderRadius={10}
            backgroundColor={"white"}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Spacer />
          <Flex width={"100%"} alignItems={"center"} justifyContent={"flex-end"}>
            <Button
              size="md"
              height="40px"
              w={"200px"}
              colorScheme="green"
              onClick={() => {
                if (id && description) {
                  apply({
                    serviceRequestId: id!!,
                    applicationDescription: description,
                  });
                } else {
                  alert("id or description is empty");
                }
              }}
            >
              Заяви услуга
            </Button>
          </Flex>
          <Spacer />
        </Box>
      </Box>
    </Container>
  );
}

const Container = styled.div`
  margin: 0px 0px 40px 0px;
  width: 70%;
  background-color: "white";
  max-height: 885px;
  height: 885px;
`;

export default RequestServicePage;
