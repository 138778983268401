const Colors = {
  white: "#ffffff",
  borderGray: "#d7dee3",
  textGray: "#565765",
  blue: "#0f7ad5",
  buttonGreen: "#06c677",
  buttonRed: "#ff0000",
  primaryYellow: "#FBCD4D",
  primaryBlue: "#262656",
  secondaryGray: "#A0A3BD",
  dirtyWhite: "#F0F0F0",
  lightGray: "#F8F8FA",
  authButtonDark: "#06071C",
  green: "#24712C",
  textRed: "#DF4B43",
  lightBorderGray: "#E8E9EF",
  blue2: "#363680",
  lightTextGray: "#AAACC2",
  orange: "#E87535",
  purple: "#363683",
  darkPurple: "#262653",
  darkGray: "#565764",
  inputError: "red.500",
  black: "black",
};

export default Colors;
