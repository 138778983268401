import { useState } from "react";
import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import { ChakraIcon } from "./ChakraIcon";
import { HTMLInputTypeAttribute } from "react";
import Colors from "../utils/colors";
import { SingleDatepicker } from "./DatePicker";
import { format } from "date-fns";

interface InputIconProps extends InputProps {
  label?: string;
  icon?: IconDefinition;
  type?: HTMLInputTypeAttribute;
  iconPosition?: "left" | "right";
  isDateInput?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export default function InputWithIcon({
  label,
  icon,
  iconPosition = "left",
  onChange,
  type = "text",
  isDateInput = false,
  minDate,
  maxDate,
  ...props
}: InputIconProps) {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const { onClose } = useDisclosure();

  const handleDateChange = (date: Date) => {
    if ((minDate && date < minDate) || (maxDate && date > maxDate)) {
      return;
    }

    const formattedDate = format(date, "yyyy-MM-dd HH:mm");
    setSelectedDate(date);
    onChange?.({ target: { value: formattedDate } } as any);
    onClose();
  };

  return (
    <InputGroup>
      {iconPosition === "left" && icon && (
        <InputLeftElement pointerEvents="none" paddingLeft={2}>
          <ChakraIcon icon={icon} color={Colors.primaryBlue} props={{ marginTop: 2 }} />
        </InputLeftElement>
      )}

      {!isDateInput ? (
        <Input
          variant={"outline"}
          textColor={Colors.primaryBlue}
          backgroundColor={"white"}
          borderRadius={"50px"}
          height={50}
          type={type}
          placeholder={label}
          _placeholder={{ opacity: 1, color: Colors.primaryBlue }}
          focusBorderColor="#d7dee3"
          onChange={(e) => onChange?.(e)}
          {...props}
        />
      ) : (
        <Box flex={1}>
          <SingleDatepicker
            name="date-input"
            date={selectedDate}
            onDateTimeChange={handleDateChange}
            minDate={minDate}
            maxDate={maxDate}
          />
        </Box>
      )}

      {iconPosition === "right" && icon && (
        <InputRightElement pointerEvents="none" paddingRight={2}>
          <ChakraIcon icon={icon} color={Colors.primaryBlue} props={{ marginTop: 2 }} />
        </InputRightElement>
      )}
    </InputGroup>
  );
}
