import { ApplicationStatus } from "../../home/types/AppliedServiceOut";
import { ImageIn, VideoIn } from "../../types/services";
import { CraftsManOut } from "./CraftsManOut";
import { ServiceDetailsOut } from "./ServiceDetailsOut";
import { ServiceRequestDetailsOut } from "./ServiceRequestDetailsOut";
import { UserOut } from "./UserOut";

export interface ChatRoomOut {
  id: string;
  socketId?: string;
  serviceId?: string;
  serviceRequestId?: string;
  craftsManId: string;
  application: ServiceApplication;
  messages: MessageOut[];
  user: UserOut;
  craftsMan: CraftsManOut;
  service?: ServiceDetailsOut;
  serviceRequest?: ServiceRequestDetailsOut;
  status: ApplicationStatus;
  createdAt: string;
}

export interface ServiceApplication {
  id: string;
  userId: string;
  serviceId: string;
  chatRoomId: string;
  description: string;
  preferedVisitDateTime?: string;
  status: ApplicationStatus;
  images?: ImageIn[];
  videos?: VideoIn[];
  phoneNumber?: string;
  location?: Location;
}

export interface MessageOut {
  id: string;
  senderId: string;
  recipientId: string;
  content: MessageContent;
  status: string;
  chatRoomId: string;
}

export enum ContentType {
  VIDEO = "VIDEO",
  IMAGE = "IMAGE",
  TEXT = "TEXT",
}

export interface MessageContent {
  contentType: ContentType;
  contentDescription?: string;
  text?: string;
  images?: ImageIn[];
  videos?: VideoIn[];
}
