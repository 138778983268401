import { Flex, Grid, GridItem, Divider, Image, Text, TextProps } from "@chakra-ui/react";
import Spacer from "./Spacer";
import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import { ChakraIcon } from "./ChakraIcon";
import Colors from "../utils/colors";

interface IconWithTextProps {
  icon: IconDefinition;
  text: string;
  size?: number;
  color?: string;
}

function IconWithText({ icon, text, size = 20, color = Colors.borderGray }: IconWithTextProps) {
  return (
    <Grid paddingLeft={4} paddingRight={4} templateColumns="repeat(10, 1fr)" gap={3}>
      <GridItem colSpan={1}>
        <ChakraIcon icon={icon} />
      </GridItem>
      <GridItem colSpan={8}>
        <Text fontSize={14} fontFamily={"Inter"} color={Colors.textGray}>
          {text}
        </Text>
      </GridItem>
    </Grid>
  );
}

export default IconWithText;
