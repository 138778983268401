import { Box, Text, Image, Flex, Icon, SimpleGrid, Avatar } from "@chakra-ui/react";
import { FaStar } from "react-icons/fa";
import Colors from "../../utils/colors";
import { Service } from "../../types/services";
import { useNavigate } from "react-router-dom";

const ServiceCard = ({ service }: { service: Service }) => {
  const navigate = useNavigate();
  return (
    <Box
      borderRadius="md"
      overflow="hidden"
      backgroundColor="white"
      border="1px solid"
      borderColor={Colors.borderGray}
      boxShadow="md"
      cursor="pointer"
      onClick={() => navigate(`/service/${service.id}`)}
    >
      {service.image ? (
        <Image src={service.image} alt={service.title} objectFit="cover" width="100%" height="150px" />
      ) : (
        <Box
          width="100%"
          height="150px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor={Colors.lightGray}
        >
          <Icon as={FaStar} color="gray.400" boxSize="3rem" />
        </Box>
      )}
      <Box padding="12px">
        {service.craftsMan && (
          <Flex alignItems="center" marginBottom="8px">
            <Avatar src={service?.craftsMan?.image} size="sm" marginRight="8px" />
            <Text fontWeight="bold" color="gray.700">
              {service.craftsMan.name}
            </Text>
            {service.craftsMan.rating && (
              <Flex alignItems="center" marginLeft="auto">
                <Icon as={FaStar} color="yellow.500" marginRight="4px" />
                <Text fontWeight="bold" fontSize="16px">
                  {service.craftsMan.rating}
                </Text>
              </Flex>
            )}
          </Flex>
        )}
        <Text fontWeight="bold" color="gray.700" marginBottom="4px" isTruncated>
          {service.title}
        </Text>
        <Flex alignItems="center">
          <Icon as={FaStar} color="gray.400" marginRight="4px" />
          <Text color="gray.500" fontSize="14px">
            {service.city}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

const SimilarServices = ({ similarServices }: { similarServices: Service[] }) => {
  return (
    <Box width="100%" margin="0 auto">
      <Text fontSize="22px" marginBottom="16px" color="gray.600" fontWeight="200">
        Подобни услуги
      </Text>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing="16px">
        {similarServices.map((service, index) => (
          <ServiceCard key={index} service={service} />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default SimilarServices;
