import { Box, Card, Text, Image, Avatar, Flex } from "@chakra-ui/react";
import { faHelmetSafety } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { add } from "lodash";
import { useNavigate } from "react-router-dom";
import Colors from "../../utils/colors";

export interface ArticleCardProps {
  id: string;
  img: string;
  title: string;
  author: { id: string; image: string; name: string };
}

function ArticlesCard({ id, img, title, author }: ArticleCardProps) {
  const navigate = useNavigate();

  return (
    <Flex flexDir={"column"}>
      <Card
        maxW="sm"
        variant={"elevated"}
        sx={{ cursor: "pointer" }}
        onClick={() => navigate(`../articles/article/` + id)}
        key={id}
      >
        <Image height={"15rem"} borderRadius={6} objectFit="cover" src={img} alt="Article Image" />
        <Box
          bgGradient="linear(to-t, gray.800, transparent)"
          position={"absolute"}
          bottom={"0px"}
          h={"100px"}
          w={"100%"}
          borderBottomRadius={6}
        ></Box>
        <Text
          fontWeight={"bold"}
          color={"white"}
          position={"absolute"}
          bottom={"12px"}
          padding={"0px 12px 0px 12px"}
        >
          {title}
        </Text>
      </Card>
      <Flex flex="1" gap={"1"} alignItems="center" flexWrap="wrap" mt={2}>
        <Avatar size={"sm"} name={author.name} src={author.image} />
        <Text fontSize={"12px"} fontWeight={"700"} color={Colors.textGray} marginLeft={"4px"}>
          {author.name}
        </Text>
        <FontAwesomeIcon icon={faHelmetSafety} color={Colors.textGray} fontSize={"14px"} />
      </Flex>
    </Flex>
  );
}

export default ArticlesCard;
